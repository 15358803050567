import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import env from './../runtime-env';
import store from '../redux/store';
import alertsActions from '../../app/actions/alerts';
import userActions from '../../app/actions/user';

const cache = new InMemoryCache({ addTypename: false });

const httpLink = new HttpLink({ uri: env.REACT_APP_API_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const authorization = localStorage.getItem('Authorization');
  const expires = localStorage.getItem('Expires');
  const refreshToken = localStorage.getItem('RefreshToken');

  if (authorization) {
    const headers = {
      Authorization: authorization,
    };

    // set refresh token if token is expired and refresh token exists
    if (expires && new Date(expires * 1000) <= new Date()) {
      if (refreshToken) {
        headers.RefreshToken = refreshToken;
      } else {
        store.dispatch(alertsActions.pushAlert({
          type: 'error',
          content: 'alerts.sessionExpired',
        }));
        store.dispatch(userActions.signOut());
        return null;
      }
    }

    operation.setContext({ headers });
  }

  return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) => (
  forward(operation).map(response => {
    const context = operation.getContext();
    const { response: { headers } } = context;

    // update token after query
    if (headers) {
      ['Authorization', 'Expires', 'RefreshToken'].forEach((key) => {
        const header = headers.get(key);
        if (header) {
          localStorage.setItem(key, header);
        }
      });
    }

    return response;
  })
));

const client = new ApolloClient({
  cache,
  link: afterwareLink.concat(concat(authMiddleware, httpLink)),
});

export default client;
