import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '../input/Input';
import normalizePhone from '../../normalize/normalizePhone';

function Phone({ name }) {
  return (
    <Field
      name={name}
      type="tel"
      label="phone"
      component={Input}
      className="field--light"
      normalize={normalizePhone}
      required
    />
  );
}

Phone.propTypes = {
  name: PropTypes.string,
};
Phone.defaultProps = {
  name: 'phone',
};

export default Phone;
