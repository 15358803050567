import React from 'react';
import PropTypes from 'prop-types';
import Radio from '../radio/Radio';

const mandateType = { veterinarian: 'veterinarian', technician: 'technician' };

function MandateType({ name, disabled }) {
  return (
    <div className="radio-inline">
      { Object.entries(mandateType).map(([label, value]) =>
        <Radio
          key={value}
          name={name}
          component="input"
          value={value}
          label={`addMandate.${label}`}
          type="radio"
          disabled={disabled}
        />,
      )}
    </div>
  );
}

MandateType.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
};
MandateType.defaultProps = {
  disabled: false,
  name: 'mandateType',
};

export default MandateType;
