export default {
  fetchLanguages: `
    query {
      languages {
        id
        key
        name
      }
    }
  `,
};
