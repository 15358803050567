// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// components
import CallToAllCardHeader from './parts/CallToAllCardHeader';
import CallToAllReviewForm from './parts/callToAlls/CallToAllReviewForm';
import ClinicInfo from './parts/ClinicInfo';

class CallToAllCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: true,
    };
    this.toggleMessage = this.toggleMessage.bind(this);
  }
  toggleMessage() {
    this.setState(({ showMessage }) => ({
      showMessage: !showMessage,
    }));
  }
  render() {
    const { showMessage } = this.state;
    const {
      callToAll,
      mandate,
      role,
    } = this.props;

    const { clinic } = mandate;
    const message = callToAll.callToAll.message || clinic.message;

    const substituteRole = role === 'substitute';
    return (
      <div
        className={classNames('mandate-card', 'mandate-card--yellow')}
      >
        <CallToAllCardHeader />

        <div className="mandate-card__content">
          <div className="mandate-card__details">
            {message &&
              <span
                className={`mandate-card__details__${showMessage ? 'hide' : 'show'}-desc`}
                tabIndex="0"
                role="button"
                onClick={this.toggleMessage}
                onKeyPress={this.toggleMessage}
              />
            }
            <ClinicInfo
              clinic={mandate.clinic}
              mandate={mandate}
              onToggleMessage={this.toggleMessage}
              showMessage={showMessage}
              substituteRole={substituteRole}
            />
            {message &&
              <p
                className={classNames('mandate-card__details__desc', {
                  'mandate-card__details__desc--open': showMessage,
                })}
              >
                {message}
              </p>
            }
          </div>
          <div className="mandate-card__content__list">
            <CallToAllReviewForm
              callToAll={callToAll}
              mandate={mandate}
            />
          </div>
        </div>
      </div>
    );
  }
}

CallToAllCard.propTypes = {
  callToAll: PropTypes.object.isRequired,
  mandate: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};
CallToAllCard.defaultProps = {};

export default CallToAllCard;
