import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ActionCableLink } from 'graphql-ruby-client';
import store from '../redux/store';
import ActionCable from './action-cable-auth';
import env from './../runtime-env';
import types from '../../app/actions/user/types';

function createSubscriptionClient(authToken) {
  const cable = ActionCable.createConsumer(env.REACT_APP_WS_URL, authToken);
  const webSocketLink = new ActionCableLink({ cable });
  const cache = new InMemoryCache({ addTypename: false });

  store.dispatch({ type: types.SUBSCRIBE_USER, subscription: cable });

  return new ApolloClient({
    cache,
    link: webSocketLink,
  });
}

export default createSubscriptionClient;
