// libs
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import store from './config/redux/store';
import actions from './app/actions/user';
import notificationsActions from './app/actions/notifications';

// components
import App from './app/components/App';

// polyfills
import './config/polyfills/polyfills';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_RAVEN_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}

const token = localStorage.getItem('Authorization');

if (token) {
  store.dispatch(actions.validateToken()).then((data) => {
    if (data && data.validateToken.valid) {
      store.dispatch(notificationsActions.subscribeToNotifications(localStorage.getItem('Authorization')));
    } else {
      localStorage.removeItem('Authorization');
      localStorage.removeItem('Expires');
      localStorage.removeItem('RefreshToken');
    }
  });
} else {
  store.dispatch(actions.validateNoToken());
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
