import accordions from './accordions';
import alerts from './alerts';
import availabilities from './availabilities';
import calendar from './calendar';
import clinics from './clinics';
import confirm from './confirm';
import event from './event';
import form from './form';
import helpcenter from './helpcenter';
import mandates from './mandates';
import matchmaking from './matchmaking';
import metas from './metas';
import nav from './nav';
import pages from './pages';
import routes from './routes';
import timesheets from './timesheets';
import users from './users';
import validation from './validation';

export default {
  accordions,
  alerts,
  availabilities,
  calendar,
  clinics,
  confirm,
  event,
  form,
  helpcenter,
  mandates,
  matchmaking,
  metas,
  nav,
  pages,
  routes,
  timesheets,
  users,
  validation,
};
