// libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import classNames from 'classnames';
import { range, yearsRange } from '../../../../helpers/range';

// components
import AddressForm from '../../../forms/fields/general/AddressForm';
import Checkbox from '../../../forms/fields/checkbox/Checkbox';
import DisplayLanguageSubForm from './DisplayLanguageSubForm';
import DistanceRadio from '../../../forms/fields/general/DistanceRadio';
import EditPassword from '../../../user/forms/EditPassword';
import ExoticAnimalsSubForm from './ExoticAnimalsSubForm';
import FileUploader from '../../../forms/fields/file/FileUploader/FileUploader';
import Input from '../../../forms/fields/input/Input';
import LanguageSubForm from './LanguageSubForm';
import PhoneField from '../../../forms/fields/general/Phone';
import PictureUploader from '../../../forms/fields/file/PictureUploader/PictureUploader';
import RadioBoolean from '../../../forms/fields/radio/RadioBoolean';
import RadioGroup from '../../../forms/fields/radio/RadioGroup';
import Select from '../../../forms/fields/select/Select';
import SelectAside from '../../../forms/fields/select/SelectAside';
import SoftwaresSubForm from './SoftwaresSubForm';
import Textarea from '../../../forms/fields/textarea/Textarea';
import TaxNumbersSubForm from './TaxNumbersSubForm';
import ClinicUserSubForm from './ClinicUserSubForm';

function UserForm({
  account,
  accountType,
  blacklistedClinics,
  exoticAnimalSkills,
  formName,
  isAdmin,
  pictureUploader,
  profile,
  profilePicture,
  profileVoidedCheck,
  role,
  softwareOthersCheck,
  surgery,
  surgerySoftTissueOthersCheck,
  surgerySterilization,
  taxNumbers,
  updateComponent,
  uploadPicture,
  uploadPictureFileReset,
  uploadVoidedCheck,
  uploadVoidedCheckFileReset,
  voidedCheckUploader,
}, { t }) {
  const isSubstitute = role === 'substitute';

  return (
    <div>
      {!account && formName === 'createUser' &&
        <div>
          <fieldset className="side-panel__form__section">
            <div>
              <h2 className="side-panel__form__section__title">{t('form.role')} *</h2>
              <div className="fields-one-col">
                <RadioGroup
                  label="role"
                  name="role"
                  disabled={formName !== 'createUser'}
                  radioList={['substitute', 'clinic_manager']}
                />
              </div>
            </div>
          </fieldset>
          {isSubstitute && (
            <fieldset className="side-panel__form__section">
              <div>
                <h2 className="side-panel__form__section__title">{t('form.accountType')}</h2>
                <RadioGroup
                  small
                  label="accountType"
                  name="profile.accountType"
                  disabled={formName !== 'createUser'}
                  radioList={['technician', 'veterinarian']}
                />
              </div>
            </fieldset>
          )}
        </div>
      }
      {formName !== 'createUser' &&
        <fieldset className="side-panel__form__section">
          <div>
            <div className="fields-one-col">
              <Field
                name="profile.picture"
                component={PictureUploader}
                uploader={pictureUploader}
                uploadFileReset={uploadPictureFileReset}
                currentPicture={profilePicture}
                upload={uploadPicture}
                displayType="profile"
                pictureSize="medium"
                noLabel
              />
            </div>
            <div className="side-panel__form__name">
              {profile.firstName} {profile.lastName}
            </div>
          </div>
        </fieldset>
      }

      <fieldset className="side-panel__form__section side-panel__form__section--continued">
        <div>
          <h2 className="side-panel__form__section__title">{t('form.userInfo')}</h2>
          <div className="fields-two-col">
            <Field
              name="profile.firstName"
              type="text"
              label="firstName"
              component={Input}
              className="field--light"
              required
            />
            <Field
              name="profile.lastName"
              type="text"
              label="lastName"
              component={Input}
              className="field--light"
              required
            />
          </div>
          <div className="fields-one-col">
            <PhoneField name="profile.phone" />
          </div>
          <div className="fields-one-col">
            {account && role !== 'admin' ?
              <div>
                <div className="tooltip__wrapper tooltip__wrapper--email">
                  <span className="tooltip">
                    {t('form.changeEmailInstructions')} <a href={`mailto:${process.env.REACT_APP_REGION_EMAIL}`}>{process.env.REACT_APP_REGION_EMAIL}</a>
                  </span>
                  <Field
                    name="email"
                    type="text"
                    component={Input}
                    className="field--light"
                    readOnly
                  />
                </div>
              </div>
              :
              <Field
                name="email"
                type="text"
                component={Input}
                className="field--light"
                required
              />
            }
          </div>
          { account && (
            <button
              className="form__link"
              type="button"
              onClick={() => updateComponent(EditPassword, 'editPassword', { sidebarIcon: 'profile' })}
            >
              {t('form.formActions.updatePassword')}
            </button>
          )}
        </div>
      </fieldset>

      {isSubstitute && (
        <fieldset className="side-panel__form__section side-panel__form__section--continued">
          <div>
            <h2 className="side-panel__form__section__title">{t('form.address')}</h2>
            <AddressForm subform="profile" />
          </div>
        </fieldset>
      )}

      {isSubstitute && (
        <fieldset className="side-panel__form__section side-panel__form__section--continued">
          <div>
            <h2 className="side-panel__form__section__title">{t('form.languages')}</h2>
            <FieldArray
              name="profile.languages"
              component={LanguageSubForm}
            />
          </div>
        </fieldset>
      )}

      <fieldset className="side-panel__form__section">
        <div>
          <h2 className="side-panel__form__section__title">{t('form.communicationType')}</h2>
          <div className="fields-one-col">
            <RadioGroup
              label="communicationType"
              name="profile.communicationType"
              radioList={['sms', 'email', 'both']}
            />
          </div>
        </div>
      </fieldset>
      {isSubstitute && (
        <div className={classNames({ 'side-panel__form__group': formName !== 'createUser' })}>
          <fieldset className="side-panel__form__section side-panel__form__section--continued">
            <div>
              <h2 className="side-panel__form__section__title">{t('form.professionalInfo')}</h2>
              {/* date */}
              <div className="fields-one-col">
                <Field
                  name="profile.graduation"
                  type="number"
                  label="graduation"
                  component={Select}
                  className="field--light"
                  required={account}
                >
                  <option value="">{t('form.addUser.selectGraduation')}</option>
                  {yearsRange(60).map(year =>
                    <option value={new Date(String(year)).toISOString()} key={year}>
                      {year}
                    </option>,
                  )}
                </Field>
              </div>
              {accountType === 'veterinarian' &&
                <div className="fields-one-col">
                  <Field
                    name="profile.licenceNumber"
                    type="text"
                    label="licenceNumber"
                    component={Input}
                    className="field--light"
                    required={formName === 'editAccount'}
                  />
                </div>
              }
            </div>
          </fieldset>

          <fieldset className="side-panel__form__section side-panel__form__section--continued">
            <div>
              <h2 className="side-panel__form__section__title">{t('form.skills')}</h2>

              <div>
                <Field
                  name="profile.consultation"
                  type="text"
                  label="consultation"
                  component={Checkbox}
                  className="field__checkbox--full-width field__checkbox--clustered"
                />
                <Field
                  name="profile.surgery"
                  type="text"
                  label="surgery"
                  component={Checkbox}
                  className="field__checkbox--full-width field__checkbox--clustered"
                />
              </div>
              {accountType === 'veterinarian' && surgery && (
                <div className="fields-one-col fields-one-col--conditional">
                  <div>
                    <Field
                      name="profile.gloveSize"
                      type="number"
                      label="gloveSize"
                      component={SelectAside}
                      className="field--light"
                      required
                    >
                      <option value="">{t('form.addUser.selectGloveSize')}</option>
                      {range(5, 9, 0.5).map(gloveSizeOption =>
                        <option value={gloveSizeOption} key={gloveSizeOption}>
                          {gloveSizeOption}
                        </option>,
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field
                      name="profile.surgerySterilization"
                      type="text"
                      label="surgerySterilization"
                      component={RadioBoolean}
                    />
                  </div>
                  {surgerySterilization &&
                    <div>
                      <Field
                        name="profile.surgeryCanineOvhWeightLimit"
                        type="number"
                        label="surgeryCanineOvhWeightLimit"
                        component={SelectAside}
                        className="field--light"
                        required={account}
                      >
                        <option value="" disabled>{t('form.canineMaxWeight')}</option>
                        <option value="10">{t('form.weightOptions.lb', { n: 10 })}</option>
                        <option value="30">{t('form.weightOptions.lb', { n: 30 })}</option>
                        <option value="50">{t('form.weightOptions.lb', { n: 50 })}</option>
                        <option value="51">{t('form.weightOptions.more', { n: 50 })}</option>
                      </Field>
                    </div>
                  }
                  <div>
                    <Field
                      name="profile.surgeryDeclawing"
                      type="text"
                      label="surgeryDeclawing"
                      component={RadioBoolean}
                    />
                  </div>
                  <div className="field__checkbox-list">
                    <label className="field__checkbox-list__label">{t('form.softTissue')}</label>
                    <div className="field__checkbox-list__wrapper">
                      <Field
                        name="profile.surgerySoftTissueCystotomy"
                        type="text"
                        label="surgerySoftTissueCystotomy"
                        component={Checkbox}
                      />
                      <Field
                        name="profile.surgerySoftTissueForeignBody"
                        type="text"
                        label="surgerySoftTissueForeignBody"
                        component={Checkbox}
                      />
                      <Field
                        name="profile.surgerySoftTissueMassAblation"
                        type="text"
                        label="surgerySoftTissueMassAblation"
                        component={Checkbox}
                      />
                      <Field
                        name="profile.surgerySoftTissueCaesarean"
                        type="text"
                        label="surgerySoftTissueCaesarean"
                        component={Checkbox}
                      />
                      <Field
                        name="profile.surgerySoftTissuePyometra"
                        type="text"
                        label="surgerySoftTissuePyometra"
                        component={Checkbox}
                      />
                      <div className="field__checkbox-list__other">
                        <Field
                          name="profile.surgerySoftTissueOthersCheck"
                          type="text"
                          label="surgerySoftTissueOthers"
                          component={Checkbox}
                        />
                        {surgerySoftTissueOthersCheck &&
                          <Field
                            name="profile.surgerySoftTissueOthers"
                            label="surgerySoftTissueOthers"
                            type="text"
                            component={Input}
                            className="field--light field--no-label"
                            required
                          />
                        }
                      </div>
                    </div>
                  </div>
                  <div>
                    <Field
                      name="profile.surgeryMessage"
                      label="surgeryMessage"
                      component={Textarea}
                      className="field--light"
                    />
                  </div>
                </div>
              )}

              <div className="fields-one-col">
                <div className="fields-one-col">
                  <div className="field__checkbox-list">
                    <label className="field__checkbox-list__label">{t('form.softwareSkills')}</label>
                    <div className="field__checkbox-list__wrapper">
                      <FieldArray
                        name="profile.softwares"
                        component={SoftwaresSubForm}
                      />
                      <div className="field">
                        <Field
                          name="profile.softwareOthersCheck"
                          type="text"
                          label="softwareOthers"
                          component={Checkbox}
                        />
                        {softwareOthersCheck &&
                          <div className="field__checkbox-list__other">
                            <Field
                              name="profile.softwareOthers"
                              type="text"
                              component={Input}
                              className="field--light field--no-label"
                              required
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="fields-one-col">
                <Field
                  name="profile.exoticAnimalSkills"
                  type="text"
                  label="exoticAnimalSkills"
                  component={RadioBoolean}
                />
              </div>
              { exoticAnimalSkills && (
                <div className="fields-one-col fields-one-col--conditional">
                  <FieldArray
                    name="profile.exoticAnimals"
                    component={ExoticAnimalsSubForm}
                  />
                  <Field
                    name="profile.exoticAnimalMessage"
                    label="exoticAnimalMessage"
                    component={Textarea}
                    className="field--light"
                  />
                </div>
              )}
            </div>
          </fieldset>

          <fieldset className="side-panel__form__section">
            <div>
              <h2 className="side-panel__form__section__title">{t('form.defaultDistance')}</h2>
              <div>
                <DistanceRadio name="profile.defaultDistance" />
              </div>
            </div>
          </fieldset>

          { ((accountType === 'veterinarian') ||
            (accountType === 'technician' && formName !== 'createUser')) &&
            <fieldset className="side-panel__form__section">
              <div>
                <h2 className="side-panel__form__section__title">{t('form.accounting')}</h2>
                {formName !== 'createUser' &&
                  <div className="fields-one-col">
                    <Field
                      name="profile.voidedCheck"
                      component={FileUploader}
                      uploader={voidedCheckUploader}
                      uploadFileReset={uploadVoidedCheckFileReset}
                      currentPicture={profileVoidedCheck}
                      upload={uploadVoidedCheck}
                      pictureSize="original"
                    />
                  </div>
                }
                {accountType === 'veterinarian' &&
                  <div className="fields-one-col">
                    <FieldArray
                      name="profile.taxNumbers"
                      component={TaxNumbersSubForm}
                      taxNumbers={taxNumbers}
                    />
                  </div>
                }
              </div>
            </fieldset>
          }
        </div>
      )}
      {!account && formName !== 'createUser' &&
        <fieldset className="side-panel__form__section">
          <div>
            <h2 className="side-panel__form__section__title">{t('form.role')} *</h2>
            <div className="fields-one-col">
              <RadioGroup
                label="role"
                name="role"
                disabled={formName !== 'createUser'}
                radioList={['admin', 'substitute', 'clinic_manager']}
              />
            </div>
          </div>
        </fieldset>
      }
      {!account && formName !== 'createUser' && isSubstitute &&
        <fieldset className="side-panel__form__section">
          <div>
            <h2 className="side-panel__form__section__title">{t('form.accountType')}</h2>
            <RadioGroup
              small
              label="accountType"
              name="profile.accountType"
              disabled={formName !== 'createUser'}
              radioList={['technician', 'veterinarian']}
            />
          </div>
        </fieldset>
      }

      <fieldset className="side-panel__form__section ">
        <div>
          <h2 className="side-panel__form__section__title">{t('form.displayedLanguage')}</h2>
          <div className="fields-one-col">
            <DisplayLanguageSubForm />
          </div>
        </div>
      </fieldset>
      {(isAdmin === 'admin') && (accountType === 'veterinarian' || accountType === 'technician') &&
        <fieldset className="side-panel__form__section side-panel__form__section--admin">
          <div>
            <h2 className="side-panel__form__section__title">{t('form.adminSection')}</h2>
            <div className="fields-one-col">
              <Field
                name="profile.adminNote"
                label="adminNoteSubstitute"
                component={Textarea}
                className="field--light"
              />
            </div>
          </div>
          <div>
            <h2 className="side-panel__form__section__title">{t('form.blacklistedClinics')}</h2>
            <FieldArray
              blacklistedClinics={blacklistedClinics}
              name="blacklistedClinics"
              formTitle={formName}
              component={ClinicUserSubForm}
            />
          </div>
        </fieldset>
      }
    </div>
  );
}

UserForm.contextTypes = {
  t: PropTypes.func,
};
UserForm.propTypes = {
  account: PropTypes.bool,
  accountType: PropTypes.string.isRequired,
  blacklistedClinics: PropTypes.array,
  exoticAnimalSkills: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  pictureUploader: PropTypes.object,
  profile: PropTypes.object,
  profilePicture: PropTypes.object,
  profileVoidedCheck: PropTypes.object,
  role: PropTypes.string.isRequired,
  softwareOthersCheck: PropTypes.bool,
  surgery: PropTypes.bool.isRequired,
  surgerySoftTissueOthersCheck: PropTypes.bool,
  surgerySterilization: PropTypes.bool.isRequired,
  taxNumbers: PropTypes.array,
  updateComponent: PropTypes.func,
  uploadPicture: PropTypes.object,
  uploadPictureFileReset: PropTypes.func,
  uploadVoidedCheck: PropTypes.object,
  uploadVoidedCheckFileReset: PropTypes.func,
  voidedCheckUploader: PropTypes.object,
  isAdmin: PropTypes.string.isRequired,
};
UserForm.defaultProps = {
  account: false,
  blacklistedClinics: [],
  pictureUploader: {},
  profile: {},
  profilePicture: {},
  profileVoidedCheck: {},
  softwareOthersCheck: false,
  surgerySoftTissueOthersCheck: false,
  taxNumbers: [],
  updateComponent: () => {},
  uploadPicture: {},
  uploadPictureFileReset: () => {},
  uploadVoidedCheck: {},
  uploadVoidedCheckFileReset: () => {},
  voidedCheckUploader: {},
};

function mapStateToProps(state, { formName }) {
  const selector = formValueSelector(formName);
  return {
    accountType: selector(state, 'profile.accountType') || '',
    exoticAnimalSkills: selector(state, 'profile.exoticAnimalSkills') === 'true' || false,
    profile: selector(state, 'profile') || '',
    role: selector(state, 'role') || '',
    softwareOthersCheck: selector(state, 'profile.softwareOthersCheck') || false,
    surgerySoftTissueOthersCheck: selector(state, 'profile.surgerySoftTissueOthersCheck') || false,
    surgery: selector(state, 'profile.surgery') || false,
    surgerySterilization: selector(state, 'profile.surgerySterilization') === 'true' || false,
    taxNumbers: selector(state, 'profile.taxNumbers') || [],
    isAdmin: state.user.role || '',
  };
}

export default connect(mapStateToProps)(UserForm);
