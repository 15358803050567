import { changeHour } from './dates';
import formatSoftwares from './formatSoftwares';

export function formatClinicFormData(values, editForm = false) {
  const {
    addBillingAddress,
    adminNote,
    billingAddress,
    billingContact,
    consultationAverageDuration,
    picture,
    softwareOthersCheck,
    ...input
  } = values;

  return {
    ...input,
    phone: `+1${input.phone.replace(/[^\d]/g, '')}`,
    ...consultationAverageDuration && {
      consultationAverageDuration: parseInt(consultationAverageDuration, 10),
    },
    openingHours: input.openingHours
      .filter((openingHour) => (openingHour.opensAt !== '' && openingHour.closesAt !== ''))
      .map((openingHour) => ({
        ...openingHour,
        opensAt: changeHour(null, openingHour.opensAt).toISOString(),
        closesAt: changeHour(null, openingHour.closesAt).toISOString(),
      })),
    otherVets: input.otherVets === 'true',
    softwareOthers: softwareOthersCheck ? input.softwareOthers : null,
    softwares: formatSoftwares(input.softwares),
    softwareSkills: input.softwareSkills === 'true',
    ...(billingContact) && { billingContact },
    ...(picture && picture.id) && { picture },
    ...(adminNote !== null) && { adminNote },
    ...(editForm) ? {
      ...(billingAddress && billingAddress.address) && {
        billingAddress: {
          ...billingAddress,
          ...(addBillingAddress === 'false') && {
            destroy: true,
          },
        },
      },
    } : {
      ...(addBillingAddress && billingAddress && billingAddress.address) && {
        billingAddress,
      },
    },
  };
}

export default formatClinicFormData;
