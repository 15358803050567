// libs
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  formatTimeRange,
} from '../../../helpers/formatDates';
import { statusColor } from '../../../helpers/events';
import { matchmakingDaysRange } from '../../../helpers/range';
import AvailabilityEventTooltip from './AvailabilityEventTooltip';

class AvailabilityEvent extends React.Component {
  constructor() {
    super();
    this.state = {
      isHovered: false,
    };
  }
  handleHover() {
    const { onSetEventIdForTooltip, event } = this.props;
    this.setState(prevState => ({
      isHovered: !prevState.isHovered,
    }));
    if (this.state.isHovered === false) {
      onSetEventIdForTooltip(event.id);
    }
  }
  render() {
    const {
      activeProposal,
      event,
      count,
      event: {
        id,
        adminNote,
        consultation,
        endsAt,
        latestProposal,
        startsAt,
        surgery,
        message,
      },
      deleteEvent,
      deleteProposal,
      updateEvent,
      unselectEvent,
      filteredOut,
    } = this.props;
    const { replacement = {} } = latestProposal !== null && latestProposal;
    const { mandate = {} } = replacement;
    const {
      surgery: latestProposalSurgery,
      consultation: latestProposalConsultation,
    } = mandate;
    const showProposalInfo =
      latestProposal &&
      latestProposal.replacement &&
      !['expired', 'rejected'].includes(latestProposal.status);
    const { isHovered } = this.state;
    return (
      <div
        key={id}
        className={classNames(
        'matchmaking__event',
        {
          'matchmaking__event--disabled': !filteredOut,
          'matchmaking__mandate--start': count === 0,
          'matchmaking__mandate--end': count === matchmakingDaysRange(startsAt) - 1,
        },
      )}
        onMouseEnter={() => { this.handleHover(); }}
        onMouseLeave={() => { this.handleHover(); }}
      >
        <div>
          <div className="matchmaking__event__header">
            <div>
              {showProposalInfo ? (
                <React.Fragment>
                  {latestProposalConsultation && <span className="matchmaking__event__icon matchmaking__event__icon--consult" />}
                  {latestProposalSurgery && (
                  <span
                    className={classNames(
                        'matchmaking__event__icon',
                        {
                          'matchmaking__event__icon--surgery': latestProposalSurgery === 'true',
                          'matchmaking__event__icon--surgery matchmaking__event__icon--surgery--optional': latestProposalSurgery === 'optional',
                        },
                        )}
                  />
                )}
                </React.Fragment>
            ) : (
              <React.Fragment>
                {consultation && <span className="matchmaking__event__icon matchmaking__event__icon--consult" />}
                {surgery && <span className="matchmaking__event__icon matchmaking__event__icon--surgery-substitute" />}
              </React.Fragment>
              )
            }
            </div>
            <div className="matchmaking__event__header--centered-icons">
              { message && <span className="matchmaking__event__icon matchmaking__event__icon--message" />}
              { adminNote && <span className="matchmaking__event__icon matchmaking__event__icon--admin-note" />}
            </div>
          </div>
          <div
            className={classNames(
            'matchmaking__timerange',
            `matchmaking__timerange--${statusColor(event)}`,
            { 'matchmaking__timerange--active': activeProposal },
          )}
          >
            {activeProposal && (
            <button
              className="matchmaking__event__unselect"
              onClick={() => unselectEvent(event)}
            />
          )}
            <div>
              {showProposalInfo ?
                <span>
                  {formatTimeRange(
                  latestProposal.replacement.startsAt,
                  latestProposal.replacement.endsAt,
                  )}
                </span>
              : <span>{formatTimeRange(startsAt, endsAt)}</span>
            }
            </div>
          </div>
          {isHovered &&
          <AvailabilityEventTooltip
            deleteEvent={deleteEvent}
            deleteProposal={deleteProposal}
            event={event}
            updateEvent={updateEvent}
          />
        }
        </div>
      </div>
    );
  }
}
AvailabilityEvent.contextTypes = {
  t: PropTypes.func,
};
AvailabilityEvent.propTypes = {
  activeProposal: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  deleteProposal: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  unselectEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  onSetEventIdForTooltip: PropTypes.func.isRequired,
  filteredOut: PropTypes.bool.isRequired,
};

AvailabilityEvent.contextTypes = {
  t: PropTypes.func,
};

export default AvailabilityEvent;
