import types from '../actions/exoticAnimals/types';

const initialState = {
  exoticAnimals: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_EXOTIC_ANIMALS:
      return { ...state };
    case types.FETCH_EXOTIC_ANIMALS_SUCCESS:
      return {
        ...state,
        exoticAnimals: action.payload.exoticAnimals,
      };
    default:
  }
  return state;
}
