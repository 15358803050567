function validate(values) {
  const errors = {};

  if (!values.email) {
    errors.email = 'validation.required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'validation.invalid';
  }

  if (values.password && !values.passwordConfirmation) {
    errors.passwordConfirmation = 'validation.required';
  } else if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'validation.passwordRequirements.passwordsMustMatch';
  } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i.test(values.password)) {
    errors.password = 'validation.passwordRequirements.completeRequirements';
  }

  return errors;
}

export default validate;
