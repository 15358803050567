import React from 'react';
import classNames from 'classnames';
import dates from 'react-big-calendar/lib/utils/dates';
import { inRange } from 'react-big-calendar/lib/utils/eventLevels';
import { isSelected } from 'react-big-calendar/lib/utils/selection';

import Agenda from 'react-big-calendar/lib/Agenda';
// Styles
import './styles.scss';

class CustomAgenda extends Agenda {
  isSameDay = (d1, d2) => {
    if (d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()) {
      return true;
    }
    return false;
  };

  renderDay = (day, events, dayKey) => {
    const {
      selected,
      getters,
      accessors,
      localizer,
      components: { event: Event, date: AgendaDate },
    } = this.props;

    events = events.filter(e =>
      inRange(e, dates.startOf(day, 'day'), dates.endOf(day, 'day'), accessors),
    );
    if (events.length === 0) {
      const dateLabel = localizer.format(day, 'agendaDateFormat');
      const dayLabel = localizer.format(day, 'DD');
      return (
        <tr
          key={dayKey}
        >
          <td rowSpan="1" valign="middle" className="rbc-agenda-date-cell">
            <div className="schedule__agenda__day">{dateLabel}<span>{dayLabel}</span></div>
          </td>
          <td className="rbc-agenda-time-cell" />
          <td className="rbc-agenda-event-cell rbc-agenda-event-cell--empty" />
        </tr>
      );
    }
    return events.map((event, idx) => {
      const title = accessors.title(event);
      const end = accessors.end(event);
      const start = accessors.start(event);
      const arrayKey = `${dayKey}_${idx}`;

      const userProps = getters.eventProp(
        event,
        start,
        end,
        isSelected(event, selected),
      );

      const dateLabel = idx === 0 && localizer.format(day, 'agendaDateFormat');
      const dayLabel = idx === 0 && localizer.format(day, 'DD');
      const currentDate = new Date(day);
      const todayDate = new Date();
      const isToday = this.isSameDay(currentDate, todayDate);
      const first =
        idx === 0 ? (
          <td rowSpan={events.length} valign="middle" className="rbc-agenda-date-cell">
            {AgendaDate ? (
              <AgendaDate day={day} label={dateLabel} />
            ) : (
              <div className={classNames('schedule__agenda__day', { 'schedule__agenda__day--today': isToday })}>{dateLabel}<span>{dayLabel}</span></div>
            )}
          </td>
        ) : (
          false
        );

      return (
        <tr
          key={arrayKey}
          className={userProps.className}
          style={userProps.style}
        >
          {first}
          <td className="rbc-agenda-time-cell" />
          <td className="rbc-agenda-event-cell">
            {Event ? <Event event={event} title={title} /> : title}
          </td>
        </tr>
      );
    }, []);
  };

  render() {
    const { length, date, accessors } = this.props;
    let { events } = this.props;
    const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const end = dates.add(date, length, 'day');

    const range = dates.range(monthStart, monthEnd, 'day');

    events = events.filter(event => inRange(event, monthStart, end, accessors));

    events.sort((a, b) => +accessors.start(a) - +accessors.start(b));
    return (
      <div className="rbc-agenda-view schedule__agenda">
        <React.Fragment>
          <table ref={(c) => { this.header = c; }} className="rbc-agenda-table schedule__agenda__head">
            <thead>
              <tr>
                <th className="rbc-header" ref={(c) => { this.dateCol = c; }} />
                <th className="rbc-header" ref={(c) => { this.timeCol = c; }} />
                <th className="rbc-header" />
              </tr>
            </thead>
          </table>
          <div className="rbc-agenda-content schedule__agenda__content" ref={(c) => { this.content = c; }}>
            <table className="rbc-agenda-table">
              <tbody ref={(c) => { this.tbody = c; }}>
                {range.map((day, idx) => this.renderDay(day, events, idx))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default CustomAgenda;
