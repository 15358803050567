// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// actions
import alertsActions from '../../../actions/alerts/index';

const contextTypes = {
  t: PropTypes.func,
};

const defaultProps = {
  alertDetails: null,
};

const propTypes = {
  alertContent: PropTypes.string.isRequired,
  alertDetails: PropTypes.string,
  alertType: PropTypes.string.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

class Alert extends Component {
  componentDidMount() {
    setTimeout(this.hideAlert.bind(this), 5000);
  }

  hideAlert() {
    const { removeAlert } = this.props;
    removeAlert();
  }

  render() {
    const {
      alertContent,
      alertDetails,
      alertType,
    } = this.props;

    const { t } = this.context;

    return (
      <div className={`alert-message alert-message--${alertType}`} >
        <div>
          {t(alertContent)}
          {alertDetails && alertDetails}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts.alerts,
  };
}

Alert.contextTypes = contextTypes;
Alert.defaultProps = defaultProps;
Alert.propTypes = propTypes;

export default connect(mapStateToProps, alertsActions)(Alert);
