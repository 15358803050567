import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import '../Fields.scss';
import './Input.scss';

function Input(field, { t }) {
  const originalError = field.meta ? field.meta.error : '';
  const errorTranslation = t(originalError);
  const error = errorTranslation === originalError ? originalError : errorTranslation;
  const label = field.label ? field.label : field.input.name;

  const showError = field.meta && field.meta.touched && field.meta.error && !field.meta.disabled;
  const isDirty = (field.meta && field.meta.dirty) || (field.innerLabel && field.meta.initial);
  return (
    <div
      className={classNames(
        'field',
        field.className, {
        'field--inner': field.innerLabel,
        'field--dirty': isDirty,
        'field--error': showError,
      })}
    >
      <label>
        <input
          {...field.input}
          className={classNames(
            `field__input ${field.inputClass ? field.inputClass : ''}`, {
              'field--invalid': showError,
            })}
          type={field.type}
          disabled={field.disabled}
          onKeyPress={field.onKeyPress}
          placeholder={field.placeholder ? t(`form.${field.placeholder}`) : null}
          autoComplete="off"
          readOnly={field.readOnly}
        />
        {!field.noLabel &&
          <span className="field__label">
            <span>
              {t(`form.${label}`)}{field.required && ' *'}
            </span>
          </span>
        }
      </label>
      {showError && <span className="field__error">{t(error)}</span>}
    </div>
  );
}

Input.contextTypes = {
  t: PropTypes.func,
};

export default Input;
