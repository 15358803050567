// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Checkbox from '../../forms/fields/checkbox/Checkbox';
import Select from '../../forms/fields/select/Select';
import { timeSelector } from '../../../helpers/form';

// CSS
import './styles.scss';

class DayAvailability extends Component {
  constructor() {
    super();
    this.state = {
      disabledFields: [],
    };
  }

  componentDidMount() {
    const { periodDays, fields, periodDidUpdate } = this.props;
    periodDays.forEach((day, index) => {
      fields.push({ day, allDay: true, startTime: '08:00', endTime: '21:00' });
      this.reinitDisabledStates(index);
    });
    periodDidUpdate();
  }

  componentDidUpdate(prevProps) {
    const { updatePeriod: prevUpdatePeriod } = prevProps;
    const { periodDays, fields, updatePeriod, periodDidUpdate } = this.props;
    if (prevUpdatePeriod !== updatePeriod && updatePeriod) {
      this.clearFields(periodDays, fields, periodDidUpdate);
    }
  }

  reinitDisabledStates(index) {
    const { disabledFields } = this.state;
    const newDisabledState = disabledFields;
    newDisabledState[index] = { disableField: true };
    this.setState({
      disabledFields: newDisabledState,
    });
  }

  clearFields(periodDays, fields, periodDidUpdate) {
    fields.removeAll();
    periodDays.forEach((day, index) => {
      fields.push({ day, allDay: true, startTime: '08:00', endTime: '21:00' });
      this.reinitDisabledStates(index);
    });
    periodDidUpdate();
  }

  changeFieldDisabledState(index) {
    const { disabledFields } = this.state;
    const newDisabledState = disabledFields;
    newDisabledState[index] = { disableField: !newDisabledState[index].disableField };
    this.setState({
      disabledFields: newDisabledState,
    });
  }

  render() {
    const { fields, moment, periodDays } = this.props;
    const { disabledFields } = this.state;
    const { t } = this.context;
    return (
      <div>
        {fields.map((date, index) => (
          <div key={periodDays[index]} className="day-availability">
            <div className="day-availability--date">
              { moment(periodDays[index]).format('ddd') }
              <span>{ moment(periodDays[index]).format('DD') }</span>
            </div>
            <div className="day-availability__schedule">
              <Field
                name={`${date}.startTime`}
                type="text"
                component={Select}
                label="addAvailability.startTime"
                className="field--select"
                disabled={disabledFields[index].disableField ? 'disabled' : ''}
                innerLabel
              >
                { timeSelector() }
              </Field>
              <span className="day-availability__arrow" />
              <Field
                name={`${date}.endTime`}
                type="text"
                component={Select}
                label="addAvailability.endTime"
                className="field--select"
                disabled={disabledFields[index].disableField ? 'disabled' : ''}
                innerLabel
              >
                { timeSelector() }
              </Field>
              {disabledFields[index].disableField &&
                <div className="day-availability__tooltip">
                  <span>{t('form.addAvailability.allDayTooltip')}</span>
                </div>
              }
            </div>
            <Field
              name={`${date}.allDay`}
              type="text"
              component={Checkbox}
              label="addAvailability.allDay"
              className="day-availability__checkbox"
              onChange={() => this.changeFieldDisabledState(index)}
            />
          </div>
        ))}
      </div>
    );
  }
}

DayAvailability.propTypes = {
  fields: PropTypes.object.isRequired,
  periodDays: PropTypes.array.isRequired,
  updatePeriod: PropTypes.bool.isRequired,
  periodDidUpdate: PropTypes.func.isRequired,
  moment: PropTypes.func.isRequired,
};

DayAvailability.contextTypes = {
  t: PropTypes.func,
};

export default DayAvailability;
