export default {
  clinicCannotBeChanged: 'Clinic status cannot be changed',
  dayAvail: {
    format: 'Please enter valid format, e.g.: 1:23 AM',
    required: 'Enter a time period or select all day',
    startBeforeEnd: 'Selected time combination is invalid',
  },
  duplicateLanguage: 'Language already added',
  gloveSize: 'Glove size must be between 5 and 9',
  invalid: 'Field is invalid',
  passwordRequirements: {
    case: 'Contains lowercase and uppercase letters',
    characters: 'Contains at least one letter, one number and one special character',
    completeRequirements: 'Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
    length: 'Contains at least 8 characters',
    passwordsMustMatch: 'Passwords must match',
  },
  required: 'Field is required',
  selectASkill: 'Select at least one skill',
  selectOneSurgery: 'Select one surgery option only',
  timesheetEntries: {
    breaks: {
      duration: 'Enter a duration of at least 0.5h',
      reason: 'Select a reason for the break',
    },
    time: 'Start and end values must be different',
    distanceTraveledEmpty: 'Enter a traveled distance',
    distanceTraveled: 'Traveled distance must be greater than 0 km',
  },
  userCannotBeChanged: 'User status cannot be changed',
  uniqueness: 'Unique field',
  userSelected: 'User already assigned to this clinic',
  wantedCount: 'Enter the number of placement days wanted for this period',
  wantedCountLength: 'You cannot request more days than the period length',
  zipcode: 'Please enter valid format, e.g.: A1A 1A1',
}
