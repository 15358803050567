import { async, createTypes } from 'redux-action-creator';

export default createTypes([
  ...async('SIGN_IN'),
  ...async('SIGN_UP'),
  ...async('VALIDATE_TOKEN'),
  ...async('FORGOT_PASSWORD'),
  ...async('RESET_PASSWORD'),
  ...async('UPDATE_ACCOUNT'),
  'CLOSE_SUBSCRIPTIONS',
  'SUBSCRIBE_USER',
  'VALIDATE_NO_TOKEN',
  'UPDATE_CURRENT_CLINIC',
  'SIGN_OUT',
], 'USER');
