import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getRemainingHours } from '../../../../../../../helpers/formatProposals';

function Remaining({ expiresAt, expirationExtended, pending }) {
  const remaining = getRemainingHours(expiresAt);
  return (
    <div className="list-item__remaining">
      <span className={classNames(
          'type__icon type__icon--extended',
          { 'type__icon--invisible': !expirationExtended },
        )}
      />
      {pending ?
        <span>{`${remaining}h00`}</span>
      :
        <span>-- h --</span>
      }
    </div>
  );
}

Remaining.propTypes = {
  expiresAt: PropTypes.string.isRequired,
  expirationExtended: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
};
Remaining.defaultProps = {};

export default Remaining;
