import React from 'react';
import PropTypes from 'prop-types';
import ProposalRow from './ProposalRow';

function ProposalsSubForm(props) {
  const { acceptedProposals, fields, proposals, role, change } = props;
  return (
    <ul className="mandate-card__form__list">
      {fields.map((field, index) =>
        <div key={field}>
          { proposals[index] &&
            <ProposalRow
              acceptedProposals={acceptedProposals}
              field={field}
              proposal={proposals[index]}
              role={role}
              change={change}
            />
          }
        </div>,
      )}
    </ul>
  );
}

ProposalsSubForm.propTypes = {
  acceptedProposals: PropTypes.bool.isRequired,
  change: PropTypes.func,
  fields: PropTypes.object,
  proposals: PropTypes.array,
  role: PropTypes.string.isRequired,
};

ProposalsSubForm.defaultProps = {
  change: () => {},
  fields: {},
  proposals: [],
};

ProposalsSubForm.contextTypes = {
  t: PropTypes.func,
};

export default ProposalsSubForm;
