import { async, createTypes } from 'redux-action-creator';

export default createTypes([
  ...async('FETCH_ADMIN_MANDATES'),
  ...async('FETCH_CLINIC_MANDATES'),
  ...async('FETCH_SUBSTITUTE_MANDATES'),
  ...async('FETCH_NEXT_ADMIN_MANDATES'),
  ...async('FETCH_NEXT_CLINIC_MANDATES'),
  ...async('FETCH_NEXT_SUBSTITUTE_MANDATES'),
  ...async('ADMIN_REVIEW_USERS_PROPOSALS'),
  ...async('ADMIN_REVIEW_CLINICS_PROPOSALS'),
  ...async('ADMIN_REVIEW_COMPLETE_PROPOSALS'),
  ...async('USER_REVIEW_PROPOSALS'),
  ...async('CLINIC_REVIEW_PROPOSALS'),
  ...async('UPDATE_PROPOSALS'),
  ...async('FETCH_CLINIC_MANDATE'),
  ...async('FETCH_SUBSTITUTE_MANDATE'),
], 'MANDATE_PROPOSALS');
