function normalizeZipCode(value) {
  if (!value) {
    return value;
  }

  const zipcode = value.toUpperCase().replace(/\s+/g, '').replace(/[^a-zA-Z0-9 ]/g, '');

  if (zipcode.length <= 3) {
    return zipcode;
  }

  return `${zipcode.slice(0, 3)} ${zipcode.slice(3, 6)}`;
}

export default normalizeZipCode;
