import React from 'react';
import PropTypes from 'prop-types';

function Tooltip({ tooltip }, { t }) {
  return (
    <span className="tooltip__wrapper">
      <span className="tooltip">{t(`form.reasons.${tooltip}`)}</span>
      <span className="timesheet-card__icon timesheet-card__icon--info" />
    </span>
  );
}

Tooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
};
Tooltip.defaultProps = {};
Tooltip.contextTypes = {
  t: PropTypes.func,
};

export default Tooltip;
