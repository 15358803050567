// libs
import React from 'react';
import PropTypes from 'prop-types';

function TextLogo({ logoClass }) {
  return (
    <img
      src={`/vetreseau-white-text-${process.env.REACT_APP_REGION_CODE}.svg`}
      alt="Vetreseau"
      className={logoClass}
    />
  );
}

TextLogo.propTypes = {
  logoClass: PropTypes.string,
};

TextLogo.defaultProps = {
  logoClass: '',
};

export default TextLogo;
