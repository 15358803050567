export default {
  fetchAvailabilities: `
    query(
      $id: ID!,
      $from: ISO8601DateTime!,
      $to: ISO8601DateTime!
      $withoutProposals: Boolean
    ) {
      user(id: $id) {
        availabilities(from: $from, to: $to, withoutProposals: $withoutProposals) {
          nodes {
            id
            adminNote
            allDay
            availabilityType
            consultation
            createdAt
            distance
            endsAt
            message
            startsAt
            surgery
            latestProposal {
              status
              clinic {
                name
                distance(userId: $id)
                address {
                  address
                  address2
                  city
                  country
                  province
                  zipcode
                }
              }
              replacement {
                id
                startsAt
                endsAt
                mandate {
                  id
                  consultation
                  accommodation
                  surgery
                }
              }
              updatedAt
            }
          }
        }
      }
    }
  `,
  createAvailabilities: `
    mutation($userId: ID!, $input: AvailabilitiesInput!) {
      createAvailabilities(userId: $userId, input: $input) {
        availabilities {
          id
          adminNote
          allDay
          availabilityType
          consultation
          createdAt
          distance
          endsAt
          message
          latestProposal {
            id
            status
            userStatus
            clinicStatus
            clinic {
              id
              name
            }
            replacement {
              id
              createdAt
              endsAt
              mandate {
                consultation
                surgery
              }
              startsAt
              updatedAt
            }
          }
          startsAt
          surgery
          updatedAt
          user {
            id
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  disableAvailability: `
    mutation($id: ID!) {
      disableAvailability(id: $id) {
        availability {
          id
          user {
            id
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  disableProposal: `
    mutation disableProposal($id: ID!) {
      disableProposal(id: $id) {
        proposal {
          id
          replacement {
            id
            latestProposal {
              status
            }
          }
          availability {
            id
            latestProposal {
              id
              clinicStatus
              userStatus
              status
              createdAt
              updatedAt
              clinic{
                name
              }
              status
            }
            user {
              id
            }
          }
          discarded
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  updateAvailability: `
    mutation($id: ID!, $input: AvailabilityInput!) {
      updateAvailability(id: $id, input: $input) {
        availability {
          id
          adminNote
          allDay
          availabilityType
          consultation
          createdAt
          distance
          endsAt
          latestProposal {
            id
            status
            userStatus
            clinicStatus
            clinic {
              id
              name
            }
            replacement {
              id
              createdAt
              endsAt
              mandate {
                consultation
                surgery
              }
              startsAt
              updatedAt
            }
          }
          message
          startsAt
          surgery
          updatedAt
          user {
            id
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  fetchAvailability: `
    query($id: ID!) {
      availability(id: $id) {
        id
        adminNote
        allDay
        availabilityType
        consultation
        distance
        endsAt
        message
        startsAt
        surgery
        user {
          id
        }
      }
    }
  `,
};
