export default {
  signIn: `
    mutation($email: String!, $password: String!, $rememberMe: Boolean!) {
      signIn(email: $email, password: $password, rememberMe: $rememberMe) {
        user {
          id
          email
          managedClinics {
            id
            name
          }
          profile {
            id
            accountType
            consultation
            defaultDistance
            firstName
            lastName
            surgery
            displayLanguage {
              key
            }
            picture {
              mini
              small
              medium
              large
            }
            timeZone
          }
          unreadNotificationsCount
          roles {
            name
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  forgotPassword: `
    mutation($email: String!) {
      forgotPassword(email: $email) {
        success
      }
    }
  `,
  resetPassword: `
    mutation($token: String!, $password: String!, $passwordConfirmation: String!) {
      resetPassword(resetPasswordToken: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
        success
        errors {
          field
          message
          details
        }
      }
    }
  `,
  updateAccount: `
    mutation($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
      updateAccount(currentPassword: $currentPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
        user {
          id
          email
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  validateToken: `
    mutation {
      validateToken {
        valid
        user {
          id
          email
          managedClinics {
            id
            name
          }
          profile {
            id
            accountType
            consultation
            defaultDistance
            firstName
            lastName
            surgery
            displayLanguage {
              key
            }
            picture {
              mini
              small
              medium
              large
            }
            timeZone
          }
          unreadNotificationsCount
          roles {
            name
          }
        }
      }
    }
  `,
};
