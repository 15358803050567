import React from 'react';
import PropTypes from 'prop-types';

function Details({ accommodation, consultation, surgery, toggleDetails }, { t }) {
  return (
    <div className="list-item__drawer">
      <button
        className="list-item__drawer__close"
        onClick={toggleDetails}
      />
      <div className="list-item__drawer__infos">
        {consultation &&
          <div className="list-item__bullet list-item__bullet--consult">
            <span>{ t('event.consultation') }</span>
          </div>
        }
        {surgery !== 'false' &&
          <div className="list-item__bullet list-item__bullet--surgery">
            <span>
              { surgery === 'optional' ? t('event.surgeryOptional') : t('event.surgery') }
            </span>
          </div>
        }
        {accommodation &&
          <div className="list-item__bullet list-item__bullet--lodging">
            <span>{t('mandates.withLodging')}</span>
          </div>
        }
      </div>
    </div>
  );
}

Details.propTypes = {
  accommodation: PropTypes.bool.isRequired,
  consultation: PropTypes.bool.isRequired,
  surgery: PropTypes.string.isRequired,
  toggleDetails: PropTypes.func.isRequired,
};
Details.defaultProps = {};
Details.contextTypes = {
  t: PropTypes.func,
};

export default Details;
