// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

// actions
import matchmakingActions from '../../../actions/matchmaking';

// components
import SmallLoader from '../../loader/SmallLoader';

// helpers
import { formatDayWithDateAndMonth } from '../../../helpers/formatDates';
import { getClinicProposalsList } from '../../../helpers/formatProposals';

class ReplacementEventTooltip extends Component {
  static propTypes = {
    addCallToAll: PropTypes.func.isRequired,
    clinicProposals: PropTypes.array,
    deleteEvent: PropTypes.func.isRequired,
    deleteProposal: PropTypes.func.isRequired,
    editCallToAll: PropTypes.func.isRequired,
    enableEvent: PropTypes.func.isRequired,
    event: PropTypes.object,
    fetchClinicProposals: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    resetEventProposals: PropTypes.func.isRequired,
    updateEvent: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    clinicProposals: [],
    event: {},
    loading: false,
  };

  componentWillMount() {
    const { resetEventProposals } = this.props;
    resetEventProposals();
  }

  componentDidMount() {
    const {
      fetchClinicProposals,
      event,
    } = this.props;
    const { latestProposal } = event;
    if (latestProposal !== null) {
      fetchClinicProposals({
        replacementId: event.id,
      });
    }
  }

  componentWillUnmount() {
    const { resetEventProposals } = this.props;
    resetEventProposals();
  }

  render() {
    const {
      addCallToAll,
      event,
      event: {
        adminNote,
        callToAll,
        startsAt,
        createdAt,
        latestProposal,
        message,
        discarded,
      },
      clinicProposals,
      deleteEvent,
      deleteProposal,
      editCallToAll,
      enableEvent,
      loading,
      updateEvent,
    } = this.props;
    const { t } = this.context;
    const activeCallToAll = callToAll && callToAll.status !== 'canceled';
    const isDeletable = !activeCallToAll && ['confirmed'].includes(latestProposal && latestProposal.status);
    const isEditable = !['confirmed'].includes(latestProposal && latestProposal.status);
    const proposalsList = getClinicProposalsList(clinicProposals, latestProposal);
    return (
      <div className="matchmaking__tooltip">
        <div>
          <div className="matchmaking__tooltip__header">
            <span className="matchmaking__tooltip__date">
              {formatDayWithDateAndMonth(startsAt)}
              {loading && <SmallLoader />}
            </span>
            {!discarded && (
              <div>
                {isEditable &&
                  <button
                    className="matchmaking__event__action matchmaking__event__action--edit"
                    onClick={() => updateEvent(event)}
                  />
                }
                {!latestProposal && !activeCallToAll &&
                  <button
                    className="matchmaking__event__action matchmaking__event__action--delete"
                    onClick={() => deleteEvent(event)}
                  />
                }
              </div>
            )}
          </div>
          <div className="matchmaking__tooltip__proposals">
            {proposalsList && (
              <ul>
                {proposalsList.map((
                    {
                      id: proposalId,
                      substitute: {
                        profile,
                        profile: { picture },
                      },
                      status: clinicStatus,
                      userStatus,
                      status,
                      updatedAt,
                    }) => (
                      <li key={proposalId}>
                        <div className="matchmaking__user__infos">
                          {picture && picture.mini ?
                            <div
                              className="matchmaking__user__image"
                              style={{ backgroundImage: `url(${picture.mini})` }}
                            />
                            :
                            <div className="matchmaking__user__image matchmaking__user__image--empty" />
                          }
                          <div className="matchmaking__user__name">
                            <span>{profile.firstName} {profile.lastName}</span>
                            <span className="date">{moment(updatedAt).format('DD MMM YYYY')}</span>
                          </div>
                        </div>
                        <div className="matchmaking__event__actions">
                          <div className="tooltip__wrapper">
                            <span className="tooltip">{t('matchmaking.substitute')}</span>
                            <div className={classNames(
                            'matchmaking__user__status',
                            `matchmaking__user__status--${userStatus}`, {
                              'matchmaking__user__status--expired': status === 'expired',
                            })}
                            />
                          </div>
                          <div className="tooltip__wrapper">
                            <span className="tooltip">{t('matchmaking.clinic')}</span>
                            <div className={classNames(
                            'matchmaking__clinic__status',
                            `matchmaking__clinic__status--${clinicStatus}`)}
                            />
                          </div>
                          {isDeletable || ['expired', 'rejected', 'confirmed'].includes(status) ?
                            <div>
                              <button
                                className="matchmaking__event__action matchmaking__event__action--delete"
                                onClick={() => deleteProposal(event, proposalId)}
                              />
                            </div>
                              : <div className="matchmaking__event__action matchmaking__event__action--no-action" />
                            }
                        </div>
                      </li>
                  ))}
              </ul>
              )}
          </div>
          {(
            <div className="matchmaking__tooltip__footer">
              {!discarded ? (
                <div>
                  {activeCallToAll ? (
                    <button
                      className="matchmaking__tooltip__action matchmaking__tooltip__action--full"
                      onClick={editCallToAll}
                    >
                      {t('matchmaking.editCallToAll')}
                    </button>
                  ) : (
                    <button
                      className="matchmaking__tooltip__action matchmaking__tooltip__action--full"
                      onClick={addCallToAll}
                    >
                      {t('matchmaking.addCallToAll')}
                    </button>
                  )}
                </div>
              ) : (
                <button
                  className="matchmaking__tooltip__action"
                  onClick={() => enableEvent(event)}
                >
                  {t('form.formActions.enable')}
                </button>
              )}
            </div>
          )}
          {message &&
            <div className="matchmaking__tooltip__footer">
              <div className="tooltip__wrapper">
                <span className="tooltip">{t('matchmaking.tooltip.clinicNote')}</span>
                <span className="
                matchmaking__event__icon
                matchmaking__event__icon--message"
                />
              </div>
              <div className="matchmaking__event__message">
                <div>{message}</div>
              </div>
            </div>
          }
          {adminNote &&
          <div className="matchmaking__tooltip__footer">
            <div className="tooltip__wrapper">
              <span className="tooltip">{t('matchmaking.tooltip.adminNote')}</span>
              <span className="
          matchmaking__event__icon
          matchmaking__event__icon--admin-note"
              />
            </div>
            <div className="matchmaking__event__message">
              <div>{adminNote}</div>
            </div>
          </div>
          }
          <div className="matchmaking__tooltip__footer matchmaking__tooltip__footer--dates">
            {(event.updatedAt !== event.createdAt) && (<span>{`${t('matchmaking.tooltip.updatedAt')}: ${moment(event.updatedAt).format('DD MMM YYYY')}`}</span>)}
            {createdAt && (<span>{`${t('matchmaking.tooltip.createdAt')}: ${moment(createdAt).format('DD MMM YYYY')}`}</span>)}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clinicProposals: state.matchmaking.eventTooltip.proposals,
    loading: state.matchmaking.eventTooltip.loading,
  };
}

export default connect(
  mapStateToProps,
  {
    ...matchmakingActions,
  })(ReplacementEventTooltip);
