// libs
import React from 'react';
import PropTypes from 'prop-types';

function ManagerWithoutClinic(props, { t }) {
  return (
    <div>
      <div className="message">
        <p>{t('clinics.managerWithoutClinic.content')}</p>
      </div>
    </div>
  );
}

ManagerWithoutClinic.contextTypes = {
  t: PropTypes.func,
};

export default ManagerWithoutClinic;
