// libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Components
import Header from '../layouts/header/Header';
import SidePanel from '../layouts/sidePanel/SidePanel';
import Alerts from '../layouts/alerts/Alerts';
import ConfirmAction from '../confirmAction/ConfirmAction';

// Styles
import './styles.scss';

const contextTypes = {
  t: PropTypes.func,
};

const propTypes = {
  children: PropTypes.node.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  alerts: PropTypes.array.isRequired,
  showConfirmation: PropTypes.bool.isRequired,
};

function Main(props) {
  const { isAuthenticated, children, isVisible, alerts, showConfirmation } = props;
  return (
    <div className={classNames('page', { 'page--no-scroll': isVisible })}>
      { isAuthenticated ? (
        <div className="page__logged-in">
          <Header />
          <div className={
            classNames(
              'page__with-side-panel',
              { 'page__with-side-panel--small': isVisible },
            )}
          >
            <main>
              {children}
            </main>
            <SidePanel />
            {showConfirmation && <ConfirmAction />}
          </div>
        </div>
      ) : (
        <main>
          {children}
        </main>
      )}
      {alerts.length > 0 && <Alerts />}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.authenticated,
    isVisible: state.dynamicSidePanel.isVisible,
    alerts: state.alerts.alerts,
    showConfirmation: state.confirmAction.showConfirmation,
  };
}

Main.contextTypes = contextTypes;
Main.propTypes = propTypes;

export default connect(mapStateToProps)(Main);
