import React from 'react';
import PropTypes from 'prop-types';
import Accordion from './Accordion';

import '../helpcenter.scss';

function SubstituteFaq(props, { t }) {
  return (
    <div className="helpcenter__faq">
      <div className="helpcenter__faq__container">
        <div className="helpcenter__faq__title title">
          <h2>{ t('helpcenter.faq') }</h2>
        </div>
        <Accordion />
        <div className="divider" />
        <section id="contact">
          <div className="contact__container">
            <div className="contact__image icon--question" />
            <div className="contact__title">
              <h4>{t('helpcenter.substitute.tutorial.questions.title')}</h4>
            </div>
            <div className="contact__paragraph">
              <p>{t('helpcenter.substitute.tutorial.questions.contactInfo')}<a className="email" href={`mailto:${process.env.REACT_APP_REGION_EMAIL}`}>{process.env.REACT_APP_REGION_EMAIL}</a><br />{t('helpcenter.substitute.tutorial.questions.or')}<a className="phone" href={`tel:${process.env.REACT_APP_REGION_PHONE}`}>{process.env.REACT_APP_REGION_PHONE}</a></p>
            </div>
            <div className="contact__paragraph">
              <p>{t('helpcenter.substitute.tutorial.questions.paragraph1')}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

SubstituteFaq.contextTypes = {
  t: PropTypes.func,
};

export default SubstituteFaq;
