// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// actions
import actions from '../../actions/confirmAction/index';

// Styles
import './styles.scss';

// Props and Context
const contextTypes = {
  t: PropTypes.func,
};
const propTypes = {
  hideConfirmation: PropTypes.func.isRequired,
  actionToTrigger: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

// Index Component
class ConfirmAction extends Component {
  constructor() {
    super();
    this.declineConfirmation = this.declineConfirmation.bind(this);
    this.acceptConfirmation = this.acceptConfirmation.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  acceptConfirmation() {
    const { hideConfirmation, actionToTrigger } = this.props;
    actionToTrigger();
    hideConfirmation();
  }

  declineConfirmation() {
    const { hideConfirmation } = this.props;
    hideConfirmation();
  }

  handleKeyDown(event) {
    switch (event.key) {
      case 'Enter':
        this.acceptConfirmation();
        break;
      case 'Escape':
        this.declineConfirmation();
        break;
      default:
    }
  }

  render() {
    const { t } = this.context;
    const { message } = this.props;
    return (
      <div className="action-confirmation">
        <div className="action-confirmation__content">
          <p>{t(`confirm.${message}`)}</p>
          <div className="form__actions">
            <button
              className="form__cancel"
              onClick={this.declineConfirmation}
            >
              {t('confirm.cancel')}
            </button>
            <button
              className="form__submit"
              onClick={this.acceptConfirmation}
              autoFocus
            >
              {t('confirm.confirm')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    actionToTrigger: state.confirmAction.actionToTrigger,
    message: state.confirmAction.message,
  };
}

ConfirmAction.contextTypes = contextTypes;
ConfirmAction.propTypes = propTypes;

export default connect(mapStateToProps, actions)(ConfirmAction);
