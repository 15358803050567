import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PictureUploadPlaceholder from './PictureUploadPlaceholder';

function PictureUploadPreview(
  { picture, pictureSize, displayType, toggleUpload, uploadIsActive },
  { t },
) {
  const hasPicture = Object.entries(picture).length > 0;
  const style = hasPicture ? {
    backgroundImage: `url(${picture[pictureSize]})`,
  } : {};

  return (
    <div
      className={classNames(
        'picture-uploader__preview', {
        'picture-uploader__preview--filled': hasPicture,
        'picture-uploader__preview--round': displayType === 'profile',
        'picture-uploader__preview--minified': uploadIsActive,
      })}
    >
      {hasPicture &&
        <div className="picture-uploader__preview__filler" style={style} />
      }
      <PictureUploadPlaceholder displayType={displayType} />
      {toggleUpload && !uploadIsActive &&
        <button
          type="button"
          className="picture-uploader__preview__edit"
          onClick={() => toggleUpload()}
        >
          <span>
            {t('form.uploadImage')}
          </span>
        </button>
      }
    </div>
  );
}

PictureUploadPreview.propTypes = {
  picture: PropTypes.object,
  pictureSize: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired,
  toggleUpload: PropTypes.func,
  uploadIsActive: PropTypes.bool,
};

PictureUploadPreview.defaultProps = {
  picture: null,
  toggleUpload: null,
  uploadIsActive: false,
};

PictureUploadPreview.contextTypes = {
  t: PropTypes.func,
};

export default PictureUploadPreview;
