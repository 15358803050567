import types from '../actions/notifications/types';
import userTypes from '../actions/user/types';
import { filterNotificationStatus } from '../helpers/formatNotifications';

const initialState = {
  notifications: {
    read: {
      notifications: [],
      hasNextPage: false,
      endCursor: null,
      loading: false,
    },
    unread: {
      notifications: [],
      hasNextPage: false,
      endCursor: null,
      loading: false,
    },
  },
  unreadNotificationsCount: 0,
  updateReadNotifications: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case userTypes.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        unreadNotificationsCount: action.payload.validateToken.user.unreadNotificationsCount,
      };
    case userTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        unreadNotificationsCount: action.payload.signIn.user.unreadNotificationsCount,
      };
    case userTypes.SIGN_OUT:
      return initialState;
    case types.SUBSCRIBE_TO_NOTIFICATIONS_INCOMING:
      return {
        ...state,
        unreadNotificationsCount: state.unreadNotificationsCount + 1,
        notifications: {
          read: {
            notifications: [
              ...state.notifications.read.notifications,
              ...filterNotificationStatus([action.payload.data.notificationReceived], 'read'),
            ],
          },
          unread: {
            notifications: [
              ...state.notifications.unread.notifications,
              ...filterNotificationStatus([action.payload.data.notificationReceived], 'unread'),
            ],
          },
        },
      };
    case types.UPDATE_NOTIFICATION_STATUS_SUCCESS: {
      const { notifications } = state;
      const updatedNotifications = action.payload.updateStatusNotifications.notifications;
      const readNotifications = notifications.read.notifications;
      const read = [...updatedNotifications, ...readNotifications];

      const unread = notifications.unread.notifications.filter(notification =>
        !updatedNotifications.map(updated => updated.id).includes(notification.id));

      return {
        ...state,
        notifications: {
          read: {
            notifications: read,
          },
          unread: {
            ...state.notifications.unread,
            notifications: unread,
          },
        },
        unreadNotificationsCount: state.unreadNotificationsCount - 1,
        updateReadNotifications: true,
      };
    }
    case types.FETCH_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          read: {
            ...state.notifications.read,
            loading: true,
          },
          unread: {
            ...state.notifications.unread,
            loading: true,
          },
        },
      };
    case types.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          read: {
            notifications: action.payload.user.readNotifications.nodes,
            hasNextPage: action.payload.user.readNotifications.pageInfo.hasNextPage,
            endCursor: action.payload.user.readNotifications.pageInfo.endCursor,
            startCursor: action.payload.user.readNotifications.pageInfo.startCursor,
            loading: false,
          },
          unread: {
            notifications: action.payload.user.unreadNotifications.nodes,
            hasNextPage: action.payload.user.unreadNotifications.pageInfo.hasNextPage,
            endCursor: action.payload.user.unreadNotifications.pageInfo.endCursor,
            startCursor: action.payload.user.unreadNotifications.pageInfo.startCursor,
            loading: false,
          },
        },
        unreadNotificationsCount: action.payload.user.unreadNotificationsCount,
      };
    case types.UPDATE_READ_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          read: {
            ...state.notifications.read,
            loading: true,
          },
          unread: {
            ...state.notifications.unread,
            loading: false,
          },
        },
        updateReadNotifications: false,
      };
    case types.UPDATE_READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          read: {
            notifications: [
              ...state.notifications.read.notifications,
              ...action.payload.user.notifications.nodes,
            ],
            hasNextPage: action.payload.user.notifications.pageInfo.hasNextPage,
            endCursor: action.payload.user.notifications.pageInfo.endCursor,
            startCursor: action.payload.user.notifications.pageInfo.startCursor,
            loading: false,
          },
          unread: {
            ...state.notifications.unread,
            loading: false,
          },
        },
        updateReadNotifications: false,
      };
    case types.UPDATE_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          read: {
            ...state.notifications.read,
            loading: false,
          },
          unread: {
            ...state.notifications.unread,
            loading: true,
          },
        },
      };

    case types.UPDATE_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          read: {
            ...state.notifications.read,
            loading: false,
          },
          unread: {
            notifications: [
              ...state.notifications.unread.notifications,
              ...action.payload.user.notifications.nodes,
            ],
            hasNextPage: action.payload.user.notifications.pageInfo.hasNextPage,
            endCursor: action.payload.user.notifications.pageInfo.endCursor,
            startCursor: action.payload.user.notifications.pageInfo.startCursor,
            loading: false,
          },
        },
      };

    default:
  }

  return state;
}
