import React from 'react';
import PropTypes from 'prop-types';

function Address({ addressObject }) {
  const {
    address,
    address2,
    city,
    province,
    zipcode,
  } = addressObject;
  if (addressObject === null) {
    return null;
  }
  return (
    <div className="mandate-card__content__header__address">
      <p>{ address }</p>
      { address2 &&
        <p>{ address2 }</p>
      }
      <p>{ city }, {province && province.toUpperCase()}</p>
      <p>{ zipcode }</p>
    </div>
  );
}

Address.propTypes = {
  addressObject: PropTypes.object,
};
Address.defaultProps = {
  addressObject: {},
};
Address.contextTypes = {};

export default Address;
