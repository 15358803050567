import { async, createTypes } from 'redux-action-creator';

export default createTypes([
  ...async('FETCH_SUGGESTED_USERS'),
  ...async('FETCH_USERS'),
  ...async('FETCH_NEXT_USERS'),
  ...async('FETCH_USER'),
  ...async('CREATE_USER'),
  ...async('UPDATE_USER'),
  ...async('DISABLE_USER'),
  ...async('ENABLE_USER'),
], 'USERS');
