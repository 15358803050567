// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// Components
import ReactDates from '../forms/fields/reactDates/ReactDates';

// Actions
import actions from '../../actions/timesheet';

// Styles
import './styles.scss';

class TimesheetsDateRange extends Component {
  static propTypes = {
    accountType: PropTypes.string.isRequired,
    fetchTimesheets: PropTypes.func.isRequired,
    userId: PropTypes.string,
    updateListDateRange: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    userId: null,
  };

  constructor() {
    super();
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.state = {
      from: moment(new Date()),
      to: moment(new Date()),
      focusedInput: null,
    };
  }

  handlePeriodChange(from, to) {
    const { accountType, fetchTimesheets, userId, updateListDateRange } = this.props;
    updateListDateRange(from, to);
    if (from && to) {
      fetchTimesheets({
        accountType,
        userId,
        from: from.toISOString(),
        to: to.toISOString(),
        first: 10000,
      });
    }
  }

  render() {
    const { t } = this.context;
    return (
      <div>
        <ReactDates
          startDate={this.state.from}
          startDateId="periodStart"
          endDate={this.state.to}
          endDateId="periodEnd"
          onDatesChange={
            ({ startDate, endDate }) => this.setState({ from: startDate, to: endDate })
          }
          onClose={({ startDate, endDate }) => this.handlePeriodChange(startDate, endDate)}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          numberOfMonths={1}
          startDatePlaceholderText={t('form.addAvailability.startDate')}
          endDatePlaceholderText={t('form.addAvailability.endDate')}
          isOutsideRange={(date) => date.year() < 2019}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { role } = state.user.data;
  return {
    accountType: role === 'admin' ? state.matchmaking.selectedAccountType : state.user.data.profile.accountType,
    userId: state.user.data.id,
  };
}

export default withRouter(connect(
  mapStateToProps,
  { ...actions },
)(TimesheetsDateRange));
