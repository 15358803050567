// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// helpers
import {
  formatClinicDistance,
  formatClinicTravelTime,
} from '../../../../helpers/distance';

class ProximityTag extends Component {
  static propTypes = {
    distance: PropTypes.number.isRequired,
    travelTime: PropTypes.number.isRequired,
  };

  constructor() {
    super();
    this.state = {
      isHovered: false,
    };
  }

  onHover() {
    this.setState({
      isHovered: !this.state.isHovered,
    });
  }

  render() {
    const { distance, travelTime } = this.props;
    const { isHovered } = this.state;
    return (
      <span
        className="matchmaking__user__proximity-tag"
        onMouseEnter={() => this.onHover()}
        onMouseLeave={() => this.onHover()}
      >
        {isHovered ? formatClinicTravelTime(travelTime) : formatClinicDistance(distance)}
      </span>
    );
  }
}

export default ProximityTag;
