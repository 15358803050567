import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FileUploadPlaceholder from './FileUploadPlaceholder';


function FileUploadPreview(props, { t }) {
  const { picture, pictureSize, displayType, toggleUpload, uploadIsActive } = props;
  const hasPicture = Object.entries(picture).length > 0;

  return (
    <div className={classNames(
      'file-uploader__display', {
      'file-uploader__display--minified': uploadIsActive,
    })}
    >
      <div className="file-uploader__display-image">
        <div
          className={classNames(
            'file-uploader__preview', {
            'file-uploader__preview--filled': hasPicture,
            'file-uploader__preview--round': displayType === 'profile',
            'file-uploader__preview--minified': uploadIsActive,
          })}
        >
          <FileUploadPlaceholder displayType={displayType} />
          {toggleUpload && !uploadIsActive &&
            <button
              type="button"
              className="file-uploader__preview__edit"
              onClick={() => toggleUpload()}
            >
              <span>
                {hasPicture ? t('form.replaceFileShort') : t('form.uploadFileShort')}
              </span>
            </button>
          }
        </div>
      </div>
      <div className="file-uploader__actions">
        {toggleUpload && !uploadIsActive &&
          <button
            type="button"
            className={classNames(
              'file-uploader__action',
              `file-uploader__action--${hasPicture ? 'replace' : 'upload'}`,
            )}
            onClick={() => toggleUpload()}
          >
            <span>
              {hasPicture ? t('form.replaceFile') : t('form.uploadFile')}
            </span>
          </button>
        }
        {hasPicture ?
          <a
            href={picture[pictureSize]}
            target="_blank"
            rel="noopener noreferrer"
            className="file-uploader__action file-uploader__action--view"
          >
            {t('form.viewFile')}
          </a>
          :
          <span className="file-uploader__action file-uploader__action--text">
            {t('form.noFileYet')}
          </span>
        }
      </div>
    </div>
  );
}

FileUploadPreview.propTypes = {
  picture: PropTypes.object,
  pictureSize: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired,
  toggleUpload: PropTypes.func,
  uploadIsActive: PropTypes.bool,
};

FileUploadPreview.defaultProps = {
  picture: null,
  toggleUpload: null,
  uploadIsActive: false,
};

FileUploadPreview.contextTypes = {
  t: PropTypes.func,
};

export default FileUploadPreview;
