// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { statusColor } from '../../../helpers/events';
import {
  formatTimeRange,
} from '../../../helpers/formatDates';
import { matchmakingDaysRange } from '../../../helpers/range';

// components
import AddCallToAll from '../../callToAll/AddCallToAll';
import EditCallToAll from '../../callToAll/EditCallToAll';
import ReplacementEventTooltip from './ReplacementEventTooltip';

// actions
import actionsSidePanel from '../../../actions/dynamicSidePanel';
import actionsCallToAll from '../../../actions/callToAll';

class ReplacementEvent extends Component {
  constructor() {
    super();
    this.addCallToAll = this.addCallToAll.bind(this);
    this.editCallToAll = this.editCallToAll.bind(this);
    this.state = {
      isHovered: false,
    };
  }

  handleHover() {
    const { onSetEventIdForTooltip, event } = this.props;
    this.setState(prevState => ({
      isHovered: !prevState.isHovered,
    }));
    if (this.state.isHovered === false) {
      onSetEventIdForTooltip(event.id);
    }
  }

  addCallToAll() {
    const {
      updateComponent,
      replacementForCallToAll,
      event,
      clinic,
    } = this.props;

    replacementForCallToAll({ ...event, clinicName: clinic.name, clinicId: clinic.id });
    updateComponent(AddCallToAll, 'addCallToAll');
  }

  editCallToAll() {
    const {
      updateComponent,
      replacementForCallToAll,
      fetchCallToAll,
      event: { callToAll: { id } },
      event,
      clinic,
    } = this.props;

    fetchCallToAll({ id }).then((data) => {
      if (data.callToAll) {
        replacementForCallToAll({ ...event, clinicName: clinic.name, clinicId: clinic.id });
        updateComponent(EditCallToAll, 'editCallToAll', { sidebarIcon: 'edit' });
      }
    });
  }

  render() {
    const {
      activeProposal,
      count,
      dateFrom,
      dateTo,
      event,
      event: {
        id,
        adminNote,
        callToAll,
        deletableMandate,
        mandateId,
        startsAt,
        endsAt,
        consultation,
        surgery,
        flexibleTime,
        firstItem,
        lastItem,
        latestProposal,
        isSingleDay,
        wantedCount,
        totalCount,
        accommodation,
        priority,
        message,
        index,
        discarded,
      },
      deleteEvent,
      deleteMandate,
      deleteProposal,
      enableEvent,
      eventMatch,
      isInteractive,
      onSetEventIdForTooltip,
      selectEvent,
      unselectEvent,
      updateEvent,
      filteredOut,
    } = this.props;
    const activeCallToAll = callToAll && callToAll.status !== 'canceled';
    const canceledCallToAll = callToAll && callToAll.status === 'canceled';
    const activeStatuses = ['confirmed', 'pending', 'pending_clinic', 'pending_admin'];
    const styles = { top: `${(index * 4.9375)}rem` };
    const eventIsUnmatchable = !eventMatch && !activeProposal &&
      !(latestProposal && activeStatuses.includes(latestProposal.status));
    const { isHovered } = this.state;
    return (
      <div className="matchmaking__mandate__wrapper" style={styles}>
        <div
          key={id}
          className={classNames(
            'matchmaking__mandate',
            {
              'matchmaking__event--discarded': discarded,
              'matchmaking__event--disabled': !filteredOut,
              'matchmaking__event--unmatchable': eventIsUnmatchable,
              'matchmaking__event--interactive': isInteractive,
              'matchmaking__mandate--start': count === 0,
              'matchmaking__mandate--end': count === matchmakingDaysRange(dateFrom, dateTo) - 1,
              'matchmaking__mandate--first': firstItem,
              'matchmaking__mandate--last': lastItem,
              'matchmaking__mandate--middle': (!isSingleDay && !firstItem && !lastItem),
            },
          )}
          onMouseEnter={() => { this.handleHover(); }}
          onMouseLeave={() => { this.handleHover(); }}
        >
          <div className="matchmaking__mandate__header">
            {(firstItem || isSingleDay || count === 0) && consultation === true &&
            <span className="matchmaking__event__icon matchmaking__event__icon--consult" />
            }
            {(firstItem || isSingleDay || count === 0) && ['true', 'optional'].includes(surgery) &&
            <span
              className={classNames(
                'matchmaking__event__icon',
                {
                  'matchmaking__event__icon--surgery': surgery,
                  'matchmaking__event__icon--surgery--optional': surgery === 'optional',
                },
            )}
            />
            }
            {(firstItem || isSingleDay || count === 0) && accommodation &&
            <span className="matchmaking__event__icon matchmaking__event__icon--lodging" />
            }
            {(firstItem || isSingleDay || count === 0) && message &&
            <span className="matchmaking__event__icon matchmaking__event__icon--message" title={message} />
            }
            {(firstItem || isSingleDay || count === 0) && adminNote &&
            <span className="matchmaking__event__icon matchmaking__event__icon--admin-note" />
            }
            {(firstItem || isSingleDay || count === 0) && (wantedCount !== totalCount) &&
            <span className="matchmaking__mandate__count">{wantedCount}/{totalCount}</span>
            }
            {(lastItem || isSingleDay || count === matchmakingDaysRange(startsAt) - 1)
              && deletableMandate && !activeCallToAll &&
              <div className="matchmaking__mandate__actions">
                <button
                  className="matchmaking__mandate__action matchmaking__mandate__action--delete"
                  onClick={() => deleteMandate(mandateId)}
                />
              </div>
            }
          </div>
          <div
            className={classNames(
              'matchmaking__event',
              { 'matchmaking__event--first': firstItem },
              { 'matchmaking__event--last': lastItem },
              { 'matchmaking__event--middle': (!isSingleDay && !firstItem && !lastItem) },
            )}
          >
            <div>
              <div className={classNames(
                'matchmaking__timerange',
                `matchmaking__timerange--${statusColor(event)}`,
                {
                  'matchmaking__timerange--bordered': priority,
                  'matchmaking__timerange--matchable': eventMatch,
                  'matchmaking__timerange--active': activeProposal,
                  'matchmaking__timerange--call-to-all': activeCallToAll,
                  'matchmaking__timerange--canceled-call-to-all': canceledCallToAll,
                },
              )}
              >
                {activeProposal &&
                  <button
                    className="matchmaking__event__unselect"
                    onClick={() => unselectEvent(event)}
                  />
                }
                {eventMatch ? (
                  <button
                    className="matchmaking__event__select"
                    onClick={() => selectEvent(event)}
                  >
                    {flexibleTime && <span className="matchmaking__timerange__icon" />}
                    <span>{formatTimeRange(startsAt, endsAt)}</span>
                  </button>
                ) : (
                  <div>
                    {flexibleTime && <span className="matchmaking__timerange__icon" />}
                    <span>{formatTimeRange(startsAt, endsAt)}</span>
                  </div>
                )}
              </div>
              {isHovered &&
              <ReplacementEventTooltip
                addCallToAll={this.addCallToAll}
                deleteEvent={deleteEvent}
                deleteProposal={deleteProposal}
                editCallToAll={this.editCallToAll}
                enableEvent={enableEvent}
                event={event}
                updateEvent={updateEvent}
                onSetEventIdForTooltip={onSetEventIdForTooltip}
              />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReplacementEvent.propTypes = {
  activeProposal: PropTypes.bool.isRequired,
  clinic: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  deleteMandate: PropTypes.func.isRequired,
  deleteProposal: PropTypes.func.isRequired,
  enableEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  eventMatch: PropTypes.bool,
  fetchCallToAll: PropTypes.func.isRequired,
  filteredOut: PropTypes.bool.isRequired,
  isInteractive: PropTypes.bool.isRequired,
  onSetEventIdForTooltip: PropTypes.func.isRequired,
  replacementForCallToAll: PropTypes.func.isRequired,
  selectEvent: PropTypes.func.isRequired,
  unselectEvent: PropTypes.func.isRequired,
  updateComponent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
};

ReplacementEvent.defaultProps = {
  eventMatch: false,
};

ReplacementEvent.contextTypes = {
  t: PropTypes.func,
};

export default connect(null, { ...actionsSidePanel, ...actionsCallToAll })(ReplacementEvent);
