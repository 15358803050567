// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import matchmakingActions from '../../../../actions/matchmaking';
import alertActions from '../../../../actions/alerts';
import clinicsActions from '../../../../actions/admin/clinics';
import sidePanelActions from '../../../../actions/dynamicSidePanel/index';

// components
import EditClinic from '../../../admin/clinics/EditClinic';

// helper
import { openInGoogleMaps, copyAddressToClipboard } from '../../../../helpers/addresses';

class ClinicMiniProfile extends Component {
  static propTypes = {
    clinic: PropTypes.object,
    clinicAddress: PropTypes.object,
    fetchClinic: PropTypes.func.isRequired,
    fetchClinicAddress: PropTypes.func.isRequired,
    pushAlert: PropTypes.func.isRequired,
    resetClinicAddress: PropTypes.func.isRequired,
    toggleClinicMiniprofile: PropTypes.func.isRequired,
    updateComponent: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    clinic: {},
    clinicAddress: {},
  };

  constructor(props) {
    super(props);
    this.navRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillMount() {
    const { resetClinicAddress } = this.props;
    resetClinicAddress();
  }

  componentDidMount() {
    const { fetchClinicAddress, clinic } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside);
    fetchClinicAddress({ id: clinic.id });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { toggleClinicMiniprofile } = this.props;
    if (this.navRef && !this.navRef.current.contains(event.target)) {
      toggleClinicMiniprofile();
    }
  }

  handleCopyAddress(addressObject) {
    const { pushAlert } = this.props;
    copyAddressToClipboard(addressObject, 'clinic');
    pushAlert({
      type: 'success',
      content: 'alerts.addressClipboard',
    });
  }

  editClinic(id) {
    const { updateComponent, fetchClinic } = this.props;
    fetchClinic({ id, userType: 'manager' });
    updateComponent(EditClinic, 'editClinic', { sidebarIcon: 'clinic' });
  }

  render() {
    const {
      clinicAddress,
      clinic: {
        id,
        adminNote,
      },
      clinicAddress: {
        address,
        address2,
        city,
        zipcode,
      },
    } = this.props;
    const { t } = this.context;

    return (
      <div className="matchmaking__miniprofile" ref={this.navRef}>
        <div className="matchmaking__miniprofile__wrapper">
          <div className="matchmaking__miniprofile__header">
            <span>{t('matchmaking.miniprofile.profileOverview')}</span>
            <div>
              <button
                onClick={() => { this.editClinic(id); }}
              >
                <span className="matchmaking__miniprofile__icon matchmaking__miniprofile__icon--edit" />
              </button>
            </div>
          </div>
          <div>
            <div className="matchmaking__miniprofile__section matchmaking__miniprofile__section--address">
              { address &&
              <div>
                {address}
                <button
                  onClick={() => { this.handleCopyAddress(clinicAddress); }}
                >
                  <span className="matchmaking__miniprofile__icon matchmaking__miniprofile__icon--copy-address" />
                </button>
                <button
                  onClick={() => { openInGoogleMaps(clinicAddress); }}
                >
                  <span className="matchmaking__miniprofile__icon matchmaking__miniprofile__icon--map" />
                </button>
              </div>
           }
              { address2 && <div>{address2}</div> }
              { city && <div>{city}</div> }
              { zipcode && <div>{zipcode}</div> }
            </div>
            {adminNote &&
            <div className="matchmaking__miniprofile__section matchmaking__miniprofile__section--adminNote">
              <span className="matchmaking__miniprofile__section__title">{t('matchmaking.miniprofile.adminNote')}</span>
              <p>{adminNote}</p>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedMiniprofileClinicId: state.matchmaking.selectedMiniprofileClinicId,
    clinicAddress: state.matchmaking.clinicAddress,
  };
}

export default connect(
  mapStateToProps,
  {
    ...matchmakingActions,
    ...alertActions,
    ...clinicsActions,
    ...sidePanelActions,
  },
)(ClinicMiniProfile);
