// libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { statuses } from '../../../helpers/events';
import actions from '../../../actions/matchmaking';

// components
import Checkbox from '../../forms/fields/checkbox/StatusCheckbox';

function Statuses(props, { t }) {
  return (
    <div className="matchmaking__toolbar__filters">
      <span className="matchmaking__toolbar__filters__title">{t('matchmaking.statuses')}</span>
      {statuses.map((status) =>
        <Field
          key={status}
          name={status}
          label={status}
          component={Checkbox}
          className={`matchmaking__toolbar__filter matchmaking__toolbar__filter--${status}`}
          status={t(`matchmaking.status.${status}`)}
        />,
      )}
    </div>
  );
}

Statuses.propTypes = {};
Statuses.defaultProps = {};
Statuses.contextTypes = {
  t: PropTypes.func,
};

export default connect(
  null,
  actions,
)(reduxForm({
  form: 'statuses',
  initialValues: statuses.reduce((object, status) => ({ ...object, [status]: true }), {}),
})(Statuses));
