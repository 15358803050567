// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

// components
import Clinic from './Clinic';
import AddClinic from './AddClinic';
import SearchClinic from './forms/SearchForm';

// actions
import actions from '../../../actions/admin/clinics';
import actionsSidePanel from '../../../actions/dynamicSidePanel/index';

// styles
import './styles.scss';

class Clinics extends Component {
  static propTypes = {
    clinics: PropTypes.array.isRequired,
    updateComponent: PropTypes.func.isRequired,
    fetchClinics: PropTypes.func.isRequired,
    search: PropTypes.string,
    after: PropTypes.string,
    hasNextPage: PropTypes.bool,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    search: '',
    after: '',
    hasNextPage: false,
  };

  constructor(props) {
    super(props);
    this.fetchClinics = this.fetchClinics.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  componentDidMount() {
    const { fetchClinics } = this.props;
    fetchClinics();
  }

  nextPage() {
    const { fetchClinics, after, search } = this.props;
    fetchClinics({ after, search });
  }

  fetchClinics() {
    const { fetchClinics, search } = this.props;
    fetchClinics({ search });
  }

  render() {
    const { clinics, updateComponent, hasNextPage } = this.props;
    const { t } = this.context;

    return (
      <div className="content-list">
        <div className="content-list__tools">
          <div className="content-list__tools__search">
            <SearchClinic fetchClinics={this.fetchClinics} />
          </div>
          <button
            onClick={() => updateComponent(AddClinic, 'addClinic', { sidebarIcon: 'clinic' })}
            className="schedule__toolbar__add-availability"
          >
            {t('clinics.add')}
          </button>
        </div>
        <div className="content-list__content">
          {clinics.map(clinic =>
            <Clinic
              key={clinic.id}
              clinic={clinic}
              updateComponent={updateComponent}
            />,
          )}
        </div>
        {hasNextPage && (
          <div className="form__actions">
            <button className="content-list__button form__submit" type="button" onClick={this.nextPage}>
              {t('form.formActions.loadMore')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector('searchClinics');

  return {
    clinics: state.clinics.results,
    after: state.clinics.pageInfo.endCursor,
    hasNextPage: state.clinics.pageInfo.hasNextPage,
    search: selector(state, 'search'),
  };
}

export default connect(mapStateToProps, { ...actions, ...actionsSidePanel })(Clinics);

