// libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// components
import { Field, reduxForm } from 'redux-form';
import Select from '../../forms/fields/select/NoLabelSelect';

// actions
import actions from '../../../actions/user';
import sidePanelActions from '../../../actions/dynamicSidePanel';

// styles
import './styles.scss';

function ClinicSwitcher({ hideSidePanel, managedClinics, updateCurrentClinic }) {
  return (
    <div className="matchmaking__toolbar">
      {managedClinics.length > 0 && (
        <div className="matchmaking__toolbar__schedule">
          <form>
            <div className="field__select__wrapper">
              <Field
                name="currentClinicId"
                component={Select}
                className="field--light"
                onChange={(event) => {
                  hideSidePanel();
                  updateCurrentClinic(event.target.value);
                }}
              >
                {managedClinics.map((clinic) =>
                  <option key={clinic.id} value={clinic.id}>{clinic.name}</option>,
                )}
              </Field>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { user: { currentClinicId, managedClinics } } = state;
  return {
    managedClinics,
    initialValues: {
      currentClinicId,
    },
  };
}

ClinicSwitcher.propTypes = {
  hideSidePanel: PropTypes.func.isRequired,
  managedClinics: PropTypes.array,
  updateCurrentClinic: PropTypes.func.isRequired,
};

ClinicSwitcher.defaultProps = {
  managedClinics: [],
};

export default connect(
  mapStateToProps,
  { ...actions, ...sidePanelActions },
)(reduxForm({
  form: 'selectClinic',
  enableReinitialize: true,
})(ClinicSwitcher));
