export default {
  createCallToAll: `
    mutation($input: CallToAllInput!) {
      createCallToAll(input: $input) {
        callToAll {
          message
          replacement {
            callToAll {
              id
              status
            }
            discarded
            endsAt
            flexibleTime
            id
            latestProposal {
              clinicStatus
              expirationExtended
              expiresAt
              message
              replacement {
                id
              }
              status
              userStatus
            }
            mandate {
              id
            }
            priority
            startsAt
          }
          users {
            id
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  fetchAvailableSubstitutesList: `
    query(
      $clinicId: ID!,
      $replacementId: ID!
    ) {
      availableSubstitutes(
        replacementId: $replacementId
      ) {
        id
        email
        roles {
          name
        }
        profile {
          id
          firstName
          lastName  
        }
        distance(clinicId: $clinicId)
      }
    }
  `,
  fetchCallToAll: `
    query(
      $id: ID!
    ) {
      callToAll(
        id: $id
      ) {
        id
        message
        callToAllUsers {
          id
          user {
            profile {
              firstName
              lastName
            }
          }
        }
        replacement {
          id
          endsAt
          flexibleTime
          mandate {
            id
            accommodation
            consultation
            clinic {
              id
              address {
                address
                address2
                city
                country
                province
                zipcode
              }
              message
              name
            }
            mandateType
            message
            replacementsCount
            surgery
            wantedCount
          }
          mandateId
          priority
          startsAt
        }
        status
        users {
          id
          profile {
            firstName
            lastName
          }
        }
      }
    }
  `,
  fetchUserCallToAlls: `
    query(
      $id: ID!
    ) {
      user(id: $id) {
        callToAlls {
          id
          message
          replacement {
            id
            endsAt
            flexibleTime
            mandate {
              id
              accommodation
              consultation
              clinic {
                id
                address {
                  address
                  address2
                  city
                  country
                  province
                  zipcode
                }
                distance(userId: $id)
                message
                name
              }
              mandateType
              replacementsCount
              surgery
              wantedCount
            }
            mandateId
            priority
            startsAt
          }
        }
      }
    }
  `,
  updateStatusCallToAll: `
    mutation($id: ID!, $status: String!) {
      updateStatusCallToAll(id: $id, status: $status) {
        callToAll {
          message
          replacement {
            callToAll {
              id
              status
            }
            discarded
            endsAt
            flexibleTime
            id
            latestProposal {
              clinicStatus
              expirationExtended
              expiresAt
              message
              replacement {
                id
              }
              status
              userStatus
            }
            mandate {
              id
            }
            priority
            startsAt
          }
          users {
            id
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  userReviewCallToAlls: `
    mutation($input: CallToAllReviewsInput!) {
      userReviewCallToAlls(input: $input) {
        callToAlls {
          id
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
};
