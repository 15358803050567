import ActionCable from 'actioncable';

const protocols = ['actioncable-v1-json', 'actioncable-unsupported'];

ActionCable.createConsumer = function createConsumer(url, auth = null) {
  if (url == null) {
    url = this.getConfig('url') != null ? this.getConfig('url') : this.INTERNAL.default_mount_path;
  }

  if (auth) {
    ActionCable.protocols = [...protocols, auth.replace('Bearer ', '')];
  }

  return new ActionCable.Consumer(this.createWebSocketURL(url));
};

ActionCable.Connection.prototype.open = function open() {
  if (this.isActive()) {
    ActionCable.log(`Attempted to open WebSocket, but existing socket is ${this.getState()}`);
    return false;
  }

  ActionCable.log(`Opening WebSocket, current state is ${this.getState()} , subprotocols: ${ActionCable.protocols}`);

  if (this.webSocket != null) {
    this.uninstallEventHandlers();
  }
  this.webSocket = new ActionCable.WebSocket(this.consumer.url, ActionCable.protocols);
  this.installEventHandlers();
  this.monitor.start();
  return true;
};

export default ActionCable;
