import { async, createTypes } from 'redux-action-creator';

export default createTypes([
  ...async('FETCH_SUGGESTED_CLINICS'),
  ...async('FETCH_CLINICS'),
  ...async('FETCH_NEXT_CLINICS'),
  ...async('FETCH_CLINIC'),
  ...async('CREATE_CLINIC'),
  ...async('UPDATE_CLINIC'),
  ...async('DISABLE_CLINIC'),
  ...async('ENABLE_CLINIC'),
], 'CLINICS');
