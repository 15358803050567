export default {
  'accepted-mandates': 'mandats-acceptes',
  'accepted-replacements': 'remplacements-acceptes',
  account: 'mon-compte',
  availabilities: 'disponibilites',
  callToAll: 'appel-a-tous',
  clinics: 'cliniques',
  en: 'fr',
  forgotPassword: 'mot-de-passe-oublie',
  login: 'connexion',
  mandates: 'mandats',
  'mandates-to-confirm': 'mandats-a-confirmer',
  matchmaking: 'matchmaking',
  notFound: 'page-introuvable',
  proposals: 'propositions',
  'proposed-mandates': 'mandats-proposes',
  'proposed-replacements': 'remplacements-proposes',
  resetPassword: 'nouveau-mot-de-passe',
  substitutes: 'remplacants',
  timesheets: 'feuilles-de-temps',
  users: 'utilisateurs',
  matchmakingRedirect: '/fr/matchmaking/remplacants',
};
