import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function Date({ startsAt }) {
  return (
    <div key="date" className="list-item__date">
      {moment(startsAt).format('ddd')}
      <span className="date">{(`0${moment(startsAt).format('D')}`).slice(-2)}</span>
      <span className="month">{moment(startsAt).format('MMM')}</span>
    </div>
  );
}

Date.propTypes = {
  startsAt: PropTypes.string.isRequired,
};
Date.defaultProps = {};

export default Date;
