import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// accordion content
import { accordions } from '../../../../config/locales/fr/accordions';

// actions
import helpcenterActions from '../../../actions/helpcenter';

import '../helpcenter.scss';
import '../accordion.scss';


class Accordion extends Component {
  static propTypes = {
    toggleAccordion: PropTypes.func.isRequired,
    closeAllPanels: PropTypes.func.isRequired,
    accordionId: PropTypes.string,
  }

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    accordionId: '',
  }

  toggleShowPanel(accordionId) {
    const { toggleAccordion, closeAllPanels } = this.props;
    if (accordionId === this.props.accordionId) {
      closeAllPanels();
    } else {
      toggleAccordion(accordionId);
    }
  }

  render() {
    const { accordionId } = this.props;
    const { t } = this.context;
    const subjects = Object.values(accordions);
    const accordionRows = Object.keys(subjects);
    return (
      <div className="helpcenter__accordion">
        {accordionRows.map((index) =>
          <div className="helpcenter__accordion__subject">
            <div className="helpcenter__accordion__subject__title">
              <h3>{t(`accordions.${Object.keys(accordions)[index]}.title`)}</h3>
            </div>
            {Object.keys(subjects[index].content).map((row) =>
              <button
                key={row}
                className={classNames('helpcenter__accordion__container', {
                    'helpcenter__accordion__container--active': accordionId === row,
                })}
                onClick={() => { this.toggleShowPanel(`${row}`); }}
              >
                <div className="helpcenter__accordion__action">
                  <span
                    className={classNames({
                    'helpcenter__accordion__action--active': accordionId === row,
                    'helpcenter__accordion__action__icon--close': accordionId === row,
                    'helpcenter__accordion__action__icon--open': accordionId !== row,
                  })}
                  />
                  <div className={
                    classNames('helpcenter__accordion__title', {
                    'helpcenter__accordion__title--active': accordionId === row,
                  })}
                  >
                    <span>{t(`accordions.${Object.keys(accordions)[index]}.content.${row}.rowTitle`)}</span>
                  </div>
                </div>
                <div
                  className={classNames('helpcenter__accordion__panel', {
                    'helpcenter__accordion__panel--active': accordionId === row,
                  })}
                >
                  <p>{t(`accordions.${Object.keys(accordions)[index]}.content.${row}.rowContent`)}</p>
                </div>
              </button>,
            )}
          </div>,
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    accordionId: state.helpcenter.accordionId,
  };
}

export default connect(mapStateToProps, { ...helpcenterActions })(Accordion);
