export default {
  addCallToAll: {
    send: 'Êtes-vous certain de vouloir envoyer cet appel à tous?',
  },
  editCallToAll: {
    send: 'Êtes-vous certain de vouloir modifier cet appel à tous?',
  },
  availability: {
    delete: 'Êtes-vous certain de vouloir supprimer cette disponibilité?',
    edit: 'Êtes-vous certain de vouloir modifier cette disponibilité?',
  },
  cancel: 'annuler',
  clinic: {
    disable: 'Êtes-vous certain de vouloir désactiver cette clinique?',
    enable: 'Êtes-vous certain de vouloir activer cette clinique?',
  },
  confirm: 'confirmer',
  mandate: {
    delete: 'Êtes-vous certain de vouloir supprimer ce mandat?',
  },
  matchmaking: {
    discardSelection: 'Êtes-vous certain de vouloir annuler la sélection? Toutes les propositions en cours seront perdues.',
    submitSelection: 'Êtes-vous certain de vouloir soumettre les propositions?',
    unselectSubstitute: 'Êtes-vous certain de vouloir désélectionner le remplaçant actuel? Toutes les propositions en cours seront perdues.',
  },
  proposal: {
    delete: 'Êtes-vous certain de vouloir supprimer cette proposition?',
    startingBefore: 'Le remplaçant souhaite commencer plus tard. Êtes-vous certain de vouloir ajouter cette date?',
    endingAfter: 'Le remplaçant souhaite terminer plus tôt. Êtes-vous certain de vouloir ajouter cette date?',
    startingBeforeAndEndingAfter : 'Le remplaçant souhaite commencer plus tard et terminer plus tôt. Êtes-vous certain de vouloir ajouter cette date?',
  },
  replacement: {
    delete: 'Êtes-vous certain de vouloir supprimer ce remplacement?',
    enable: 'Êtes-vous certain de vouloir modifier ce remplacement?',
  },
  timesheets: {
    confirm: 'Êtes-vous certain de vouloir confirmer la feuille de temps?',
    confirmAllEntries: 'Êtes-vous certain de vouloir confirmer toutes les entrées de temps?',
  },
  user: {
    disable: 'Êtes-vous certain de vouloir désactiver cet utilisateur?',
    enable: 'Êtes-vous certain de vouloir activer cet utilisateur?',
  },
};
