import { async, createTypes } from 'redux-action-creator';

export default createTypes([
  ...async('PRESIGN_UPLOAD'),
  'UPLOAD_FILE_CHANGED',
  'UPLOAD_PICTURE_FILE_CHANGED',
  'UPLOAD_VOIDED_CHECK_FILE_CHANGED',
  'UPLOAD_FILE_RESET',
  'UPLOAD_PICTURE_FILE_RESET',
  'UPLOAD_VOIDED_CHECK_FILE_RESET',
], 'UPLOAD');
