export default {
  'accepted-mandates': 'accepted-requests',
  'accepted-replacements': 'accepted-placements',
  account: 'my-account',
  availabilities: 'availabilities',
  callToAll: 'call-to-all',
  clinics: 'clinics',
  en: 'en',
  forgotPassword: 'forgot-password',
  login: 'login',
  mandates: 'mandates',
  'mandates-to-confirm': 'mandates-to-confirm',
  matchmaking: 'matchmaking',
  notFound: 'not-found',
  proposals: 'offers',
  'proposed-mandates': 'offers',
  'proposed-replacements': 'proposed-placements',
  resetPassword: 'new-password',
  substitutes: 'locums',
  timesheets: 'timesheets',
  users: 'users',
  matchmakingRedirect: '/en/matchmaking/locums',
};
