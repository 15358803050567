import Holidays from 'date-holidays';
import moment from 'moment';
import _ from 'lodash';

export function formatToDaylightSavingTime(date) {
  // if date is not in daylight saving time, add 3 hours
  // to return the right date.
  if (!moment(date).isDST()) {
    return moment(date).add(3, 'hours');
  }
  return moment(date);
}

export function formatDatesToArray(dates) {
  return Array.isArray(dates) ? dates : [dates];
}

export function getCurrentYearHolidays(dates, lang, timeZone = 'America/New_York') {
  const holidays = new Holidays();
  const state = process.env.REACT_APP_REGION_CODE.toUpperCase();

  holidays.init(
    { country: 'CA', state },
    {
      languages: lang,
      types: ['public', 'bank'],
      timeZone,
    },
  );

  const yearRangeHolidays = formatDatesToArray(dates).map((date) => {
    const holidaysForCurrentYear = holidays.getHolidays(moment(date).year());
    const publicHolidaysForCurrentYear = [];

    holidaysForCurrentYear.forEach((holiday) => {
      publicHolidaysForCurrentYear.push({
        type: 'holiday',
        start: formatToDaylightSavingTime(moment(holiday.start)).set('hour', 0).toDate(),
        end: moment(holiday.end).set('hour', 0).toDate(),
        title: holiday.name,
        allDay: true,
      });
    });
    return publicHolidaysForCurrentYear;
  });

  return yearRangeHolidays.flat();
}

export function getHoliday(currentDay, holidays) {
  const formatedDayString = `${moment(currentDay).format('YYYY-MM-DD')}`;
  const formatedDay = moment(formatedDayString).toDate();
  const holiday = _.find(holidays, { start: formatedDay });
  return holiday;
}

export default getCurrentYearHolidays;
