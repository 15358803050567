export default {
  accountActivation: {
    instructionsSent: 'Les instructions pour activer votre compte ont été envoyées à l\'adresse',
    spam: 'Si vous ne recevez pas le courriel d\'activation, pensez à vérifier vos pourriels.',
    submit: 'Obtenir un lien d\'activation',
    title: 'Activation de votre compte',
  },
  forgotPassword: {
    instructionsSent: 'Les instructions de réinitialisation de mot de passe ont été envoyées à l\'adresse',
    submit: 'Obtenir un lien de réinitialisation',
    title: 'Mot de passe oublié?',
  },
  login: {
    forgotPassword: 'Mot de passe oublié?',
    submit: 'Connexion',
    forgotPasswordLink: 'Obtenir un nouveau lien',
    modal: {
      title: 'Bienvenue sur l\'appli ',
      content: 'S\'il s\'agit de votre première visite et que vous êtes déjà client ou remplaçant, cliquez sur ce lien pour activer votre compte 👇',
      contact: 'Si vous n\'avez pas de compte et souhaitez obtenir plus d\'informations, contactez-nous au',
      orWebsite: ' ou via notre site web : ',
      website: 'https://www.vetreseau.com/joindre',
      activationLink: 'https://app.vetreseau.com/fr/activation',
    },
  },
  logout: 'Déconnexion',
  newPassword: {
    passwordWasReset: 'Le mot de passe a été réinitialisé',
    passwordResetFailed: 'Le lien de réinitialisation du mot de passe est expiré',
    submit: 'Enregistrer le mot de passe',
    title: 'Nouveau mot de passe',
  },
  notFound: {
    text:'La page que vous cherchez n\'existe pas',
    back: 'Retour au site',
  },
  notAllowed: {
    text:'Vous n\'êtes pas autorisé à accéder à cette page. Pour plus d\'informations, contactez le support client.',
    back: 'Retour au site',
  },
  notifications: {
    read: 'Notifications lues',
    unread: 'Nouvelles notifications',
    markAsRead: 'Marquer comme lue',
  },
  sidePanel: {
    title: {
      account: 'Mon profil',
      addAvailabilities: 'Ajouter des disponibilités',
      addCallToAll: 'Envoyer un appel à tous',
      addClinic: 'Ajouter une clinique',
      addMandate: 'Ajouter une demande',
      addUser: 'Ajouter un utilisateur',
      admin: 'Administration',
      editAvailability: 'Édition de la disponibilité',
      editCallToAll: "Édition de l'appel à tous",
      editClinic: 'Éditer une clinique',
      editEntry: 'Édition de la plage horaire',
      editPassword: 'Changer mon mot de passe',
      editReplacement: 'Éditer un remplacement',
      editUser: 'Éditer un utilisateur',
      notifications: 'Notifications',
      editMandate: 'Éditer une demande',
      helpCenter: 'Centre d\'aide',
    },
  },
  titles: {
    'accepted-mandates': 'Remplacements acceptés',
    'associations': 'Associations',
    'availabilities': 'Gestion des disponibilités',
    'disponibilites': 'Gestion des disponibilités',
    'clinics': 'Cliniques',
    'cliniques': 'Cliniques',
    'users': 'Gestion des utilisateurs',
    'utilisateurs': 'Gestion des utilisateurs',
    'edit-account': 'Modifier mon profil',
    'fr': 'Accueil',
    'mandates-to-confirm': 'Propositions à confirmer',
    'mandats': 'Demandes saisies',
    'matchmaking': 'Association',
    'my-account': 'Mon profil',
    'page-introuvable': 'Page introuvable',
    'proposals': 'Associations',
    'proposed-mandates': 'Remplacements proposés',
    'propositions': 'Propositions',
    'remplacants': 'Remplaçants',
    'remplacements-acceptes': 'Remplacements acceptés',
    'remplacements-proposes': 'Remplacements proposés',
    'mandats-proposes': 'Remplacements proposés',
    'mandats-acceptes': 'Remplacements acceptés',
    'notAllowed': 'Page inaccessible',
    'notFound': 'Page introuvable',
    'substitutes': 'Remplaçants',
    'timesheets': 'Feuilles de temps',
    'feuilles-de-temps': 'Feuilles de temps',
  },
};
