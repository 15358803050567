import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Styles
import '../Fields.scss';
import './RadioAccept.scss';

class RadioCheckboxAccept extends Component {
  constructor() {
    super();
    this.resetRadio = this.resetRadio.bind(this);
  }

  resetRadio(e) {
    const { input: { value, name }, change } = this.props;
    if (value === e.target.value) {
      change(name, null);
    }
  }

  render() {
    const { input, disabled } = this.props;
    return (
      <div className="radio-accept">
        <div className="radio-accept__wrapper">
          <label>
            <Field
              name={input.name}
              type="radio"
              component="input"
              value="false"
              label="acceptProposition.refuse"
              className="field__radio"
              disabled={disabled}
              onClick={(e) => this.resetRadio(e)}
            />
            <span className="radio-accept__label radio-accept__label--refuse" />
          </label>
        </div>
        <div className="radio-accept__wrapper">
          <label>
            <Field
              name={input.name}
              type="radio"
              component="input"
              value="true"
              label="acceptProposition.accept"
              className="field__radio"
              disabled={disabled}
              onClick={(e) => this.resetRadio(e)}
            />
            <span className="radio-accept__label radio-accept__label--accept" />
          </label>
        </div>
      </div>
    );
  }
}

RadioCheckboxAccept.contextTypes = {
  t: PropTypes.func,
};

RadioCheckboxAccept.propTypes = {
  change: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
};

RadioCheckboxAccept.defaultProps = {
  disabled: false,
};

export default RadioCheckboxAccept;
