// libs
import React from 'react';
import PropTypes from 'prop-types';
import { CrumbRoute } from 'o2web-react-core';

// component
import NotAllowed from '../pages/NotAllowed';

const contextTypes = {
  t: PropTypes.func,
};

const propTypes = {
  allowedRoles: PropTypes.array.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.bool]),
  currentRole: PropTypes.string,
  render: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.bool]),
  title: PropTypes.string,
};

const defaultProps = {
  component: false,
  currentRole: '',
  render: false,
  title: '',
};

function ProtectedRoute(props) {
  const { currentRole, allowedRoles, component, render, title, ...remainingProps } = props;
  return (
    <div>
      { (!currentRole || allowedRoles.includes(currentRole)) ? (
        <CrumbRoute {...remainingProps} title={title} component={component} render={render} />
        ) : (
          <CrumbRoute {...remainingProps} title="notAllowed" component={NotAllowed} render={render} />
        )
      }
    </div>
  );
}

ProtectedRoute.contextTypes = contextTypes;
ProtectedRoute.propTypes = propTypes;
ProtectedRoute.defaultProps = defaultProps;

export default ProtectedRoute;
