const validateTimesheet = values => {
  const errors = {};
  const entriesArrayErrors = [];
  values.entries.forEach((entryValue, entryIndex) => {
    const entriesError = {};
    if (!entryValue.startsAt) {
      entriesError.startsAt = 'validation.required';
    }
    if (!entryValue.endsAt) {
      entriesError.startsAt = 'validation.required';
    }
    if (!entryValue.distanceTraveled > 0) {
      entriesError.distanceTraveled = 'validation.timesheetEntries.distanceTraveled';
    }
    entriesArrayErrors[entryIndex] = entriesError;
  });
  errors.entries = entriesArrayErrors;
  return errors;
};

export default validateTimesheet;
