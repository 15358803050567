import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Styles
import '../Fields.scss';
import './ConfirmCheckbox.scss';

function Checkbox(field) {
  return (
    <div className="confirm-checkbox">
      <div className="confirm-checkbox__wrapper">
        <label>
          <Field
            name={field.name}
            {...field.input}
            type="checkbox"
            component="input"
            value={field.value}
            disabled={field.disabled}
            className="field__checkbox"
          />
          <span className="confirm-checkbox__label" />
        </label>
      </div>
    </div>
  );
}

Checkbox.contextTypes = {
  t: PropTypes.func,
};

export default Checkbox;
