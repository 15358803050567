import moment from 'moment';

export function getNextMonth(date) {
  return new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1,
  );
}

export function getPrevMonth(date) {
  return new Date(
    date.getFullYear(),
    date.getMonth() - 1,
    1,
  );
}

export function getNextWeek(date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 7,
  );
}

export function getPrevWeek(date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - 7,
  );
}

export function getCurrentMonth(date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
  );
}

export function getEndOfMonth(date) {
  const newDate = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1);
  newDate.setDate(newDate.getDate() - 1);
  return newDate;
}

export function getNthMonth(date, nth) {
  return new Date(
    date.getFullYear(),
    date.getMonth() + nth,
    1);
}

export function getBeginningOfMonth(date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
  );
}

export function getEndOfNthMonth(date, nth) {
  const newDate = new Date(
    date.getFullYear(),
    date.getMonth() + nth,
    1);
  newDate.setDate(newDate.getDate() - 1);
  return newDate;
}

export function getDateRange(startDate, endDate) {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).endOf('day');
  const days = end.diff(start, 'days') + 1;
  return [...Array(days).keys()].map((count) => moment(start).add(count, 'days'));
}

export function changeHour(dayDate, time) {
  const day = new Date(dayDate);
  const timeArr = time.split(':');
  day.setHours(timeArr[0], timeArr[1]);
  return new Date(day);
}

export function daysBetween(from, to) {
  const firstDay = moment(from).startOf('day');
  const lastDay = moment(to).endOf('day');
  return lastDay.diff(firstDay, 'days') + 1;
}

export function isWeekendDay(date, index = 0) {
  const day = moment(date).add(index, 'days');
  const isWeekend = moment(day).day() === 6 || moment(day).day() === 0;
  return isWeekend;
}
