import types from '../actions/alerts/types';
import notificationsTypes from '../actions/notifications/types';

const initialState = {
  alerts: [],
  count: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.REMOVE_ALERT:
      return { ...state, alerts: [...state.alerts.slice(1)] };
    case notificationsTypes.SUBSCRIBE_TO_NOTIFICATIONS_INCOMING:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            type: 'notification',
            details: action.payload.data.notificationReceived.message,
            count: state.count,
          },
        ],
        count: state.count + 1,
      };
    case types.PUSH_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            type: action.alert.type,
            content: action.alert.content,
            details: action.alert.details,
            count: state.count,
          },
        ],
        count: state.count + 1,
      };
    default:
  }
  return state;
}
