import React from 'react';
import classNames from 'classnames';
import FlipMove from 'react-flip-move';
import PropTypes from 'prop-types';

// helpers
import { formatDateAndTime } from '../../helpers/formatDates';
import { filterNotificationColor } from '../../helpers/formatNotifications';

function NotificationsList({ notifications, status, markNotificationAsRead }) {
  return (
    <FlipMove>
      {notifications[status].notifications.map(({ notificationType, ...notification }) =>
        <div
          className={classNames('notification', `notification--${notificationType}`, filterNotificationColor(notificationType))}
          key={`${status}-${notification.id}`}
        >
          <div className="notification__content">
            {notification.appLink && <a href={notification.appLink} className="notification__link" />}
            <p className="notification__message">{notification.message}</p>
            <span className="notification__date">
              {formatDateAndTime(notification.createdAt)}
            </span>
          </div>
          {status === 'unread' && (
          <button
            type="button"
            className="notification__button"
            onClick={() => markNotificationAsRead(notification)}
          />
          )}
        </div>,
    )}
    </FlipMove>
  );
}

NotificationsList.propTypes = {
  notifications: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  markNotificationAsRead: PropTypes.func.isRequired,
};

NotificationsList.defaultProps = {};

export default NotificationsList;
