import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// components
import Date from './parts/Date';
import DateTime from './parts/DateTime';
import Details from './parts/Details';
import DetailsClinic from './parts/DetailsClinic';
import Edit from './parts/Edit';
import Fields from './parts/Fields';
import Flexible from './parts/Flexible';
import PendingSubstitute from './parts/PendingSubstitute';
import Priority from './parts/Priority';
import Remaining from './parts/Remaining';
import Substitute from './parts/Substitute';
import Time from './parts/Time';
import MessageDrawer from './parts/MessageDrawer';

class ProposalRow extends Component {
  static propTypes = {
    acceptedProposals: PropTypes.bool,
    allMandates: PropTypes.bool,
    confirmProposal: PropTypes.func,
    field: PropTypes.string,
    proposal: PropTypes.object.isRequired,
    role: PropTypes.string.isRequired,
    change: PropTypes.func,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    acceptedProposals: false,
    allMandates: false,
    change: () => {},
    confirmProposal: () => {},
    field: null,
  };

  constructor(props) {
    super(props);
    this.confirmProposal = this.confirmProposal.bind(this);
    this.toggleDetails = this.toggleDetails.bind(this);
    const { substituteMessage } = this.props.proposal;
    this.state = {
      showDetails: substituteMessage,
    };
  }

  toggleDetails() {
    this.setState(({ showDetails }) => ({
      showDetails: !showDetails,
    }));
  }

  confirmProposal() {
    const { confirmProposal, proposal } = this.props;
    confirmProposal(proposal);
  }

  render() {
    const {
      acceptedProposals,
      allMandates,
      change,
      field,
      proposal,
      role,
    } = this.props;
    const { showDetails } = this.state;
    const {
      clinicPrice,
      clinicStatus,
      expirationExtended,
      expiresAt,
      replacement,
      replacement: {
        discarded,
        endsAt,
        flexibleTime,
        latestProposal,
        mandate: { consultation, surgery, accommodation },
        priority,
        startsAt,
      },
      substituteMessage,
    } = proposal;

    const adminRole = role === 'admin';
    const clinicRole = role === 'clinic_manager';
    const substituteRole = role === 'substitute';

    const status = latestProposal && latestProposal.status ?
      latestProposal.status : proposal.status;

    const substitute = latestProposal && latestProposal.substitute ?
      latestProposal.substitute : proposal.substitute;

    const userStatus = latestProposal && latestProposal.userStatus ?
      latestProposal.userStatus : proposal.userStatus;

    const userDistance = latestProposal && latestProposal.distance ?
      latestProposal.distance : proposal.distance;

    const isConfirmed = status === 'confirmed';
    const isPendingClinic = status === 'pending_clinic' || status === 'pending';
    const isPendingAdmin = status === 'pending_admin';
    const userPending = userStatus === 'pending' && status !== 'expired';
    const userExpired = userStatus === 'pending' && status === 'expired';

    const displaySubstitute = userStatus !== 'rejected' && status !== 'expired' && substitute;
    return (
      <li className={classNames(
        'list-item', {
        'list-item--open': showDetails,
        'list-item--clinic': clinicRole,
        'list-item--discarded': discarded,
        'list-item--expired': userExpired,
        'list-item--pending': !substituteRole && !adminRole && userPending,
        'list-item--has-drawer': substituteMessage,
      })}
      >
        <div className="list-item__header">
          <div
            className={classNames(
              'list-item__infos', {
              'list-item__infos--desktop': clinicRole,
            })}
            onClick={this.toggleDetails}
            onKeyPress={this.toggleDetails}
            tabIndex={0}
            role="button"
          >
            {(clinicRole || adminRole) && !isPendingClinic && !isConfirmed &&
              <Priority priority={priority} />
            }
            <DateTime
              endsAt={endsAt}
              flexibleTime={flexibleTime}
              role={role}
              startsAt={startsAt}
              status={status}
            />
            {substituteRole && substituteMessage &&
              <div className="list-item__message" />
            }
            {(
              (adminRole && substitute && displaySubstitute) ||
              (clinicRole && displaySubstitute)
            ) && (
              <Substitute
                adminRole={adminRole}
                clinicPrice={clinicPrice}
                endsAt={endsAt}
                isPendingClinic={isPendingClinic}
                startsAt={startsAt}
                substitute={substitute}
                userDistance={userDistance}
                userPending={userPending}
              />
            )}
            { clinicRole && !substitute && isPendingClinic &&
              <PendingSubstitute substitute={substitute} />
            }
            {adminRole && (
              <Remaining
                expiresAt={expiresAt}
                expirationExtended={expirationExtended}
                status={status}
                pending={userPending}
              />
            )}
          </div>
          {clinicRole &&
            <div
              className="list-item__infos list-item__infos--mobile"
            >
              <Date startsAt={startsAt} />
              {!isPendingClinic && !isConfirmed &&
                <div className="list-item__column">
                  <Priority priority={priority} />
                  <Flexible flexibleTime={flexibleTime} />
                </div>
              }
              <div className="list-item__column">
                {displaySubstitute && (
                  <Substitute
                    adminRole={adminRole}
                    clinicPrice={clinicPrice}
                    endsAt={endsAt}
                    isPendingClinic={isPendingClinic}
                    startsAt={startsAt}
                    substitute={substitute}
                    userDistance={userDistance}
                    userPending={userPending}
                  />
                )}
                {!isPendingClinic && !isConfirmed && !isPendingAdmin &&
                  <div className="list-item__hours">
                    <Time startsAt={startsAt} endsAt={endsAt} />
                  </div>
                }
              </div>
            </div>
          }
          {field && (
            <Fields
              acceptedProposals={acceptedProposals}
              confirmProposal={this.confirmProposal}
              field={field}
              role={role}
              pending={userPending}
              status={status}
              proposalDate={startsAt}
              change={change}
              clinicStatus={clinicStatus}
            />
          )}
          {allMandates && (
            <Edit replacement={replacement} />
          )}
        </div>
        {substituteRole && displaySubstitute && showDetails && (
          <Details
            accommodation={accommodation}
            consultation={consultation}
            surgery={surgery}
            toggleDetails={this.toggleDetails}
          />
        )}
        {substituteRole && substituteMessage && showDetails && (
          <MessageDrawer
            message={substituteMessage}
            toggleDetails={this.toggleDetails}
          />
        )}
        {clinicRole && displaySubstitute && showDetails && (
          <DetailsClinic
            clinicPrice={clinicPrice}
            substitute={substitute}
            userDistance={userDistance}
            userPending={userPending}
            toggleDetails={this.toggleDetails}
          />
        )}
      </li>
    );
  }
}

export default ProposalRow;
