import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducers from '../../app/reducers/index';

// helpers
import {
  shouldReturnEventProposals,
} from '../../app/helpers/customMiddleware';

const shouldReturnDataMiddleware = store => next => action => {
  switch (action.type) {
    case 'MATCHMAKING_FETCH_SUBSTITUTE_PROPOSALS_SUCCESS':
    case 'MATCHMAKING_FETCH_CLINIC_PROPOSALS_SUCCESS':
      return shouldReturnEventProposals(action, store, next);
    default:
  }
  return next(action);
};
const middlewares = [reduxThunk, shouldReturnDataMiddleware];

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
const store = createStoreWithMiddleware(reducers);

export default store;
