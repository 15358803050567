import moment from 'moment';
import types from '../actions/availabilities/types';
import {
  formatEvents,
  replaceAvailabilityInList,
  deleteAvailabilityInList,
  listAvailabilitiesByMonth,
} from '../helpers/formatAvailabilities';

const initialState = {
  loading: false,
  failed: false,
  events: [],
  updateSchedule: false,
  eventsInPeriod: [],
  allEventsInPeriod: [],
  periodIsUpdated: false,
  availabilityToUpdateId: '',
  availabilityToUpdate: {},
  userId: null,
  availabilitiesForList: [],
  updateList: false,
  listPagerCurrentDate: null,
  listPager: 0,
  scheduleIsLoading: false,
  defaultMonthForAvailabilities: null,
  submitting: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_AVAILABILITIES:
      return {
        ...state,
        loading: true,
        failed: false,
        events: [],
        updateSchedule: false,
        scheduleIsLoading: true,
      };
    case types.FETCH_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        events: formatEvents(action.payload.user.availabilities.nodes),
        updateSchedule: false,
        scheduleIsLoading: false,
      };
    case types.FETCH_AVAILABILITIES_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
        scheduleIsLoading: false,
      };
    case types.CREATE_AVAILABILITIES:
      return {
        ...state,
        loading: true,
        failed: false,
        updateSchedule: false,
        submitting: true,
      };
    case types.CREATE_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: true,
        updateList: true,
        submitting: false,
      };
    case types.CREATE_AVAILABILITIES_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
        submitting: false,
      };
    case types.FETCH_AVAILABILITIES_IN_PERIOD:
      return {
        ...state,
        loading: true,
        failed: false,
        updateSchedule: false,
        periodIsUpdated: false,
      };
    case types.FETCH_AVAILABILITIES_IN_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        eventsInPeriod: action.payload.user.availabilities.nodes
          .map(event => moment(event.startsAt).format('DD-MM-YYYY')),
        updateSchedule: false,
        periodIsUpdated: true,
      };
    case types.FETCH_AVAILABILITIES_IN_PERIOD_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
        periodIsUpdated: false,
      };
    case types.PERIOD_IS_UPDATED:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
        periodIsUpdated: false,
      };
    case types.FETCH_ALL_AVAILABILITIES:
      return { ...state, loading: true, failed: false, updateSchedule: false };
    case types.FETCH_ALL_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        allEventsInPeriod: action.payload.user.availabilities.nodes
          .map(event => moment(event.startsAt).format('DD-MM-YYYY')),
        updateSchedule: false,
      };
    case types.FETCH_ALL_AVAILABILITIES_FAIL:
      return { ...state, loading: false, failed: true, updateSchedule: false };
    case types.DELETE_AVAILABILITY:
      return { ...state, loading: true, failed: false, updateSchedule: false };
    case types.DELETE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: true,
      };
    case types.DELETE_AVAILABILITY_FAIL:
      return { ...state, loading: false, failed: true, updateSchedule: false };
    case types.SET_USER:
      return { ...state, userId: action.userId };
    case types.SET_AVAILABILITY_TO_UPDATE:
      return {
        ...state,
        loading: false,
        failed: false,
        availabilityToUpdateId: action.availabilityId,
      };
    case types.FETCH_AVAILABILITY_TO_UPDATE:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: false,
      };
    case types.FETCH_AVAILABILITY_TO_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: false,
        availabilityToUpdate: action.payload.availability,
      };
    case types.FETCH_AVAILABILITY_TO_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
      };
    case types.UPDATE_AVAILABILITY:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: false,
        submitting: true,
      };
    case types.UPDATE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: true,
        submitting: false,
      };
    case types.UPDATE_AVAILABILITY_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
        submitting: false,
      };
    case types.FETCH_AVAILABILITIES_FOR_LIST:
      return {
        ...state,
        loading: true,
        failed: false,
        scheduleIsLoading: true,
      };
    case types.FETCH_AVAILABILITIES_FOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        availabilitiesForList: state.availabilitiesForList.concat(
          listAvailabilitiesByMonth(
            action.payload.user.availabilities.nodes,
            state.listPagerCurrentDate,
            state.availabilityListLength,
          ),
        ),
        scheduleIsLoading: false,
      };
    case types.FETCH_AVAILABILITIES_FOR_LIST_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        scheduleIsLoading: false,
      };
    case types.UPDATE_AVAILABILITIES_FOR_LIST:
      return { ...state, loading: true, failed: false, updateList: false };
    case types.UPDATE_AVAILABILITIES_FOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        availabilitiesForList: listAvailabilitiesByMonth(
          action.payload.user.availabilities.nodes,
          new Date(),
          state.availabilityListLength * state.listPager,
        ),
        updateList: false,
      };
    case types.UPDATE_AVAILABILITIES_FOR_LIST_FAIL:
      return { ...state, loading: false, failed: true, updateList: false };
    case types.AVAILABILITIIES_LIST_IS_UPDATED:
      return {
        ...state,
        loading: false,
        failed: false,
        updateList: false,
      };
    case types.FETCH_UPDATED_AVAILABILITY:
      return { ...state, loading: true, failed: false };
    case types.FETCH_UPDATED_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: false,
        availabilitiesForList: replaceAvailabilityInList(
          [...state.availabilitiesForList],
          action.payload.availability,
        ),
      };
    case types.FETCH_UPDATED_AVAILABILITY_FAILED:
      return { ...state, loading: false, failed: true };
    case types.REMOVE_AVAILABILITY_FROM_LIST:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: false,
        availabilitiesForList: deleteAvailabilityInList(
          [...state.availabilitiesForList],
          action.availabilityId,
        ),
      };
    case types.SET_AVAILABILITY_LIST_LENGTH:
      return {
        ...state,
        availabilityListLength: action.length,
      };
    case types.UPDATE_AVAILABILITIES_LIST_DATE:
      return { ...state, listPagerCurrentDate: action.currentDate };
    case types.UPDATE_AVAILABILITIES_LIST_PAGER:
      return { ...state, listPager: action.currentPage };
    case types.SET_DEFAULT_MONTH_FOR_AVAILABILITIES:
      return {
        ...state,
        defaultMonthForAvailabilities: action.defaultMonthForAvailabilities,
      };
    default:
  }
  return state;
}
