import React from 'react';
import PropTypes from 'prop-types';

import placeholderProfile from '../../../../../../assets/images/picture.svg';
import placeholder from '../../../../../../assets/images/placeholder.svg';

function PictureUploadPlaceholder({ displayType }, { t }) {
  return (
    <img
      src={displayType === 'profile' ? placeholderProfile : placeholder}
      alt={t('form.uploadImage')}
      className="picture-uploader__preview__placeholder"
    />
  );
}

PictureUploadPlaceholder.propTypes = {
  displayType: PropTypes.string,
};

PictureUploadPlaceholder.defaultProps = {
  displayType: 'picture',
};

PictureUploadPlaceholder.contextTypes = {
  t: PropTypes.func,
};

export default PictureUploadPlaceholder;
