export default {
  next_month: 'Next month',
  noEventsInRangeClinic: 'No requests for this month',
  noEventsInRange: 'No availabilities for this month',
  prev_month: 'Previous month',
  today: 'Today',
  modal: {
    welcome: 'Bienvenue dans la nouvelle app VetRéseau! 🥳🎉',
    substitute: {
      line1: 'Assure-toi d’aller valider les informations de ton profil',
      picture: 'et de mettre à jour ta photo.',
    },
    clinic_manager: {
      line1: 'Assurez-vous d’aller valider les informations dans le profil de votre clinique',
    },
  },
};
