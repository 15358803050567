// libs
import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';

// components
import Proposals from './Proposals';
import Checkbox from '../../../../forms/fields/checkbox/Checkbox';
import ProposalsPrices from '../ProposalsPrices';

class Clinics extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (JSON.stringify(this.props) === JSON.stringify(nextProps)) {
      return false;
    }
    return true;
  }
  render() {
    const {
      fields,
      onToggleLockPrices,
      onToggleSubstituteMessage,
      substituteMessageReplacementIds,
      unlockedPricesClinicIds,
      unselectEvent,
    } = this.props;
    const { t } = this.context;
    return (
      <div>
        {fields.length > 0 ? (
        fields.map((field, index, data) => {
          const { clinic } = data.get(index);
          return (
            <div className="matchmaking__proposals__clinic" key={clinic.id}>
              <div className="clinic__wrapper">
                <span className="clinic__name">{clinic.name}</span>
                <div className="tooltip__wrapper">
                  <span className="tooltip">{t('form.manualValidation')}</span>
                  <Field
                    name={`${field}.manualValidation`}
                    component={Checkbox}
                    label="manualValidation"
                  />
                </div>
              </div>
              <ProposalsPrices
                field={field}
                onToggleLockPrices={() => { onToggleLockPrices(clinic.id); }}
                isPricesUnlocked={unlockedPricesClinicIds.includes(clinic.id)}
              />
              <FieldArray
                name={`${field}.proposals`}
                component={Proposals}
                onToggleSubstituteMessage={onToggleSubstituteMessage}
                substituteMessageReplacementIds={substituteMessageReplacementIds}
                unselectEvent={unselectEvent}
              />
            </div>
          );
        })
      ) : (
        <p>{t('matchmaking.noProposals')}</p>
      )}
      </div>
    );
  }
}

Clinics.propTypes = {
  fields: PropTypes.object.isRequired,
  onToggleLockPrices: PropTypes.func.isRequired,
  onToggleSubstituteMessage: PropTypes.func.isRequired,
  substituteMessageReplacementIds: PropTypes.array.isRequired,
  unlockedPricesClinicIds: PropTypes.array.isRequired,
  unselectEvent: PropTypes.func.isRequired,
};

Clinics.contextTypes = {
  t: PropTypes.func,
};

export default Clinics;
