// Libs
import 'react-dates/initialize';
import React, { Component } from 'react';
import { reduxForm, FieldArray, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { getDateRange, changeHour, daysBetween } from '../../helpers/dates';
import { scrollToError } from '../../helpers/scrollToError';

// Components
import Accommodation from '../forms/fields/general/Accommodation';
import DayAvailability from './fieldArray/DaysMandate';
import CheckboxField from '../forms/fields/checkbox/CheckboxField';
import Input from '../forms/fields/input/Input';
import MandateType from '../forms/fields/general/MandateType';
import ReactDates from '../forms/fields/reactDates/ReactDates';
import Textarea from '../forms/fields/textarea/Textarea';
import BasicLoader from '../loader/BasicLoader';

// Actions
import alertsActions from '../../actions/alerts/index';
import userActions from '../../actions/user/index';
import mandatesActions from '../../actions/mandates/index';

// Validation
import validate from './validate/validate';

class AddMandate extends Component {
  static createReplacement(day) {
    const startHour = day.allDay ? '08:00' : day.startTime;
    const endHour = day.allDay ? '21:00' : day.endTime;
    const startTime = changeHour(day.day, startHour);
    const endTime = changeHour(day.day, endHour);
    return {
      endsAt: endTime.toISOString(),
      startsAt: startTime.toISOString(),
      flexibleTime: day.flexibleTime,
      priority: day.priority,
    };
  }

  static propTypes = {
    allEventsInPeriod: PropTypes.array.isRequired,
    changeFieldsValues: PropTypes.func.isRequired,
    clinic: PropTypes.object,
    clinicId: PropTypes.string.isRequired,
    createMandate: PropTypes.func.isRequired,
    currentDate: PropTypes.instanceOf(Date),
    defaultStartDate: PropTypes.instanceOf(Date),
    disabledMandateType: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchAllMandates: PropTypes.func.isRequired,
    fetchMandatesInPeriod: PropTypes.func.isRequired,
    formIsSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    periodDidUpdate: PropTypes.func.isRequired,
    periodIsUpdated: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    pushAlert: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    defaultStartDate: null,
    clinic: null,
    error: '',
    currentDate: null,
  };

  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.periodDidUpdate = this.periodDidUpdate.bind(this);
    this.highlightMandates = this.highlightMandates.bind(this);
    this.updateHighlightedMandates = this.updateHighlightedMandates.bind(this);
    const startDate = props.defaultStartDate ? moment(props.defaultStartDate) : null;

    this.state = {
      startDate,
      endDate: null,
      focusedInput: null,
      periodDays: [],
      updatePeriod: false,
    };
  }

  componentDidMount() {
    const { fetchAllMandates, clinicId } = this.props;
    const startOfTheMonth = moment().startOf('month');
    const endOfTheMonth = moment().endOf('month');
    fetchAllMandates(
      {
        id: clinicId,
        from: startOfTheMonth.toISOString(),
        to: endOfTheMonth.toISOString(),
      },
    );
  }

  componentDidUpdate() {
    const { periodIsUpdated, periodDidUpdate } = this.props;
    const { startDate, endDate } = this.state;
    const bothDateSelected = startDate && endDate;

    if (periodIsUpdated && bothDateSelected) {
      const selectedDays = getDateRange(startDate, endDate);
      const periodDays = [];
      selectedDays.forEach((day) => {
        periodDays.push(day);
      });
      this.updatePeriod(periodDays);
      periodDidUpdate();
    }
  }

  updatePeriod(periodDays) {
    if (periodDays.length !== 0) {
      this.setState({
        periodDays,
        updatePeriod: true,
      });
    }
  }

  handleFormSubmit({ adminNote, ...values }) {
    const { createMandate, hidePanel, pushAlert, clinicId } = this.props;
    const {
      replacements,
      mandateType,
      accommodation,
      message,
      wantedCount,
      surgery,
      surgeryOptional,
      consultation,
    } = values;
    const inputArray = [];
    let surgeryType = 'false';
    if (surgery || surgeryOptional) {
      surgeryType = surgery ? 'true' : 'optional';
    }
    replacements.forEach((day) => {
      const input = AddMandate.createReplacement(day, values);
      inputArray.push(input);
    });
    const mandateToCreate = {
      input: {
        clinicId,
        replacements: inputArray,
        accommodation: accommodation === 'true',
        message,
        mandateType,
        wantedCount: parseInt(wantedCount, 10),
        consultation,
        surgery: surgeryType,
        ...(adminNote !== null) && { adminNote },
      },
    };
    createMandate(mandateToCreate)
      .then((data) => {
        if (data && data.createMandate.success) {
          pushAlert({
            type: 'success',
            content: 'alerts.added',
          });
          hidePanel();
        }
      });
  }

  handlePeriodChange(startDate, endDate) {
    const { changeFieldsValues, clinicId, fetchMandatesInPeriod } = this.props;
    const periodLength = daysBetween(startDate, endDate);
    changeFieldsValues('wantedCount', periodLength);
    if (startDate && endDate) {
      fetchMandatesInPeriod(
        {
          id: clinicId,
          from: startDate.toISOString(),
          to: endDate.toISOString(),
        },
      );
    }
  }

  periodDidUpdate() {
    this.setState({
      updatePeriod: false,
    });
  }

  highlightMandates(date) {
    const { allEventsInPeriod } = this.props;
    return allEventsInPeriod.includes(date.format('DD-MM-YYYY'));
  }

  updateHighlightedMandates(date) {
    const { fetchAllMandates, clinicId } = this.props;
    const startOfTheMonth = moment(date).startOf('month');
    const endOfTheMonth = moment(date).endOf('month');

    fetchAllMandates(
      {
        id: clinicId,
        from: startOfTheMonth.toISOString(),
        to: endOfTheMonth.toISOString(),
      },
    );
  }

  render() {
    const {
      clinic,
      currentDate,
      disabledMandateType,
      error,
      formIsSubmitting,
      handleSubmit,
      hidePanel,
      isAdmin,
      lang,
      pristine,
      submitting,
    } = this.props;
    const { periodDays, updatePeriod } = this.state;
    const { t } = this.context;

    const submitForm = handleSubmit(this.handleFormSubmit);

    return (
      <div>
        <form onSubmit={submitForm} className="side-panel__form">
          <div>
            {formIsSubmitting && <BasicLoader />}
            {error && <div className="form-error">{error}</div>}
            {clinic && (
              <fieldset className="side-panel__form__section side-panel__form__section--profile">
                <div>
                  <div className="profile">
                    <div className="profile__infos">
                      <span className="profile__infos__title">
                        {clinic.name}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            )}
            <fieldset className="side-panel__form__section">
              <div className={`react-dates-lang-wrapper-${lang}`}>
                <h2 className="side-panel__form__section__title">{t('form.addMandate.period')}*</h2>
                <ReactDates
                  startDate={this.state.startDate}
                  startDateId="periodStart"
                  endDate={this.state.endDate}
                  endDateId="periodEnd"
                  onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                  onClose={({ startDate, endDate }) => this.handlePeriodChange(startDate, endDate)}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                  numberOfMonths={1}
                  startDatePlaceholderText={t('form.addAvailability.startDate')}
                  endDatePlaceholderText={t('form.addAvailability.endDate')}
                  isDayHighlighted={(date) => this.highlightMandates(date)}
                  initialVisibleMonth={
                    this.state.startDate === null ?
                      () => moment(currentDate) : () => this.state.startDate
                  }
                  onPrevMonthClick={(newMonth) => this.updateHighlightedMandates(newMonth)}
                  onNextMonthClick={(newMonth) => this.updateHighlightedMandates(newMonth)}
                />
                <Field
                  name="wantedCount"
                  component={Input}
                  label="addMandate.wantedCount"
                  type="number"
                  className="field__wanted-count"
                />
              </div>
            </fieldset>
            {Array.isArray(periodDays) && periodDays.length > 0 ?
              <div>
                <fieldset className="side-panel__form__section">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.addMandate.availabilities')}*</h2>
                    <div>
                      <FieldArray
                        name="replacements"
                        component={DayAvailability}
                        periodDays={periodDays}
                        updatePeriod={updatePeriod}
                        periodDidUpdate={this.periodDidUpdate}
                        moment={moment}
                        rerenderOnEveryChange
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset className="side-panel__form__section">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.addMandate.mandateType')}</h2>
                    <MandateType disabled={disabledMandateType} />
                  </div>
                </fieldset>
                <fieldset className="side-panel__form__section">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.addMandate.skills.title')}</h2>
                    <div>
                      <div>
                        <Field
                          name="consultation"
                          component={CheckboxField}
                          type="checkbox"
                          label="addMandate.skills.consultation"
                          className="field__checkbox--full-width field__checkbox--clustered"
                        />
                        <Field
                          name="surgery"
                          component={CheckboxField}
                          type="checkbox"
                          label="addMandate.skills.required"
                          className="field__checkbox--full-width field__checkbox--clustered"
                        />
                        <Field
                          name="surgeryOptional"
                          component={CheckboxField}
                          type="checkbox"
                          label="addMandate.skills.optional"
                          className="field__checkbox--full-width field__checkbox--clustered"
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="side-panel__form__section">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.addMandate.accommodation.available')}</h2>
                    <Accommodation />
                  </div>
                </fieldset>
                <fieldset className="side-panel__form__section">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.addMandate.other')}</h2>
                    <Field
                      name="message"
                      component={Textarea}
                      label="addMandate.message"
                    />
                  </div>
                </fieldset>
                {isAdmin &&
                <fieldset className="side-panel__form__section side-panel__form__section--admin">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.adminSection')}</h2>
                    <div className="fields-one-col">
                      <Field
                        name="adminNote"
                        label="adminNoteGeneral"
                        component={Textarea}
                        className="field--light"
                      />
                    </div>
                  </div>
                </fieldset>
                }
              </div>
              :
              <div>
                <fieldset className="side-panel__form__section side-panel__form__section--padded">
                  <div>
                    <span className="side-panel__form__hint">{t('form.addMandate.selectPeriod')}</span>
                  </div>
                </fieldset>
              </div>
            }
          </div>
          <div className="form__actions">
            <button
              className="form__cancel"
              type="button"
              disabled={submitting || formIsSubmitting}
              onClick={() => hidePanel()}
            >
              {t('form.formActions.cancel')}
            </button>
            <button
              className="form__submit"
              type="submit"
              disabled={
                pristine || submitting || formIsSubmitting ||
                !Array.isArray(periodDays) || periodDays.length === 0
              }
            >
              {t('form.formActions.add')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const disabledMandateType = state.user.role === 'admin';

  return {
    initialValues: {
      message: '',
      mandateType: disabledMandateType ?
        state.matchmaking.selectedAccountType : 'veterinarian',
      accommodation: 'false',
      wantedCount: 0,
      surgeryOptional: false,
      surgery: false,
      consultation: true,
    },
    allEventsInPeriod: state.mandates.allEventsInPeriod,
    clinicId: state.mandates.clinicId,
    currentDate: state.schedule.date,
    disabledMandateType,
    eventsInPeriod: state.mandates.eventsInPeriod,
    formIsSubmitting: state.mandates.submitting,
    isAdmin: state.user.role === 'admin',
    lang: state.i18nState.lang,
    periodIsUpdated: state.mandates.periodIsUpdated,
  };
}

export default withRouter(connect(mapStateToProps,
  { ...alertsActions, ...userActions, ...mandatesActions })(
  reduxForm({
    form: 'addMandate',
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToError(errors),
    validate,
  })(AddMandate)));
