import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import scheduleActions from '../../actions/schedule';

class CalendarModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
  }

  static contextTypes = {
    t: PropTypes.func,
  }

  closeModal() {
    const { closeModal } = this.props;
    closeModal();
  }
  render() {
    const { t } = this.context;
    const { role } = this.props;
    return (
      <div className="modal__container">
        <button
          className="icon icon--close"
          onClick={() => { this.closeModal(); }}
        />
        <div className="modal__content">
          <span><strong>{t('calendar.modal.welcome')}</strong></span>
          {role === 'clinic_manager' &&
            <span>{t('calendar.modal.clinic_manager.line1')}<span className="icon--clinic" /></span>
          }
          {role === 'substitute' &&
            <span>{t('calendar.modal.substitute.line1')}<span className="icon--profile" />{t(`calendar.modal.${role}.picture`)}</span>
          }
        </div>
      </div>
    );
  }
}

export default connect(null, { ...scheduleActions })(CalendarModal);
