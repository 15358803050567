// libs
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';

// components
import BasicLoader from '../loader/BasicLoader';
import TextArea from '../forms/fields/textarea/Textarea';
import SubstitutesSubForm from './forms/SubstitutesSubForm';

// actions
import actionsCallToAll from '../../actions/callToAll';
import alertsActions from '../../actions/alerts';
import confirmActionActions from '../../actions/confirmAction/index';

// validation
import validate from './forms/validate/validate';

// styles
import './styles.scss';

class AddCallToAll extends Component {
  constructor() {
    super();
    this.submitCallToAll = this.submitCallToAll.bind(this);
    this.triggerCallToAllSubmit = this.triggerCallToAllSubmit.bind(this);
  }

  componentDidMount() {
    const {
      replacement,
      fetchAvailableSubstitutesList,
    } = this.props;

    fetchAvailableSubstitutesList(replacement.id, replacement.clinicId);
  }

  submitCallToAll() {
    const { actionToConfirm } = this.props;
    actionToConfirm(this.triggerCallToAllSubmit, 'addCallToAll.send');
  }

  triggerCallToAllSubmit() {
    const {
      replacement,
      createCallToAll,
      pushAlert,
      hidePanel,
      formValues: values,
    } = this.props;

    const { substitutes } = values;

    const substitutesToContact = substitutes.filter((substitute) => substitute.selected)
      .map(substitute => ({ userId: substitute.id }));

    createCallToAll({
      input: {
        replacementId: replacement.id,
        message: values.message,
        users: substitutesToContact,
      },
    }).then((data) => {
      if (data && data.createCallToAll.success) {
        pushAlert({
          type: 'success',
          content: 'alerts.callToAllSent',
        });
        hidePanel();
      } else {
        data.createCallToAll.errors.map(error => pushAlert({
          type: 'error',
          content: error.message,
        }));
      }
    });
  }

  render() {
    const { t } = this.context;
    const {
      handleSubmit,
      error,
      hidePanel,
      pristine,
      replacement,
      submitting,
      substitutes,
      formDataIsLoading,
      formIsSubmitting,
    } = this.props;
    const submitForm = handleSubmit(this.submitCallToAll);

    return (
      <form onSubmit={submitForm} className="side-panel__form">
        {(formIsSubmitting || formDataIsLoading) && <BasicLoader />}
        {error && <div className="form-error">{error}</div>}
        <fieldset className="side-panel__form__section">
          <div className="call-to-all__replacement">
            <div className="call-to-all__replacement__date">
              <span>
                {moment(replacement.start).format('MMM')} <strong>{moment(replacement.start).format('D')}</strong>
              </span>
            </div>
            <div className="call-to-all__replacement__details">
              <h3 className="call-to-all__replacement__clinic">{replacement.clinicName}</h3>
              <div className="call-to-all__replacement__range">
                <span>{moment(replacement.start).format('HH:mm')}</span>
                <div className="arrow" />
                <span>{moment(replacement.end).format('HH:mm')}</span>
              </div>
            </div>
          </div>
          <h2 className="side-panel__form__section__title">{t('form.addCallToAll.selectUsers')}*</h2>
          <FieldArray
            component={SubstitutesSubForm}
            name="substitutes"
            substitutes={substitutes}
            formName="addCallToAll"
            required
          />
          <Field
            name="message"
            label="addCallToAll.message"
            component={TextArea}
            required
          />
        </fieldset>
        <div className="form__actions">
          <button
            className="form__cancel"
            type="button"
            onClick={() => hidePanel()}
          >
            {t('form.formActions.cancel')}
          </button>
          <button className="form__submit" type="submit" disabled={pristine || submitting || formIsSubmitting}>
            {t('form.formActions.submit')}
          </button>
        </div>
      </form>
    );
  }
}

AddCallToAll.propTypes = {
  actionToConfirm: PropTypes.func.isRequired,
  createCallToAll: PropTypes.func.isRequired,
  error: PropTypes.string,
  fetchAvailableSubstitutesList: PropTypes.func.isRequired,
  formDataIsLoading: PropTypes.bool.isRequired,
  formIsSubmitting: PropTypes.bool.isRequired,
  formValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  hidePanel: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  pushAlert: PropTypes.func.isRequired,
  replacement: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  substitutes: PropTypes.array.isRequired,
};
AddCallToAll.defaultProps = {
  error: '',
  formValues: {},
};
AddCallToAll.contextTypes = {
  t: PropTypes.func,
};

function mapStateToProps(state) {
  const { replacement, substitutes } = state.callToAll;

  return {
    replacement,
    substitutes,
    formIsSubmitting: state.callToAll.submitting,
    formDataIsLoading: state.callToAll.loading,
    formValues: getFormValues('addCallToAll')(state),
    initialValues: {
      replacement,
      substitutes: substitutes.map(({ id }) => ({
        id,
        selected: false,
      })),
    },
  };
}

export default connect(
  mapStateToProps,
  { ...actionsCallToAll, ...alertsActions, ...confirmActionActions },
)(reduxForm({
  form: 'addCallToAll',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate,
})(AddCallToAll));
