// Libs
import 'react-dates/initialize';
import React, { Component } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { scrollToError } from '../../helpers/scrollToError';

// Actions
import alertsActions from '../../actions/alerts/index';
import userActions from '../../actions/user/index';
import mandatesActions from '../../actions/mandates/index';

// Components
import BasicLoader from '../loader/BasicLoader';

// Validation
import validate from './validate/validate';
import Select from '../forms/fields/select/Select';
import { timeSelector } from '../../helpers/form';
import Checkbox from '../forms/fields/checkbox/Checkbox';
import { changeHour } from '../../helpers/dates';
import MandateType from '../forms/fields/general/MandateType';
import CheckboxField from '../forms/fields/checkbox/CheckboxField';
import Accommodation from '../forms/fields/general/Accommodation';
import Textarea from '../forms/fields/textarea/Textarea';

class EditReplacement extends Component {
  static propTypes = {
    clinic: PropTypes.object,
    editableMandate: PropTypes.bool.isRequired,
    error: PropTypes.string,
    formIsSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    clinic: null,
    error: '',
  };

  constructor() {
    super();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = {
      formIsLoading: false,
    };
  }

  componentDidMount() {
    const {
      replacementToUpdateId,
      getReplacementToUpdate,
      mandateToUpdateId,
      getMandateToUpdate,
    } = this.props;
    getReplacementToUpdate({ id: replacementToUpdateId });
    getMandateToUpdate({ id: mandateToUpdateId });
  }

  componentDidUpdate(prevProps) {
    const {
      replacementToUpdateId: prevReplacementToUpdateId,
      mandateToUpdateId: prevMandateToUpdateId,
    } = prevProps;
    const {
      replacementToUpdateId,
      getReplacementToUpdate,
      mandateToUpdateId,
      getMandateToUpdate,
    } = this.props;
    if (prevReplacementToUpdateId !== replacementToUpdateId) {
      getReplacementToUpdate({ id: replacementToUpdateId });
    }
    if (mandateToUpdateId !== prevMandateToUpdateId) {
      getMandateToUpdate({ id: mandateToUpdateId });
    }
  }

  createReplacement(values) {
    const { replacementToUpdate: { startsAt } } = this.props;
    const startHour = values.startsAt;
    const endHour = values.endsAt;
    const dayDate = startsAt;
    const startTime = changeHour(dayDate, startHour);
    const endTime = changeHour(dayDate, endHour);
    return {
      endsAt: endTime.toISOString(),
      startsAt: startTime.toISOString(),
      priority: values.priority,
      flexibleTime: values.flexibleTime,
    };
  }

  handleFormSubmit() {
    const {
      replacementToUpdateId,
      hidePanel,
      updateReplacement,
      pushAlert,
      formValues: values,
      mandateToUpdateId,
      mandateToUpdate: {
        clinic: {
          id: clinicId,
        },
        replacements,
        wantedCount,
      },
      updateMandate,
    } = this.props;
    const {
      adminNote,
      mandateType,
      accommodation,
      message,
      surgery,
      surgeryOptional,
      consultation,
    } = values;
    this.setState({ formIsLoading: true });
    let surgeryType = 'false';
    if (surgery || surgeryOptional) {
      surgeryType = surgery ? 'true' : 'optional';
    }
    const mandateReplacements = replacements.map((replacement) => {
      const input = {
        id: replacement.id,
        mandateId: replacement.mandate.id,
        flexibleTime: replacement.flexibleTime,
        endsAt: replacement.endsAt,
        startsAt: replacement.startsAt,
        priority: replacement.priority,
        destroy: false,
      };
      return input;
    });
    const mandateToUpdate = {
      id: mandateToUpdateId,
      input: {
        accommodation: accommodation === 'true',
        message,
        mandateType,
        consultation,
        surgery: surgeryType,
        clinicId,
        replacements: mandateReplacements,
        wantedCount,
        ...(adminNote !== null) && { adminNote },
      },
    };
    const input = this.createReplacement(values);
    updateMandate(mandateToUpdate)
      .then(() => {
        updateReplacement({
          id: replacementToUpdateId,
          input,
        }).then((data) => {
          this.setState({ formIsLoading: false });
          if (data && data.updateReplacement.success) {
            pushAlert({
              type: 'success',
              content: 'alerts.edited',
            });
            hidePanel();
          }
        });
      });
  }

  render() {
    const {
      clinic,
      handleSubmit,
      pristine,
      submitting,
      error,
      hidePanel,
      isAdmin,
      replacementToUpdate: { startsAt },
      formIsSubmitting,
      formValues: { priority },
      mandateToUpdate,
      disabledMandateType,
      editableMandate,
      role,
    } = this.props;
    const { t } = this.context;
    const { formIsLoading } = this.state;

    const submitForm = handleSubmit(this.handleFormSubmit);

    return (
      <div>
        <form onSubmit={submitForm} className="side-panel__form">
          <div>
            {(formIsSubmitting || !Object.keys(mandateToUpdate).length || formIsLoading) &&
              <BasicLoader />}
            {error && <div className="form-error">{error}</div>}
            {clinic && (
              <fieldset className="side-panel__form__section side-panel__form__section--profile">
                <div>
                  <div className="profile">
                    <div className="profile__infos">
                      <span className="profile__infos__title">
                        {clinic.name}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            )}
            <fieldset className="side-panel__form__section">
              <div>
                <h2 className="side-panel__form__section__title">{t('form.addMandate.period')}*</h2>
                <div>
                  <div className="day-mandate days-mandate">
                    <div className="day-mandate--date">
                      { moment(startsAt).format('ddd') }
                      <span>{ moment(startsAt).format('DD') }</span>
                    </div>
                    <Field
                      name="startsAt"
                      type="text"
                      component={Select}
                      label="addMandate.startTime"
                      className={classNames('field--select', { 'field--priority': priority })}
                      innerLabel
                    >
                      { timeSelector() }
                    </Field>
                    <span className="day-mandate__arrow" />
                    <Field
                      name="endsAt"
                      type="text"
                      component={Select}
                      label="addMandate.endTime"
                      className={classNames('field--select', { 'field--priority': priority })}
                      innerLabel
                    >
                      { timeSelector() }
                    </Field>
                    <div className="checkbox-tooltip__wrapper">
                      <span className="checkbox-tooltip">{ t('form.addMandate.priority') }</span>
                      <Field
                        name="priority"
                        type="text"
                        component={Checkbox}
                        label="addMandate.priority"
                        className="field__checkbox--round field__checkbox--priority day-mandate__checkbox"
                      />
                    </div>
                    <div className="checkbox-tooltip__wrapper">
                      <span className="checkbox-tooltip">{ t('form.addMandate.flexible') }</span>
                      <Field
                        name="flexibleTime"
                        type="text"
                        component={Checkbox}
                        label="addMandate.flexible"
                        className="field__checkbox--round field__checkbox--flexible day-mandate__checkbox"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            { mandateToUpdate &&
              <div>
                { (editableMandate || role === 'admin') &&
                  <div>
                    <fieldset className="side-panel__form__section">
                      <div>
                        <h2 className="side-panel__form__section__title">{t('form.addMandate.mandateType')}</h2>
                        <MandateType disabled={disabledMandateType} />
                      </div>
                    </fieldset>
                    <fieldset className="side-panel__form__section">
                      <div>
                        <h2 className="side-panel__form__section__title">{t('form.addMandate.skills.title')}</h2>
                        <div>
                          <div>
                            <Field
                              name="consultation"
                              component={CheckboxField}
                              type="checkbox"
                              label="addMandate.skills.consultation"
                              className="field__checkbox--full-width field__checkbox--clustered"
                            />
                            <Field
                              name="surgery"
                              component={CheckboxField}
                              type="checkbox"
                              label="addMandate.skills.required"
                              className="field__checkbox--full-width field__checkbox--clustered"
                            />
                            <Field
                              name="surgeryOptional"
                              component={CheckboxField}
                              type="checkbox"
                              label="addMandate.skills.optional"
                              className="field__checkbox--full-width field__checkbox--clustered"
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="side-panel__form__section">
                      <div>
                        <h2 className="side-panel__form__section__title">{t('form.addMandate.accommodation.available')}</h2>
                        <Accommodation />
                      </div>
                    </fieldset>
                  </div>
                }
                <fieldset className="side-panel__form__section">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.addMandate.other')}</h2>
                    <Field
                      name="message"
                      component={Textarea}
                      label="addMandate.message"
                    />
                  </div>
                </fieldset>
                {isAdmin &&
                <fieldset className="side-panel__form__section side-panel__form__section--admin">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.adminSection')}</h2>
                    <div className="fields-one-col">
                      <Field
                        name="adminNote"
                        label="adminNoteGeneral"
                        component={Textarea}
                        className="field--light"
                      />
                    </div>
                  </div>
                </fieldset>
                }
              </div>
            }
          </div>
          <div className="form__actions">
            <button
              className="form__cancel"
              type="button"
              disabled={submitting || formIsSubmitting}
              onClick={() => hidePanel()}
            >
              {t('form.formActions.cancel')}
            </button>
            <button
              className="form__submit"
              type="submit"
              disabled={pristine || submitting || formIsSubmitting}
            >
              {t('form.formActions.update')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

EditReplacement.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pushAlert: PropTypes.func.isRequired,
  replacementToUpdateId: PropTypes.string.isRequired,
  replacementToUpdate: PropTypes.object.isRequired,
  getReplacementToUpdate: PropTypes.func.isRequired,
  updateReplacement: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  mandateToUpdate: PropTypes.object,
  disabledMandateType: PropTypes.bool.isRequired,
  mandateToUpdateId: PropTypes.string.isRequired,
  getMandateToUpdate: PropTypes.func.isRequired,
  updateMandate: PropTypes.func.isRequired,
};

EditReplacement.defaultProps = {
  formValues: {},
  mandateToUpdate: {},
};

function mapStateToProps(state) {
  const { mandates: { replacementToUpdate } } = state;
  const initialStartsAt = moment(replacementToUpdate.startsAt).format('HH:mm');
  const initialEndsAt = moment(replacementToUpdate.endsAt).format('HH:mm');
  const disabledMandateType = state.user.role === 'admin';
  const { mandateToUpdate } = state.mandates;
  const editableMandate = !Object.keys(mandateToUpdate).length ? false : mandateToUpdate
    .replacements
    .every((replacement) => replacement.latestProposal === null);
  return {
    initialValues: {
      accommodation: mandateToUpdate && mandateToUpdate.accommodation ? 'true' : 'false',
      adminNote: mandateToUpdate ? mandateToUpdate.adminNote : true,
      consultation: mandateToUpdate ? mandateToUpdate.consultation : true,
      endsAt: initialEndsAt,
      flexibleTime: replacementToUpdate.flexibleTime,
      mandateType: mandateToUpdate ? mandateToUpdate.mandateType : 'veterinarian',
      message: mandateToUpdate ? mandateToUpdate.message : '',
      priority: replacementToUpdate.priority,
      startsAt: initialStartsAt,
      surgery: mandateToUpdate && mandateToUpdate.surgery === 'true',
      surgeryOptional: mandateToUpdate && mandateToUpdate.surgery === 'optional',
    },
    lang: state.i18nState.lang,
    isAdmin: state.user.role === 'admin',
    userId: state.user.data.id,
    replacementToUpdateId: state.mandates.replacementToUpdateId,
    replacementToUpdate,
    formValues: getFormValues('editReplacement')(state),
    formIsSubmitting: state.mandates.submitting,
    disabledMandateType,
    mandateToUpdate: state.mandates.mandateToUpdate,
    mandateToUpdateId: state.mandates.mandateToUpdateId,
    editableMandate,
    role: state.user.role,
  };
}

export default withRouter(connect(mapStateToProps,
  { ...alertsActions, ...userActions, ...mandatesActions })(
  reduxForm({
    enableReinitialize: true,
    form: 'editReplacement',
    onSubmitFail: (errors) => scrollToError(errors),
    validate,
  })(EditReplacement)));
