// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import BigCalendar from 'react-big-calendar';
import { connect } from 'react-redux';
import { getEndOfMonth, getCurrentMonth } from '../../helpers/dates';
import { getCurrentYearHolidays } from '../../helpers/holidays';

// components
import AgendaToolbar from './toolbar/AgendaToolbar';
import BasicLoader from '../loader/BasicLoader';
import CustomAgenda from './agenda/CustomAgenda';
import CustomToolbar from './toolbar/CustomToolbar';
import DayHeader from './dayHeader/DayHeader';
import EventWrapper from './event/EventWrapper';
import ManagerWithoutClinic from './ManagerWithoutClinic';

// actions
import actionsSchedule from '../../actions/schedule';
import actionsUser from '../../actions/user';
import actionsMandates from '../../actions/mandates';

// Styles
import './styles.scss';

class ScheduleClinic extends Component {
  constructor() {
    super();
    this.checkWindowPosition = this.checkWindowPosition.bind(this);
    this.state = {
      calendarView: 'month',
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkWindowPosition);
    this.checkWindowPosition();
    this.fetchScheduleEvents();
  }

  componentDidUpdate(prevProps) {
    const { currentClinicId: prevClinicId, currentDate: prevDate, userId: prevUserId } = prevProps;
    const { currentClinicId, userId, currentDate, updateSchedule } = this.props;
    if (
      currentClinicId !== prevClinicId ||
      currentDate !== prevDate ||
      userId !== prevUserId || updateSchedule
    ) {
      this.fetchScheduleEvents();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowPosition);
  }

  fetchScheduleEvents() {
    const { currentDate, fetchMandates, currentClinicId } = this.props;
    if (currentClinicId) {
      const currentMonth = getCurrentMonth(currentDate);
      const nextMonth = getEndOfMonth(currentDate);
      fetchMandates({
        id: currentClinicId,
        from: currentMonth.toISOString(),
        to: nextMonth.toISOString(),
      });
    }
  }

  checkWindowPosition() {
    const calendarView = window.innerWidth > 1000 ? 'month' : 'customAgenda';
    this.setState({ calendarView });
  }

  updatesScheduleEvents() {
    const { mandates, currentDate, lang, userTimeZone } = this.props;
    const holidays = getCurrentYearHolidays(currentDate, lang, userTimeZone);
    return holidays.concat(mandates);
  }

  render() {
    const { currentDate, changeDate, lang, scheduleIsLoading, currentClinicId } = this.props;
    const { t } = this.context;
    const { calendarView } = this.state;

    if (!currentClinicId) {
      return <ManagerWithoutClinic />;
    }

    moment.locale(lang, {
      week: {
        dow: 0,
        doy: 6,
      },
    });
    const localizer = BigCalendar.momentLocalizer(moment);
    const components = {
      event: EventWrapper,
      toolbar: CustomToolbar,
      month: {
        toolbar: CustomToolbar,
        dateHeader: DayHeader,
      },
      customAgenda: {
        toolbar: AgendaToolbar,
      },
    };
    const events = this.updatesScheduleEvents();
    const formats = {
      dateFormat: (date, culture, rbcLocalizer) =>
        rbcLocalizer.format(date, 'ddd', culture),
      weekdayFormat: (date, culture, rbcLocalizer) =>
        rbcLocalizer.format(date, 'dddd', culture),
      agendaHeaderFormat: (date, culture, rbcLocalizer) =>
        rbcLocalizer.format(date, 'MMMM YYYY', culture),
      agendaDateFormat: (date, culture, rbcLocalizer) =>
        rbcLocalizer.format(date, 'ddd', culture),
    };

    return (
      <div className="schedule schedule--clinic">
        <BigCalendar
          popup={false}
          localizer={localizer}
          events={events}
          default
          components={components}
          date={currentDate}
          views={{
            month: true,
            customAgenda: CustomAgenda,
          }}
          view={calendarView}
          defaultView={calendarView}
          onView={() => {}}
          onNavigate={(date) => changeDate(date)}
          formats={formats}
          culture={lang}
          messages={{
            noEventsInRange: t('calendar.noEventsInRangeClinic'),
          }}
        />
        { scheduleIsLoading && <BasicLoader /> }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentDate: state.schedule.date,
    lang: state.i18nState.lang,
    mandates: state.mandates.events,
    updateSchedule: state.mandates.updateSchedule,
    userId: state.user.data.id,
    userTimeZone: state.user.data.profile.timeZone,
    scheduleIsLoading: state.mandates.scheduleIsLoading,
    currentClinicId: state.user.currentClinicId,
  };
}

ScheduleClinic.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  changeDate: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  mandates: PropTypes.array.isRequired,
  updateSchedule: PropTypes.bool.isRequired,
  fetchMandates: PropTypes.func.isRequired,
  scheduleIsLoading: PropTypes.bool.isRequired,
  currentClinicId: PropTypes.string,
  userId: PropTypes.string,
  userTimeZone: PropTypes.string,
};

ScheduleClinic.defaultProps = {
  currentClinicId: null,
  userId: null,
  userTimeZone: null,
};

ScheduleClinic.contextTypes = {
  t: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { ...actionsSchedule, ...actionsUser, ...actionsMandates },
)(ScheduleClinic);
