// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// actions
import matchmakingActions from '../../../../actions/matchmaking';
import alertActions from '../../../../actions/alerts';
import usersActions from '../../../../actions/admin/users';
import sidePanelActions from '../../../../actions/dynamicSidePanel/index';

// components
import EditUser from '../../../admin/users/EditUser';

// helper
import { openInGoogleMaps, copyAddressToClipboard } from '../../../../helpers/addresses';

// styles
import './styles.scss';

class SubstituteMiniProfile extends Component {
  static propTypes = {
    fetchSubstituteAddress: PropTypes.func.isRequired,
    fetchUser: PropTypes.func.isRequired,
    pushAlert: PropTypes.func.isRequired,
    resetSubstituteAddress: PropTypes.func.isRequired,
    selectedMiniprofileUserId: PropTypes.string.isRequired,
    substitute: PropTypes.object,
    substituteAddress: PropTypes.object,
    toggleSubstituteMiniprofile: PropTypes.func.isRequired,
    updateComponent: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    substitute: {},
    substituteAddress: {},
  };

  constructor(props) {
    super(props);
    this.navRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleCopyAddress = this.handleCopyAddress.bind(this);
  }

  componentWillMount() {
    const { resetSubstituteAddress } = this.props;
    resetSubstituteAddress();
  }

  componentDidMount() {
    const { fetchSubstituteAddress, selectedMiniprofileUserId, substitute } = this.props;
    if (selectedMiniprofileUserId) {
      document.addEventListener('mousedown', this.handleClickOutside);
      fetchSubstituteAddress({ id: substitute.id });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  editUser(id) {
    const { updateComponent, fetchUser } = this.props;
    fetchUser({ id });
    updateComponent(EditUser, 'editUser', { sidebarIcon: 'edit' });
  }

  handleClickOutside(event) {
    const { toggleSubstituteMiniprofile } = this.props;
    if (this.navRef && !this.navRef.current.contains(event.target)) {
      toggleSubstituteMiniprofile();
    }
  }

  handleCopyAddress(addressObject) {
    const { pushAlert } = this.props;
    copyAddressToClipboard(addressObject);
    pushAlert({
      type: 'success',
      content: 'alerts.addressClipboard',
    });
  }

  render() {
    const {
      substitute: {
        id,
        profile: {
          adminNote,
        },
      },
      substituteAddress,
      substituteAddress: {
        address,
        address2,
        city,
        zipcode,
      },
    } = this.props;
    const { substitute } = this.props;
    const addressObject = substitute && substituteAddress;
    const { t } = this.context;
    return (
      <div className="matchmaking__miniprofile" ref={this.navRef}>
        <div className="matchmaking__miniprofile__wrapper">
          <div className="matchmaking__miniprofile__header">
            <span>{t('matchmaking.miniprofile.profileOverview')}</span>
            <div>
              <button
                onClick={() => { this.editUser(id); }}
              >
                <span className="matchmaking__miniprofile__icon matchmaking__miniprofile__icon--edit" />
              </button>
            </div>
          </div>
          <div>
            <div className="matchmaking__miniprofile__section matchmaking__miniprofile__section--address">
              { address &&
              <div>
                {address}
                <button
                  onClick={() => { this.handleCopyAddress(addressObject); }}
                >
                  <span className="matchmaking__miniprofile__icon matchmaking__miniprofile__icon--copy-address" />
                </button>
                <button
                  onClick={() => { openInGoogleMaps(addressObject); }}
                >
                  <span className="matchmaking__miniprofile__icon matchmaking__miniprofile__icon--map" />
                </button>
              </div>
           }
              { address2 && <div>{address2}</div> }
              { city && <div>{city}</div> }
              { zipcode && <div>{zipcode}</div> }
            </div>
            {adminNote &&
            <div className="matchmaking__miniprofile__section matchmaking__miniprofile__section--adminNote">
              <span className="matchmaking__miniprofile__section__title">{t('matchmaking.miniprofile.adminNote')}</span>
              <p>{adminNote}</p>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedMiniprofileUserId: state.matchmaking.selectedMiniprofileUserId,
    substituteAddress: state.matchmaking.substituteAddress,
  };
}

export default connect(
  mapStateToProps, {
    ...matchmakingActions,
    ...alertActions,
    ...usersActions,
    ...sidePanelActions,
  })(SubstituteMiniProfile);
