import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function CardTitle({
  acceptedProposals,
  allMandates,
  error,
  validForm,
  role,
}, { t }) {
  if (allMandates) {
    return (
      <div className="mandate-card__content__header">
        <div>
          <h3>
            {t(`mandates.listTitle.${role}.all`)}
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className="mandate-card__content__header">
      <div>
        <h3>
          {t(`mandates.listTitle.${role}.${acceptedProposals ? 'confirmed' : 'pending'}`)}
        </h3>
        {!acceptedProposals && <p>{ t('mandates.instructions') }</p>}
        {error && <div className="mandate-card__form-error">{t(error)}</div>}
      </div>
      {!acceptedProposals &&
        <div key="submit">
          <button
            className={classNames(
              'mandate-card__submit',
              { 'mandate-card__submit--disabled': validForm },
            )}
            type="submit"
            disabled={validForm}
          >
            { t('form.formActions.submit') }
          </button>
        </div>
      }
      {acceptedProposals && role === 'admin' &&
        <div key="submit">
          <button
            className={classNames(
              'mandate-card__submit',
              { 'mandate-card__submit--disabled': validForm },
            )}
            type="submit"
            disabled={validForm}
          >
            { t('form.formActions.confirm') }
          </button>
        </div>
      }
    </div>
  );
}

CardTitle.propTypes = {
  acceptedProposals: PropTypes.bool.isRequired,
  allMandates: PropTypes.bool.isRequired,
  error: PropTypes.string,
  role: PropTypes.string.isRequired,
  validForm: PropTypes.bool.isRequired,
};
CardTitle.defaultProps = {
  error: null,
};
CardTitle.contextTypes = {
  t: PropTypes.func,
};

export default CardTitle;
