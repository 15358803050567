// libs
import React from 'react';

// assets
import logo from '../../../assets/images/loaders/vetreseau-blue.svg';

// styles
import './styles.scss';

function BasicLoader() {
  return (
    <div className="basic-loader">
      <img src={logo} alt="Vetmatch" />
    </div>
  );
}

export default BasicLoader;
