export default {
  acceptedMandates: 'Accepted requests',
  acceptedReplacements: 'Accepted replacements',
  accordion: 'Accordion',
  availabilities: 'Availabilities',
  calendar: 'Calendar',
  clinics: 'Clinic management',
  faq: 'F.A.Q.',
  faqUrl: {
    on: {
      clinic_manager: 'https://www.vetlocum.com/faq-clinic',
      substitute: 'https://www.vetlocum.com/faq-locum'
    },
    qc: {
      clinic_manager: 'https://www.vetreseau.com/faq-clinique',
      substitute: 'https://www.vetreseau.com/faq-remplacant'
    }
  },
  helpcenter: 'Tutorial',
  history: 'History',
  mandates: 'Requests',
  mandatesToConfirm: 'Confirmed requests / Requests awaiting confirmation',
  matchmakingClinics: 'Clinics',
  matchmakingSubstitutes: 'Locums',
  matchmakingTechnician: 'Animal health technicians',
  matchmakingVeterinarian: 'Veterinarians',
  proposedMandates: 'Requests',
  proposedReplacements: 'Proposed replacements',
  timesheets: 'Time sheet',
  users: 'User management',
};
