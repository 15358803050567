// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Styles
import './styles.scss';

class ListItemDetails extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      toggleDetails,
      disableAvailability,
      updateAvailability,
      availability: { surgery, consultation, message, distance },
    } = this.props;
    const { t } = this.context;
    return (
      <div className={classNames('list-item__drawer', { 'list-item__drawer--desktop': message })}>
        <button
          className="list-item__drawer__close"
          tabIndex="0"
          onClick={toggleDetails}
          onKeyPress={toggleDetails}
        />
        <div className="list-item__drawer__infos">
          <div className="list-item__bullet list-item__bullet--mobile list-item__bullet--marker">
            <span>{ t(`availabilities.distancesText.${distance}`) }</span>
          </div>
          { consultation &&
            <div className="list-item__bullet list-item__bullet--mobile list-item__bullet--consult">
              <span>{ t('event.consultation') }</span>
            </div>
          }
          { surgery &&
            <div className="list-item__bullet list-item__bullet--mobile list-item__bullet--surgery">
              <span>{ t('event.surgery') }</span>
            </div>
          }
          { message &&
            <div className="list-item__bullet list-item__bullet--info">
              <strong>{ t('event.additionnalInfo') }:</strong>
              <span>
                { message }
              </span>
            </div>
          }
        </div>

        <div className="list-item__drawer__actions">
          <button
            className="list-item__btn list-item__btn--delete"
            tabIndex="0"
            onClick={disableAvailability}
            onKeyPress={disableAvailability}
          >
            { t('event.actions.delete') }
          </button>
          <button
            className="list-item__btn list-item__btn--edit"
            tabIndex="0"
            onClick={updateAvailability}
            onKeyPress={updateAvailability}
          >
            { t('event.actions.edit') }
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    updateSchedule: state.availabilities.updateSchedule,
    userId: state.user.data.id,
  };
}

ListItemDetails.propTypes = {
  availability: PropTypes.object.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  disableAvailability: PropTypes.func.isRequired,
  updateAvailability: PropTypes.func.isRequired,
};

ListItemDetails.defaultProps = {
  // userId: null,
};

ListItemDetails.contextTypes = {
  t: PropTypes.func,
};

export default connect(
  mapStateToProps,
  {},
)(ListItemDetails);
