// libs
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function CallToAllCardHeader(_, { t }) {
  return (
    <div className={classNames(
      'mandate-card__header',
    )}
    >
      <div className="mandate-card__tab">
        <span>{t('mandates.callToAllHeader')}</span>
      </div>
    </div>
  );
}

CallToAllCardHeader.propTypes = {};

CallToAllCardHeader.defaultProps = {};

CallToAllCardHeader.contextTypes = {
  t: PropTypes.func,
};

export default CallToAllCardHeader;
