import types from '../actions/mandateProposals/types';
import mandatesTypes from '../actions/mandates/types';

const initialState = {
  endCursor: null,
  hasNextPage: false,
  mandates: [],
  loading: false,
  refresh: false,
  uniqueMandate: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_PROPOSALS_SUCCESS:
    case types.ADMIN_REVIEW_USERS_PROPOSALS_SUCCESS:
    case types.ADMIN_REVIEW_CLINICS_PROPOSALS_SUCCESS:
    case types.ADMIN_REVIEW_COMPLETE_PROPOSALS_SUCCESS:
    case types.CLINIC_REVIEW_PROPOSALS_SUCCESS:
    case types.USER_REVIEW_PROPOSALS_SUCCESS:
    case mandatesTypes.DISABLE_REPLACEMENT_SUCCESS:
    case mandatesTypes.ENABLE_REPLACEMENT_SUCCESS:
    case mandatesTypes.UPDATE_REPLACEMENT_SUCCESS:
      return {
        ...state,
        refresh: true,
      };
    case types.FETCH_ADMIN_MANDATES:
    case types.FETCH_CLINIC_MANDATES:
    case types.FETCH_SUBSTITUTE_MANDATES:
      return {
        ...state,
        refresh: false,
        mandates: [],
        loading: true,
      };
    case types.FETCH_NEXT_ADMIN_MANDATES:
    case types.FETCH_NEXT_CLINIC_MANDATES:
    case types.FETCH_NEXT_SUBSTITUTE_MANDATES:
      return {
        ...state,
        refresh: false,
        loading: true,
      };
    case types.FETCH_ADMIN_MANDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        mandates: action.payload.mandates.nodes,
        ...action.payload.mandates.pageInfo,
      };
    case types.FETCH_CLINIC_MANDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        mandates: action.payload.clinic.mandates.nodes,
        ...action.payload.clinic.mandates.pageInfo,
      };
    case types.FETCH_SUBSTITUTE_MANDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        mandates: action.payload.user.mandates.nodes,
        ...action.payload.user.mandates.pageInfo,
      };
    case types.FETCH_NEXT_ADMIN_MANDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        mandates: [...state.mandates, ...action.payload.mandates.nodes],
        ...action.payload.mandates.pageInfo,
      };
    case types.FETCH_NEXT_CLINIC_MANDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        mandates: [...state.mandates, ...action.payload.clinic.mandates.nodes],
        ...action.payload.clinic.mandates.pageInfo,
      };
    case types.FETCH_NEXT_SUBSTITUTE_MANDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        mandates: [...state.mandates, ...action.payload.user.mandates.nodes],
        ...action.payload.user.mandates.pageInfo,
      };
    case types.FETCH_ADMIN_MANDATES_FAIL:
    case types.FETCH_CLINIC_MANDATES_FAIL:
    case types.FETCH_SUBSTITUTE_MANDATES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case types.FETCH_CLINIC_MANDATE:
      return {
        ...state,
        listIsLoading: true,
        updateSchedule: false,
        refreshMandateList: false,
      };
    case types.FETCH_CLINIC_MANDATE_SUCCESS: {
      const { payload: { mandate } } = action;
      mandate.replacements = mandate.replacements.sort((replacement, nextReplacement) => (
        new Date(replacement.startsAt) - new Date(nextReplacement.startsAt)
      ));
      return {
        ...state,
        listIsLoading: false,
        uniqueMandate: mandate,
      };
    }
    case types.FETCH_CLINIC_MANDATE_FAIL:
      return {
        ...state,
        listIsLoading: false,
        mandateUnavailable: true,
        uniqueMandate: {},
      };
    case types.FETCH_SUBSTITUTE_MANDATE:
      return {
        ...state,
        listIsLoading: true,
        updateSchedule: false,
        refreshMandateList: false,
      };
    case types.FETCH_SUBSTITUTE_MANDATE_SUCCESS: {
      const { payload: { mandate } } = action;
      mandate.proposals = mandate.proposals.sort((proposal, nextProposal) => (
        new Date(proposal.startsAt) - new Date(nextProposal.startsAt)
      ));
      return {
        ...state,
        listIsLoading: false,
        uniqueMandate: mandate,
      };
    }
    case types.FETCH_SUBSTITUTE_MANDATE_FAIL:
      return {
        ...state,
        listIsLoading: false,
        mandateUnavailable: true,
        uniqueMandate: {},
      };
    default:
  }
  return state;
}
