// libs
import { combineReducers } from 'redux';
import { i18nState } from 'redux-i18n';
import { reducer as formReducer } from 'redux-form';

// reducers
import alertsReducer from './alerts';
import availabilitiesReducer from './availabilities';
import callToAllReducer from './callToAll';
import clinicsReducer from './clinics';
import confirmActionReducer from './confirmAction';
import dynamicSidePanelReducer from './dynamicSidePanel';
import expertisesReducer from './expertises';
import exoticAnimalsReducer from './exoticAnimals';
import languagesReducer from './languages';
import mandatesReducer from './mandates';
import mandateProposalsReducer from './mandateProposals';
import matchmakingReducer from './matchmaking';
import notificationsReducer from './notifications';
import proposalsReducer from './proposals';
import scheduleReducer from './schedule';
import softwaresReducer from './softwares';
import timesheetsReducer from './timesheets';
import uploadsReducer from './uploads';
import userReducer from './user';
import usersReducer from './users';
import helpcenterReducer from './helpcenter';

const rootReducer = combineReducers({
  alerts: alertsReducer,
  availabilities: availabilitiesReducer,
  callToAll: callToAllReducer,
  clinics: clinicsReducer,
  confirmAction: confirmActionReducer,
  dynamicSidePanel: dynamicSidePanelReducer,
  exoticAnimals: exoticAnimalsReducer,
  expertises: expertisesReducer,
  form: formReducer,
  helpcenter: helpcenterReducer,
  i18nState,
  languages: languagesReducer,
  mandateProposals: mandateProposalsReducer,
  mandates: mandatesReducer,
  matchmaking: matchmakingReducer,
  notifications: notificationsReducer,
  proposals: proposalsReducer,
  schedule: scheduleReducer,
  softwares: softwaresReducer,
  timesheets: timesheetsReducer,
  uploads: uploadsReducer,
  user: userReducer,
  users: usersReducer,
});

export default rootReducer;
