import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Priority({ priority }) {
  return (
    <span
      className={classNames(
        'list-item__priority',
        'type__icon type__icon--priority', {
        'type__icon--active': priority,
      })}
    />
  );
}

Priority.propTypes = {
  priority: PropTypes.bool,
};
Priority.defaultProps = {
  priority: false,
};
Priority.contextTypes = {};

export default Priority;
