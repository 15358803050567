export default {
  confirm: 'Êtes-vous certain de vouloir confirmer la feuille de temps?',
  confirmed: 'Feuille de temps confirmée le',
  confirmEntry: 'Confirmer',
  dinner: 'Souper',
  entryIsConfirmed: 'Entrée confirmée',
  export: {
    download: 'Télécharger',
    generate: 'Générer le rapport',
    ready: 'Le rapport est prêt à être téléchargé',
    technician: 'Techniciens',
    veterinarian: 'Vétérinaires',
  },
  filters: {
    title: 'Filtrer par',
    none: 'Aucun filtre',
    complete: 'Complété',
    incomplete: 'Incomplet',
    confirmed: 'Confirmé',
  },
  instructions: 'Vous devez remplir les informations relatives à chaque journée afin de pouvoir envoyer votre feuille de temps.',
  late: 'La feuille de temps est en retard',
  lunch: 'Dîner',
  noTimesheet: 'Aucune feuille de temps disponible',
  phone_reminders: 'Rappels téléphoniques',
  title: 'Feuilles de temps',
  schedule: 'Plage horaire',
  submitted: 'Feuille de temps envoyée le',
};
