// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import moment from 'moment';
import FlipMove from 'react-flip-move';

// components
import BasicLoader from '../loader/BasicLoader';
import ClinicRow from './row/ClinicRow';
import DatesHeader from './row/DatesHeader';
import HeaderRow from './row/HeaderRow';
import EmptyRow from './row/EmptyRow';
import SearchClinic from '../admin/clinics/forms/SearchForm';

// helpers
import { matchmakingRange, matchmakingDaysRange } from '../../helpers/range';
import { scrollToSelectedDay, isClinicSelected } from '../../helpers/matchmaking';

// actions
import matchmakingActions from '../../actions/matchmaking';
import mandatesActions from '../../actions/mandates';

// styles
import './styles.scss';

class Clinics extends Component {
  static propTypes = {
    clinics: PropTypes.array.isRequired,
    currentDate: PropTypes.instanceOf(Date).isRequired,
    dateFrom: PropTypes.object.isRequired,
    dateTo: PropTypes.object.isRequired,
    endCursor: PropTypes.string,
    fetchClinics: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    holidays: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    mandateType: PropTypes.string.isRequired,
    search: PropTypes.string,
    selectClinic: PropTypes.func.isRequired,
    selectedClinics: PropTypes.array.isRequired,
    unselectClinic: PropTypes.func.isRequired,
    refreshCallToAllStatus: PropTypes.bool.isRequired,
    updateSchedule: PropTypes.bool.isRequired,
    selectedMiniprofileClinicId: PropTypes.string,
    toggleClinicMiniprofile: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    endCursor: null,
    search: '',
    selectedMiniprofileClinicId: '',
  };

  constructor(props) {
    super(props);
    this.toggleClinicMiniprofile = this.toggleClinicMiniprofile.bind(this);
    this.updateClinics = this.updateClinics.bind(this);
    this.toggleClinicSelection = this.toggleClinicSelection.bind(this);
  }

  componentDidMount() {
    this.updateClinics();
  }

  componentDidUpdate(prevProps) {
    const {
      currentDate: prevDate,
      mandateType: prevMandateType,
      refreshCallToAllStatus: prevRefreshCallToAllStatus,
      updateSchedule: prevUpdateSchedule,
    } = prevProps;
    const { currentDate, mandateType, refreshCallToAllStatus, updateSchedule } = this.props;
    if (moment(prevDate).valueOf() !== moment(currentDate).valueOf() ||
      prevMandateType !== mandateType ||
      (refreshCallToAllStatus && !prevRefreshCallToAllStatus) ||
      (updateSchedule !== prevUpdateSchedule && updateSchedule)
    ) {
      this.updateClinics();
    }
  }

  fetchClinicsList(nextPage = false) {
    const {
      dateFrom,
      dateTo,
      endCursor,
      fetchClinics,
      mandateType,
      search,
      updateSchedule,
    } = this.props;

    const { from, to } = matchmakingRange(dateFrom, dateTo);

    fetchClinics({
      from,
      to,
      search,
      mandateType,
      ...(nextPage && { after: endCursor }),
    }).then(() => {
      if (!updateSchedule) scrollToSelectedDay('selectedDay', 'matchmaking__calendar__wrapper');
    });
  }

  updateClinics() {
    this.fetchClinicsList();
  }

  toggleClinicMiniprofile(clinidId) {
    const { toggleClinicMiniprofile } = this.props;
    toggleClinicMiniprofile(clinidId);
  }

  toggleClinicSelection(clinic) {
    const { selectClinic, selectedClinics, unselectClinic } = this.props;

    if (isClinicSelected(selectedClinics, clinic)) {
      unselectClinic(clinic);
    } else {
      selectClinic(clinic);
    }
  }

  render() {
    const {
      clinics,
      currentDate,
      dateFrom,
      dateTo,
      hasNextPage,
      holidays,
      loading,
      selectedClinics,
      selectedMiniprofileClinicId,
    } = this.props;
    const { t } = this.context;

    return (
      <div className="wrapper--fullwidth">
        <div className="matchmaking__calendar__wrapper">
          <div className="matchmaking__calendar">
            <div className="matchmaking__calendar__header">
              <DatesHeader
                currentDate={currentDate}
                date={dateFrom}
                days={matchmakingDaysRange(dateFrom, dateTo)}
                holidays={holidays}
              >
                <SearchClinic
                  fetchClinics={this.updateClinics}
                  formName="matchmakingClinicSearch"
                />
              </DatesHeader>
            </div>
            <HeaderRow
              date={dateFrom}
              days={matchmakingDaysRange(dateFrom, dateTo)}
              holidays={holidays}
              title={t('matchmaking.clinics')}
            />
            <FlipMove>
              {clinics.map(clinic =>
                <div key={clinic.id}>
                  <ClinicRow
                    key={clinic.id}
                    clinic={clinic}
                    date={moment(dateFrom)}
                    days={matchmakingDaysRange(dateFrom, dateTo)}
                    holidays={holidays}
                    onSelect={this.toggleClinicSelection}
                    onToggleClinicMiniprofile={this.toggleClinicMiniprofile}
                    selected={isClinicSelected(selectedClinics, clinic)}
                    selectedMiniprofileClinicId={selectedMiniprofileClinicId}
                  />
                </div>,
              )}
            </FlipMove>
            {clinics.length === 0 &&
              <EmptyRow text={t('matchmaking.search.noClinics')} />
            }
            { loading && (
              <BasicLoader />
            )}
          </div>

          {hasNextPage && (
            <div className="matchmaking__calendar__pagination">
              <button
                className="content-item__button form__submit"
                type="button"
                onClick={() => this.fetchClinicsList(true)}
              >
                {t('form.formActions.loadMore')}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapsStateToProps(state) {
  const selector = formValueSelector('matchmakingClinicSearch');

  return {
    mandateType: state.matchmaking.selectedAccountType,
    clinics: state.matchmaking.clinics,
    currentDate: state.schedule.date,
    dateFrom: state.schedule.from,
    dateTo: state.schedule.to,
    holidays: state.schedule.holidays,
    loading: state.matchmaking.loading,
    search: selector(state, 'search'),
    selectedClinics: state.matchmaking.selectedClinics,
    refreshCallToAllStatus: state.callToAll.refreshCallToAllStatus,
    updateSchedule: state.mandates.updateSchedule,
    endCursor: state.matchmaking.endCursor,
    hasNextPage: state.matchmaking.hasNextPage,
    selectedMiniprofileClinicId: state.matchmaking.selectedMiniprofileClinicId,
  };
}

export default connect(
  mapsStateToProps,
  { ...matchmakingActions, ...mandatesActions },
)(Clinics);
