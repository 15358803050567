// libs
import React from 'react';

// assets
import loader from '../../../assets/images/loaders/simple-loader.svg';

// styles
import './styles.scss';

function BasicLoader() {
  return (
    <div className="small-loader">
      <img src={loader} alt="Loading" />
    </div>
  );
}

export default BasicLoader;
