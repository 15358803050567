// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// components
import EditClinic from './EditClinic';
import EditGroup from '../EditGroup';

// actions
import actions from '../../../actions/admin/clinics';
import actionsSidePanel from '../../../actions/dynamicSidePanel/index';
import alertsActions from '../../../actions/alerts/index';
import confirmActionActions from '../../../actions/confirmAction';

// helpers
import normalizePhone from '../../forms/normalize/normalizePhone';

class Clinic extends Component {
  static propTypes = {
    updateComponent: PropTypes.func.isRequired,
    clinic: PropTypes.object.isRequired,
    disableClinic: PropTypes.func.isRequired,
    enableClinic: PropTypes.func.isRequired,
    fetchClinic: PropTypes.func.isRequired,
    pushAlert: PropTypes.func.isRequired,
    actionToConfirm: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.disableClinic = this.disableClinic.bind(this);
    this.editClinic = this.editClinic.bind(this);
    this.enableClinic = this.enableClinic.bind(this);
  }

  disableClinic() {
    const { disableClinic, pushAlert, clinic: { id } } = this.props;
    disableClinic({ id })
      .then((data) => {
        if (data && data.disableClinic.success) {
          pushAlert({
            type: 'success',
            content: 'alerts.disabled',
          });
        }
      });
  }

  editClinic() {
    const { updateComponent, fetchClinic, clinic: { id } } = this.props;
    fetchClinic({ id, userType: 'manager' });
    updateComponent(EditClinic, 'editClinic', { sidebarIcon: 'clinic' });
  }

  enableClinic() {
    const { enableClinic, pushAlert, clinic: { id } } = this.props;
    enableClinic({ id })
      .then((data) => {
        if (data && data.enableClinic.success) {
          pushAlert({
            type: 'success',
            content: 'alerts.enabled',
          });
        }
      });
  }

  render() {
    const {
      clinic: { discarded, name, address, phone },
      actionToConfirm,
    } = this.props;
    const { t } = this.context;
    return (
      <div className={classNames(
          'content-list__content-item',
          { 'content-list__content-item--discarded': discarded },
        )}
      >
        <div className="user">
          <h2 className="user__name">{name}</h2>
          <p className="user__address">{`${address.address}, ${address.city}`}</p>
          <p className="user__phone">{t('clinics.phone')} <a href={`tel:${phone}`}>{normalizePhone(phone)}</a></p>
        </div>
        <EditGroup
          edit={this.editClinic}
          disable={() => actionToConfirm(this.disableClinic, 'clinic.disable')}
          enable={() => actionToConfirm(this.enableClinic, 'clinic.enable')}
          discarded={discarded}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clinics: state.clinics.results,
  };
}

export default connect(
  mapStateToProps,
  { ...actions, ...actionsSidePanel, ...alertsActions, ...confirmActionActions },
)(Clinic);
