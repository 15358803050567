import { async, createTypes } from 'redux-action-creator';

export default createTypes([
  ...async('FETCH_CLINICS'),
  ...async('FETCH_CLINIC_ADDRESS'),
  ...async('FETCH_CLINIC_PROPOSALS'),
  ...async('FETCH_NEXT_CLINICS'),
  ...async('FETCH_SUBSTITUTE_ADDRESS'),
  ...async('FETCH_SUBSTITUTE_PROPOSALS'),
  ...async('FETCH_SUBSTITUTE_AND_CLINICS'),
  ...async('FETCH_NEXT_SUBSTITUTE_AND_CLINICS'),
  ...async('FETCH_SUBSTITUTES'),
  ...async('FETCH_NEXT_SUBSTITUTES'),
  ...async('CREATE_PROPOSALS'),
  'RESET_CLINIC_ADDRESS',
  'RESET_SUBSTITUTE_ADDRESS',
  'RESET_EVENT_PROPOSALS',
  'SELECT_REPLACEMENT',
  'UNSELECT_REPLACEMENT',
  'DISCARD_PROPOSALS',
  'SELECT_ACCOUNT_TYPE',
  'TOGGLE_SUBSTITUTE_MINIPROFILE',
  'TOGGLE_CLINIC_MINIPROFILE',
  'SHOW_SUBSTITUTE_MESSAGE',
  'HIDE_SUBSTITUTE_MESSAGE',
  'SELECT_SUBSTITUTE',
  'UNSELECT_SUBSTITUTE',
  'SET_EVENT_ID_FOR_TOOLTIP',
  'SELECT_CLINIC',
  'UNSELECT_CLINIC',
  'LOCK_PRICES',
  'UNLOCK_PRICES',
  ...async('ENABLE_MATCHMAKING_REPLACEMENT'),
], 'MATCHMAKING');
