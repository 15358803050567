import types from '../actions/dynamicSidePanel/types';

const initialState = {
  isVisible: false,
  component: null,
  name: '',
  defaultProps: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_DISPLAYED_COMPONENT:
      return {
        ...state,
        isVisible: true,
        component: action.component,
        name: action.name,
        defaultProps: action.props,
      };
    case types.HIDE_SIDE_PANEL:
      return { ...state, isVisible: false, component: null, name: '' };
    default:
  }
  return state;
}
