const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'validation.required';
  }

  if (!values.phone) {
    errors.phone = 'validation.required';
  } else if (!/^(1\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/.test(values.phone)) {
    errors.phone = 'validation.invalid';
  }

  if (!values.rate) {
    errors.rate = 'validation.required';
  }

  if (values.clinicUsers) {
    errors.clinicUsers = [];

    values.clinicUsers.forEach((clinicUser, index) => {
      errors.clinicUsers[index] = {};

      if (!clinicUser.userId) {
        errors.clinicUsers[index].userId = 'validation.required';
      } else if (values.clinicUsers.filter(user => user.userId === clinicUser.userId).length > 1) {
        errors.clinicUsers[index].userId = 'validation.userSelected';
      }
    });
  }

  if (values.blacklistedUsers) {
    errors.blacklistedUsers = [];

    values.blacklistedUsers.forEach((clinicUser, index) => {
      errors.blacklistedUsers[index] = {};

      if (!clinicUser.userId) {
        errors.blacklistedUsers[index].userId = 'validation.required';
      } else if (values.blacklistedUsers.filter(
        user => user.userId === clinicUser.userId).length > 1
      ) {
        errors.blacklistedUsers[index].userId = 'validation.userSelected';
      }
    });
  }

  if (values.openingHours) {
    errors.openingHours = [];
    values.openingHours.forEach((openingHour, index) => {
      const openingHourErrors = {};
      if (openingHour.opensAt !== '') {
        if (openingHour.closesAt === '') {
          openingHourErrors.closesAt = 'validation.required';
          errors.openingHours[index] = openingHourErrors;
        }
      }
      if (openingHour.closesAt !== '') {
        if (openingHour.opensAt === '') {
          openingHourErrors.opensAt = 'validation.required';
          errors.openingHours[index] = openingHourErrors;
        }
      }
      if (Date.parse(`01/01/2019 ${openingHour.closesAt}:00`) <= Date.parse(`01/01/2019 ${openingHour.opensAt}:00`)) {
        openingHourErrors.opensAt = 'validation.dayAvail.startBeforeEnd';
        errors.openingHours[index] = openingHourErrors;
      }
    });
  }

  const addressRequiredFields = ['address', 'city', 'zipcode', 'province', 'country'];
  values.address = values.address || {};
  values.billingAddress = values.billingAddress || {};

  if (values.address || values.billingAddress) {
    errors.address = {};
    errors.billingAddress = {};

    addressRequiredFields.forEach((requiredFieldName) => {
      if (!values.address[requiredFieldName]) {
        errors.address[requiredFieldName] = 'validation.required';
      }
      if (!values.billingAddress[requiredFieldName]) {
        errors.billingAddress[requiredFieldName] = 'validation.required';
      }
    });
  }

  if (values.address.zipcode) {
    if ((!/^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$/i.test(values.address.zipcode))) {
      errors.address.zipcode = 'validation.zipcode';
    }
  }

  if (values.billingAddress.zipcode) {
    if ((!/^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$/i.test(values.billingAddress.zipcode))) {
      errors.billingAddress.zipcode = 'validation.zipcode';
    }
  }

  return errors;
};

export default validate;
