// libs
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { Field } from 'redux-form';
import { formatHours } from '../../../../../helpers/formatDates';

// components
import TextArea from '../../../../forms/fields/textarea/Textarea';

function Proposals({
  fields,
  unselectEvent,
  onToggleSubstituteMessage,
  substituteMessageReplacementIds,
}, { t }) {
  return (
    <div>
      {fields.map((field, index, data) => {
        const { replacement } = data.get(index);
        const displayed = substituteMessageReplacementIds.includes(replacement.id);

        return (
          <React.Fragment key={replacement.id}>
            <div className="clinic__proposal">
              <div className="clinic__proposal__infos">
                <div className="clinic__proposal__date">
                  <span className="date">{moment(replacement.startsAt).format('DD')}</span>
                  <span className="month">{moment(replacement.startsAt).format('MMM')}</span>
                </div>
                <div className="clinic__proposal__type">
                  <span
                    title={t('matchmaking.priority')}
                    className={classNames(
                    'type__icon',
                    'type__icon--priority',
                    { 'type__icon--active': replacement.priority },
                  )}
                  />
                  <span
                    title={t('matchmaking.flexible')}
                    className={classNames(
                    'type__icon',
                    'type__icon--flexible',
                    { 'type__icon--active': replacement.flexibleTime },
                  )}
                  />
                </div>
                { replacement.allDay ?
                  <div className="clinic__proposal__hours">
                    {t('event.allDay')}
                  </div>
                :
                  <div className="clinic__proposal__hours">
                    {formatHours(replacement.startsAt)} <span className="arrow" /> {formatHours(replacement.endsAt)}
                  </div>
              }
              </div>
              <div className="clinic__proposal__actions">
                <button
                  type="button"
                  className="clinic__proposal__action--message"
                  onClick={() => { onToggleSubstituteMessage(replacement.id); }}
                />
                <button
                  type="button"
                  className="clinic__proposal__action--delete"
                  onClick={() => (unselectEvent(replacement))}
                />
              </div>

            </div>
            {displayed &&
              <div className="clinic__proposal__message">
                <Field
                  name={`${field}.substituteMessage`}
                  className="message__field message__field--show"
                  component={TextArea}
                  noLabel
                />
              </div>
            }
          </React.Fragment>
        );
      })}
    </div>
  );
}

Proposals.propTypes = {
  fields: PropTypes.object.isRequired,
  unselectEvent: PropTypes.func.isRequired,
  onToggleSubstituteMessage: PropTypes.func.isRequired,
  substituteMessageReplacementIds: PropTypes.array.isRequired,
};

Proposals.contextTypes = {
  t: PropTypes.func,
};

export default Proposals;
