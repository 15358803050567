import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// components
import EditReplacement from '../../../../../EditReplacement';

// actions
import alertsActions from '../../../../../../../actions/alerts';
import mandatesActions from '../../../../../../../actions/mandates';
import sidePanelActions from '../../../../../../../actions/dynamicSidePanel';

class Edit extends Component {
  static propTypes = {
    replacement: PropTypes.object.isRequired,
    disableReplacement: PropTypes.func.isRequired,
    enableReplacement: PropTypes.func.isRequired,
    pushAlert: PropTypes.func.isRequired,
    setReplacementToUpdate: PropTypes.func.isRequired,
    updateComponent: PropTypes.func.isRequired,
    setMandateToUpdate: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.deleteReplacement = this.deleteReplacement.bind(this);
    this.enableReplacement = this.enableReplacement.bind(this);
    this.updateReplacement = this.updateReplacement.bind(this);
  }

  deleteReplacement() {
    const {
      disableReplacement,
      replacement: { id },
      pushAlert,
    } = this.props;

    disableReplacement({ id }).then((data) => {
      if (data && data.disableReplacement.success) {
        pushAlert({
          type: 'success',
          content: 'alerts.disabled',
        });
      } else {
        pushAlert({
          type: 'error',
          content: data.disableReplacement.errors[0].message,
        });
      }
    });
  }

  enableReplacement() {
    const {
      enableReplacement,
      replacement: { id },
      pushAlert,
    } = this.props;

    enableReplacement({ id }).then((data) => {
      if (data && data.enableReplacement.success) {
        pushAlert({
          type: 'success',
          content: 'alerts.enabled',
        });
      } else {
        pushAlert({
          type: 'error',
          content: data.enableReplacement.errors[0].message,
        });
      }
    });
  }

  updateReplacement() {
    const {
      setReplacementToUpdate,
      updateComponent,
      replacement: {
        id,
        mandate: {
          id: mandateId,
        },
      },
      setMandateToUpdate,
    } = this.props;
    setMandateToUpdate(mandateId);
    setReplacementToUpdate(id);
    updateComponent(EditReplacement, 'editReplacement', { sidebarIcon: 'edit' });
  }

  render() {
    const {
      replacement,
      replacement: { latestProposal },
    } = this.props;

    const { t } = this.context;

    const pendingStatus = ['pending', 'pending_clinic'];
    const status = latestProposal ? latestProposal.status : '';
    const hasProposals = ['pending', 'pending_clinic', 'pending_admin', 'confirmed'].includes(status);

    return (
      <div>
        {hasProposals ? (
          <div className="mandate-item__actions">
            {pendingStatus.includes(replacement.latestProposal.status) ? (
              <span className="list-item__action--pending" />
            ) : (
              <span className={`list-item__action--${replacement.latestProposal.status}`} />
            )}
          </div>
        ) : (
          <div className="mandate-item__actions">
            {replacement.discarded ? (
              <span
                onClick={() => this.enableReplacement()}
                onKeyPress={() => this.enableReplacement()}
                role="button"
                tabIndex="0"
                className="mandate-item__actions__enable"
              >
                {t('form.formActions.enable')}
              </span>
            ) : (
              <div>
                <span
                  onClick={() => this.updateReplacement()}
                  onKeyPress={() => this.updateReplacement()}
                  role="button"
                  tabIndex="0"
                  className="mandate-item__actions__edit"
                />
                <span
                  className="mandate-item__actions__delete"
                  onClick={() => this.deleteReplacement()}
                  onKeyPress={() => this.deleteReplacement()}
                  role="button"
                  tabIndex="0"
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, { ...alertsActions, ...mandatesActions, ...sidePanelActions })(Edit);
