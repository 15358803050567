export default {
  acceptedReplacements: 'Liste des remplacements acceptés',
  add: 'Ajouter une demande',
  addTime: {
    long: 'Ajouter 24h de délai supplémentaire',
    short: 'Ajouter 24h'
  },
  allAcceptedMandates: 'Voir tous les remplacements acceptés',
  allMandates: 'Voir tous les demandes saisies',
  allProposedReplacements: 'Voir tous les remplacements proposés',
  awaitingConfirmation: 'En attente de confirmation',
  callToAllHeader: 'Appel à tous',
  cards: {
    deleteMandate: 'Supprimer la demande',
    details: 'Détails',
    drawerTitle: 'Message de {name}',
    proposalWaiting: 'En attente...',
    title: {
      'accepted-replacement': 'Remplacements acceptés',
      list: 'Plages horaires sélectionnées',
      'pending-replacement': 'Remplacements proposés',
    },
    year: ' an',
    years: ' ans',
    hourlyRate: '$/h',
  },
  instructions: 'Acceptez ou refusez chaque date puis cliquez sur Envoyer.',
  listTitle: {
    substitute: {
      confirmed: 'Liste des remplacements acceptés',
      pending: 'Liste des remplacements à approuver',
    },
    clinic_manager: {
      all: 'Liste des demandes saisies',
      confirmed: 'Liste des remplacements acceptés',
      pending: 'Liste des remplacements à approuver',
    },
    admin: {
      confirmed: 'Liste des remplacements acceptés',
      pending: 'Liste des remplacements à approuver',
    },
  },
  lodging: 'Hébergement',
  mandate: {
    confirmed: 'Remplacement accepté',
    pending: 'Remplacement proposé',
    pending_admin: 'Remplacement accepté en attente de validation',
    pending_clinic: 'Remplacement accepté en attente de validation',
  },
  mandatesList: 'Liste des demandes saisies',
  noMandates: 'Aucune proposition à approuver pour le moment.',
  noProposals: 'Aucune proposition à afficher pour le moment.',
  proposedReplacements: 'Liste des remplacements à approuver',
  remaining: 'restantes',
  title: {
    admin: {
      confirmed: 'Remplacements à confirmer / confirmés',
      pending: 'Remplacements en attente d’approbation',
    },
    confirmed: 'Liste des remplacements acceptés',
    list: 'Liste des demandes saisies',
    pending: 'Liste des remplacements à approuver',
  },
  unavailableMandate: 'Le remplacement que vous cherchez n\'existe pas',
  viewCurrent: 'Voir les remplacements à venir',
  viewPast: 'Voir les remplacements passés',
  withLodging: 'Hébergement fourni',
  withoutLodging: 'Hébergement non fourni',
  editMandate: 'Modifier le remplacement',
}
