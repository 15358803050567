export function formatClinicDistance(distance) {
  if (distance < 10) {
    return '< 10 km';
  }

  return `${Math.round(parseInt(distance, 10) / 10) * 10} km`;
}

export function formatClinicTravelTime(travelTime) {
  travelTime = Number(travelTime);
  const hour = Math.floor(travelTime / 3600);
  const minute = Math.floor((travelTime % 3600) / 60);

  const formatedMinutes = minute <= 9 ? `0${minute}` : minute;
  const formatedTravelTime = hour > 0 ? `${hour}h${formatedMinutes}` : `${minute} min`;

  return formatedTravelTime;
}

export default formatClinicDistance;
