export default {
  fetchSoftwares: `
    query {
      softwares {
        id
        name
      }
    }
  `,
};
