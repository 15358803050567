import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

// components
import Select from '../../../forms/fields/select/Select';

// actions
import languagesActions from '../../../../actions/languages';

// styles
import './styles.scss';

class LanguageSubForm extends Component {
  static propTypes = {
    fields: PropTypes.object,
    fetchLanguages: PropTypes.func.isRequired,
    languages: PropTypes.array.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    fields: {},
  };

  constructor() {
    super();
    this.removeLanguage = this.removeLanguage.bind(this);
  }

  componentDidMount() {
    const { languages, fetchLanguages } = this.props;

    if (languages.length === 0) {
      fetchLanguages();
    }
  }

  removeLanguage(index) {
    const { fields } = this.props;
    const data = fields.get(index);
    fields.remove(index);
    if (data.id) {
      fields.push({
        ...data,
        destroy: true,
      });
    }
  }

  render() {
    const { languages, fields } = this.props;
    const { t } = this.context;

    const levels = ['fluent', 'functional'];

    const activeLanguages = typeof fields.getAll() !== 'undefined' ?
      fields.getAll().filter(lang => lang.destroy !== true) : [];

    if (languages.length === 0) {
      return null;
    }
    if (!fields.length) {
      fields.push({ languageId: languages[0].id, level: levels[0] });
    }

    return (
      <div className="language-subform">
        {fields.map((language, index, data) => (
          !data.get(index).destroy && (
            <div key={`language-${language}`} className="language-subform__row">
              <Field
                name={`${language}.languageId`}
                type="select"
                component={Select}
                label="language"
                className="field--select"
                required
              >
                {languages.map(({ id, name }) =>
                  <option key={id} value={id}>{name}</option>,
                )}
              </Field>
              <Field
                name={`${language}.level`}
                type="text"
                component={Select}
                label="level"
                className="field--select"
                required
              >
                {levels.map((level) =>
                  <option key={level} value={level}>{t(`form.levels.${level}`)}</option>,
                )}
              </Field>
              <button type="button" className="language-subform__delete" onClick={() => this.removeLanguage(index)} />
            </div>
          )
        ))}
        {activeLanguages.length < languages.length &&
          <button
            type="button"
            className="form__submit"
            onClick={() => fields.push({ languageId: languages[0].id, level: levels[0] })}
          >
            {t('form.formActions.add')}
          </button>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    languages: state.languages.languages,
  };
}

export default connect(mapStateToProps, languagesActions)(LanguageSubForm);

