import types from '../actions/languages/types';

const initialState = {
  languages: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LANGUAGES_SUCCESS:
      return { ...state, languages: action.payload.languages };
    default:
  }
  return state;
}
