// libs
import React from 'react';
import PropTypes from 'prop-types';
import Event from './Event';
import Holiday from './Holiday';
import Replacement from './Replacement';

function EventWrapper(props) {
  const { event: { type } } = props;
  switch (type) {
    case 'holiday':
      return <Holiday {...props} />;
    case 'availability':
      return <Event {...props} />;
    case 'replacement':
      return <Replacement {...props} />;
    default:
  }
}

EventWrapper.propTypes = {
  event: PropTypes.object.isRequired,
};

EventWrapper.contextTypes = {
  t: PropTypes.func,
};

export default EventWrapper;
