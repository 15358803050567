const validate = (values, { role }) => {
  const errors = {};
  if (values.proposals) {
    errors.proposals = values.proposals.map((proposal) => {
      const error = {};

      if (role === 'substitute' && !['true', 'false'].includes(proposal.userAccepted)) {
        error.userAccepted = 'validation.required';
      }
      if (role === 'clinic_manager' && !['true', 'false'].includes(proposal.clinicAccepted)) {
        error.clinicAccepted = 'validation.required';
      }

      if (
        proposal.clinicAcceptedWas !== null &&
        proposal.clinicAccepted !== proposal.clinicAcceptedWas
      ) {
        errors._error = 'validation.clinicCannotBeChanged';
      } else if (
        proposal.userAcceptedWas !== null &&
        proposal.userAccepted !== proposal.userAcceptedWas
      ) {
        errors._error = 'validation.userCannotBeChanged';
      }

      return error;
    });
  }

  return errors;
};

export default validate;
