import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


function Task({ accommodation, consultation, textOnly, surgery }, { t }) {
  surgery = surgery && surgery.toString();
  return (
    <div className="list-item__type">
      <span className={classNames('type__text', {
        'type__text--text-only': textOnly,
    })}
      >
        { consultation && <span>{ t('event.consultation') }</span> }
        { surgery !== 'false' &&
          <span>
            {t('event.surgery')} {surgery === 'optional' && <small>{t('event.optional')}</small>}
          </span>
        }
      </span>
      {consultation && !textOnly &&
        <span
          title={t('event.consultation')}
          className="type__icon type__icon--consult type__icon--active"
        />
      }
      {(surgery !== 'false') && !textOnly &&
        <span
          title={t('event.surgery')}
          className="type__icon type__icon--surgery type__icon--active"
        />
      }
      {accommodation && !textOnly &&
        <span
          title={t('mandates.lodging')}
          className="type__icon type__icon--lodging type__icon--active"
        />
      }
    </div>
  );
}

Task.propTypes = {
  accommodation: PropTypes.bool,
  consultation: PropTypes.bool.isRequired,
  surgery: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  textOnly: PropTypes.bool,
};
Task.defaultProps = {
  accommodation: false,
  textOnly: false,
};
Task.contextTypes = {
  t: PropTypes.func,
};

export default Task;
