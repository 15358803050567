import types from '../actions/helpcenter/types';

const initialState = {
  accordionId: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_ACCORDION:
      return {
        ...state,
        accordionId: action.accordionId,
      };
    case types.CLOSE_ALL_PANELS:
      return {
        ...state,
        accordionId: '',
      };
    default:
  }
  return state;
}
