// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { translateRoute } from 'o2web-react-core';
import moment from 'moment';

// components
import BasicLoader from '../loader/BasicLoader';
import DatesHeader from './row/DatesHeader';
import HeaderRow from './row/HeaderRow';
import EmptyRow from './row/EmptyRow';
import SearchUser from '../admin/users/forms/SearchForm';
import SubstituteRow from './row/SubstituteRow';

// actions
import actions from '../../actions/matchmaking';
import actionsSidePanel from '../../actions/dynamicSidePanel';

// helpers
import { matchmakingDaysRange, matchmakingRange } from '../../helpers/range';
import { scrollToSelectedDay } from '../../helpers/matchmaking';

// styles
import './styles.scss';


class Substitutes extends Component {
  static propTypes = {
    accountType: PropTypes.string.isRequired,
    currentDate: PropTypes.instanceOf(Date).isRequired,
    dateFrom: PropTypes.object.isRequired,
    dateTo: PropTypes.object.isRequired,
    endCursor: PropTypes.string,
    fetchSubstitutes: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    holidays: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    search: PropTypes.string,
    selectSubstitute: PropTypes.func.isRequired,
    toggleSubstituteMiniprofile: PropTypes.func.isRequired,
    substitutes: PropTypes.array.isRequired,
    substituteSelected: PropTypes.bool.isRequired,
    selectedMiniprofileUserId: PropTypes.string,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    endCursor: null,
    search: '',
    selectedMiniprofileUserId: '',
  };

  constructor(props, context) {
    super(props, context);
    this.updateSubstitutes = this.updateSubstitutes.bind(this);
    this.selectSubstitute = this.selectSubstitute.bind(this);
    this.toggleSubstituteMiniprofile = this.toggleSubstituteMiniprofile.bind(this);

    if (props.substituteSelected) {
      this.redirectToProposals();
    }
  }

  componentDidMount() {
    this.updateSubstitutes();
  }

  componentDidUpdate(prevProps) {
    const { currentDate: prevDate, accountType: prevAccountType } = prevProps;
    const { currentDate, accountType } = this.props;

    if (moment(prevDate).valueOf() !== moment(currentDate).valueOf() ||
        accountType !== prevAccountType) {
      this.updateSubstitutes();
    }
  }

  toggleSubstituteMiniprofile(userId) {
    const { toggleSubstituteMiniprofile } = this.props;
    toggleSubstituteMiniprofile(userId);
  }

  selectSubstitute(userId) {
    const { selectSubstitute } = this.props;
    selectSubstitute(userId);
    this.redirectToProposals();
  }

  redirectToProposals() {
    const { history } = this.props;
    const { t } = this.context;

    history.push(translateRoute('/en/matchmaking/proposals', t));
  }

  updateSubstitutes() {
    this.fetchSubstitutesList();
  }

  fetchSubstitutesList(nextPage = false) {
    const { accountType, dateFrom, dateTo, endCursor, fetchSubstitutes, search } = this.props;
    const { from, to } = matchmakingRange(dateFrom, dateTo);
    fetchSubstitutes({
      accountType,
      from,
      to,
      search,
      ...(nextPage && { after: endCursor }),
    }).then(() => {
      scrollToSelectedDay('selectedDay', 'matchmaking__calendar__wrapper');
    });
  }

  render() {
    const {
      accountType,
      currentDate,
      dateFrom,
      dateTo,
      hasNextPage,
      holidays,
      loading,
      selectedMiniprofileUserId,
      substitutes,
    } = this.props;
    const { t } = this.context;
    return (
      <div className="wrapper--fullwidth">
        <div className="matchmaking__calendar__wrapper">
          <div className="matchmaking__calendar">
            <div className="matchmaking__calendar__header">
              <DatesHeader
                date={dateFrom}
                currentDate={currentDate}
                days={matchmakingDaysRange(dateFrom, dateTo)}
                holidays={holidays}
              >
                <SearchUser
                  fetchUsers={this.updateSubstitutes}
                  accountType={accountType}
                />
              </DatesHeader>
            </div>
            <HeaderRow
              date={dateFrom}
              days={matchmakingDaysRange(dateFrom, dateTo)}
              holidays={holidays}
              title={t(`matchmaking.substitutes.${accountType}`)}
            />
            {substitutes.map(substitute =>
              <SubstituteRow
                key={substitute.id}
                date={moment(dateFrom)}
                days={matchmakingDaysRange(dateFrom, dateTo)}
                onSelect={this.selectSubstitute}
                onToggleSubstituteMiniprofile={this.toggleSubstituteMiniprofile}
                selectedMiniprofileUserId={selectedMiniprofileUserId}
                substitute={substitute}
              />,
            )}
            {substitutes.length === 0 &&
              <EmptyRow text={t('matchmaking.search.noSubstitutes')} />
            }
          </div>

          {hasNextPage && (
            <div className="matchmaking__calendar__pagination">
              <button
                className="content-item__button form__submit"
                type="button"
                onClick={() => this.fetchSubstitutesList(true)}
              >
                {t('form.formActions.loadMore')}
              </button>
            </div>
          )}
        </div>
        { loading && (
          <BasicLoader />
        )}
      </div>
    );
  }
}

function mapsStateToProps(state) {
  const selector = formValueSelector('searchUsers');

  return {
    loading: state.matchmaking.loading,
    accountType: state.matchmaking.selectedAccountType,
    currentDate: state.schedule.date,
    dateFrom: state.schedule.from,
    dateTo: state.schedule.to,
    holidays: state.schedule.holidays,
    search: selector(state, 'search'),
    substitutes: state.matchmaking.substitutes,
    substituteSelected: state.matchmaking.substituteSelected,
    selectedMiniprofileUserId: state.matchmaking.selectedMiniprofileUserId,
    endCursor: state.matchmaking.endCursor,
    hasNextPage: state.matchmaking.hasNextPage,
  };
}

export default withRouter(connect(
  mapsStateToProps,
  { ...actions, ...actionsSidePanel },
)(Substitutes));
