// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'o2web-react-core';
import MandateCard from './list/card/MandateCard';
import BasicLoader from '../loader/BasicLoader';

// actions
import actionsUser from '../../actions/user/index';
import actionsMandates from '../../actions/mandates/index';
import actionsMandateProposals from '../../actions/mandateProposals/index';

class MandatesList extends Component {
  componentDidMount() {
    const { lang } = this.props;
    moment.locale(lang);
    this.updateMandate();
  }

  componentDidUpdate() {
    const {
      updateSchedule,
      refreshMandateList,
    } = this.props;
    if (updateSchedule || refreshMandateList) {
      this.updateMandate();
    }
  }

  updateMandate() {
    const {
      fetchSubstituteMandate,
      fetchClinicMandate,
      match: { params: { mandateId } },
      role,
      userId,
      proposalStatus,
    } = this.props;
    if (role === 'clinic_manager') {
      const statusObj = {
        pending: 'pending_clinic',
        confirmed_substitute: 'confirmed',
      };
      const status = statusObj[proposalStatus] ? statusObj[proposalStatus] : proposalStatus;
      const params = proposalStatus ?
        { id: mandateId, status } :
        { id: mandateId };
      fetchClinicMandate(params);
    } else {
      const params = proposalStatus ?
        { id: mandateId, userId, status: proposalStatus } :
        { id: mandateId, userId };
      fetchSubstituteMandate(params);
    }
  }

  render() {
    const { mandate, listIsLoading, mandateUnavailable, role, proposalStatus } = this.props;
    const { t } = this.context;
    const acceptedProposals = proposalStatus === 'confirmed_substitute';
    const key = proposalStatus || 'all';
    const routes = {
      confirmed_substitute: '/en/accepted-mandates',
      pending: '/en/proposed-mandates',
      all: '/en/mandates',
    };
    const labels = {
      confirmed_substitute: 'mandates.allAcceptedMandates',
      pending: 'mandates.allProposedReplacements',
      all: 'mandates.allMandates',
    };

    return (
      <div>
        <div className="mandates-list__wrapper">
          <div className="mandates-list__back-to-list">
            <NavLink to={routes[key]} exact strict>{ t(labels[key]) }</NavLink>
          </div>
          {Object.entries(mandate).length !== 0 && (
            <MandateCard
              mandate={mandate}
              allMandates={role === 'clinic_manager' && !proposalStatus}
              acceptedProposals={acceptedProposals}
              extendProposalsTime={() => {}}
              fetchMandates={() => {}}
              role={role}
            />
          )}
          {listIsLoading && <BasicLoader />}
          {mandateUnavailable && <span>{ t('mandates.unavailableMandate') }</span>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.i18nState.lang,
    listIsLoading: state.mandates.listIsLoading,
    mandate: state.mandateProposals.uniqueMandate,
    mandateUnavailable: state.mandates.mandateUnavailable,
    refreshMandateList: state.mandates.refreshMandateList,
    role: state.user.role,
    updateSchedule: state.mandates.updateSchedule,
    userId: state.user.data.id,
  };
}

MandatesList.propTypes = {
  fetchSubstituteMandate: PropTypes.func.isRequired,
  fetchClinicMandate: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  listIsLoading: PropTypes.bool.isRequired,
  mandate: PropTypes.object.isRequired,
  mandateUnavailable: PropTypes.bool.isRequired,
  match: PropTypes.object,
  refreshMandateList: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  updateSchedule: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  proposalStatus: PropTypes.string,
};

MandatesList.defaultProps = {
  match: {},
  proposalStatus: null,
};

MandatesList.contextTypes = {
  t: PropTypes.func,
};

export default withRouter(connect(
  mapStateToProps,
  { ...actionsUser, ...actionsMandates, ...actionsMandateProposals },
)(MandatesList));
