// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'o2web-react-core';
import moment from 'moment';
import { formatHours, formatDayWithDateAndMonth } from '../../../helpers/formatDates';
import { getClinicAddress } from '../../../helpers/formatProposals';
import { formatClinicDistance } from '../../../helpers/distance';

// components
import EditAvailability from '../../availabilities/EditAvailability';

// actions
import actionsSidePanel from '../../../actions/dynamicSidePanel/index';
import actionsAvailabilities from '../../../actions/availabilities/index';
import confirmActionActions from '../../../actions/confirmAction/index';

// css
import './styles.scss';

class EventDetails extends Component {
  constructor() {
    super();
    this.detailsRef = React.createRef();
    this.state = {
      left: '0',
      positionX: '',
      positionY: '',
    };
  }

  componentDidMount() {
    this.changeDetailsPositionX();
    this.changeDetailsPositionY();
  }


  changeDetailsPositionX() {
    const { wrapperWidth } = this.props;
    const detailsDOMData = this.detailsRef.current.getBoundingClientRect();
    let positionLeft = `-${(detailsDOMData.width / 2) - (wrapperWidth / 2)}`;
    let positionX = '';
    if (window.innerWidth > 1000) {
      if (detailsDOMData.x < 200) {
        positionLeft = 0;
        positionX = 'schedule__event-details--left';
      } else if ((detailsDOMData.x + 220) > window.innerWidth) {
        positionLeft = `-${detailsDOMData.width - wrapperWidth}`;
        positionX = 'schedule__event-details--right';
      }
      this.setState({
        left: `${positionLeft}px`,
        positionX,
      });
    }
  }

  changeDetailsPositionY() {
    const detailsDOMData = this.detailsRef.current.getBoundingClientRect();
    const positionY = (detailsDOMData.bottom > window.innerHeight) ? 'schedule__event-details--top' : '';
    this.setState({
      positionY,
    });
  }

  updateAvailability() {
    const {
      setAvailabilityToUpdate,
      toggleDetails,
      updateComponent,
      event: { id },
    } = this.props;
    setAvailabilityToUpdate(id);
    updateComponent(EditAvailability, 'editAvailability', { sidebarIcon: 'edit' });
    toggleDetails();
  }

  render() {
    const {
      toggleDetails,
      event: {
        allDay,
        start,
        end,
        distance: eventDistance,
        consultation: eventConsultation,
        surgery: eventSurgery,
        message,
        latestProposal,
      },
      deleteEvent,
      actionToConfirm,
    } = this.props;
    const { left, positionX, positionY } = this.state;
    const { t } = this.context;
    const status = latestProposal ? latestProposal.status : 'availability';
    const hasProposals = ['pending', 'pending_clinic', 'pending_admin', 'confirmed'].includes(status);
    const pendingConfirmation = ['pending_clinic', 'pending_admin'].includes(status);
    const mandateId = latestProposal ? latestProposal.replacement.mandate.id : null;

    const replacement = hasProposals && latestProposal.replacement ?
      latestProposal.replacement : false;
    const startDate = replacement ? replacement.startsAt : start;
    const endDate = replacement ? replacement.endsAt : end;
    const consultation = replacement ? replacement.mandate.consultation : eventConsultation;
    let surgery = replacement ? replacement.mandate.surgery : eventSurgery;
    if (typeof surgery === 'boolean') {
      surgery = surgery ? 'true' : 'false';
    }
    const distance = hasProposals ? latestProposal.clinic.distance : eventDistance;
    const isPast = moment().diff(startDate, 'days') > 0;

    return (
      <div className={`schedule__event-details ${positionX} ${positionY}`} ref={this.detailsRef} style={{ left }}>
        <div className="schedule__event-details__head">
          <span className="schedule__event-details__head--date">{formatDayWithDateAndMonth(start)}</span>
          <span
            className="schedule__event-details__head__close"
            onClick={toggleDetails}
            onKeyPress={toggleDetails}
            role="button"
            tabIndex="0"
          />
        </div>
        {pendingConfirmation &&
          <div className="schedule__event-details__pending">
            {t('event.pendingConfirmation')}
          </div>
        }
        <div className="schedule__event-details__body">
          {allDay && !hasProposals ?
            <div className="schedule__event-details__time">
              <span>{t('event.allDay')}</span>
            </div>
          :
            <div className="schedule__event-details__time">
              <span className="schedule__event__from">{formatHours(startDate)}</span>
              <span className="schedule__event__to">{formatHours(endDate)}</span>
            </div>
          }
          {distance && (
            hasProposals ?
              <span className="schedule__event-details__distance">{formatClinicDistance(distance)}</span>
            :
              <span className="schedule__event-details__distance">{t(`availabilities.distancesText.${distance}`)}</span>
          )}
          {consultation && <span className="schedule__event-details__consultation">{t('event.consultation')}</span>}
          {surgery !== 'false' &&
            <span className="schedule__event-details__surgery">
              { surgery === 'optional' ? t('event.surgeryOptional') : t('event.surgery') }
            </span>
          }
          {message &&
            <span className="schedule__event-details__info">
              <strong>{t('event.additionnalInfo')}</strong>
              <span>{message}</span>
            </span>
          }
        </div>
        {latestProposal && hasProposals ?
          <div>
            <div className="schedule__event-details__clinic">
              <div className="clinic">
                <div className="clinic__infos">
                  <span className="clinic__infos__title">
                    {latestProposal.clinic.name}
                  </span>
                  <span className="clinic__infos__desc">
                    {getClinicAddress(latestProposal.clinic.address)}
                  </span>
                </div>
              </div>
            </div>
            {mandateId && !isPast &&
              <div className="schedule__event-details__foot">
                <NavLink
                  to={status === 'pending' ?
                    '/en/proposed-mandates' : `/en/accepted-mandates/${mandateId}`}
                  className="schedule__event-details__btn schedule__event-details__btn--modif"
                >
                  {t('event.actions.details')}
                </NavLink>
              </div>
            }
          </div>
        :
          <div>
            { !isPast &&
              <div className="schedule__event-details__foot">
                <span
                  className="schedule__event-details__btn schedule__event-details__btn--delete icon"
                  onClick={() => actionToConfirm(deleteEvent, 'availability.delete')}
                  onKeyPress={() => actionToConfirm(deleteEvent, 'availability.delete')}
                  role="button"
                  tabIndex="0"
                />
                <span
                  onClick={() => this.updateAvailability()}
                  onKeyPress={() => this.updateAvailability()}
                  role="button"
                  tabIndex="0"
                  className="schedule__event-details__btn schedule__event-details__btn--modif"
                >
                  {t('event.actions.edit')}
                </span>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

EventDetails.propTypes = {
  toggleDetails: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  wrapperWidth: PropTypes.number.isRequired,
  updateComponent: PropTypes.func.isRequired,
  setAvailabilityToUpdate: PropTypes.func.isRequired,
  actionToConfirm: PropTypes.func.isRequired,
};

EventDetails.contextTypes = {
  t: PropTypes.func,
};

function mapStateToProps() {
  return {
  };
}

export default connect(
  mapStateToProps,
  { ...actionsSidePanel, ...actionsAvailabilities, ...confirmActionActions },
)(EventDetails);
