// libs
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { matchmakingDaysRange } from '../../../helpers/range';

// components
import DayEvents from '../event/DayEvents';
import SubstituteMiniProfile from './miniprofiles/SubstituteMiniProfile';

function SubstituteRow({
  date,
  days,
  onSelect,
  onToggleSubstituteMiniprofile,
  selected,
  selectedMiniprofileUserId,
  substitute,
  substitute: {
    id,
    availabilities,
    profile: {
      firstName,
      lastName,
      picture,
    },
  },
}) {
  return (
    <div className={`matchmaking__row ${selected && 'matchmaking__row--fixed'}`}>
      <div className="matchmaking__user">
        <div className="matchmaking__user__infos">
          {picture && picture.small ?
            <div
              className="matchmaking__user__image"
              style={{ backgroundImage: `url(${picture.small})` }}
            />
          :
            <div className="matchmaking__user__image matchmaking__user__image--empty" />
          }
          <div className="matchmaking__user__name">
            <span>{`${firstName} ${lastName}`}</span>
          </div>
        </div>
        <div className="matchmaking__user__actions">
          {selectedMiniprofileUserId === id ? (
            <>
              <button
                className={classNames(
                  'matchmaking__user__action',
                  'matchmaking__user__action--hideProfile',
                )}
                onClick={() => onToggleSubstituteMiniprofile()}
              />
              <SubstituteMiniProfile substitute={substitute} />
            </>
          ) : (
            <button
              className={classNames(
                  'matchmaking__user__action',
                  'matchmaking__user__action--showProfile',
                )}
              onClick={() => onToggleSubstituteMiniprofile(id)}
            />
            )
          }
          {selected ?
            <button
              className={classNames(
                'matchmaking__user__action',
                'matchmaking__user__action--unselect',
              )}
              onClick={() => onSelect()}
            />
          :
            <button
              className={classNames(
                'matchmaking__user__action',
                'matchmaking__user__action--select',
              )}
              onClick={() => onSelect(id)}
            />
          }
        </div>
      </div>
      {[...Array(days).keys()].map((count) =>
        <DayEvents
          key={`${id} ${count}`}
          eventType="availability"
          userId={id}
          user={substitute}
          count={count}
          date={moment(date).add(count, 'days').toDate()}
          events={availabilities[moment(date).add(count, 'days').format('DD-MM-YYYY')]}
        />,
      )}
    </div>
  );
}

SubstituteRow.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.object,
  ]).isRequired,
  days: PropTypes.number,
  selectedMiniprofileUserId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onToggleSubstituteMiniprofile: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  substitute: PropTypes.object.isRequired,
};
SubstituteRow.defaultProps = {
  days: matchmakingDaysRange(),
  selected: false,
  selectedMiniprofileUserId: '',
};

export default connect(
  null,
)(SubstituteRow);
