// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// components
import ClinicInfo from './parts/ClinicInfo';
import MandateInfo from './parts/MandateInfo';
import MandateProposalsForm from './parts/proposals/MandateProposalsForm';
import MandateCardHeader from './parts/proposals/parts/MandateCardHeader';

class MandateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false,
    };
    this.toggleMessage = this.toggleMessage.bind(this);
  }
  toggleMessage() {
    this.setState(({ showMessage }) => ({
      showMessage: !showMessage,
    }));
  }
  render() {
    const { showMessage } = this.state;
    const {
      acceptedProposals,
      allMandates,
      extendProposalsTime,
      mandate,
      role,
      pastMandates,
    } = this.props;
    const substituteRole = role === 'substitute';
    const managerRole = role === 'clinic_manager';
    const { proposals, clinic } = mandate;
    const message = substituteRole ? clinic.message : mandate.message;
    const { substitutePrice } = mandate.proposals.length && mandate.proposals[0];
    return (
      <div
        className={classNames('mandate-card', {
          'mandate-card--blue': allMandates,
          'mandate-card--green': (!allMandates && acceptedProposals),
          'mandate-card--orange': (!allMandates && !acceptedProposals),
          'mandate-card--grey': pastMandates,
        })}
      >
        {proposals.length > 0 && (
          <MandateCardHeader
            extendProposalsTime={extendProposalsTime}
            acceptedProposals={acceptedProposals}
            proposals={mandate.proposals}
            role={role}
          />
        )}
        <div className="mandate-card__content">
          <div className="mandate-card__details">
            {!managerRole &&
              <ClinicInfo
                clinic={mandate.clinic}
                mandate={mandate}
                substitutePrice={substitutePrice}
                onToggleMessage={this.toggleMessage}
                showMessage={showMessage}
                substituteRole={substituteRole}
              />
            }
            {!substituteRole && <MandateInfo mandate={mandate} />}
            {message &&
              <p
                className={classNames('mandate-card__details__desc', {
                  'mandate-card__details__desc--open': showMessage,
                })}
              >
                {message}
              </p>
            }
          </div>
          <div className="mandate-card__content__list">
            <MandateProposalsForm
              acceptedProposals={acceptedProposals}
              allMandates={allMandates}
              mandate={mandate}
            />
          </div>
        </div>
      </div>
    );
  }
}

MandateCard.propTypes = {
  acceptedProposals: PropTypes.bool.isRequired,
  allMandates: PropTypes.bool.isRequired,
  extendProposalsTime: PropTypes.func.isRequired,
  mandate: PropTypes.object.isRequired,
  pastMandates: PropTypes.bool,
  role: PropTypes.string.isRequired,
};
MandateCard.defaultProps = {
  pastMandates: false,
};

export default MandateCard;
