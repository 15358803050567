// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

// components
import UserForm from './forms/UserForm';
import BasicLoader from '../../loader/BasicLoader';

// actions
import actions from '../../../actions/admin/users';
import alertsActions from '../../../actions/alerts/index';
import validate from './validate/validate';

// helpers
import formatExoticAnimals from '../../../helpers/formatExoticAnimals';
import formatSoftwares from '../../../helpers/formatSoftwares';
import { generateTaxNumbers } from '../../../helpers/formatUsers';
import { scrollToError } from '../../../helpers/scrollToError';

class AddUser extends Component {
  static propTypes = {
    createUser: PropTypes.func.isRequired,
    error: PropTypes.string,
    fetchUsers: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    pushAlert: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    formIsSubmitting: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    error: '',
  };

  constructor() {
    super();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(values) {
    const { createUser, fetchUsers, pushAlert, hidePanel } = this.props;
    const {
      formName,
      saveAddress,
      profile: {
        accountType,
        address,
        graduation,
        picture,
        softwareOthersCheck,
        surgerySoftTissueOthersCheck,
        ...profile
      },
      ...input
    } = values;
    const isSubstitute = values.role === 'substitute';

    createUser({
      input: {
        ...input,
        profile: {
          ...profile,
          defaultDistance: parseInt(profile.defaultDistance, 10),
          gloveSize: parseFloat(profile.gloveSize),
          phone: `+1${profile.phone.replace(/[^\d]/g, '')}`,
          exoticAnimals: formatExoticAnimals(profile.exoticAnimals),
          exoticAnimalSkills: profile.exoticAnimalSkills === 'true',
          softwares: formatSoftwares(profile.softwares),
          ...profile.surgeryCanineOvhWeightLimit && {
            surgeryCanineOvhWeightLimit: parseInt(profile.surgeryCanineOvhWeightLimit, 10),
          },
          surgeryDeclawing: profile.surgeryDeclawing === 'true',
          surgerySterilization: profile.surgerySterilization === 'true',
          ...(graduation !== '') && { graduation },
          ...(isSubstitute) && { accountType },
          ...(isSubstitute || saveAddress === 'true') && { address },
        },
      },
    })
      .then((data) => {
        if (data && data.createUser.success) {
          fetchUsers();
          pushAlert({
            type: 'success',
            content: 'alerts.added',
          });
          hidePanel();
        }
      });
  }

  render() {
    const { error, handleSubmit, hidePanel, pristine, submitting, formIsSubmitting } = this.props;
    const { t } = this.context;
    const submitForm = handleSubmit(this.handleFormSubmit);

    return (
      <div>
        <form onSubmit={submitForm} className="side-panel__form">
          {formIsSubmitting && <BasicLoader />}
          {error && <div className="form-error">{error}</div>}
          <UserForm formName="createUser" />
          <div className="form__actions">
            <button
              className="form__cancel"
              type="button"
              disabled={submitting}
              onClick={() => hidePanel()}
            >
              {t('form.formActions.cancel')}
            </button>
            <button className="form__submit" type="submit" disabled={pristine || submitting}>
              {t('form.formActions.add')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formIsSubmitting: state.users.submitting,
  };
}

export default connect(
  mapStateToProps,
  { ...actions, ...alertsActions },
)(
  reduxForm({
    validate,
    form: 'createUser',
    destroyOnUnmount: true,
    enableReinitialize: true,
    initialValues: {
      formName: 'createUser',
      profile: {
        accountType: 'technician',
        bringAnimals: false,
        communicationType: 'email',
        consultation: false,
        defaultDistance: '150',
        gloveSize: '5.5',
        graduation: '',
        surgery: false,
        taxNumbers: generateTaxNumbers(),
        address: {
          country: 'ca',
          province: process.env.REACT_APP_REGION_CODE,
        },
        exoticAnimals: [],
        exoticAnimalSkills: false,
        softwares: [],
      },
      saveAddress: 'false',
    },
    onSubmitFail: (errors) => scrollToError(errors),
  })(AddUser),
);
