import React from 'react';
import PropTypes from 'prop-types';

// helpers
import { formatClinicDistance } from '../../../../../helpers/distance';

// components
import Address from './proposals/parts/Address';
import ProposalDetails from './proposals/parts/ProposalDetails';

function ClinicInfo({
  clinic,
  mandate,
  onToggleMessage,
  showMessage,
  substitutePrice,
  substituteRole,
}, { t }) {
  const message = substituteRole ? clinic.message : mandate.message;
  return (
    <div className="mandate-card__content__header">
      <div className="mandate-card__content__header__container">
        <h3>{clinic.name}</h3>
        <Address
          addressObject={clinic.address}
          onToggleMessage={onToggleMessage}
        />
        { message &&
          <div
            className={`mandate-card__details__wrapper mandate-card__details__wrapper__${showMessage ? 'hide' : 'show'}-message`}
            onClick={() => onToggleMessage()}
            onKeyPress={() => onToggleMessage()}
            role="button"
            tabIndex="0"
          >
            <div
              className="mandate-card__details__show-message"
            >
              {t('mandates.cards.details')}
            </div>
          </div>
        }
      </div>
      {substituteRole &&
        <div className="mandate-card__content__header__container">
          <ProposalDetails
            distance={formatClinicDistance(clinic.distance)}
            mandate={mandate}
            substitutePrice={substitutePrice}
          />
        </div>
      }
    </div>
  );
}

ClinicInfo.defaultProps = {
  showMessage: false,
  mandate: {},
  substitutePrice: 0,
  substituteRole: false,
};
ClinicInfo.propTypes = {
  clinic: PropTypes.object.isRequired,
  mandate: PropTypes.object,
  onToggleMessage: PropTypes.func.isRequired,
  showMessage: PropTypes.bool,
  substitutePrice: PropTypes.number,
  substituteRole: PropTypes.bool,
};
ClinicInfo.contextTypes = {
  t: PropTypes.func,
};

export default ClinicInfo;
