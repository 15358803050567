// libs
import React from 'react';
import PropTypes from 'prop-types';
import { formatDay } from '../../../helpers/formatDates';

// css
import './styles.scss';

function DayHeader(props) {
  const { date, label } = props;

  return (
    <div className="schedule__day-header">
      <div className="schedule__day-header--date">
        <span>{ label }</span>
        { formatDay(date) }
      </div>
    </div>
  );
}

DayHeader.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  label: PropTypes.string.isRequired,
};

DayHeader.contextTypes = {
  t: PropTypes.func,
};

export default DayHeader;
