import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DateTime from './DateTime';

function Heading({
  endsAt,
  startsAt,
  invalid,
  status,
  userRole,
  confirmAllEntries,
  actionToConfirm,
  confirmedAt,
  submittedAt,
  cannotSubmitTimesheet,
}, { t }) {
  const isSubstituteSubmitted = ['submitted', 'confirmed'].includes(status) && userRole === 'substitute';
  const isAdminSubmitted = status === 'submitted' && userRole === 'admin';
  const isAdminConfirmed = status === 'confirmed' && userRole === 'admin';

  return (
    <div className="timesheet-card__header">
      <DateTime endsAt={endsAt} startsAt={startsAt} />
      <div className="timesheet-card__part timesheet-card__part--between timesheet-card__headings">
        <div className="timesheet-card__heading">{t('timesheets.schedule')}</div>
        <div className="timesheet-card__heading">{t('timesheets.lunch')}</div>
        <div className="timesheet-card__heading">{t('timesheets.dinner')}</div>
        <div className="timesheet-card__heading">{t('form.editTimesheetEntry.distance')}</div>
      </div>
      <div className="timesheet-card__part timesheet-card__part--20 timesheet-card__part--right timesheet-card__actions">
        { isAdminSubmitted &&
          <button
            className={classNames('timesheet-card__submit', { 'timesheet-card__submit--invalid': invalid })}
            type="button"
            onClick={() => actionToConfirm(confirmAllEntries, 'timesheets.confirmAllEntries')}
          >
            {t('form.formActions.confirmAllEntries')}
          </button>
        }
        { ((status === 'pending' || status === 'late') && !cannotSubmitTimesheet) &&
          <button className={classNames('timesheet-card__submit', { 'timesheet-card__submit--invalid': invalid })}>
            {t('form.formActions.confirm')}
          </button>
        }
        { isSubstituteSubmitted &&
          <span className="timesheet-card__submitted">
            {t('timesheets.submitted')} {submittedAt}
          </span>
        }
        { isAdminConfirmed &&
          <span className="timesheet-card__submitted">
            {t('timesheets.confirmed')} {confirmedAt}
          </span>
        }
      </div>
    </div>
  );
}

Heading.propTypes = {
  actionToConfirm: PropTypes.func.isRequired,
  cannotSubmitTimesheet: PropTypes.bool.isRequired,
  confirmAllEntries: PropTypes.func.isRequired,
  endsAt: PropTypes.string.isRequired,
  startsAt: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  confirmedAt: PropTypes.string,
  submittedAt: PropTypes.string,
};
Heading.defaultProps = {
  confirmedAt: '',
  submittedAt: '',
};
Heading.contextTypes = {
  t: PropTypes.func,
};

export default Heading;
