export default {
  acceptProposition: {
    accept: 'Accepter',
    refuse: 'Refuser'
  },
  accommodationBringAnimals: 'Je souhaite apporter mes animaux dans l\'hébergement',
  accommodationPreference: 'Préférence d\'hébergement',
  accommodationPreferences: {
    alone: 'Seul',
    none: 'Ça ne me dérange pas de cohabiter avec l\'hôte',
  },
  account: 'Compte',
  accounting: 'Comptabilité / Facturation',
  accountType: 'Type de compte',
  accountTypes: {
    technician: 'Technicien en santé animale',
    veterinarian: 'Vétérinaire',
  },
  addAvailability: {
    allDay: 'Toute la journée',
    allDayTooltip: 'Disponible toute la journée',
    availabilities: 'Horaire',
    availability: 'Disponibilité',
    consultation: 'Consultation',
    datesFilled: 'Les journées sélectionnées ont déjà des disponibilités associées.',
    endDate: 'Fin',
    endTime: 'Heure de fin',
    moreInfo: 'Informations complémentaires',
    other: 'Habiletés',
    over150: 'Plus de 150 km',
    period: 'Période',
    selectPeriod: 'Sélectionnez la période pour ajouter des disponibilités',
    startDate: 'Début',
    startTime: 'Heure de début',
    surgery: 'Chirurgie',
    travel: 'Déplacement',
    under50: 'Moins de 50 km',
    under150: 'Moins de 150 km',
  },
  addBillingAddress: 'Ajouter une adresse de facturation différente',
  addCallToAll: {
    message: 'Description de l\'appel à tous*',
    removeAll: 'Réinitialiser la sélection',
    selectAll: 'Sélectionner tous les remplaçants',
    selectOneSub: 'Vous devez sélectionner au moins un remplaçant',
    selectUsers: 'Sélectionner les remplaçants',
  },
  addMandate: {
    accommodation: {
      available: 'Hébergement offert',
      no: 'Non',
      yes: 'Oui',
    },
    availabilities: 'Horaire',
    flexible: 'Plage horaire flexible',
    mandateType: 'Type de professionnel',
    message: 'Message',
    other: 'Autres détails',
    period: 'Période',
    priority: 'Journée prioritaire',
    selectPeriod: 'Sélectionnez la période pour ajouter une demande',
    skills: {
      consultation: 'Consultation',
      optional: 'Chirurgie facultative',
      required: 'Chirurgie obligatoire',
      title: 'Compétences',
    },
    technician: 'Technicien (TSA)',
    veterinarian: 'Vétérinaire',
    wantedCount: 'Nombre de jours dans la période',
  },
  addProposals: {
    clinicPrice: "Prix de la clinique",
    substitutePrice: "Tarif du remplaçant",
  },
  address2: 'Détails de l\'adresse',
  address: 'Adresse',
  addressBilling: 'Adresse de facturation',
  addUser: {
    selectGloveSize: 'Sélectionnez votre taille de gants',
    selectGraduation: 'Sélectionnez votre année de diplomation',
  },
  adminNoteClinic: 'Notes sur la clinique',
  adminNoteGeneral: 'Notes à usage interne',
  adminNoteSubstitute: 'Notes sur le remplaçant',
  adminSection: 'Section admin',
  allowExtendedExpiration: 'Permettre l\'ajout d\'un délai',
  animals: 'Je possède des animaux',
  billing: 'Facturation',
  billingContact: 'Personne ressource pour facturation',
  blacklistedUsers: 'Utilisateurs dans la blacklist',
  blacklistedClinics: 'Cliniques dans la blacklist',
  boolean: {
    no: 'Non',
    yes: 'Oui',
  },
  bringAnimals: 'Je souhaite les apporter en remplacement',
  canineMaxWeight: 'Poids maximal',
  changeEmailInstructions: 'Pour modifier votre adresse courriel, veuillez communiquer avec',
  city: 'Ville',
  clinic: {
    picture: "Façade de la clinique",
  },
  clinicBilling: 'Contact de facturation',
  clinicInfo: 'Informations de la clinique',
  communicationType: 'Préférences de communication',
  communicationTypes: {
    both: 'Les deux',
    email: 'Courriel',
    sms: 'SMS',
  },
  confirmNewPassword: 'Confirmez le nouveau mot de passe',
  passwordConfirmation: 'Confirmez le mot de passe',
  consultation: 'Consultation',
  consultationAverageDuration: 'Temps moyen d\'une consultation (en minutes)',
  country: 'Pays',
  defaultDistance: 'Préférences de déplacement',
  description: 'Description de la clinique',
  displayedLanguage: 'Langue de l\'interface',
  editCallToAll: {
    changeStatus: 'Statut',
    message: 'Description de l\'appel à tous',
    selectedUsers: 'Remplaçants sélectionnés',
    status: {
      pending: 'En attente',
      confirmed: 'Confirmé',
      canceled: 'Annulé',
    },
  },
  editTimesheetEntry: {
    dinner: 'Souper',
    distance: 'Km/Jour',
    distanceTraveled: 'Déplacement',
    duration: 'Durée',
    endsAt: 'Fin',
    lunch: 'Dîner',
    message: 'Message',
    moreInfo: 'Informations supplémentaires',
    schedule: 'Horaire',
    startsAt: 'Début',
  },
  email: 'Adresse courriel',
  employed: 'Employé',
  en: 'Anglais',
  expertise: 'Domaines de pratique',
  expertiseAreas: 'Domaines de pratique',
  exoticAnimal: 'Espèce(s)',
  exoticAnimalAreas: 'Animaux exotiques',
  exoticAnimalMessage: 'Informations complémentaires (concernant les espèces)',
  exoticAnimalSkills: 'Animaux exotiques',
  firstName: 'Prénom',
  formActions: {
    add: 'Ajouter',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    confirmAllEntries: 'Tout confirmer',
    confirmClinicTooltip: 'Confirmation clinique',
    confirmUserTooltip: 'Confirmation remplaçant',
    delete: 'Supprimer',
    disable: 'Désactiver',
    edit: 'Modifier',
    enable: 'Activer',
    loadMore: 'Afficher plus',
    remove: 'Supprimer',
    submit: 'Envoyer',
    update: 'Enregistrer',
    updatePassword: 'Mettre à jour mon mot de passe',
    uploader: {
      dragDrop: {
        browse: 'cliquez pour sélectionner',
        clinic: {
          drop: 'SVP, ajouter une photo de la façade de votre bâtisse pour aider le remplaçant à identifier l’endroit. Déposez un fichier ou %{browse}',
        },
        drop: 'Déposez un fichier ou %{browse}',
      },
      submitToUpdate: 'Soumettez le formulaire pour mettre à jour {n}',
      submitToUpdatePicture: 'Soumettez le formulaire pour mettre à jour votre image',
      uploadedFile: 'Fichier téléversé',
      statusBar: {
        uploading: 'Téléversement',
        complete: 'Téléversement complété',
        uploadFailed: 'Erreur lors du téléversement',
      },
    },
  },
  fr: 'Français',
  gloveSize: 'Grandeur de gants',
  graduation: 'Année de diplomation',
  language: 'Langue',
  languages: 'Langues',
  lastName: 'Nom',
  level: 'Niveau',
  levels: {
    fluent: 'Courant',
    functional: 'Fonctionnel',
  },
  licenceNumber: 'Numéro de permis',
  locations: {
    ca: 'Canada',
    qc: 'Québec',
    on: 'Ontario',
  },
  managers: 'Gestionnaires',
  manualValidation: 'Validation par la clinique',
  message: 'Message',
  name: 'Nom',
  newPassword: 'Nouveau mot de passe',
  noFileYet: 'Aucun fichier pour le moment',
  notes: 'Notes',
  oldPassword: 'Mot de passe actuel',
  openingHours: 'Heures d\'ouverture',
  otherVets: 'Autres vétérinaires sur le plancher',
  password: 'Mot de passe',
  phone: 'Numéro de téléphone',
  professionalInfo: 'Informations professionnelles',
  profile: {
    picture: "Photo de profil",
    voidedCheck: 'Spécimen de chèque',
    voidedCheckMsg: 'Si ton spécimen chèque nous a déjà été transmis, tu n\'as pas besoin de remplir cette section.',
  },
  province: 'Province',
  rate: 'Taux',
  rates: {
    city: 'Ville',
    remote: 'À distance',
  },
  reasons: {
    files: 'Dossiers',
    phone: 'Rappels téléphoniques',
    work: 'Travail (consultation/chirurgie)',
    notAvailable: 'Non applicable',
  },
  replaceFile: 'Remplacer le fichier',
  replaceFileShort: 'Remplacer',
  rememberMe: 'Se souvenir de moi',
  role: 'Rôle',
  roles: {
    admin: 'Administrateur',
    clinic_manager: 'Gestionnaire',
    substitute: 'Remplaçant',
  },
  saveAddress: 'Enregistrer l\'adresse',
  search: 'Rechercher',
  selectSoftware: 'Sélectionner un logiciel',
  skills: 'Habiletés',
  softTissue: 'Tissus mous',
  softwareUsed: 'Logiciel pour la tenue de dossiers?',
  softwareSkills: 'Logiciels maîtrisés pour la tenue de dossiers',
  software: 'Logiciel utilisé',
  softwareOthers: 'Autre(s)',
  surgery: 'Chirurgie',
  surgeryCanineOvhWeightLimit: 'OVH Canine',
  surgeryDeclawing: 'Onyxectomie',
  surgeryMessage: 'Notes additionnelles (concernant la chirurgie)',
  surgerySoftTissueCaesarean: 'Césarienne',
  surgerySoftTissueCystotomy: 'Cystotomie',
  surgerySoftTissueForeignBody: 'Corps étranger',
  surgerySoftTissueMassAblation: 'Ablation de masse',
  surgerySoftTissuePyometra: 'Pyométrie',
  surgerySoftTissueOthers: 'Autre(s)',
  surgerySterilization: 'Stérilisations',
  taxNumber: 'Numéro de taxes',
  taxNumbers: {
    types: {
      hst: 'Numéro de TVH (si inscrit)',
      gst: 'Numéro de TPS (si inscrit)',
      qst: 'Numéro de TVQ (si inscrit)',
    },
  },
  uploadFile: 'Téléverser un fichier',
  uploadFileShort: 'Téléverser',
  uploadImage: 'Téléverser une image',
  userInfo: 'Informations personnelles',
  viewFile: 'Télécharger le fichier',
  weightOptions: {
    lb: '{n} lb',
    less: '{n} lb et moins',
    more: 'Plus de {n} lb',
  },
  zipcode: 'Code postal',
};
