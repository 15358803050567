const allPrices = {
  // row = experience rank
  // col = price based on distance: <120, (>=120 and <250), (>=250 and <500), >=500km
  // @source https://docs.google.com/spreadsheets/d/1Qvplv6EwQfuLnOD39pGxJW3ejiIPcxupJstI74Uwefw/edit#gid=0
  clinic: {
    veterinarian: {
      0: [87, 95, 108, 120],
      1: [95, 100, 113, 130],
      2: [100, 105, 118, 130],
      3: [105, 110, 123, 130],
      4: [110, 115, 125, 130],
    },
    technician: {
      0: [38.25],
      1: [40.95],
      2: [42.80],
    },
  },
  substitute: {
    veterinarian: {
      0: [60, 65, 70, 80],
      1: [65, 70, 75, 90],
      2: [70, 75, 80, 90],
      3: [75, 80, 85, 90],
      4: [80, 85, 87, 90],
    },
    technician: {
      0: [21.55],
      1: [24.00],
      2: [26.00],
    },
  },
};

export function getExperienceIndex(substituteType, experience) {
  let experienceIndex = null;
  if (substituteType === 'veterinarian') {
    switch (true) {
      case (experience < 3):
        experienceIndex = 0;
        break;
      case ((experience >= 3) && (experience < 5)):
        experienceIndex = 1;
        break;
      case ((experience >= 5) && (experience < 10)):
        experienceIndex = 2;
        break;
      case ((experience >= 10) && (experience < 15)):
        experienceIndex = 3;
        break;
      case ((experience >= 15)):
        experienceIndex = 4;
        break;
      default:
    }
  } else if (substituteType === 'technician') {
    switch (true) {
      case (experience < 5):
        experienceIndex = 0;
        break;
      case ((experience >= 5) && (experience < 9)):
        experienceIndex = 1;
        break;
      case ((experience >= 9)):
        experienceIndex = 2;
        break;
      default:
    }
  }
  return experienceIndex;
}

export function getDistanceIndex(substituteType, distance) {
  let distanceIndex = null;
  if (substituteType === 'veterinarian') {
    switch (true) {
      case (distance < 120):
        distanceIndex = 0;
        break;
      case ((distance >= 120) && (distance < 250)):
        distanceIndex = 1;
        break;
      case ((distance >= 250) && (distance < 500)):
        distanceIndex = 2;
        break;
      case ((distance >= 500)):
        distanceIndex = 3;
        break;

      default:
    }
  } else if (substituteType === 'technician') {
    switch (true) {
      case ((distance > 0)):
        distanceIndex = 0;
        break;

      default:
    }
  }
  return distanceIndex;
}

export function formatValueToMoney(value = null) {
  if (value.toString().indexOf('.') !== -1) {
    let money = (Math.round(value * 100) / 100);
    money = parseFloat(money).toFixed(2);
    return money;
  }
  return value;
}

export function getPrice(substituteType, experience, distance, priceFor) {
  distance *= 2; // prices are based on the two way travel
  const userPrices = allPrices[priceFor][substituteType];
  const priceIndexes = {
    experienceIndex: getExperienceIndex(substituteType, experience),
    distanceIndex: getDistanceIndex(substituteType, distance),
  };

  let price =
    Object.entries(userPrices)[priceIndexes.experienceIndex][1][priceIndexes.distanceIndex];
  price = formatValueToMoney(price);
  return price;
}
