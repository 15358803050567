import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

// components
import Checkbox from '../../../forms/fields/checkbox/Checkbox';

// actions
import exoticAnimalsActions from '../../../../actions/exoticAnimals';

// styles
import './styles.scss';


class ExoticAnimalsSubForm extends Component {
  static propTypes = {
    fetchExoticAnimals: PropTypes.func.isRequired,
    exoticAnimals: PropTypes.array.isRequired,
    userExoticAnimals: PropTypes.array.isRequired,
    fields: PropTypes.object,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    fields: {},
  };

  constructor(props) {
    super(props);
    this.formatFields = this.formatFields.bind(this);
  }

  componentDidMount() {
    const { exoticAnimals, fetchExoticAnimals } = this.props;

    if (exoticAnimals.length === 0) {
      fetchExoticAnimals().then(() => {
        this.formatFields();
      });
    } else {
      this.formatFields();
    }
  }

  formatFields() {
    const { exoticAnimals, userExoticAnimals, fields } = this.props;
    const userExoticAnimalsIds = userExoticAnimals.map((exoticAnimal) =>
      (exoticAnimal.exoticAnimalId));
    const exoticAnimalsFields = exoticAnimals.map((exoticAnimal) => ({
      id: userExoticAnimals
        .filter((userExoticAnimal) => (userExoticAnimal.exoticAnimalId === exoticAnimal.id))
        .map(userExoticAnimal => userExoticAnimal.id)[0] || null,
      exoticAnimalId: exoticAnimal.id,
      name: exoticAnimal.name,
      checked: userExoticAnimalsIds.includes(exoticAnimal.id),
    }));

    fields.removeAll();
    exoticAnimalsFields.forEach((exoticAnimal) => (
      fields.push({
        id: exoticAnimal.id,
        exoticAnimalId: exoticAnimal.exoticAnimalId,
        name: exoticAnimal.name,
        checked: exoticAnimal.checked,
      })
    ));
  }

  render() {
    const { exoticAnimals, fields } = this.props;
    const { t } = this.context;

    if (exoticAnimals.length === 0) {
      return null;
    }

    return (
      <div className="field__checkbox-list">
        <label className="field__checkbox-list__label">{t('form.exoticAnimal')}</label>
        <div className="field__checkbox-list__wrapper">
          { fields.map((exoticAnimal, index, data) =>
            <div key={`exoticAnimal-${data.get(index).exoticAnimalId}`} className="field">
              <label>
                <Field
                  name={`${exoticAnimal}.checked`}
                  type="checkbox"
                  component={Checkbox}
                  checked={data.get(index).checked}
                  label={data.get(index).name}
                  exactLabel
                />
              </label>
            </div>,
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users: { user = {} } } = state;
  const { ...formData } = user;
  return {
    userExoticAnimals: formData.profile && formData.profile.exoticAnimals
      ? formData.profile.exoticAnimals : [],
    exoticAnimals: (state.exoticAnimals && state.exoticAnimals.exoticAnimals) || [],
  };
}

export default connect(mapStateToProps, exoticAnimalsActions)(ExoticAnimalsSubForm);

