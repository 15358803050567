// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// Components
import ReactDateSingle from '../forms/fields/reactDates/ReactDateSingle';

// Actions
import actions from '../../actions/timesheet';

// Styles
import './styles.scss';

class TimesheetSelectWeek extends Component {
  static propTypes = {
    accountType: PropTypes.string.isRequired,
    fetchTimesheets: PropTypes.func.isRequired,
    updateListDate: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {};

  constructor() {
    super();
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.state = {
      date: moment(new Date()),
      focused: null,
    };
  }

  handlePeriodChange(selectedDate) {
    const { accountType, fetchTimesheets, updateListDate } = this.props;
    if (selectedDate) {
      updateListDate(selectedDate);
      fetchTimesheets({
        accountType,
        from: selectedDate.toISOString(),
        to: selectedDate.toISOString(),
        first: 10000,
      });
    }
  }

  render() {
    return (
      <div>
        <ReactDateSingle
          date={this.state.date}
          id="timesheetsWeekSelector"
          onDateChange={(date) => this.setState({ date })}
          onClose={({ date }) => this.handlePeriodChange(date)}
          focused={this.state.focused}
          onFocusChange={({ focused }) => this.setState({ focused })}
          numberOfMonths={1}
          isOutsideRange={(date) => date.year() < 2019}
          isDayHighlighted={(date) => date.format('w') === this.state.date.format('w')}
        />
      </div>
    );
  }
}

export default withRouter(connect(
  null,
  { ...actions },
)(TimesheetSelectWeek));
