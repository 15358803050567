// libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddAvailabilities from '../../availabilities/AddAvailabilities';

// actions
import actionsSidePanel from '../../../actions/dynamicSidePanel/index';

// css
import './styles.scss';

function CustomToolbar({ ...props }, { t }) {
  const { updateComponent } = props;
  return (
    <div className="availabilities__toolbar">
      <div className="availabilities__toolbar__title">
        <h2>{t('availabilities.availabilities')}</h2>
      </div>
      <button
        onClick={() => updateComponent(AddAvailabilities, 'addAvailabilities', { sidebarIcon: 'edit' })}
        className="availabilities__toolbar__add-availability"
      >
        <span>{t('availabilities.add')}</span>
      </button>
    </div>
  );
}

function mapStateToProps() {
  return {};
}

CustomToolbar.propTypes = {
  updateComponent: PropTypes.func.isRequired,
};

CustomToolbar.contextTypes = {
  t: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { ...actionsSidePanel },
)(CustomToolbar);
