import { asyncQuery, asyncMutation } from '../../../config/graphql/async-action-creator';
import queries from './queries';
import types from './types';
import store from '../../../config/redux/store';
import client from '../../../config/graphql/client';

export default {
  createCallToAll: (variables) => asyncMutation(
    store,
    types.CREATE_CALL_TO_ALL,
    queries.createCallToAll,
    variables,
    client,
  ),
  fetchAvailableSubstitutesList: (replacementId, clinicId) => asyncQuery(
    store,
    types.FETCH_AVAILABLE_SUBSTITUTES_LIST,
    queries.fetchAvailableSubstitutesList,
    { replacementId, clinicId },
    client,
  ),
  fetchCallToAll: (id) => asyncQuery(
    store,
    types.FETCH_CALL_TO_ALL,
    queries.fetchCallToAll,
    id,
    client,
  ),
  fetchUserCallToAlls: (id) => asyncQuery(
    store,
    types.FETCH_USER_CALL_TO_ALLS,
    queries.fetchUserCallToAlls,
    { id },
    client,
  ),
  replacementForCallToAll: (replacement) => ({
    type: types.REPLACEMENT_FOR_CALL_TO_ALL,
    replacement,
  }),
  updateStatusCallToAll: (variables) => asyncMutation(
    store,
    types.UPDATE_STATUS_CALL_TO_ALL,
    queries.updateStatusCallToAll,
    variables,
    client,
  ),
  userReviewCallToAlls: (variables) => asyncMutation(
    store,
    types.USER_REVIEW_CALL_TO_ALLS,
    queries.userReviewCallToAlls,
    variables,
    client,
  ),
};
