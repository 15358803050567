import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '../input/Input';
import normalizeZipCode from '../../normalize/normalizeZipCode';

function ZipCode({ name }) {
  return (
    <Field
      name={name}
      type="text"
      label="zipcode"
      component={Input}
      className="field--light"
      normalize={normalizeZipCode}
      required
    />
  );
}

ZipCode.propTypes = {
  name: PropTypes.string,
};
ZipCode.defaultProps = {
  name: 'zipCode',
};

export default ZipCode;
