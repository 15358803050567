import React from 'react';
import PropTypes from 'prop-types';

// Component
import EntryDate from './EntryDate';
import Fields from './Fields';

function EntryRow({ field, entry: { startsAt, clinic: { name }, id }, entry }) {
  return (
    <li className="list-item">
      <div className="list-item__wrapper">
        <div className="timesheet-card__part list-item__details">
          <EntryDate date={startsAt} />
          <div className="list-item__user">
            <span>{ name }</span>
          </div>
        </div>
        <Fields field={field} entryId={id} entry={entry} />
      </div>
    </li>
  );
}

EntryRow.propTypes = {
  entry: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
};
EntryRow.defaultProps = {};
EntryRow.contextTypes = {};

export default EntryRow;
