function flattenArray(arrayToFlatten) {
  return arrayToFlatten.reduce((acc, val) => {
    if (Array.isArray(val)) {
      acc.concat(flattenArray(val));
    }
    return acc.concat(val);
  }, []);
}

function isNumber(n) {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(parseFloat(n));
}

function getNewKey(prefix, key) {
  if (prefix) {
    return (isNumber(key)) ? `${prefix}[${key}]` : `${prefix}.${key}`;
  }
  return key;
}

function getAllFieldsWithAnError(errors, prefix = '') {
  const fieldNamesArray = Object.keys(errors).map((key) => {
    if (typeof errors[key] === 'string') {
      return getNewKey(prefix, key);
    }
    if (typeof errors[key] === 'object') {
      const newKey = getNewKey(prefix, key);
      return getAllFieldsWithAnError(errors[key], newKey);
    }
    return null;
  });
  return flattenArray(fieldNamesArray);
}

function getFirstFieldWithAnError(errors) {
  const fieldsNames = getAllFieldsWithAnError(errors);
  const firstFieldWithAnError = fieldsNames.reduce((firstField, field) => {
    const firstElement = document.querySelector(`input[name="${firstField}"]`);
    const fieldElement = document.querySelector(`input[name="${field}"]`);
    if (firstElement && fieldElement) {
      const firstElRect = firstElement.closest('.field').getBoundingClientRect();
      const fieldElRect = fieldElement.closest('.field').getBoundingClientRect();
      if (firstElRect.top > fieldElRect.top) {
        return field;
      }
    }

    return firstField;
  });
  return firstFieldWithAnError;
}

export function scrollToError(errors) {
  if (Object.keys(errors).length > 0) {
    const firstFieldWithAnError = getFirstFieldWithAnError(errors);
    const inputElement = document.querySelector(`[name="${firstFieldWithAnError}"]`);
    if (inputElement) {
      document.querySelector('.side-panel__section__wrapper').scrollTop =
        inputElement.closest('.field').offsetTop - 120;
    }
  }
}

export default scrollToError;
