import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'redux-form';
import classNames from 'classnames';
import Radio from './Radio';

function RadioGroup({ disabled, radioList, name, small }, { t }) {
  return (
    <div className={classNames('radio-custom', { 'radio-custom__small': small })}>
      { radioList.map(({ value, label }) =>
        <Radio
          key={value}
          name={name}
          component="input"
          value={value}
          label={label}
          disabled={disabled}
          type="radio"
        />,
      )}
      <Field
        name={name}
        component={({ meta }) =>
          <div>
            {meta.error && meta.touched && <span className="field__error">{t(meta.error)}</span>}
          </div>}
      />
    </div>
  );
}

RadioGroup.contextTypes = {
  t: PropTypes.func,
};
RadioGroup.propTypes = {
  disabled: PropTypes.bool,
  radioList: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
};
RadioGroup.defaultProps = {
  disabled: false,
  small: false,
};

export default RadioGroup;
