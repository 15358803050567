// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import moment from 'moment/moment';
import Select from '../../../forms/fields/select/Select';
import { openingHourSelector } from '../../../../helpers/form';

// CSS
import './styles.scss';

class OpeningHoursSubForm extends Component {
  componentDidMount() {
    const { fields, openingHours } = this.props;
    if (fields.length === 0) {
      openingHours.forEach((openingHour) => {
        fields.push({ ...openingHour });
      });
    }
  }

  render() {
    const { fields } = this.props;
    return (
      <div>
        {fields.map((openingHour, index, data) => (
          <div key={openingHour} className="opening-hour">
            <div className="opening-hour__day">
              {moment(data.get(index).day, 'e').format('dddd')}
            </div>
            <Field
              name={`${openingHour}.opensAt`}
              type="text"
              component={Select}
              label="openingHour.opensAt"
              className="field--select field--no-message"
              innerLabel
            >
              <option value="" />
              { openingHourSelector() }
            </Field>
            <span className="opening-hour__arrow" />
            <Field
              name={`${openingHour}.closesAt`}
              type="text"
              component={Select}
              label="openingHour.closesAt"
              className="field--select field--no-message"
              innerLabel
            >
              <option value="" />
              { openingHourSelector() }
            </Field>
          </div>
        ))}
      </div>
    );
  }
}

OpeningHoursSubForm.propTypes = {
  fields: PropTypes.object,
  openingHours: PropTypes.array.isRequired,
};

OpeningHoursSubForm.defaultProps = {
  fields: {},
};


OpeningHoursSubForm.contextTypes = {
  t: PropTypes.func,
};

export default OpeningHoursSubForm;
