import React from 'react';
import PropTypes from 'prop-types';

// components
import Distance from './Distance';
import Price from './Price';
import Experience from './Experience';

function DetailsClinic({
  clinicPrice,
  substitute,
  userDistance,
  userPending,
  toggleDetails,
}, { t }) {
  const {
    profile: {
      firstName,
      lastName,
      picture,
      graduation,
      surgery,
      consultation,
      description,
    },
  } = substitute;

  return (
    <div className="list-item__drawer">
      <button
        className="list-item__drawer__close"
        onClick={toggleDetails}
      />
      <div className="list-item__drawer__infos">
        <div className="substitute">
          <div className="substitute__picture__wrapper">
            {!userPending && picture && picture.mini ?
              <span
                className="substitute__picture"
                style={{ backgroundImage: `url(${picture.mini})` }}
              />
            :
              <span className="substitute__picture substitute__picture--empty" />
            }
          </div>
          {!userPending ?
            <div className="substitute__infos">
              {
                (firstName && lastName) ?
                  <span className="mandate-item__user__name">{`${firstName} ${lastName}`}</span>
                :
                  <span className="mandate-item__user__name">{t('mandates.cards.proposalWaiting')}</span>
              }
              <div className="substitute__profile">
                {consultation &&
                  <span
                    title={t('event.consultation')}
                    className="type__icon type__icon--consult type__icon--active"
                  />
                }
                {surgery !== 'false' &&
                  <span
                    title={t('event.surgery')}
                    className="type__icon type__icon--surgery type__icon--active"
                  />
                }
                <Distance userDistance={userDistance} userPending={userPending} />
                {graduation &&
                  <Experience graduation={graduation} />
                }
                {clinicPrice &&
                  <Price
                    price={clinicPrice}
                  />
                }
                {description &&
                  <span className="substitute__desc">
                    {description}
                  </span>
                }
              </div>
            </div>
            :
            <div className="substitute__infos">
              <span className="substitute__pending">
                {t('mandates.cards.proposalWaiting')}
              </span>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

DetailsClinic.propTypes = {
  clinicPrice: PropTypes.number.isRequired,
  substitute: PropTypes.object.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  userDistance: PropTypes.number.isRequired,
  userPending: PropTypes.bool.isRequired,
};
DetailsClinic.defaultProps = {};
DetailsClinic.contextTypes = {
  t: PropTypes.func,
};

export default DetailsClinic;
