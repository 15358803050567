import types from '../actions/user/types';
import usersTypes from '../actions/admin/users/types';
import { formatRole, getCurrentClinicId, setCurrentClinicId } from '../helpers/formatUsers';

const initialState = {
  validatingToken: true,
  loading: false,
  authenticated: false,
  data: {},
  role: null,
  displayedLanguage: process.env.REACT_APP_REGION_DEFAULT_LOCALE,
  managedClinics: [],
  currentClinicId: null,
  subscription: null,
  profilePicture: {},
  profileVoidedCheck: {},
  submitting: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.VALIDATE_TOKEN:
      return { ...state, validatingToken: true };
    case types.VALIDATE_TOKEN_SUCCESS: {
      const profilePicture = action.payload.validateToken.user.profile.picture;
      return {
        ...state,
        currentClinicId: getCurrentClinicId(action.payload.validateToken.user.managedClinics),
        data: action.payload.validateToken.user,
        role: formatRole(action.payload.validateToken.user),
        displayedLanguage: action.payload.validateToken.user.profile.displayLanguage.key,
        managedClinics: action.payload.validateToken.user.managedClinics,
        validatingToken: false,
        authenticated: action.payload.validateToken.valid,
        profilePicture: profilePicture !== null ? profilePicture : {},

      };
    }
    case types.VALIDATE_TOKEN_FAIL:
      return { ...state, data: {}, role: null, authenticated: false, validatingToken: false };
    case types.VALIDATE_NO_TOKEN:
      return { ...state, validatingToken: false };
    case types.SIGN_IN:
      return { ...state, loading: true };
    case types.SIGN_IN_SUCCESS: {
      const profilePicture = action.payload.signIn.user.profile.picture;
      return {
        ...state,
        currentClinicId: getCurrentClinicId(action.payload.signIn.user.managedClinics),
        role: formatRole(action.payload.signIn.user),
        managedClinics: action.payload.signIn.user.managedClinics,
        displayedLanguage: action.payload.signIn.user.profile.displayLanguage.key,
        data: action.payload.signIn.user,
        authenticated: true,
        profilePicture: profilePicture !== null ? profilePicture : {},
        loading: false,
      };
    }
    case types.SIGN_IN_FAIL:
      return { ...state, data: {}, role: null, authenticated: false, loading: false };
    case types.SIGN_OUT:
      return { ...state, data: {}, role: null, authenticated: false };
    case types.UPDATE_CURRENT_CLINIC:
      return {
        ...state,
        currentClinicId: setCurrentClinicId(action.clinicId),
      };
    case types.SUBSCRIBE_USER:
      return {
        ...state,
        subscription: action.subscription,
      };
    case types.CLOSE_SUBSCRIPTIONS:
      return {
        ...state,
        subscription: null,
      };
    case usersTypes.FETCH_USER: {
      return {
        ...state,
        profilePicture: {},
        profileVoidedCheck: {},
        loading: true,
      };
    }
    case usersTypes.FETCH_USER_SUCCESS: {
      const profilePicture = action.payload.user.profile.picture;
      const profileVoidedCheck = action.payload.user.profile.voidedCheck;
      return {
        ...state,
        profilePicture: profilePicture !== null ? profilePicture : {},
        profileVoidedCheck: profileVoidedCheck !== null ? profileVoidedCheck : {},
        loading: false,
      };
    }
    case usersTypes.FETCH_USER_FAIL: {
      return { ...state, loading: false };
    }
    case usersTypes.UPDATE_USER_SUCCESS: {
      const profilePicture = action.payload.updateUser.user.profile.picture;
      const profileVoidedCheck = action.payload.updateUser.user.profile.voidedCheck;
      return {
        ...state,
        profilePicture: profilePicture !== null ? profilePicture : {},
        profileVoidedCheck: profileVoidedCheck !== null ? profileVoidedCheck : {},
        displayedLanguage: action.payload.updateUser.user.id === state.data.id ?
          action.payload.updateUser.user.profile.displayLanguage.key : state.displayedLanguage,
      };
    }
    case types.UPDATE_ACCOUNT:
      return {
        ...state,
        submitting: true,
        loading: true,
      };
    case types.UPDATE_ACCOUNT_SUCCESS:
    case types.UPDATE_ACCOUNT_FAIL:
      return {
        ...state,
        submitting: false,
        loading: false,
      };
    default:
  }

  return state;
}
