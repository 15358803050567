import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function DateTime({ endsAt, startsAt }, { t }) {
  return (
    <div className="timesheet-card__part timesheet-card__infos">
      <div className="timesheet-card__range">
        <div className="timesheet-card__range__day">
          <span>
            {moment(startsAt).format('MMM')} <strong>{moment(startsAt).format('DD')}</strong>
          </span>
        </div>
        <span className="timesheet-card__range__spacer" />
        <div className="timesheet-card__range__day">
          <span>
            {moment(endsAt).format('MMM')} <strong>{moment(endsAt).format('DD')}</strong>
          </span>
        </div>
      </div>
      <div className="timesheet-card__desc">
        {t('timesheets.instructions')}
      </div>
    </div>
  );
}

DateTime.propTypes = {
  endsAt: PropTypes.string.isRequired,
  startsAt: PropTypes.string.isRequired,
};
DateTime.defaultProps = {};
DateTime.contextTypes = {
  t: PropTypes.func,
};

export default DateTime;
