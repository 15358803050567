import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Flexible({ flexibleTime }) {
  return (
    <span
      className={classNames(
        'type__icon',
        'type__icon--flex', {
        'type__icon--active': flexibleTime,
      })}
    />
  );
}

Flexible.propTypes = {
  flexibleTime: PropTypes.bool,
};
Flexible.defaultProps = {
  flexibleTime: false,
};
Flexible.contextTypes = {};

export default Flexible;
