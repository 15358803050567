// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getNextMonth, getPrevMonth } from '../../../helpers/dates';

// components
import AddAvailabilities from '../../availabilities/AddAvailabilities';
import AddMandate from '../../mandates/AddMandate';
import MonthSelector from '../../monthSelector/MonthSelector';

// actions
import actionsAvailabilities from '../../../actions/availabilities';
import actionsMandate from '../../../actions/mandates';
import actionsSchedule from '../../../actions/schedule';
import actionsSidePanel from '../../../actions/dynamicSidePanel';

// css
import './styles.scss';

class CustomToolbar extends Component {
  constructor() {
    super();
    this.nextMonth = this.nextMonth.bind(this);
    this.prevMonth = this.prevMonth.bind(this);
    this.currentMonth = this.currentMonth.bind(this);
    this.addAvailabilities = this.addAvailabilities.bind(this);
    this.addMandate = this.addMandate.bind(this);
  }

  nextMonth() {
    const { date, onNavigate } = this.props;
    const newDate = getNextMonth(date);
    onNavigate('next', newDate);
  }

  prevMonth() {
    const { date, onNavigate } = this.props;
    const newDate = getPrevMonth(date);
    onNavigate('prev', newDate);
  }

  currentMonth() {
    const { date, onNavigate, changeDate } = this.props;
    const now = new Date();
    date.setMonth(now.getMonth());
    date.setYear(now.getFullYear());
    onNavigate('current');
    changeDate(now);
  }

  addAvailabilities() {
    const { setUser, userId, updateComponent } = this.props;
    setUser(userId);
    updateComponent(AddAvailabilities, 'addAvailabilities', { sidebarIcon: 'edit' });
  }

  addMandate() {
    const { updateComponent, setClinic, clinicId } = this.props;
    setClinic(clinicId);
    updateComponent(AddMandate, 'addMandate', { sidebarIcon: 'edit' });
  }

  render() {
    const { currentDate, userRole, changeDate } = this.props;
    const { t } = this.context;
    return (
      <div className="schedule__toolbar">
        <div className="schedule__toolbar__sides">
          <button
            onClick={this.prevMonth}
            className="schedule__toolbar__action schedule__toolbar__action--prev"
            title={t('calendar.prev_month')}
          />
          <button
            onClick={this.currentMonth}
            className="schedule__toolbar__action schedule__toolbar__action--today"
          />
          <button
            onClick={this.nextMonth}
            className="schedule__toolbar__action schedule__toolbar__action--next"
            title={t('calendar.next_month')}
          />
        </div>
        <MonthSelector
          wrapperClassNames="schedule__toolbar__current-date"
          className="schedule__toolbar__month-selector"
          currentDate={currentDate}
          changeDate={changeDate}
        />
        <div className="schedule__toolbar__sides">
          { userRole === 'clinic_manager' ? (
            <button
              onClick={this.addMandate}
              className="schedule__toolbar__add-availability"
            >
              {t('mandates.add')}
            </button>
          ) : (
            <button
              onClick={this.addAvailabilities}
              className="schedule__toolbar__add-availability"
            >
              {t('availabilities.add')}
            </button>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentDate: state.schedule.date,
    userRole: state.user.role,
    userId: state.user.data.id,
    clinicId: state.user.currentClinicId,
  };
}

CustomToolbar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onNavigate: PropTypes.func.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  changeDate: PropTypes.func.isRequired,
  updateComponent: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  setUser: PropTypes.func.isRequired,
  setClinic: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  clinicId: PropTypes.string,
};

CustomToolbar.defaultProps = {
  clinicId: null,
};

CustomToolbar.contextTypes = {
  t: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { ...actionsSchedule, ...actionsSidePanel, ...actionsAvailabilities, ...actionsMandate },
)(CustomToolbar);
