import types from '../actions/matchmaking/types';
import callToAllTypes from '../actions/callToAll/types';
import availabilitiesTypes from '../actions/availabilities/types';
import mandatesTypes from '../actions/mandates/types';
import {
  addUserAvailabilities,
  updateUserAvailabilities,
  formatMatchmakingAvailabilities,
  updateUserAvailability,
  deleteUserAvailability,
  disableUserAvailabilityProposal,
} from '../helpers/formatAvailabilities';
import {
  addClinicMandate,
  formatMatchmakingMandates,
  updateClinicReplacements,
  removeClinicMandate,
  removeClinicProposal,
  sortSelectedClinics,
} from '../helpers/formatMandates';
import { getCurrentYearHolidays } from '../helpers/holidays';

const initialState = {
  eventTooltip: {
    eventId: '',
    loading: false,
    proposals: [],
  },
  clinics: [],
  endCursor: null,
  hasNextPage: false,
  holidays: getCurrentYearHolidays(new Date(), localStorage.getItem('Lang')),
  loading: false,
  unlockedPricesClinicIds: [],
  proposals: [],
  rawClinics: [],
  rawSubstitutes: [],
  selectedAccountType: localStorage.getItem('selectedAccountType') || 'veterinarian',
  selectedClinics: [],
  selectedUserId: null,
  substitutes: [],
  substituteMessageReplacementIds: [],
  substituteSelected: false,
  suggested: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_SUBSTITUTES:
      return { ...state, loading: true };
    case types.FETCH_SUBSTITUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        substitutes: formatMatchmakingAvailabilities(action.payload.users.nodes),
        rawSubstitutes: action.payload.users.nodes,
        ...action.payload.users.pageInfo,
      };
    case types.FETCH_SUBSTITUTE_ADDRESS:
      return {
        ...state,
      };
    case types.FETCH_SUBSTITUTE_ADDRESS_SUCCESS:
      return {
        ...state,
        substituteAddress: action.payload.user.profile.address,
      };
    case types.RESET_SUBSTITUTE_ADDRESS:
      return {
        ...state,
        substituteAddress: {},
      };
    case types.FETCH_SUBSTITUTE_AND_CLINICS:
      return { ...state, loading: true };
    case types.FETCH_SUBSTITUTE_AND_CLINICS_SUCCESS: {
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(action.payload.bestClinics.nodes),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(action.payload.bestClinics.nodes);
      return {
        ...state,
        loading: false,
        substitutes: formatMatchmakingAvailabilities([action.payload.user]),
        rawSubstitutes: [action.payload.user],
        clinics: formatedClinics,
        rawClinics: action.payload.bestClinics.nodes,
        endCursor: action.payload.bestClinics.pageInfo.endCursor,
        hasNextPage: action.payload.bestClinics.pageInfo.hasNextPage,
      };
    }

    case types.FETCH_SUBSTITUTE_PROPOSALS:
      return {
        ...state,
        eventTooltip: {
          ...state.eventTooltip,
          loading: true,
        },
      };
    case types.FETCH_SUBSTITUTE_PROPOSALS_SUCCESS:
      return {
        ...state,
        eventTooltip: {
          ...state.eventTooltip,
          loading: false,
          proposals: action.payload.matchmakingAvailabilityProposals,
        },
      };
    case types.RESET_EVENT_PROPOSALS:
      return {
        ...state,
        eventTooltip: {
          ...state.eventTooltip,
          loading: false,
          proposals: [],
        },
      };
    case types.SET_EVENT_ID_FOR_TOOLTIP:
      return {
        ...state,
        eventTooltip: {
          eventId: action.eventId,
        },
      };

    case types.FETCH_CLINICS:
      return { ...state, loading: true };
    case types.FETCH_CLINICS_SUCCESS: {
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(action.payload.clinics.nodes),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(action.payload.clinics.nodes);
      return {
        ...state,
        loading: false,
        clinics: formatedClinics,
        rawClinics: action.payload.clinics.nodes,
        ...action.payload.clinics.pageInfo,
      };
    }
    case types.FETCH_CLINIC_ADDRESS:
      return {
        ...state,
      };
    case types.FETCH_CLINIC_ADDRESS_SUCCESS:
      return {
        ...state,
        clinicAddress: action.payload.clinic.address,
      };
    case types.RESET_CLINIC_ADDRESS:
      return {
        ...state,
        substituteAddress: {},
      };
    case types.FETCH_CLINIC_PROPOSALS:
      return {
        ...state,
        eventTooltip: {
          ...state.eventTooltip,
          loading: true,
        },
      };
    case types.FETCH_CLINIC_PROPOSALS_SUCCESS:
      return {
        ...state,
        eventTooltip: {
          ...state.eventTooltip,
          loading: false,
          proposals: action.payload.matchmakingReplacementProposals,
        },
      };
    case types.FETCH_NEXT_CLINICS:
      return { ...state, loading: true };
    case types.FETCH_NEXT_CLINICS_SUCCESS: {
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(action.payload.clinics.nodes),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(action.payload.clinics.nodes);
      return {
        ...state,
        loading: false,
        clinics: [...state.clinics, ...formatedClinics],
        rawClinics: [...state.rawClinics, ...action.payload.clinics.nodes],
        endCursor: action.payload.clinics.pageInfo.endCursor,
        hasNextPage: action.payload.clinics.pageInfo.hasNextPage,
      };
    }

    case types.FETCH_NEXT_SUBSTITUTE_AND_CLINICS:
      return { ...state, loading: true };
    case types.FETCH_NEXT_SUBSTITUTE_AND_CLINICS_SUCCESS: {
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(action.payload.bestClinics.nodes),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(action.payload.bestClinics.nodes);
      return {
        ...state,
        loading: false,
        substitutes: formatMatchmakingAvailabilities([action.payload.user]),
        rawSubstitutes: [action.payload.user],
        clinics: [...state.clinics, ...formatedClinics],
        rawClinics: [...state.rawClinics, ...action.payload.bestClinics.nodes],
        endCursor: action.payload.bestClinics.pageInfo.endCursor,
        hasNextPage: action.payload.bestClinics.pageInfo.hasNextPage,
      };
    }

    case types.FETCH_NEXT_SUBSTITUTES:
      return { ...state, loading: true };
    case types.FETCH_NEXT_SUBSTITUTES_SUCCESS: {
      const formatedUsers = formatMatchmakingAvailabilities(action.payload.users.nodes);
      return {
        ...state,
        loading: false,
        substitutes: [...state.substitutes, ...formatedUsers],
        rawSubstitutes: [...state.rawSubstitutes, ...action.payload.users.nodes],
        endCursor: action.payload.users.pageInfo.endCursor,
        hasNextPage: action.payload.users.pageInfo.hasNextPage,
      };
    }

    case types.TOGGLE_SUBSTITUTE_MINIPROFILE:
      return {
        ...state,
        selectedMiniprofileUserId: action.userId,
      };
    case types.TOGGLE_CLINIC_MINIPROFILE:
      return {
        ...state,
        selectedMiniprofileClinicId: action.clinicId,
      };
    case types.SHOW_SUBSTITUTE_MESSAGE:
      return {
        ...state,
        substituteMessageReplacementIds: [
          ...state.substituteMessageReplacementIds, action.replacementId,
        ],
      };
    case types.HIDE_SUBSTITUTE_MESSAGE:
      return {
        ...state,
        substituteMessageReplacementIds: state.substituteMessageReplacementIds.filter(
          (id) => id !== action.replacementId,
        ),
      };
    case types.SELECT_SUBSTITUTE:
      return {
        ...state,
        selectedUserId: action.userId,
        substituteSelected: true,
        selectedClinics: [],
      };
    case types.UNSELECT_SUBSTITUTE:
      return {
        ...state,
        selectedUserId: null,
        substituteSelected: false,
        selectedClinics: [],
      };
    case types.SELECT_CLINIC:
      return {
        ...state,
        selectedClinics: [...state.selectedClinics, action.clinic],
        clinics: sortSelectedClinics(
          formatMatchmakingMandates(state.rawClinics),
          [...state.selectedClinics, action.clinic],
        ),
      };
    case types.UNSELECT_CLINIC:
      return {
        ...state,
        selectedClinics: state.selectedClinics.filter(({ id }) => id !== action.clinic.id),
        clinics: sortSelectedClinics(
          formatMatchmakingMandates(state.rawClinics),
          state.selectedClinics.filter(({ id }) => id !== action.clinic.id),
        ),
      };
    case types.SELECT_ACCOUNT_TYPE:
      localStorage.setItem('selectedAccountType', action.accountType);

      return {
        ...state,
        selectedAccountType: action.accountType,
        selectedClinics: state.selectedAccountType === action.accountType ?
          [...state.selectedClinics]
          : [],
      };
    case types.SELECT_REPLACEMENT:
      return {
        ...state,
        proposals: [...state.proposals, action.event],
      };
    case types.UNSELECT_REPLACEMENT:
      return {
        ...state,
        proposals: state.proposals.filter((proposal) =>
          proposal[action.event.type].id !== action.event.id,
        ),
      };
    case types.DISCARD_PROPOSALS:
      return {
        ...state,
        proposals: [],
      };
    case types.CREATE_PROPOSALS:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_PROPOSALS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case types.CREATE_PROPOSALS_SUCCESS: {
      const rawClinics = updateClinicReplacements(state.rawClinics,
        action.payload.createProposals.proposals.map((proposal) => proposal.replacement),
      );
      const rawSubstitutes = updateUserAvailabilities(state.rawSubstitutes,
        action.payload.createProposals.proposals.map((proposal) => proposal.availability),
      );
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(rawClinics, false),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(rawClinics, false);

      return {
        ...state,
        proposals: [],
        rawSubstitutes,
        substitutes: formatMatchmakingAvailabilities(rawSubstitutes),
        rawClinics,
        clinics: formatedClinics,
        loading: false,
      };
    }
    case types.LOCK_PRICES:
      return {
        ...state,
        unlockedPricesClinicIds: [...state.unlockedPricesClinicIds, action.clinicId],
      };
    case types.UNLOCK_PRICES:
      return {
        ...state,
        unlockedPricesClinicIds: state.unlockedPricesClinicIds.filter(
          (id) => id !== action.clinicId,
        ),
      };
    case availabilitiesTypes.CREATE_AVAILABILITIES_SUCCESS: {
      const rawSubstitutes = addUserAvailabilities(state.rawSubstitutes,
        action.payload.createAvailabilities.availabilities);
      return {
        ...state,
        rawSubstitutes,
        substitutes: formatMatchmakingAvailabilities(rawSubstitutes),
      };
    }
    case availabilitiesTypes.UPDATE_AVAILABILITY_SUCCESS: {
      const rawSubstitutes = updateUserAvailability(state.rawSubstitutes,
        action.payload.updateAvailability.availability);
      return {
        ...state,
        rawSubstitutes,
        substitutes: formatMatchmakingAvailabilities(rawSubstitutes),
      };
    }
    case availabilitiesTypes.DELETE_AVAILABILITY_SUCCESS: {
      const rawSubstitutes = deleteUserAvailability(state.rawSubstitutes,
        action.payload.disableAvailability.availability);
      return {
        ...state,
        rawSubstitutes,
        substitutes: formatMatchmakingAvailabilities(rawSubstitutes),
      };
    }
    case availabilitiesTypes.DISABLE_AVAILABILITY_PROPOSAL_SUCCESS: {
      const rawSubstitutes = disableUserAvailabilityProposal(state.rawSubstitutes,
        action.payload.disableProposal.proposal);
      return {
        ...state,
        rawSubstitutes,
        substitutes: formatMatchmakingAvailabilities(rawSubstitutes),
      };
    }
    case callToAllTypes.CREATE_CALL_TO_ALL_SUCCESS: {
      const rawClinics = updateClinicReplacements(
        state.rawClinics,
        [action.payload.createCallToAll.callToAll.replacement],
      );
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(rawClinics, false),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(rawClinics, false);
      return {
        ...state,
        rawClinics,
        clinics: formatedClinics,
      };
    }
    case mandatesTypes.CREATE_MANDATE_SUCCESS: {
      const rawClinics = addClinicMandate(state.rawClinics, action.payload.createMandate.mandate);
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(rawClinics),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(rawClinics);
      return {
        ...state,
        rawClinics,
        clinics: formatedClinics,
      };
    }
    case mandatesTypes.DISABLE_MANDATE_SUCCESS: {
      const rawClinics = removeClinicMandate(state.rawClinics,
        action.payload.disableMandate.mandate);
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(rawClinics),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(rawClinics);
      return {
        ...state,
        rawClinics,
        clinics: formatedClinics,
      };
    }
    case mandatesTypes.DISABLE_REPLACEMENT_PROPOSAL_SUCCESS: {
      const rawClinics = removeClinicProposal(state.rawClinics,
        action.payload.disableProposal.proposal);
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(rawClinics),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(rawClinics);
      return {
        ...state,
        rawClinics,
        clinics: formatedClinics,
      };
    }
    case mandatesTypes.UPDATE_REPLACEMENT_SUCCESS: {
      const rawClinics = updateClinicReplacements(state.rawClinics,
        [action.payload.updateReplacement.replacement]);
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(rawClinics),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(rawClinics);
      return {
        ...state,
        rawClinics,
        clinics: formatedClinics,
      };
    }
    case mandatesTypes.DISABLE_REPLACEMENT_SUCCESS: {
      const rawClinics = updateClinicReplacements(state.rawClinics,
        [action.payload.disableReplacement.replacement]);
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(rawClinics),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(rawClinics);
      return {
        ...state,
        rawClinics,
        clinics: formatedClinics,
      };
    }
    case types.ENABLE_MATCHMAKING_REPLACEMENT:
      return { ...state };
    case types.ENABLE_MATCHMAKING_REPLACEMENT_SUCCESS: {
      const rawClinics = updateClinicReplacements(state.rawClinics,
        [action.payload.enableReplacement.replacement]);
      const formatedClinics = state.selectedClinics.length ? sortSelectedClinics(
        formatMatchmakingMandates(rawClinics),
        [...state.selectedClinics],
      ) : formatMatchmakingMandates(rawClinics);
      return {
        ...state,
        rawClinics,
        clinics: formatedClinics,
      };
    }
    default:
  }
  return state;
}
