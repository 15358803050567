import { asyncMutation } from '../../../config/graphql/async-action-creator';
import graphQLClient from '../../../config/graphql/client';
import queries from './queries';
import types from './types';
import store from '../../../config/redux/store';

export default {
  signIn: (variables) => asyncMutation(
    store,
    types.SIGN_IN,
    queries.signIn,
    variables,
    graphQLClient,
  ),
  signOut: () => {
    localStorage.removeItem('Authorization');
    localStorage.removeItem('Expires');
    localStorage.removeItem('RefreshToken');
    const { user: { subscription } } = store.getState();
    if (subscription) {
      subscription.disconnect();
      store.dispatch({ type: types.CLOSE_SUBSCRIPTIONS });
    }
    return {
      type: types.SIGN_OUT,
    };
  },
  updateAccount: (variables) => asyncMutation(
    store,
    types.UPDATE_ACCOUNT,
    queries.updateAccount,
    variables,
    graphQLClient,
  ),
  resetPassword: (variables) => asyncMutation(
    store,
    types.RESET_PASSWORD,
    queries.resetPassword,
    variables,
    graphQLClient,
  ),
  forgotPassword: (variables) => asyncMutation(
    store,
    types.FORGOT_PASSWORD,
    queries.forgotPassword,
    variables,
    graphQLClient,
  ),
  validateToken: (variables) => asyncMutation(
    store,
    types.VALIDATE_TOKEN,
    queries.validateToken,
    variables,
    graphQLClient,
  ),
  validateNoToken: () => ({
    type: types.VALIDATE_NO_TOKEN,
  }),
  updateCurrentClinic: (clinicId) => ({
    clinicId,
    type: types.UPDATE_CURRENT_CLINIC,
  }),
  subscribeUser: (subscription) => ({
    subscription,
    type: types.SUBSCRIBE_USER,
  }),
  closeSubscription: (subscription) => ({
    subscription,
    type: types.CLOSE_SUBSCRIPTIONS,
  }),
};
