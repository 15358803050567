// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import validateTimesheet from '../validate/validateTimesheet';

// components
import Heading from './parts/Heading';
import EntriesSubForm from './parts/EntriesSubForm';
import Footer from './parts/Footer';

// actions
import actionsTimesheets from '../../../actions/timesheet';
import alertsActions from '../../../actions/alerts';
import confirmActionActions from '../../../actions/confirmAction/index';

// helpers
import { formatHours } from '../../../helpers/formatDates';
import { totalWorkDuration } from '../../../helpers/form';

class TimesheetCard extends Component {
  constructor() {
    super();
    this.submitTimesheet = this.submitTimesheet.bind(this);
    this.submitPendingTimesheet = this.submitPendingTimesheet.bind(this);
    this.confirmTimesheet = this.confirmTimesheet.bind(this);
    this.confirmAllEntries = this.confirmAllEntries.bind(this);
  }

  submitTimesheet() {
    const { actionToConfirm, userRole, timesheet: { status } } = this.props;
    const isAdminSubmited = userRole === 'admin' && status === 'submitted';
    if (isAdminSubmited) {
      actionToConfirm(this.confirmTimesheet, 'timesheets.confirm');
    } else if (status === 'pending' || status === 'late') {
      actionToConfirm(this.submitPendingTimesheet, 'timesheets.confirm');
    }
  }

  confirmAllEntries() {
    this.confirmTimesheet(true);
  }

  submitPendingTimesheet() {
    const {
      pushAlert,
      setReinitTimesheets,
      reviewTimesheetEntries,
      timesheet: { entries },
    } = this.props;
    const entriesToSend = {
      input: {
        reviews: entries.map((entry) => ({
          entryId: entry.id,
          status: 'submitted',
        })),
      },
    };
    reviewTimesheetEntries(entriesToSend).then((data) => {
      if (data && data.reviewTimesheetEntries.success) {
        setReinitTimesheets();
        pushAlert({
          type: 'success',
          content: 'alerts.timesheetSent',
        });
      }
    });
  }

  confirmTimesheet(confirmAll = false) {
    const {
      pushAlert,
      reviewTimesheetEntries,
      timesheet: { entries },
      setReinitTimesheets,
    } = this.props;
    const reviewsToConfirmArr = entries.map((entry) => {
      if (entry.confirm === 'true' || confirmAll) {
        return {
          entryId: entry.id,
          status: 'confirmed',
        };
      }
      return false;
    });
    const entriesToConfirm = {
      input: {
        reviews: reviewsToConfirmArr,
      },
    };
    reviewTimesheetEntries(entriesToConfirm).then((data) => {
      if (data && data.reviewTimesheetEntries.success) {
        setReinitTimesheets();
        pushAlert({
          type: 'success',
          content: 'alerts.timesheetSent',
        });
      }
    });
  }

  render() {
    const {
      handleSubmit,
      timesheet: {
        startsAt,
        endsAt,
        entries,
        status,
        confirmedAt,
        submittedAt,
      },
      invalid,
      userRole,
      actionToConfirm,
    } = this.props;

    const submitForm = handleSubmit(this.submitTimesheet);
    const isSubstitutePending = status === 'pending' && userRole === 'substitute';
    const isSubstituteLate = status === 'late' && userRole === 'substitute';
    const isAdminSubmitted = status === 'submitted' && userRole === 'admin';
    const cannotSubmitTimesheet = moment().isBefore(moment(entries[entries.length - 1].endsAt), 'second');
    return (
      <div
        className={classNames('timesheet-card', {
          'timesheet-card--purple': isSubstitutePending || isAdminSubmitted,
          'timesheet-card--orange': isSubstituteLate,
        })}
      >
        <form onSubmit={submitForm}>
          <Heading
            startsAt={startsAt}
            endsAt={endsAt}
            invalid={invalid}
            status={status}
            userRole={userRole}
            confirmAllEntries={this.confirmAllEntries}
            actionToConfirm={actionToConfirm}
            confirmedAt={confirmedAt}
            submittedAt={submittedAt}
            cannotSubmitTimesheet={cannotSubmitTimesheet}
          />
          <FieldArray
            name="entries"
            component={EntriesSubForm}
            entries={entries}
          />
          <Footer invalid={invalid} status={status} submittedAt={submittedAt} />
        </form>
      </div>
    );
  }
}

TimesheetCard.propTypes = {
  actionToConfirm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  timesheet: PropTypes.object.isRequired,
  reviewTimesheetEntries: PropTypes.func.isRequired,
  pushAlert: PropTypes.func.isRequired,
  setReinitTimesheets: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};
TimesheetCard.defaultProps = {};
TimesheetCard.contextTypes = {
  t: PropTypes.func,
};

function mapStateToProps(state, { timesheet }) {
  const totalWorkEntries = timesheet.entries.map(({ startsAt, endsAt, breaks }) =>
    totalWorkDuration(startsAt, endsAt, breaks),
  );
  const radioStatus = { confirmed: 'true', submitted: 'false', pending: null };
  return {
    form: `submitTimesheet-${timesheet.id}`,
    initialValues: {
      entries: timesheet.entries.map(({
        id,
        adminNote,
        startsAt,
        endsAt,
        breaks,
        status,
        distanceTraveled,
        message,
      }, index) => ({
        id,
        adminNote: adminNote || null,
        startsAt: startsAt ? formatHours(startsAt) : '--:--',
        endsAt: endsAt ? formatHours(endsAt) : '--:--',
        lunch: (breaks && breaks[0] && breaks[0].active) ? `${breaks[0].duration}h` : ' --h',
        dinner: (breaks && breaks[1] && breaks[1].active) ? `${breaks[1].duration}h` : ' --h',
        distanceTraveled: distanceTraveled || 0,
        message: message || null,
        total: totalWorkEntries[index],
        confirm: radioStatus[status],
      })),
    },
    userRole: state.user.role,
  };
}

export default connect(
  mapStateToProps,
  {
    ...actionsTimesheets,
    ...alertsActions,
    ...confirmActionActions,
  },
)(reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate: validateTimesheet,
})(TimesheetCard));
