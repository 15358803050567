// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import normalizePhone from '../../forms/normalize/normalizePhone';

// components
import ClinicForm from './forms/ClinicForm';
import BasicLoader from '../../loader/BasicLoader';

// helpers
import { formatOpeningHours } from '../../../helpers/form';
import { formatClinicFormData } from '../../../helpers/formatClinics';
import uploader from '../../../helpers/uploader';
import { scrollToError } from '../../../helpers/scrollToError';

// actions
import actions from '../../../actions/admin/clinics';
import alertsActions from '../../../actions/alerts/index';
import uploadActions from '../../../actions/uploads';
import validate from './validate/validate';

class EditClinic extends Component {
  static propTypes = {
    blacklistedUsers: PropTypes.array,
    change: PropTypes.func.isRequired,
    clinicPicture: PropTypes.object,
    error: PropTypes.string,
    fetchClinics: PropTypes.func.isRequired,
    formDataIsLoading: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    clinicId: PropTypes.string,
    managers: PropTypes.array,
    presignUpload: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    pushAlert: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    formIsSubmitting: PropTypes.bool.isRequired,
    updateClinic: PropTypes.func.isRequired,
    uploadPicture: PropTypes.object,
    uploadPictureFileChanged: PropTypes.func.isRequired,
    uploadPictureFileReset: PropTypes.func.isRequired,
    userRole: PropTypes.string.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    error: '',
    blacklistedUsers: [],
    clinicPicture: {},
    clinicId: '',
    managers: [],
    uploadPicture: {},
  };

  constructor() {
    super();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.uploader = uploader(
      this.presignedUploadPath.bind(this),
      this.updateClinicPicture.bind(this),
      'clinic_picture',
      ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
    );
  }

  handleFormSubmit(values) {
    const { updateClinic, fetchClinics, pushAlert, hidePanel, uploadPictureFileReset } = this.props;
    const { id, ...input } = values;

    updateClinic({
      id,
      input: formatClinicFormData(input, true),
    })
      .then((data) => {
        if (data && data.updateClinic.success) {
          fetchClinics();
          pushAlert({
            type: 'success',
            content: 'alerts.edited',
          });
          hidePanel();
          uploadPictureFileReset();
        }
      });
  }

  presignedUploadPath({ filename }) {
    const { presignUpload, clinicId } = this.props;
    return presignUpload({ input: { filename, instanceId: clinicId, uploadType: 'clinic' } });
  }

  updateClinicPicture({ fileData }) {
    const { change, uploadPictureFileChanged } = this.props;
    uploadPictureFileChanged({ pictureFilename: fileData.metadata.filename });
    change('picture', fileData);
  }

  render() {
    const {
      clinicPicture,
      blacklistedUsers,
      error,
      handleSubmit,
      hidePanel,
      managers,
      pristine,
      submitting,
      formDataIsLoading,
      formIsSubmitting,
      uploadPicture,
      uploadPictureFileReset,
      userRole,
    } = this.props;
    const { t } = this.context;
    const submitForm = handleSubmit(this.handleFormSubmit);

    return (
      <div>
        <form onSubmit={submitForm} className="side-panel__form">
          {(formIsSubmitting || formDataIsLoading) && <BasicLoader />}
          {error && <div className="form-error">{error}</div>}
          <ClinicForm
            formTitle="updateClinic"
            blacklistedUsers={blacklistedUsers}
            managers={managers}
            userRole={userRole}
            uploader={this.uploader}
            clinicPicture={clinicPicture}
            uploadPicture={uploadPicture}
            uploadPictureFileReset={uploadPictureFileReset}
          />
          <div className="form__actions">
            <button
              className="form__cancel"
              type="button"
              disabled={submitting || formIsSubmitting}
              onClick={() => hidePanel()}
            >
              {t('form.formActions.cancel')}
            </button>
            <button className="form__submit" type="submit" disabled={pristine || submitting || formIsSubmitting}>
              {t('form.formActions.update')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    clinics: {
      loading,
      submitting,
      clinicPicture,
      clinic: { clinicUsers = [], blacklistedUsers = [], managers, ...initialValues },
    },
  } = state;

  const provinceCode = process.env.REACT_APP_REGION_CODE;
  const userRole = state.user.role;

  return {
    clinicId: initialValues.id,
    clinicPicture,
    uploadPicture: {
      fileChanged: state.uploads.pictureFileChanged,
      filename: state.uploads.pictureFilename,
    },
    initialValues: {
      ...initialValues,
      phone: initialValues.phone ? normalizePhone(initialValues.phone) : '',
      clinicUsers: clinicUsers.map(({ id, userId, userType }) => (
        { id, userId, userType }
      )),
      blacklistedUsers: blacklistedUsers.map(({ id, userId, userType, clinicBlacklistedUser }) => (
        { id, userId, userType, clinicBlacklistedUser }
      )),
      addBillingAddress: initialValues.billingAddress && initialValues.billingAddress.address
        ? 'true' : 'false',
      billingAddress: initialValues.billingAddress
        ? { ...initialValues.billingAddress } : { country: 'ca', province: provinceCode },
      openingHours: initialValues.openingHours ?
        formatOpeningHours(initialValues.openingHours) :
        [...Array(7).keys()].map((day) => ({ day, opensAt: '', closesAt: '' })),
      otherVets: initialValues.otherVets ? 'true' : 'false',
      softwares: [],
      softwareSkills: initialValues.softwareSkills ? 'true' : 'false',
      softwareOthersCheck:
        initialValues.softwareOthers !== '' && initialValues.softwareOthers !== null,
    },
    managers: userRole === 'admin' ? clinicUsers.map(clinicUser => clinicUser.user) : managers,
    blacklistedUsers: blacklistedUsers.map(clinicUser => clinicUser.user),
    userRole,
    formIsSubmitting: submitting,
    formDataIsLoading: loading,
  };
}

export default connect(
  mapStateToProps,
  { ...actions, ...alertsActions, ...uploadActions },
)(
  reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: 'updateClinic',
    onSubmitFail: (errors) => scrollToError(errors),
    validate,
  })(EditClinic),
);
