// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';

// components
import { CrumbRoute, Route, ValidateRoutes } from 'o2web-react-core';
import AuthenticatedComponent from '../auth/AuthenticatedComponent';
import AvailabilitiesList from '../availabilitiesList/AvailabilitiesList';
import Calendar from '../pages/Calendar';
import Clinics from '../admin/clinics/Clinics';
import ForgotPassword from '../user/forms/ForgotPassword';
import Main from '../main/Main';
import Mandate from '../mandates/Mandate';
import MandatesList from '../mandates/list/MandatesList';
import MatchmakingClinics from '../matchmaking/Clinics';
import NotFound from '../pages/NotFound';
import Proposals from '../matchmaking/Proposals';
import ProtectedRoute from './ProtectedRoute';
import ResetPassword from '../user/forms/ResetPassword';
import AccountActivation from '../user/forms/AccountActivation';
import SignIn from '../user/forms/SignIn';
import Substitutes from '../matchmaking/Substitutes';
import Timesheets from '../timesheet/Timesheets';
import Users from '../admin/users/Users';
import HelpcenterWrapper from '../helpcenter/HelpcenterWrapper';
import SubstituteFaq from '../helpcenter/Substitute/SubstituteFaq';

// translations
import { momentTranslations } from '../../../config/locales/moment';

class PrimaryLayout extends Component {
  componentDidMount() {
    const { lang } = this.props;
    localStorage.setItem('Lang', lang);
    moment.updateLocale(lang, momentTranslations[lang]);
    moment.locale(lang);
  }

  componentDidUpdate(prevProps) {
    const { lang } = this.props;
    const { lang: prevLang } = prevProps;
    if (lang !== prevLang) {
      localStorage.setItem('Lang', lang);
      moment.updateLocale(lang, momentTranslations[lang]);
      moment.locale(lang);
    }
  }

  render() {
    const { t } = this.context;
    const { role } = this.props;

    return (
      <div>
        <Helmet>
          <title>{t(`metas.site.${process.env.REACT_APP_REGION_CODE}`)}</title>
        </Helmet>
        <Main>
          <ValidateRoutes notFoundPath="/en/notFound">
            <CrumbRoute
              exact
              path="/en/login"
              title="login"
              name="login"
              component={AuthenticatedComponent(SignIn, false)}
            />
            <CrumbRoute
              exact
              path="/en/forgotPassword"
              title="forgotPassword"
              component={AuthenticatedComponent(ForgotPassword, false)}
            />
            <CrumbRoute
              exact
              path="/en/activation"
              title="AccountActivation"
              component={AuthenticatedComponent(AccountActivation, false)}
            />
            <CrumbRoute
              exact
              path="/en/resetPassword/:token"
              title="resetPassword"
              withParams
              component={AuthenticatedComponent(ResetPassword, false)}
            />
            <Route exact path="/en" component={AuthenticatedComponent(Calendar)} />
            <ProtectedRoute
              exact
              title="availabilities"
              path="/en/availabilities"
              component={AuthenticatedComponent(AvailabilitiesList)}
              allowedRoles={['substitute']}
              currentRole={role}
            />
            <ProtectedRoute
              exact
              title="faq"
              path="/en/faq"
              component={AuthenticatedComponent(SubstituteFaq)}
              allowedRoles={['substitute']}
              currentRole={role}
            />
            <CrumbRoute
              path="/en/proposed-mandates"
              title="proposedMandates"
              hasChildren
              render={({ path }) =>
                <div>
                  <Route
                    exact
                    path={path}
                    component={AuthenticatedComponent(MandatesList, true)}
                  />
                  <CrumbRoute
                    path={`${path}/:mandateId`}
                    title="proposedMandates"
                    name="proposedMandates"
                    withParams
                    component={AuthenticatedComponent(Mandate, true, { proposalStatus: 'pending' })}
                  />
                </div>
              }
            />
            <ProtectedRoute
              path="/en/accepted-mandates"
              title="acceptedMandates"
              hasChildren
              allowedRoles={['substitute', 'clinic_manager']}
              currentRole={role}
              render={({ path }) =>
                <div>
                  <Route
                    exact
                    path={path}
                    component={
                      AuthenticatedComponent(MandatesList, true, { acceptedProposals: true })
                    }
                  />
                  <CrumbRoute
                    path={`${path}/:mandateId`}
                    title="acceptedMandates"
                    name="acceptedMandates"
                    withParams
                    component={AuthenticatedComponent(Mandate, true, { proposalStatus: 'confirmed_substitute' })}
                  />
                </div>
              }
            />
            <ProtectedRoute
              exact
              title="mandatesToConfirm"
              path="/en/mandates-to-confirm"
              allowedRoles={['admin']}
              currentRole={role}
              component={AuthenticatedComponent(MandatesList, true, { acceptedProposals: true })}
            />
            <CrumbRoute
              path="/en/mandates"
              title="mandates"
              hasChildren
              render={({ path }) =>
                <div>
                  <Route
                    exact
                    path={path}
                    component={AuthenticatedComponent(MandatesList, true, { allMandates: true })}
                  />
                  <CrumbRoute
                    path={`${path}/:mandateId`}
                    title="mandates"
                    name="mandates"
                    withParams
                    component={AuthenticatedComponent(Mandate)}
                  />
                </div>
              }
            />
            <Route
              exact
              path="/en/timesheets"
              component={AuthenticatedComponent(Timesheets)}
            />
            <Route
              exact
              path="/en/helpcenter"
              allowedRoles={['substitute', 'clinic_manager']}
              currentRole={role}
              component={AuthenticatedComponent(HelpcenterWrapper)}
            />
            <ProtectedRoute
              path="/en/admin"
              title="admin"
              hasChildren
              allowedRoles={['admin']}
              currentRole={role}
              render={({ path }) =>
                <div>
                  <CrumbRoute
                    path={`${path}/clinics`}
                    title="clinics"
                    name="clinics"
                    component={AuthenticatedComponent(Clinics)}
                  />
                  <CrumbRoute
                    path={`${path}/users`}
                    title="users"
                    name="users"
                    component={AuthenticatedComponent(Users)}
                  />
                </div>
              }
            />
            <ProtectedRoute
              path="/en/matchmaking"
              title="admin"
              currentRole={role}
              allowedRoles={['admin']}
              hasChildren
              render={({ path }) =>
                <div>
                  <CrumbRoute
                    path={`${path}/substitutes`}
                    title="substitutes"
                    name="substitutes"
                    component={AuthenticatedComponent(Substitutes)}
                  />
                  <CrumbRoute
                    path={`${path}/proposals`}
                    title="proposals"
                    name="proposals"
                    component={AuthenticatedComponent(Proposals)}
                  />
                  <CrumbRoute
                    path={`${path}/clinics`}
                    title="clinics"
                    name="clinics"
                    component={AuthenticatedComponent(MatchmakingClinics)}
                  />
                </div>
              }
            />
            <Route
              path="/en/notFound"
              component={AuthenticatedComponent(NotFound)}
            />
          </ValidateRoutes>
        </Main>
      </div>
    );
  }
}

PrimaryLayout.propTypes = {
  lang: PropTypes.string.isRequired,
  role: PropTypes.string,
};

PrimaryLayout.defaultProps = {
  role: 'default',
};

PrimaryLayout.contextTypes = {
  t: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    lang: state.i18nState.lang,
    role: state.user.role,
  };
}

export default connect(mapStateToProps)(PrimaryLayout);
