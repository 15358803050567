export default {
  accommodationIncluded: 'Hébergement fourni',
  actions: {
    confirm: 'Confirmer',
    delete: 'Supprimer',
    details: 'Détails',
    disable: "Désactiver",
    edit: "Modifier",
    enable: "Activer",
  },
  additionnalInfo: 'Informations supplémentaires',
  allDay: 'Toute la journée',
  availability: 'Disponibilité',
  available: "Fourni",
  consultation: 'Consultation',
  daysOutOf: {
    singular: "jour sur",
    plural: "jours sur",
  },
  isPending: 'En attente...',
  optional: '(facultative)',
  outOf: "jours sur",
  pendingConfirmation: 'En attente de confirmation',
  pendingSubstitute: 'Proposition en attente de réponse',
  surgery: 'Chirurgie',
  surgeryOptional: 'Chirurgie (facultative)',
  surgeryType: {
    optional: 'Chirurgie facultative',
    true: 'Chirurgie obligatoire',
  },
  technician: "Technicien en santé animale",
  unavailable: "Non-fourni",
  veterinarian: "Vétérinaire",
  yearOfExperience: " année d'expérience",
  yearsOfExperience: " années d'expérience",
};
