// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// Components
import BasicLoader from '../loader/BasicLoader';
import TimesheetCard from './card/TimesheetCard';
import TimesheetsDateRange from './TimesheetsDateRange';

// Actions
import actions from '../../actions/timesheet';

// Styles
import './styles.scss';

class TimesheetList extends Component {
  static propTypes = {
    endCursor: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    fetchTimesheets: PropTypes.func.isRequired,
    reinitTimesheets: PropTypes.bool.isRequired,
    timesheets: PropTypes.array,
    userId: PropTypes.string,
    hasNextPage: PropTypes.bool,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    userId: null,
    timesheets: [],
    endCursor: null,
    hasNextPage: false,
  };

  constructor() {
    super();
    this.updateListDateRange = this.updateListDateRange.bind(this);
    this.state = {
      from: moment().hour(0),
      to: moment().hour(0),
    };
  }

  componentDidMount() {
    this.fetchTimesheetsList();
  }

  componentDidUpdate() {
    const { reinitTimesheets } = this.props;
    if (reinitTimesheets) {
      this.fetchTimesheetsList();
    }
  }

  updateListDateRange(from, to) {
    this.setState({ from, to });
  }

  fetchTimesheetsList(nextPage = false) {
    const {
      fetchTimesheets,
      userId,
      endCursor,
    } = this.props;
    const { from, to } = this.state;
    fetchTimesheets({
      userId,
      first: 10,
      from,
      to,
      ...(nextPage && { after: endCursor }),
    });
  }

  render() {
    const { loading, timesheets, hasNextPage } = this.props;
    const { t } = this.context;
    return (
      <div className="timesheets__wrapper">
        <div className="timesheets__toolbar">
          <div className="timesheets__toolbar__title">
            <h2>{t('timesheets.title')}</h2>
          </div>
          <div className="timesheets__toolbar__actions">
            <TimesheetsDateRange updateListDateRange={this.updateListDateRange} />
          </div>
        </div>
        {loading && <BasicLoader />}
        { timesheets.length ?
          <div>
            {timesheets.map((timesheet) => (
              <TimesheetCard key={timesheet.id} timesheet={timesheet} />
            ))}
          </div>
        :
          <div className="timesheets-text">
            <p>{t('timesheets.noTimesheet')}</p>
          </div>
        }
        { hasNextPage &&
          <div className="form__actions">
            <button
              className="content-list__button form__submit"
              type="button"
              onClick={() => this.fetchTimesheetsList(true)}
            >
              {t('form.formActions.loadMore')}
            </button>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.timesheets.loading,
    userId: state.user.data.id,
    timesheets: state.timesheets.timesheets,
    endCursor: state.timesheets.endCursor,
    reinitTimesheets: state.timesheets.reinitTimesheets,
    hasNextPage: state.timesheets.hasNextPage,
  };
}

export default withRouter(connect(
  mapStateToProps,
  { ...actions },
)(TimesheetList));
