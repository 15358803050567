import React from 'react';
import PropTypes from 'prop-types';

// helper
import { formatValueToMoney } from '../../../../../../../helpers/formatPrices';

function Price({ price, classNames }, { t }) {
  return (
    <div className="mandate-item__price">
      <span
        className={`mandate-item__price__tag ${classNames}`}
      >
        {`${formatValueToMoney(price)}${t('mandates.cards.hourlyRate')}`}
      </span>
    </div>
  );
}

Price.propTypes = {
  classNames: PropTypes.string,
  price: PropTypes.number.isRequired,
};
Price.defaultProps = {
  classNames: '',
};
Price.contextTypes = {
  t: PropTypes.func,
};

export default Price;
