import React from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './styles.scss';

const ReactDates = (props) => (
  <div className="field field__react-date">
    <DateRangePicker
      {...props}
      hideKeyboardShortcutsPanel
      minimumNights={0}
      displayFormat="YYYY-MM-DD"
      weekDayFormat="ddd"
      readOnly
    />
  </div>
);

export default ReactDates;
