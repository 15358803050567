// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import queryString from 'query-string';
import moment from 'moment';

// Components
import BasicLoader from '../../loader/BasicLoader';
import MandateCard from './card/MandateCard';
import CallToAllCard from './card/CallToAllCard';

// actions
import actions from '../../../actions/mandateProposals';
import alertsActions from '../../../actions/alerts';
import callToAllactions from '../../../actions/callToAll/index';
import userActions from '../../../actions/user';

// Styles
import './styles.scss';

class MandatesList extends Component {
  static propTypes = {
    acceptedProposals: PropTypes.bool,
    accountType: PropTypes.string.isRequired,
    allMandates: PropTypes.bool,
    callToAlls: PropTypes.array,
    currentClinicId: PropTypes.string,
    endCursor: PropTypes.string,
    fetchUserCallToAlls: PropTypes.func.isRequired,
    fetchAdminMandates: PropTypes.func.isRequired,
    fetchClinicMandates: PropTypes.func.isRequired,
    fetchSubstituteMandates: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    managedClinics: PropTypes.array,
    mandates: PropTypes.array,
    pushAlert: PropTypes.func.isRequired,
    userId: PropTypes.string,
    refresh: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    updateCurrentClinic: PropTypes.func.isRequired,
    updateProposals: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    acceptedProposals: false,
    allMandates: false,
    callToAlls: [],
    currentClinicId: null,
    endCursor: null,
    mandates: [],
    managedClinics: [],
    userId: null,
  };

  constructor() {
    super();
    this.state = { pastMandates: false };
    this.changeFilter = this.changeFilter.bind(this);
    this.extendProposalsTime = this.extendProposalsTime.bind(this);
  }

  componentDidMount() {
    const { fetchUserCallToAlls, role, userId } = this.props;

    if (this.shouldUpdateCurrentClinic()) {
      this.updateCurrentClinic();
    } else if (this.shouldFirstFetchMandates()) {
      this.fetchMandates();
    }

    if (role === 'substitute') {
      fetchUserCallToAlls(userId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pastMandates } = this.state;
    const { pastMandates: prevPastMandates } = prevState;
    if (this.shouldUpdateMandates(prevProps) || pastMandates !== prevPastMandates) {
      this.fetchMandates();
    }
  }

  changeFilter() {
    this.setState(({ pastMandates }) => ({
      pastMandates: !pastMandates,
    }));
  }

  shouldFirstFetchMandates() {
    const { currentClinicId, role, userId } = this.props;

    return role === 'admin' ||
      (role === 'substitute' && userId) ||
      (role === 'clinic_manager' && currentClinicId);
  }

  shouldUpdateCurrentClinic() {
    const { currentClinicId, managedClinics, location: { search } } = this.props;
    const paramsClinicId = queryString.parse(search).clinicId;
    const managedClinicIds = managedClinics.map(clinic => clinic.id);
    const sameClinic = [undefined, null, '', currentClinicId].includes(paramsClinicId);

    return (!sameClinic && managedClinicIds.includes(paramsClinicId));
  }

  shouldUpdateMandates(prevProps) {
    const {
      acceptedProposals,
      accountType,
      currentClinicId,
      role,
      userId,
      refresh,
    } = this.props;
    const {
      acceptedProposals: prevAcceptedProposals,
      accountType: prevAccountType,
      currentClinicId: prevClinicId,
      userId: prevUserId,
    } = prevProps;

    return (
      refresh ||
      (role === 'substitute' && userId !== prevUserId) ||
      (role === 'clinic_manager' && currentClinicId !== prevClinicId) ||
      (prevAccountType !== accountType) ||
      acceptedProposals !== prevAcceptedProposals
    );
  }

  fetchMandates(nextPage = false) {
    const {
      acceptedProposals,
      accountType,
      allMandates,
      currentClinicId,
      endCursor,
      fetchAdminMandates,
      fetchClinicMandates,
      fetchSubstituteMandates,
      role,
      userId,
    } = this.props;

    const { pastMandates } = this.state;

    const fetchFromRole = {
      admin: () => fetchAdminMandates({
        mandateType: accountType,
        ...{ from: !pastMandates ? new Date().toISOString() : new Date(0).toISOString() },
        ...(pastMandates) && { to: moment().subtract(1, 'day').toDate().toISOString() },
        ...{ latest: pastMandates },
        ...{ proposalStatus: acceptedProposals ? 'confirmed' : 'admin_pending_substitute_or_clinic' },
        ...(nextPage && { after: endCursor }),
      }),
      clinic_manager: () => fetchClinicMandates({
        id: currentClinicId,
        ...{ from: !pastMandates ? new Date().toISOString() : new Date(0).toISOString() },
        ...(pastMandates) && { to: moment().subtract(1, 'day').toDate().toISOString() },
        ...{ latest: pastMandates, boostUnconfirmedProposals: !pastMandates },
        ...(!allMandates && { proposalStatus: acceptedProposals ? 'confirmed' : 'pending_clinic' }),
        ...(nextPage && { after: endCursor }),
      }),
      substitute: () => fetchSubstituteMandates({
        id: userId,
        ...{ from: !pastMandates ? new Date().toISOString() : new Date(0).toISOString() },
        ...(pastMandates) && { to: moment().subtract(1, 'day').toDate().toISOString() },
        ...{ latest: pastMandates },
        proposalStatus: acceptedProposals ? 'confirmed_substitute' : 'pending',
        ...(nextPage && { after: endCursor }),
      }),
    };

    fetchFromRole[role]();
  }

  updateCurrentClinic() {
    const { location: { search }, updateCurrentClinic } = this.props;
    const { clinicId } = queryString.parse(search);
    updateCurrentClinic(clinicId);
  }

  extendProposalsTime(proposals) {
    const { updateProposals, pushAlert } = this.props;

    updateProposals({
      input: { proposals: proposals.map(({ id }) => ({ id, expirationExtended: true })) },
    }).then((data) => {
      if (data.updateProposals.errors.length === 0) {
        this.fetchMandates();
        pushAlert({
          type: 'success',
          content: 'alerts.timeAdded',
        });
      }
    });
  }

  titleKey() {
    const { acceptedProposals, allMandates, role } = this.props;
    if (allMandates) {
      return 'list';
    }

    if (role === 'admin') {
      return acceptedProposals ? 'admin.confirmed' : 'admin.pending';
    }

    return acceptedProposals ? 'confirmed' : 'pending';
  }

  render() {
    const {
      acceptedProposals,
      allMandates,
      callToAlls,
      hasNextPage,
      mandates,
      loading,
      role,
    } = this.props;
    const { pastMandates } = this.state;
    const { t } = this.context;

    const substituteRole = role === 'substitute';

    const showCallToAlls = !acceptedProposals && substituteRole && callToAlls && !pastMandates;

    return (
      <div className="mandates-list__wrapper">
        <div className="mandates__toolbar">
          <div className="mandates__toolbar__title">
            <h2>{t(`mandates.title.${this.titleKey()}`)}</h2>
          </div>
          {acceptedProposals &&
            <div className="mandates__toolbar__actions">
              <button
                className={`mandates__toolbar__show-${pastMandates ? 'current' : 'past'}`}
                tabIndex="0"
                onClick={this.changeFilter}
                onKeyPress={this.changeFilter}
              >
                {pastMandates ? t('mandates.viewCurrent') : t('mandates.viewPast')}
              </button>
            </div>
          }
        </div>
        {loading && <BasicLoader />}

        {showCallToAlls &&
          <div>
            {callToAlls.map((mandate) =>
              <CallToAllCard
                key={mandate.id}
                callToAll={mandate.callToAll}
                mandate={mandate}
                role={role}
              />,
            )}
          </div>
        }

        {mandates.map((mandate) =>
          <MandateCard
            key={mandate.id}
            acceptedProposals={acceptedProposals}
            allMandates={allMandates}
            extendProposalsTime={this.extendProposalsTime}
            mandate={mandate}
            role={role}
            pastMandates={pastMandates}
          />,
        )}
        {mandates.length === 0 && !loading && (
          <div
            className={classNames('mandates-text', {
              'mandates-text--confirmed': acceptedProposals,
              'mandates-text--pending': !acceptedProposals,
            })}
          >
            <p>{t('mandates.noMandates')}</p>
          </div>
        )}
        {hasNextPage && (
          <div className="form__actions">
            <button
              className="content-list__button form__submit"
              type="button"
              onClick={() => this.fetchMandates(true)}
            >
              {t('form.formActions.loadMore')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    accountType: state.matchmaking.selectedAccountType,
    callToAlls: state.callToAll.callToAlls,
    currentClinicId: state.user.currentClinicId,
    endCursor: state.mandateProposals.endCursor,
    hasNextPage: state.mandateProposals.hasNextPage,
    loading: state.mandateProposals.loading,
    managedClinics: state.user.managedClinics,
    mandates: state.mandateProposals.mandates,
    refresh: state.mandateProposals.refresh,
    role: state.user.role,
    userId: state.user.data.id,
  };
}

export default withRouter(connect(
  mapStateToProps,
  { ...actions, ...alertsActions, ...callToAllactions, ...userActions },
)(MandatesList));
