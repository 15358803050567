// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getFormValues } from 'redux-form';
import classNames from 'classnames';
import {
  validateEventSelection,
} from '../../../helpers/formatMandates';
import { isFilteredEvent, statuses } from '../../../helpers/events';

// components
import AvailabilityEvent from './AvailabilityEvent';
import ReplacementEvent from './ReplacementEvent';
import EditAvailability from '../../availabilities/EditAvailability';
import EditReplacement from '../../mandates/EditReplacement';
import AddAvailabilities from '../../availabilities/AddAvailabilities';
import AddMandate from '../../mandates/AddMandate';

// actions
import actionsSidePanel from '../../../actions/dynamicSidePanel';
import actionsAvailabilities from '../../../actions/availabilities';
import actionsMandates from '../../../actions/mandates';
import actionsMatchmaking from '../../../actions/matchmaking';
import confirmActions from '../../../actions/confirmAction';

// helpers
import { isWeekendDay } from '../../../helpers/dates';
import { getHoliday } from '../../../helpers/holidays';

class DayEvents extends Component {
  static propTypes = {
    actionToConfirm: PropTypes.func.isRequired,
    availabilities: PropTypes.array,
    count: PropTypes.number.isRequired,
    clinic: PropTypes.object,
    date: PropTypes.instanceOf(Date).isRequired,
    dateFrom: PropTypes.instanceOf(moment).isRequired,
    dateTo: PropTypes.instanceOf(moment).isRequired,
    disableAvailability: PropTypes.func.isRequired,
    disableAvailabilityProposal: PropTypes.func.isRequired,
    disableMandate: PropTypes.func.isRequired,
    disableReplacement: PropTypes.func.isRequired,
    disableReplacementProposal: PropTypes.func.isRequired,
    enableMatchmakingReplacement: PropTypes.func.isRequired,
    events: PropTypes.array,
    eventType: PropTypes.string.isRequired,
    filters: PropTypes.object,
    holidays: PropTypes.array.isRequired,
    proposals: PropTypes.array.isRequired,
    setAvailabilityToUpdate: PropTypes.func.isRequired,
    setClinic: PropTypes.func.isRequired,
    setEventIdForTooltip: PropTypes.func.isRequired,
    setMandateToUpdate: PropTypes.func.isRequired,
    setReplacementToUpdate: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    selectReplacementForProposals: PropTypes.func.isRequired,
    unselectReplacementForProposals: PropTypes.func.isRequired,
    user: PropTypes.object,
    updateComponent: PropTypes.func.isRequired,
    userId: PropTypes.string,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    availabilities: [],
    events: [],
    userId: null,
    clinic: {},
    user: {},
    filters: statuses.reduce((object, status) => ({ ...object, [status]: true }), {}),
  };

  constructor(props) {
    super(props);
    this.createEvent = this.createEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.deleteMandate = this.deleteMandate.bind(this);
    this.deleteProposal = this.deleteProposal.bind(this);
    this.enableEvent = this.enableEvent.bind(this);
    this.onSetEventIdForTooltip = this.onSetEventIdForTooltip.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.selectEvent = this.selectEvent.bind(this);
    this.unselectEvent = this.unselectEvent.bind(this);
  }

  onSetEventIdForTooltip(eventId) {
    const { setEventIdForTooltip } = this.props;
    setEventIdForTooltip(eventId);
  }

  createEvent() {
    const {
      setUser,
      setClinic,
      updateComponent,
      user,
      userId,
      clinic,
      date,
      eventType,
    } = this.props;

    if (eventType === 'availability') {
      setUser(userId);
      updateComponent(AddAvailabilities, 'addAvailabilities', {
        defaultStartDate: date,
        user,
        sidebarIcon: 'edit',
      });
    } else if (eventType === 'replacement') {
      setClinic(clinic.id);
      updateComponent(AddMandate, 'addMandate', {
        defaultStartDate: date,
        clinic,
        sidebarIcon: 'edit',
      });
    }
  }

  updateEvent(event) {
    const {
      clinic,
      user,
      updateComponent,
      setAvailabilityToUpdate,
      setReplacementToUpdate,
      setMandateToUpdate,
    } = this.props;

    if (event.type === 'availability') {
      setAvailabilityToUpdate(event.id);
      updateComponent(EditAvailability, 'editAvailability', { user, sidebarIcon: 'edit' });
    } else if (event.type === 'replacement') {
      setReplacementToUpdate(event.id);
      setMandateToUpdate(event.mandateId);
      updateComponent(EditReplacement, 'editReplacement', { clinic, sidebarIcon: 'edit' });
    }
  }

  enableEvent(event) {
    const { actionToConfirm, enableMatchmakingReplacement } = this.props;
    actionToConfirm(() => enableMatchmakingReplacement({ id: event.id }), 'replacement.enable');
  }

  deleteEvent(event) {
    const { actionToConfirm, disableAvailability, disableReplacement } = this.props;

    if (event.type === 'availability') {
      actionToConfirm(() => disableAvailability({ id: event.id }), 'availability.delete');
    } else if (event.type === 'replacement') {
      actionToConfirm(() => disableReplacement({ id: event.id }), 'replacement.delete');
    }
  }

  deleteMandate(mandateId) {
    const { actionToConfirm, disableMandate } = this.props;
    actionToConfirm(() => disableMandate({ id: mandateId }), 'mandate.delete');
  }

  deleteProposal(event, proposalId) {
    const { actionToConfirm, disableAvailabilityProposal, disableReplacementProposal } = this.props;

    if (event.type === 'availability') {
      actionToConfirm(() => disableAvailabilityProposal({ id: proposalId }), 'proposal.delete');
    } else if (event.type === 'replacement') {
      actionToConfirm(() => disableReplacementProposal({ id: proposalId }), 'proposal.delete');
    }
  }

  selectEvent(event) {
    const { actionToConfirm, availabilities } = this.props;
    const selectedAvailability = availabilities.find((availability) => availability);
    const eventValidationMessage = validateEventSelection(event, selectedAvailability);

    if (eventValidationMessage) {
      actionToConfirm(
        () => { this.addEventToProposalList(event); },
        eventValidationMessage,
      );
    } else {
      this.addEventToProposalList(event);
    }
  }

  addEventToProposalList(event) {
    const { availabilities, clinic, selectReplacementForProposals } = this.props;

    selectReplacementForProposals({
      clinic,
      availability: availabilities.find((availability) => availability),
      replacement: event,
    });
  }

  unselectEvent(event) {
    const { unselectReplacementForProposals } = this.props;

    unselectReplacementForProposals(event);
  }

  canEventMatch(event) {
    const { availabilities, proposals } = this.props;

    // event must not be confirmed or pending
    if (event.latestProposal && ['confirmed', 'pending', 'pending_clinic', 'pending_admin'].includes(event.latestProposal.status)) {
      return false;
    }

    return availabilities.some((availability) => {
      // event does not match on pending availabilities
      if (availability.latestProposal && !['rejected', 'expired'].includes(availability.latestProposal.status)) {
        return false;
      }

      // event does not match on availabilities with active proposals
      if (proposals.some(proposal => proposal.availability.id === availability.id)) {
        return false;
      }

      // if event needs surgery, replacement must do surgery
      if (event.surgery === 'true' && availability.surgery === false) {
        return false;
      }

      // event matches if all day
      if (event.allDay && availability.allDay) {
        return true;
      }

      // other events could match
      return true;
    });
  }

  isActiveProposal(event) {
    const { proposals } = this.props;

    return proposals.some(proposal => proposal[event.type].id === event.id);
  }

  render() {
    const {
      clinic,
      count,
      date,
      dateFrom,
      dateTo,
      events,
      eventType,
      filters,
      holidays,
    } = this.props;
    const { t } = this.context;
    const anyEvents = events.length > 0;
    const showAddButton = date >= moment().startOf('day').toDate();
    const maxIndex = anyEvents ? Math.max(...events.map((event) => event.index)) : 0;
    const height = `${((maxIndex + 1) * 4.9375)}rem`;
    const styles = { height };
    const weekendDay = isWeekendDay(date);
    const isHoliday = getHoliday(date, holidays);
    const isInteractive = true;
    return (
      <div className={classNames('matchmaking__day', {
        'matchmaking__day--holiday': isHoliday,
        'matchmaking__day--weekend': weekendDay,
        'matchmaking__day--multiple': eventType === 'replacement',
        })}
      >
        <div className="matchmaking__day__expander" style={styles} />
        {((showAddButton && eventType !== 'availability') || !anyEvents) &&
          <button
            className="matchmaking__day__add matchmaking__day__add--float"
            onClick={this.createEvent}
            title={eventType === 'availability' ? t('matchmaking.addAvailability') : t('matchmaking.addReplacement')}
          />
        }
        {anyEvents && (
          events.map((event) => (
            eventType === 'availability' ? (
              <AvailabilityEvent
                key={event.id}
                deleteEvent={this.deleteEvent}
                deleteProposal={this.deleteProposal}
                event={event}
                updateEvent={this.updateEvent}
                unselectEvent={this.unselectEvent}
                activeProposal={this.isActiveProposal(event)}
                filteredOut={isFilteredEvent(event, filters)}
                count={count}
                onSetEventIdForTooltip={this.onSetEventIdForTooltip}
              />
            ) : (
              <ReplacementEvent
                key={event.id}
                dateFrom={dateFrom}
                dateTo={dateTo}
                deleteEvent={this.deleteEvent}
                deleteMandate={this.deleteMandate}
                deleteProposal={this.deleteProposal}
                enableEvent={this.enableEvent}
                event={event}
                updateEvent={this.updateEvent}
                selectEvent={this.selectEvent}
                unselectEvent={this.unselectEvent}
                eventMatch={this.canEventMatch(event)}
                activeProposal={this.isActiveProposal(event)}
                isInteractive={isInteractive}
                filteredOut={isFilteredEvent(event, filters)}
                count={count}
                clinic={clinic}
                onSetEventIdForTooltip={this.onSetEventIdForTooltip}
              />
            )
        )))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dateFrom: state.schedule.from,
    dateTo: state.schedule.to,
    proposals: state.matchmaking.proposals,
    filters: getFormValues('statuses')(state),
    holidays: state.schedule.holidays,
  };
}

export default connect(
  mapStateToProps,
  {
    ...actionsSidePanel,
    ...actionsAvailabilities,
    ...actionsMandates,
    ...actionsMatchmaking,
    ...confirmActions,
  },
)(DayEvents);
