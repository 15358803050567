import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ClinicHelpcenter from './clinic/ClinicHelpcenter';
import NotFound from '../pages/NotFound';
import SubstituteHelpcenter from './Substitute/SubstituteHelpcenter';

function HelpcenterWrapper({ role }) {
  const allowedRoles = ['substitute', 'clinic_manager'];

  if (process.env.REACT_APP_REGION_CODE === 'qc' && allowedRoles.includes(role)) {
    return (
      <>
        { role === 'substitute' && <SubstituteHelpcenter /> }
        { role === 'clinic_manager' && <ClinicHelpcenter /> }
      </>
    );
  }

  return <NotFound />;
}

HelpcenterWrapper.propTypes = {
  role: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    role: state.user.role,
  };
}

export default connect(mapStateToProps)(HelpcenterWrapper);
