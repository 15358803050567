import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Field } from 'redux-form';
import Radio from './Radio';

// Styles
import '../Fields.scss';
import './Radio.scss';

function RadioList({ disabled, radioList, label, name, noLabel, required }, { t }) {
  return (
    <div
      className={classNames(
        'radio-list', {
        'radio-list--label': !noLabel,
      })}
    >
      {!noLabel &&
        <label className="field__label">
          <span>
            {t(`form.${label}`)}{required && ' *'}
          </span>
        </label>
      }
      <Field
        name={name}
        component={({ meta }) =>
          <div>
            {meta.error && meta.touched && <span className="field__error">{t(meta.error)}</span>}
          </div>}
      />
      { radioList.map((value) =>
        <Radio
          key={value}
          name={name}
          component="input"
          value={value}
          label={`${label}s.${value}`}
          disabled={disabled}
          type="radio"
        />,
      )}
    </div>
  );
}

RadioList.contextTypes = {
  t: PropTypes.func,
};
RadioList.propTypes = {
  disabled: PropTypes.bool,
  radioList: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  noLabel: PropTypes.bool,
  required: PropTypes.bool,
};
RadioList.defaultProps = {
  disabled: false,
  noLabel: false,
  required: false,
};

export default RadioList;
