import React from 'react';
import PropTypes from 'prop-types';

// component
import Experience from './Experience';
import Price from './Price';
import Distance from './Distance';
import Time from './Time';
import Task from './Task';

function Substitute({
  adminRole,
  clinicPrice,
  endsAt,
  isPendingClinic,
  startsAt,
  substitute,
  userDistance,
  userPending,
}, { t }) {
  const {
    profile: {
      consultation,
      firstName,
      lastName,
      picture,
      graduation,
      surgery,
    },
  } = substitute;
  return (
    <div className="mandate-item__user mandate-item__user--confirmed">
      {(adminRole || !userPending) && picture && picture.mini ?
        <span
          className="mandate-item__user__picture mandate-item__user__picture--filled"
          style={{ backgroundImage: `url(${picture.mini})` }}
        />
      :
        <span className="mandate-item__user__picture mandate-item__user__picture--empty" />
      }
      <span className="mandate-item__user__infos">
        <span className="mandate-item__user__name">
          {firstName && lastName ? `${firstName} ${lastName}` : t('mandates.cards.proposalWaiting')}
        </span>

        <span className="mandate-item__user__preferences">
          <Time startsAt={startsAt} endsAt={endsAt} />
        </span>
        <span className="mandate-item__user__preferences">
          <Task
            consultation={consultation}
            surgery={surgery}
            textOnly={!adminRole}
            firstName={firstName}
          />
        </span>
        <span className="mandate-item__user__tags">
          {userDistance &&
            <Distance userDistance={userDistance} userPending={userPending && !adminRole} />
          }
          { graduation &&
            <Experience graduation={graduation} />
          }
          { clinicPrice && (isPendingClinic || adminRole) &&
            <Price
              price={clinicPrice}
            />
          }
        </span>
      </span>
    </div>
  );
}

Substitute.defaultProps = {
  clinicPrice: undefined,
};

Substitute.propTypes = {
  adminRole: PropTypes.bool.isRequired,
  clinicPrice: PropTypes.number,
  endsAt: PropTypes.string.isRequired,
  isPendingClinic: PropTypes.bool.isRequired,
  startsAt: PropTypes.string.isRequired,
  substitute: PropTypes.object.isRequired,
  userDistance: PropTypes.number.isRequired,
  userPending: PropTypes.bool.isRequired,
};
Substitute.contextTypes = {
  t: PropTypes.func,
};

export default Substitute;
