// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translateRoute } from 'o2web-react-core';

export default function (ComposedComponent, authRequired = true, defaultProps = {}) {
  class AuthenticatedComponent extends Component {
    static propTypes = {
      displayedLanguage: PropTypes.string.isRequired,
      history: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      authenticated: PropTypes.bool.isRequired,
      validatingToken: PropTypes.bool.isRequired,
    };

    static contextTypes = {
      t: PropTypes.func,
    };

    componentWillMount() {
      window.scrollTo(0, 0);
      this.validateLocation();
    }

    componentDidUpdate() {
      this.validateLocation();
    }

    validateLocation() {
      const {
        authenticated,
        displayedLanguage,
        history,
        validatingToken,
        location: { pathname },
      } = this.props;
      const { t } = this.context;

      if (!validatingToken) {
        const signedInRoute = translateRoute('/en', t);
        const signInRoute = translateRoute('/en/login', t);

        // redirect to login if user is not authenticated
        if (authenticated && !authRequired && pathname !== signedInRoute) {
          history.push(signedInRoute);
        } else if (authenticated && pathname.split('/').filter(Boolean)[0] !== displayedLanguage) {
          history.push(`/${displayedLanguage}`);
        } else if (!authenticated && authRequired && pathname !== signInRoute) {
          history.push(signInRoute);
        }
      }
    }

    canRenderComponent() {
      const { authenticated, validatingToken, location: { pathname } } = this.props;
      const { t } = this.context;

      if (validatingToken) {
        return false;
      }

      const signedInRoute = translateRoute('/en/account', t);
      if (authenticated && !authRequired && pathname !== signedInRoute) {
        return false;
      }

      const signInRoute = translateRoute('/en/login', t);
      if (!authenticated && authRequired && pathname !== signInRoute) {
        return false;
      }

      return true;
    }

    render() {
      if (this.canRenderComponent()) {
        return (
          <ComposedComponent {...defaultProps} />
        );
      }

      return null;
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: state.user.authenticated,
      displayedLanguage: state.user.displayedLanguage,
      validatingToken: state.user.validatingToken,
    };
  }

  return withRouter(connect(mapStateToProps)(AuthenticatedComponent));
}
