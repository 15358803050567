export function updateTimesheets(timesheetsList, updatedEntry) {
  return timesheetsList.map((timesheet) => ({
    ...timesheet,
    entries: timesheet.entries.map((entry) => {
      if (entry.id === updatedEntry.id) {
        entry = updatedEntry;
      }
      return entry;
    }),
  }));
}

export default updateTimesheets;
