import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// components
import DateTime from '../proposals/parts/DateTime';
import Details from '../proposals/parts/Details';
import Fields from '../proposals/parts/Fields';

class ReplacementRow extends Component {
  static propTypes = {
    reviewCallToAll: PropTypes.func,
    field: PropTypes.string,
    replacement: PropTypes.object.isRequired,
    role: PropTypes.string.isRequired,
    change: PropTypes.func,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    change: () => {},
    reviewCallToAll: () => {},
    field: null,
  };

  constructor(props) {
    super(props);
    this.reviewCallToAll = this.reviewCallToAll.bind(this);
    this.toggleDetails = this.toggleDetails.bind(this);
    this.state = { showDetails: false };
  }

  toggleDetails() {
    this.setState(({ showDetails }) => ({
      showDetails: !showDetails,
    }));
  }

  reviewCallToAll() {
    const { reviewCallToAll, replacement } = this.props;
    reviewCallToAll(replacement.callToAllid);
  }

  render() {
    const { field, replacement, role, change } = this.props;
    const { showDetails } = this.state;
    const {
      startsAt,
      endsAt,
      mandate: {
        consultation,
        surgery,
        accommodation,
      },
    } = replacement;

    const substituteRole = role === 'substitute';
    const pending = true;

    return (
      <li className={classNames(
        'list-item',
        'list-item--has-drawer', {
          'list-item--open': showDetails,
        },
      )}
      >
        <div className="list-item__header">
          <div
            className={classNames('list-item__infos')}
            onClick={(this.toggleDetails)}
            onKeyPress={this.toggleDetails}
            tabIndex="0"
            role="button"
          >
            <DateTime
              endsAt={endsAt}
              startsAt={startsAt}
              substituteRole={substituteRole}
            />
          </div>
          {field && (
            <Fields
              acceptedProposals={false}
              change={change}
              clinicStatus="pending"
              field={field}
              pending={pending}
              proposalDate={startsAt}
              reviewCallToAll={this.reviewCallToAll}
              role={role}
              status="pending"
            />
          )}
        </div>
        {showDetails &&
          <Details
            accommodation={accommodation}
            consultation={consultation}
            surgery={surgery}
            toggleDetails={this.toggleDetails}
          />
        }
      </li>
    );
  }
}

export default ReplacementRow;
