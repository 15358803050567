import moment from 'moment';
import { getBeginningOfMonth, getNthMonth } from './dates';

export function formatEvents(data) {
  return data.map(event => ({
    ...event,
    start: new Date(event.startsAt),
    end: new Date(event.endsAt),
    type: 'availability',
  }),
  );
}

export function replaceAvailabilityInList(availabilitiesList, newAvailability) {
  return availabilitiesList.map((month) => ({
    ...month,
    weeks: Object.keys(month.weeks).map((weekKey) =>
      month.weeks[weekKey].map(
        (availability) => (
          (availability.id === newAvailability.id) ? newAvailability : availability
        ),
      ),
    ),
  }));
}

export function deleteAvailabilityInList(availabilitiesList, deletedAvailability) {
  return availabilitiesList.map((month) => ({
    ...month,
    weeks: Object.keys(month.weeks).map((weekKey) =>
      month.weeks[weekKey].filter((availability) =>
        deletedAvailability.id !== availability.id,
      ),
    ),
  }));
}

export function listAvailabilitiesByMonth(availabilities, currentDate, listLength) {
  return [...Array(listLength).keys()].map((count) => {
    const date = getNthMonth(currentDate, count);
    return {
      date,
      weeks: availabilities
        .filter((availability) =>
          getBeginningOfMonth(new Date(availability.startsAt)).getTime() ===
          getBeginningOfMonth(date).getTime(),
        )
        .reduce((weeks, availability) => {
          const yearWeek =
          `${moment(availability.startsAt).year()}-${moment(availability.startsAt).week()}`;

          if (typeof weeks[yearWeek] === 'undefined') weeks[yearWeek] = [];
          weeks[yearWeek].push(availability);

          return weeks;
        }, []),
    };
  });
}

export function formatMatchmakingAvailabilities(substitutes) {
  return substitutes.map(({ availabilities, ...attributes }) => ({
    ...attributes,
    availabilities: availabilities.nodes.reduce((object, node) => {
      const formattedDate = moment(node.startsAt).format('DD-MM-YYYY');
      return { ...object, [formattedDate]: [...(object[formattedDate] || []), { ...node, type: 'availability' }] };
    }, {}),
  }),
  );
}

export function addUserAvailabilities(substitutes, availabilities) {
  return substitutes.map((substitute) => ({
    ...substitute,
    availabilities: {
      nodes: [
        ...substitute.availabilities.nodes,
        ...availabilities.filter((availability) => availability.user.id === substitute.id),
      ],
    },
  }));
}

export function updateUserAvailabilities(substitutes, updatedAvailabilities) {
  return substitutes.map((substitute) => ({
    ...substitute,
    availabilities: {
      nodes: substitute.availabilities.nodes.map((availability) => {
        const newAvailability = updatedAvailabilities.find(updatedAvailability =>
          updatedAvailability.id === availability.id,
        );

        if (newAvailability) {
          return newAvailability;
        }

        return availability;
      }),
    },
  }));
}

export function updateUserAvailability(substitutes, updatedAvailability) {
  return substitutes.map((substitute) => {
    if (substitute.id === updatedAvailability.user.id) {
      return {
        ...substitute,
        availabilities: {
          nodes: substitute.availabilities.nodes.map((availability) => {
            if (updatedAvailability.id === availability.id) {
              return updatedAvailability;
            }

            return availability;
          }),
        },
      };
    }

    return substitute;
  });
}

export function deleteUserAvailability(substitutes, deletedAvailability) {
  return substitutes.map((substitute) => {
    if (substitute.id === deletedAvailability.user.id) {
      return {
        ...substitute,
        availabilities: {
          nodes: substitute.availabilities.nodes.filter((availability) =>
            deletedAvailability.id !== availability.id,
          ),
        },
      };
    }

    return substitute;
  });
}

export function disableUserAvailabilityProposal(substitutes, disabledProposal) {
  return substitutes.map((substitute) => {
    if (substitute.id === disabledProposal.availability.user.id) {
      return {
        ...substitute,
        availabilities: {
          nodes: substitute.availabilities.nodes.map((availability) => {
            if (disabledProposal.availability.id === availability.id) {
              return {
                ...availability,
                latestProposal: disabledProposal.availability.latestProposal,
              };
            }

            return availability;
          }),
        },
      };
    }

    return substitute;
  });
}
