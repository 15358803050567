import React from 'react';
import PropTypes from 'prop-types';

import placeholderFile from '../../../../../../assets/images/paperclip.svg';
import placeholderPicture from '../../../../../../assets/images/placeholder.svg';

function FileUploadPlaceholder({ displayType }, { t }) {
  return (
    <img
      src={displayType === 'picture' ? placeholderPicture : placeholderFile}
      alt={t('form.uploadImage')}
      className="file-uploader__preview__placeholder"
    />
  );
}

FileUploadPlaceholder.propTypes = {
  displayType: PropTypes.string,
};

FileUploadPlaceholder.defaultProps = {
  displayType: 'picture',
};

FileUploadPlaceholder.contextTypes = {
  t: PropTypes.func,
};

export default FileUploadPlaceholder;
