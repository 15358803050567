// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// components
import EditUser from './EditUser';
import EditGroup from '../EditGroup';

// actions
import actions from '../../../actions/admin/users';
import actionsSidePanel from '../../../actions/dynamicSidePanel/index';
import alertsActions from '../../../actions/alerts/index';
import confirmActionActions from '../../../actions/confirmAction/index';

class User extends Component {
  static propTypes = {
    updateComponent: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    fetchUser: PropTypes.func.isRequired,
    disableUser: PropTypes.func.isRequired,
    enableUser: PropTypes.func.isRequired,
    pushAlert: PropTypes.func.isRequired,
    actionToConfirm: PropTypes.func.isRequired,
    userId: PropTypes.string,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    userId: null,
  };

  constructor(props) {
    super(props);
    this.disableUser = this.disableUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.enableUser = this.enableUser.bind(this);
  }

  disableUser() {
    const { disableUser, pushAlert, user: { id } } = this.props;
    disableUser({ id })
      .then((data) => {
        if (data && data.disableUser.success) {
          pushAlert({
            type: 'success',
            content: 'alerts.disabled',
          });
        }
      });
  }

  editUser() {
    const { updateComponent, fetchUser, user: { id } } = this.props;
    fetchUser({ id });
    updateComponent(EditUser, 'editUser', { sidebarIcon: 'edit' });
  }

  enableUser() {
    const { enableUser, pushAlert, user: { id } } = this.props;
    enableUser({ id })
      .then((data) => {
        if (data && data.enableUser.success) {
          pushAlert({
            type: 'success',
            content: 'alerts.enabled',
          });
        }
      });
  }

  render() {
    const {
      user: { id, discarded, email, roles, profile: { firstName, lastName, accountType } },
      actionToConfirm,
      userId,
    } = this.props;
    const { t } = this.context;

    return (
      <div className={classNames(
        'content-list__content-item',
        { 'content-list__content-item--discarded': discarded },
        )}
      >
        <div className="user">
          <h2 className="user__name">{`${firstName} ${lastName}`}</h2>
          <a className="user__mail" href={`mailto:${email}`}>{email}</a>
          <div className="user__roles">
            {roles.map(({ name }) =>
              <span key={name} className={`user__role ${name}`}>
                {t(`form.roles.${name}`)}
                {name === 'substitute' && (
                  <span> - {t(`form.accountTypes.${accountType}`)}</span>
                )}
              </span>,
            )}
          </div>
        </div>
        <EditGroup
          edit={this.editUser}
          disable={() => actionToConfirm(this.disableUser, 'user.disable')}
          enable={() => actionToConfirm(this.enableUser, 'user.enable')}
          discarded={discarded}
          canDisable={userId !== id}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.results,
    userId: state.user.data.id,
  };
}

export default connect(
  mapStateToProps,
  { ...actions, ...actionsSidePanel, ...alertsActions, ...confirmActionActions },
)(User);
