import React from 'react';
import PropTypes from 'prop-types';
import {
  yearsSinceGraduation,
} from '../../../../../../../helpers/formatDates';

function Experience({ graduation }, { t }) {
  return (
    <div className="mandate-item__experience">
      <span
        className="mandate-item__experience__tag"
      >
        {yearsSinceGraduation(graduation)}
        {yearsSinceGraduation(graduation) > 1 ? t('mandates.cards.years') : t('mandates.cards.year') }
      </span>
    </div>
  );
}

Experience.propTypes = {
  graduation: PropTypes.string.isRequired,
};
Experience.defaultProps = {};
Experience.contextTypes = {
  t: PropTypes.func,
};

export default Experience;
