import React from 'react';
import PropTypes from 'prop-types';
// import { Field } from 'redux-form';
import Radio from './Radio';

// Styles
import '../Fields.scss';
import './Radio.scss';

const RadioBoolean = (field, { t }) => {
  const { input, label, className } = field;
  return (
    <div className={`radio-boolean ${className}`}>
      <label className="radio-boolean__label">{label ? t(`form.${label}`) : t(`form.${input.name}`)}</label>
      <div className="radio-boolean__wrapper">
        <Radio
          key="true"
          name={input.name}
          component="input"
          value="true"
          label="boolean.yes"
          type="radio"
          onChange={(e) => input.onChange(e)}
        />
        <Radio
          key="false"
          name={input.name}
          component="input"
          value="false"
          label="boolean.no"
          type="radio"
          onChange={(e) => input.onChange(e)}
        />
      </div>
    </div>
  );
};

RadioBoolean.contextTypes = {
  t: PropTypes.func,
};

export default RadioBoolean;
