/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import squareBlue from '../../../../assets/images/helpcenter/square-blue.svg';
import squareOrange from '../../../../assets/images/helpcenter/square-orange.svg';
import squareGreen from '../../../../assets/images/helpcenter/square-green.svg';
import squareHashGreen from '../../../../assets/images/helpcenter/square-hashGreen.svg';
import edit from '../../../../assets/images/helpcenter/edit_availability.png';
import edit2 from '../../../../assets/images/helpcenter/edit_availability2.png';
import addAvailabilities from '../../../../assets/images/helpcenter/add_availabilities.png';

import '../helpcenter.scss';

class SubstituteHelpcenter extends Component {
  static contextTypes = {
    t: PropTypes.func,
  };

  render() {
    const { t } = this.context;

    return (
      <div className="faq-content addShadow">
        <div className="faq-content-title title">
          <h2>{t('helpcenter.substitute.tutorial.title')}</h2>
          <p>{t('helpcenter.substitute.tutorial.paragraph')}</p>
        </div>
        <div className="faq-content-main">
          <h3>{t('helpcenter.substitute.tutorial.homePage.title')}</h3>
          <h4>{t('helpcenter.substitute.tutorial.homePage.calendarView')}</h4>
          <p>{t('helpcenter.substitute.tutorial.homePage.description')}</p>
        </div>
        <section id="legend" className="faq-content-legend-container">
          <div className="legend-row">
            <div className="faq-legend-icon"><img src={squareBlue} alt="Blue square" /></div>
            <div className="faq-legend-content">
              <p>{t('helpcenter.substitute.tutorial.legend.a')} <strong className="blue">{t('helpcenter.substitute.tutorial.legend.blueCase')}</strong> {t('helpcenter.substitute.tutorial.legend.blue')}</p>
            </div>
          </div>
          <div className="legend-row">
            <div className="faq-legend-icon"><img src={squareOrange} alt="Orange square" /></div>
            <div className="faq-legend-content">
              <p>{t('helpcenter.substitute.tutorial.legend.a')} <strong className="orange">{t('helpcenter.substitute.tutorial.legend.orangeCase')}</strong> {t('helpcenter.substitute.tutorial.legend.orange')}</p>
            </div>
          </div>
          <div className="legend-row">
            <div className="faq-legend-icon"><img src={squareHashGreen} alt="Hash green square" /></div>
            <div className="faq-legend-content">
              <p>{t('helpcenter.substitute.tutorial.legend.a')} <strong className="green">{t('helpcenter.substitute.tutorial.legend.hashGreenCase')}</strong> {t('helpcenter.substitute.tutorial.legend.hashGreen')}</p>
            </div>
          </div>
          <div className="legend-row">
            <div className="faq-legend-icon"><img src={squareGreen} alt="Green square" /></div>
            <div className="faq-legend-content">
              <p>{t('helpcenter.substitute.tutorial.legend.a')} <strong className="green">{t('helpcenter.substitute.tutorial.legend.greenCase')}</strong> {t('helpcenter.substitute.tutorial.legend.green')}</p>
            </div>
          </div>
        </section>

        {/* Profil utilisateur */ }
        <section id="profiles">
          <div className="faq-content-main">
            <h3>{t('helpcenter.substitute.tutorial.profiles.title')}</h3>
            <p>{ t('helpcenter.substitute.tutorial.profiles.userDescription') }<span className="icon icon--profile" /></p>
          </div>
          <div className="faq-warning">
            <div className="faq-warning-icon"><span role="img" aria-label="Warning">⚠️</span></div>
            <div className="faq-warning-content">
              <p>{t('helpcenter.substitute.tutorial.profiles.userWarning.paragraph1')}</p>
              <p>{t('helpcenter.substitute.tutorial.profiles.userWarning.paragraph2')}</p>
            </div>
          </div>
        </section>
        {/* Disponibilité */ }
        <section id="replacement">
          <div className="faq-content-main">
            <h3>{t('helpcenter.substitute.tutorial.availabilities.title')}</h3>
            <h4>{t('helpcenter.substitute.tutorial.availabilities.add')}</h4>
            <p>
              {t('helpcenter.substitute.tutorial.availabilities.buttonClick')}
              <button className="button" />
              <span className="button__label--bold">« {t('availabilities.add')} »</span>
              {t('helpcenter.substitute.tutorial.availabilities.addDescription')}
            </p>
            <img src={addAvailabilities} alt="" />
          </div>

          {/* Information de la dispo */}
          <div className="faq-content-main">
            <h4>{t('helpcenter.substitute.tutorial.availabilities.information')}</h4>
            <p>{t('helpcenter.substitute.tutorial.availabilities.availabilityInformationDescription')}</p>
          </div>
          {/* item 1 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.substitute.tutorial.availabilities.informationItems.item1')}</h5>
            </div>

          </div>
          {/* item 2 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.substitute.tutorial.availabilities.informationItems.item2')}</h5>
              <p>{t('helpcenter.substitute.tutorial.availabilities.informationItems.item2Description')}</p>
            </div>
          </div>

          {/* item 3 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.substitute.tutorial.availabilities.informationItems.item3')}</h5>
              <p>{t('helpcenter.substitute.tutorial.availabilities.informationItems.item3Description')}</p>
            </div>
          </div>

          {/* item 4 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.substitute.tutorial.availabilities.informationItems.item4')}</h5>
            </div>
          </div>
          <p>Une fois tes disponibilités ajoutées, tu pourras les retrouver dans la vue <span className="nobreak"><span className="icon icon--calendar blue" /> <strong className="blue">«{t('nav.calendar')}»</strong></span>, ou sous l’onglet <span className="nobreak"><span className="icon icon--list2 blue" /> <strong className="blue">«{t('nav.availabilities')}»</strong></span> dans le menu de gauche.</p>

          <div className="faq-content-main">
            <h4>{t('helpcenter.substitute.tutorial.availabilities.edit')}</h4>
            <p>{t('helpcenter.substitute.tutorial.availabilities.editDescription')}</p>
            <p>{t('helpcenter.substitute.tutorial.availabilities.editDescription2')}<span className="icon icon--delete" /></p>
            <img src={edit} alt="" />
            <p>
              Tu peux également retrouver toutes tes disponibilités sous l’onglet <strong className="blue">«{t('nav.availabilities')}»</strong> dans le menu de gauche. Clique sur le crayon <span className="icon icon--pencil" /> pour modifier et sur la corbeille <span className="icon icon--delete" /> pour supprimer une disponibilité.
            </p>
            <img src={edit2} alt="" />
          </div>
          <div className="faq-warning">
            <div className="faq-warning-icon"><span role="img" aria-label="Warning">⚠️</span></div>
            <div className="faq-warning-content">
              <p>{t('helpcenter.substitute.tutorial.availabilities.editWarning.paragraph1')}<a className="phone" href={`tel:${process.env.REACT_APP_REGION_PHONE}`}>{process.env.REACT_APP_REGION_PHONE}</a>{t('helpcenter.substitute.tutorial.availabilities.editWarning.SMS')}<a className="phone" href={`tel:${process.env.REACT_APP_REGION_SMS}`}>{process.env.REACT_APP_REGION_SMS}</a></p>
            </div>
          </div>

        </section>
        {/* Proposition de remplacement */ }
        <section id="proposition">
          <div className="faq-content-main">
            <h3>{t('helpcenter.substitute.tutorial.proposition.title')}</h3>
            <h4>{t('helpcenter.substitute.tutorial.proposition.answer')}</h4>
            <p>Tous les remplacements qui te sont proposés et qui sont en attente de ton approbation se trouvent sous l’onglet <span className="nobreak"><span className="icon icon--schedule orange" /><strong className="orange">«{ t('nav.proposedMandates') }»</strong></span> dans le menu de gauche. <span className="underlined">Tu devras répondre à toutes les propositions d’un même mandat, afin de pouvoir soumettre tes réponses.</span></p>
          </div>

          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>Pour <strong className="green">{ t('form.acceptProposition.accept') }</strong> une proposition de remplacement, coche <span className="icon icon--circle-check green" /> et clique sur «Envoyer».</h5>
            </div>
          </div>
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>Pour <strong className="red">{ t('form.acceptProposition.refuse') }</strong> une proposition de remplacement, coche <span className="icon icon--circle-close red" /> et clique sur «Envoyer».</h5>
            </div>
          </div>
          <div className="faq-card-tip addShadow">
            <div className="faq-card-tip-icon"><span role="img" aria-label="Light bulb">💡</span></div>
            <div className="faq-card-tip-content">
              <p>{t('helpcenter.substitute.tutorial.proposition.answerTip')}<span className="icon icon--circle-add orange" /><strong className="orange">«{ t('mandates.addTime.long') }»</strong></p>
            </div>
          </div>
          <div className="faq-content-main">
            <h3>{t('helpcenter.substitute.tutorial.approval.title')}</h3>
            <h4>{t('helpcenter.substitute.tutorial.approval.awaiting')}</h4>
            <p>Les remplacements que tu as approuvés se trouvent sous l’onglet <span className="nobreak"><span className="icon icon--circle-check green" /><strong className="green">{ t('nav.acceptedMandates') }</strong></span> dans le menu de gauche. Le mandat sera labellisé «En attente de confirmation» et ce sera au tour de notre équipe de faire la validation finale.</p>
          </div>
          <div className="faq-content-main">
            <h4>{t('helpcenter.substitute.tutorial.approval.confirmed')}</h4>
            <p>Les remplacements confirmés se trouvent également sous l’onglet <span className="nobreak"><span className="icon icon--circle-check green" /><strong className="green">{ t('nav.acceptedMandates') }</strong></span> dans le menu de gauche. <strong className="underlined">Un remplacement est officiellement confirmé une fois que tu as reçu le courriel de confirmation.</strong></p>
          </div>
        </section>
        {/* Timesheet */}
        <section id="timesheet">
          <div className="faq-content-main">
            <h3>{t('helpcenter.substitute.tutorial.timesheets.title')}</h3>
            <p>À la fin de chaque semaine où tu as complété des remplacements, tu dois remplir ta feuille de temps disponible sous l’onglet <span className="nobreak"><span className="icon icon--timesheet purple" /><strong className="purple">{ t('nav.timesheets') }</strong></span> dans le menu de gauche.</p>
          </div>
          <div className="faq-warning">
            <div className="faq-warning-icon"><span role="img" aria-label="Warning">⚠️</span></div>
            <div className="faq-warning-content">
              <p>{t('helpcenter.substitute.tutorial.timesheets.warning.paragraph1')}</p>
            </div>
          </div>
          {/* item 1 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.substitute.tutorial.timesheets.informationItems.item1')}</h5>
            </div>

          </div>
          {/* item 2 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.substitute.tutorial.timesheets.informationItems.item2')}</h5>
              <p>{t('helpcenter.substitute.tutorial.timesheets.informationItems.item2Description1')}</p>
              <p>{t('helpcenter.substitute.tutorial.timesheets.informationItems.item2Description2')}</p>
            </div>
          </div>

          {/* item 3 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.substitute.tutorial.timesheets.informationItems.item3')}</h5>
              <p>{t('helpcenter.substitute.tutorial.timesheets.informationItems.item3Description')}</p>
            </div>
          </div>

          {/* item 4 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.substitute.tutorial.timesheets.informationItems.item4')}</h5>
            </div>
          </div>
          <div className="faq-card-tip addShadow">
            <div className="faq-card-tip-icon"><span role="img" aria-label="Light bulb">💡</span></div>
            <div className="faq-card-tip-content">
              <p>{t('helpcenter.substitute.tutorial.timesheets.answerTip')}</p>
            </div>
          </div>
          <p>Une fois la feuille de temps envoyée, il n’est plus possible de la modifier. Si tu souhaites y apporter des changements, contacte-nous à <a className="email" href={`mailto:${process.env.REACT_APP_REGION_EMAIL}`}>{process.env.REACT_APP_REGION_EMAIL}</a></p>
        </section>
        <div className="divider" />
        <section id="contact">
          <div className="contact__container">
            <div className="contact__image icon--question" />
            <div className="contact__title">
              <h4>{t('helpcenter.substitute.tutorial.questions.title')}</h4>
            </div>
            <div className="contact__paragraph">
              <p>{t('helpcenter.substitute.tutorial.questions.contactInfo')}<a className="email" href={`mailto:${process.env.REACT_APP_REGION_EMAIL}`}>{process.env.REACT_APP_REGION_EMAIL}</a><br />{t('helpcenter.substitute.tutorial.questions.or')}<a className="phone" href={`tel:${process.env.REACT_APP_REGION_PHONE}`}>{process.env.REACT_APP_REGION_PHONE}</a></p>
            </div>
            <div className="contact__paragraph">
              <p>{t('helpcenter.substitute.tutorial.questions.paragraph1')}</p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default SubstituteHelpcenter;
