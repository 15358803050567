export default {
  added: 'Ajout fait avec succès',
  callToAllFormSubmitted: "La réponse a bien été envoyée!",
  callToAllSent: 'Appel à tous envoyé avec succès',
  callToAllUpdated: 'Appel à tous mis à jour',
  confirmed: 'Remplacement confirmé avec succès',
  deleted: 'Suppression faite avec succès',
  disabled: 'Désactivation faite avec succès',
  edited: 'Modification faite avec succès',
  enabled: 'Activation faite avec succès',
  errorOccurred: 'Une erreur est survenue',
  proposalFormSubmitted: 'La réponse a bien été envoyée!',
  proposalAdded: 'La proposition a bien été envoyée!',
  sessionExpired: 'Oups! Session expirée. Merci de vous connecter de nouveau!',
  timeAdded: 'Temps ajouté avec succès',
  timesheetSent: 'Feuille de temps envoyée avec succès',
  addressClipboard: 'Adresse copiée avec succès'
};
