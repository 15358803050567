export default {
  add: 'Ajouter des disponibilités',
  availabilities: 'Liste des disponibilités saisies',
  distancesTag: {
    50: '< 50 km',
    150: '< 150 km',
    151: '> 150 km',
  },
  distancesText: {
    50: 'Moins de 50 km',
    150: 'Moins de 150 km',
    151: 'Plus de 150 km',
  },
  lessThan: 'Moins de ',
  moreThan: 'Plus de ',
  noAvailabilities: 'Vous n\'avez saisi aucune disponibilité pour ce mois',
};
