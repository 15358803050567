// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavLink, Route } from 'o2web-react-core';
import classNames from 'classnames';

// components
import Nav from '../nav/Nav';
import EditAccount from '../../user/forms/EditAccount';
import AdminMenu from '../../admin/AdminMenu';
import Notifications from '../../notifications/Notifications';
import Toolbar from '../../matchmaking/toolbar/Toolbar';
import ClinicSwitcher from './ClinicSwitcher';
import EditClinic from './../../admin/clinics/EditClinic';
import HelpcenterMenu from '../../helpcenter/HelpcenterMenu';

// actions
import sidePanelActions from '../../../actions/dynamicSidePanel';
import clinicActions from '../../../actions/admin/clinics';

// styles
import './styles.scss';
import logo from '../../../../assets/images/vetreseau-white.svg';

// Index Component
class Header extends Component {
  static propTypes = {
    component: PropTypes.any,
    componentName: PropTypes.string.isRequired,
    currentClinicId: PropTypes.string,
    hideSidePanel: PropTypes.func.isRequired,
    fetchClinic: PropTypes.func.isRequired,
    managedClinics: PropTypes.array,
    unreadNotificationsCount: PropTypes.number.isRequired,
    updateComponent: PropTypes.func.isRequired,
    userRole: PropTypes.string.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    component: null,
    currentClinicId: null,
    managedClinics: [],
  };

  constructor() {
    super();
    this.closeNav = this.closeNav.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.togglePanel = this.togglePanel.bind(this);
    this.state = {
      navIsActive: false,
    };
  }

  toggleNav() {
    this.setState(({ navIsActive }) => ({
      navIsActive: !navIsActive,
    }));
  }

  closeNav() {
    this.setState({
      navIsActive: false,
    });
  }

  togglePanel(toggledComponent, name, customMethod = null, panelProps) {
    const { updateComponent, hideSidePanel, component } = this.props;

    if (component === toggledComponent) {
      hideSidePanel();
    } else {
      if (customMethod) {
        customMethod();
      }
      updateComponent(toggledComponent, name, panelProps);
    }
  }

  render() {
    const {
      componentName,
      currentClinicId,
      fetchClinic,
      managedClinics,
      unreadNotificationsCount,
      userRole,
    } = this.props;
    const { navIsActive } = this.state;
    const { t } = this.context;
    const adminRole = userRole === 'admin';
    const clinicRole = userRole === 'clinic_manager';
    const region = process.env.REACT_APP_REGION_CODE;

    return (
      <div>
        {clinicRole && managedClinics.length > 1 &&
          <div className="clinic-switcher">
            <ClinicSwitcher />
          </div>
        }
        <header className={classNames('header', { 'header--open': navIsActive })}>
          <div className="header__group">
            <div className="header__vetmatch-logo">
              <NavLink to="/en">
                <img src={logo} alt={t(`metas.site.${process.env.REACT_APP_REGION_CODE}`)} />
              </NavLink>
              {process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'production' &&
                <div className="primary-nav__env__badge">{process.env.REACT_APP_ENV}</div>
              }
            </div>
            <button className="header__menu-toggle" onClick={this.toggleNav}>
              <span />
              <span />
              <span />
            </button>
            <button onClick={this.toggleNav} className="header__menu-toggle--close">
              <span />
              <span />
            </button>
          </div>
          <Route
            path="/en/matchmaking"
            render={() =>
              <Toolbar />
            }
          />
          {clinicRole && managedClinics.length > 1 && (
            <ClinicSwitcher />
          )}
          <div className="header__group header__group--last">
            {adminRole &&
              <button
                onClick={() => this.togglePanel(AdminMenu, 'admin', null, { sidebarIcon: 'settings' })}
                className={classNames(
                  'header__link header__link--settings', {
                  'header__link--close': componentName === 'admin',
                })}
              />
            }
            {!adminRole && region === 'qc' &&
              <button
                onClick={() => this.togglePanel(HelpcenterMenu, 'helpCenter', null, { sidebarIcon: 'help' })}
                className={classNames(
                  'header__link header__link--help', {
                  'header__link--close': componentName === 'helpCenter',
                })}
              />
            }
            <button
              onClick={() => this.togglePanel(Notifications, 'notifications', null, { sidebarIcon: 'notifications' })}
              className={
                classNames(
                  'header__link header__link--notifications', {
                  'header__link--close': componentName === 'notifications',
              })}
            >
              { unreadNotificationsCount > 0 &&
                <span
                  className={classNames(
                    'header__link header__link--notifications--count')}
                >
                  { unreadNotificationsCount }
                </span>
              }
            </button>
            {clinicRole && (
              <button
                onClick={() =>
                  this.togglePanel(EditClinic, 'editClinic', () =>
                    fetchClinic({ id: currentClinicId }, true), { sidebarIcon: 'clinic' })
                }
                className={classNames(
                  'header__link header__link--clinic', {
                  'header__link--close': componentName === 'editClinic',
                })}
              />
            )}
            <button
              onClick={() => this.togglePanel(EditAccount, 'account', null, { sidebarIcon: 'profile' })}
              className={
                classNames(
                  'header__link header__link--profile', {
                  'header__link--close': componentName === 'account',
              })}
            />
          </div>
        </header>
        <Nav navIsActive={navIsActive} closeNav={this.closeNav} toggleNav={this.toggleNav} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    component: state.dynamicSidePanel.component,
    componentName: state.dynamicSidePanel.name,
    currentClinicId: state.user.currentClinicId,
    unreadNotificationsCount: state.notifications.unreadNotificationsCount,
    userRole: state.user.role,
    managedClinics: state.user.managedClinics,
  };
}

export default withRouter(connect(
  mapStateToProps,
  { ...sidePanelActions, ...clinicActions },
)(Header));
