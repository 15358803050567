import React from 'react';
import PropTypes from 'prop-types';

function EmptyRow({ text }) {
  return (
    <div className="matchmaking__row matchmaking__row--header">
      <div className="matchmaking__user">
        <span className="matchmaking__user__text">{text}</span>
      </div>
      {[...Array(28).keys()].map((index) =>
        <div key={index} className="matchmaking__day matchmaking__day--empty" />,
      )}
    </div>
  );
}

EmptyRow.propTypes = {
  text: PropTypes.string.isRequired,
};
EmptyRow.defaultProps = {};

export default EmptyRow;
