import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { matchmakingDaysRange } from '../../../helpers/range';

// helpers
import { isWeekendDay } from '../../../helpers/dates';
import { getHoliday } from '../../../helpers/holidays';

function DatesHeader({
  children,
  currentDate,
  date,
  days,
  holidays,
}) {
  const today = moment();
  return (
    <div className="matchmaking__row matchmaking__row--date matchmaking__row--fixed">
      <div className="matchmaking__user matchmaking__user--search">
        {children}
      </div>

      {[...Array(days).keys()].map((index) => {
        const day = moment(date).add(index, 'days');
        const isSameDay = day.isSame(today, 'day');
        const isSelected = day.isSame(currentDate, 'day');
        const isWeekend = isWeekendDay(date, index);
        const isHoliday = getHoliday(day, holidays);

        return (
          <div
            id={isSelected ? 'selectedDay' : undefined}
            className={classNames(
              'matchmaking__day matchmaking__day--date', {
              'matchmaking__day--holiday': isHoliday !== undefined,
              'matchmaking__day--weekend matchmaking__day--weekend--border': isWeekend,
            })}
            key={day.format('DD-MM-YYYY')}
          >
            <span className={classNames('day', { 'day--today': isSameDay })}>
              {day.format('ddd')} <strong>{day.format('D')}</strong> {day.format('MMM')}
            </span>
            {isHoliday &&
              <div className="day day--holiday--tag">{isHoliday.title}</div>
            }
          </div>
        );
      })}
    </div>
  );
}

DatesHeader.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  children: PropTypes.node.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.object,
  ]).isRequired,
  days: PropTypes.number,
  holidays: PropTypes.array.isRequired,
};
DatesHeader.defaultProps = {
  days: matchmakingDaysRange(),
};

export default DatesHeader;
