export default {
  addAvailability: 'Créer une disponibilité',
  addReplacement: 'Créer une demande',
  callList: 'Remplacements à proposer',
  addCallToAll: 'Faire un appel à tous',
  editCallToAll: "Éditer l'appel à tous",
  clinic: 'Clinique',
  clinics: 'Cliniques',
  noProposals: 'Cliquez sur une demande de remplacement correspondant aux disponibilités du remplaçant pour créer une proposition',
  miniprofile: {
    profileOverview: 'Aperçu du profil',
    adminNote: 'Notes internes',
  },
  originalAvailability: 'Dispo originale',
  search: {
    noClinics: 'Aucune clinique à afficher',
    noSubstitutes: 'Aucun remplaçant à afficher',
  },
  status: {
    availability: 'Disponibilité à combler',
    confirmed: 'Proposition acceptée',
    empty: 'Disponibilité / Demande à combler',
    expired: 'Proposition expirée',
    pending: 'Proposition en attente',
    rejected: 'Proposition refusée',
    replacement: 'Demande à combler',
  },
  statuses: 'Statuts visibles',
  substitute: 'Remplaçant',
  substitutes: {
    technician: 'TSA',
    veterinarian: 'Vétérinaires',
  },
  tooltip: {
    adminNote: 'Notes à usage interne',
    clinicNote: 'Message de la clinique',
    createdAt: 'Créée le',
    proposalCreatedAt: 'Proposition crée le',
    substituteMessage: 'Message du remplaçant',
    updatedAt: 'Mise à jour',
  },
};
