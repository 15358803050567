import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Actions
import actionsSidePanel from '../../../../actions/dynamicSidePanel';
import actionsTimesheets from '../../../../actions/timesheet';
import alertsActions from '../../../../actions/alerts';
import confirmActionActions from '../../../../actions/confirmAction/index';

// Components
import Input from '../../../forms/fields/input/Input';
import Tooltip from './Tooltip';
import EditEntry from '../../EditEntry';


class Fields extends Component {
  constructor() {
    super();
    this.editEntry = this.editEntry.bind(this);
    this.confirmEntry = this.confirmEntry.bind(this);
    this.triggerEntryConfirmation = this.triggerEntryConfirmation.bind(this);
  }

  triggerEntryConfirmation() {
    const { actionToConfirm } = this.props;
    actionToConfirm(this.confirmEntry, 'timesheets.confirm');
  }

  confirmEntry() {
    const {
      pushAlert,
      reviewTimesheetEntries,
      entry: { id },
    } = this.props;
    const input = {
      input: {
        reviews: [{
          entryId: id,
          status: 'confirmed',
        }],
      },
    };
    reviewTimesheetEntries(input);
    pushAlert({
      type: 'success',
      content: 'alerts.timesheetSent',
    });
  }

  editEntry() {
    const { updateComponent, setEntryToUpdate, entryId } = this.props;
    setEntryToUpdate(entryId);
    updateComponent(EditEntry, 'editEntry', { sidebarIcon: 'edit' });
  }

  render() {
    const { field, entry, entry: { status }, userRole } = this.props;
    const { t } = this.context;
    const canEdit = status === 'pending' || (status === 'submitted' && userRole === 'admin');
    return ([
      <div key={`entries-${entry.id}`} className="timesheet-card__part timesheet-card__part--between">
        <div className="list-item__hours list-item__hours--large">
          <label><span>{t('timesheets.schedule')}</span></label>
          <span className="border" />
          <Field
            type="text"
            name={`${field}.startsAt`}
            label="timesheet.startsAt"
            component={Input}
            disabled
            noLabel
          />
          <span className="arrow" />
          <Field
            type="text"
            name={`${field}.endsAt`}
            label="timesheet.endsAt"
            component={Input}
            disabled
            noLabel
          />
        </div>
        <div className="list-item__hours">
          <label><span>{t('timesheets.lunch')}</span></label>
          <span className="border" />
          <Field
            type="text"
            name={`${field}.lunch`}
            label="timesheet.lunch"
            component={Input}
            inputClass="single"
            disabled
            noLabel
          />
          { (entry.breaks && !entry.breaks[0].active && entry.breaks[0].reason) &&
            <Tooltip tooltip={entry.breaks[0].reason} />
          }
        </div>
        <div className="list-item__hours">
          <label><span>{t('timesheets.dinner')}</span></label>
          <span className="border" />
          <Field
            type="text"
            name={`${field}.dinner`}
            label="timesheet.dinner"
            component={Input}
            inputClass="single"
            disabled
            noLabel
          />
          { (entry.breaks && !entry.breaks[1].active && entry.breaks[1].reason) &&
            <Tooltip tooltip={entry.breaks[1].reason} />
          }
        </div>
        <div className="list-item__hours">
          <label><span>{t('form.editTimesheetEntry.distance')}</span></label>
          <span className="border" />
          <Field
            type="text"
            name={`${field}.distanceTraveled`}
            label="form.editTimesheetEntry.distance"
            component={Input}
            inputClass="single"
            disabled
            noLabel
          />
        </div>
      </div>,
      <div key={`totals-${entry.id}`} className="timesheet-card__part timesheet-card__part--20 timesheet-card__part--right">
        <div className="list-item__hours">
          <Field
            type="text"
            name={`${field}.total`}
            label="timesheet.total"
            component={Input}
            disabled
            noLabel
          />
        </div>
        <div className="list-item__actions">
          { entry.adminNote &&
          <div className="tooltip__wrapper">
            <span className="tooltip tooltip--timesheet-adminNote">{entry.adminNote}</span>
            <span className="timesheet-card__icon timesheet-card__icon--adminNote" />
          </div>
          }
          { entry.message &&
          <div className="tooltip__wrapper">
            <span className="tooltip tooltip--timesheet-message">{entry.message}</span>
            <span className="timesheet-card__icon timesheet-card__icon--message" />
          </div>
          }
          {canEdit &&
            <button
              type="button"
              tabIndex="0"
              className="list-item__action list-item__action--edit"
              onClick={this.editEntry}
            />
          }
          { userRole === 'admin' &&
          <div>
            {status === 'submitted' &&
              <button
                type="button"
                tabIndex="0"
                className="timesheet-card__submit"
                onClick={this.triggerEntryConfirmation}
              >
                {t('timesheets.confirmEntry')}
              </button>
            }
            {status === 'confirmed' &&
              <span className="timesheet-card__submitted">{t('timesheets.entryIsConfirmed')}</span>
            }
          </div>
          }
        </div>
      </div>,
    ]);
  }
}

Fields.propTypes = {
  actionToConfirm: PropTypes.func.isRequired,
  entryId: PropTypes.string.isRequired,
  entry: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  pushAlert: PropTypes.func.isRequired,
  reviewTimesheetEntries: PropTypes.func.isRequired,
  setEntryToUpdate: PropTypes.func.isRequired,
  updateComponent: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

Fields.contextTypes = {
  t: PropTypes.func,
};


function mapStateToProps(state) {
  return {
    userRole: state.user.role,
  };
}

export default connect(
  mapStateToProps,
  {
    ...actionsSidePanel,
    ...actionsTimesheets,
    ...alertsActions,
    ...confirmActionActions,
  },
)(Fields);
