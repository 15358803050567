// libs
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { matchmakingDaysRange } from '../../../helpers/range';

// components
import DayEvents from '../event/DayEvents';
import ClinicMiniProfile from './miniprofiles/ClinicMiniProfile';
import ProximityTag from './parts/ProximityTag';

function ClinicRow({
  availabilities,
  date,
  days,
  substituteSelected,
  clinic,
  clinic: {
    id,
    picture,
  },
  onSelect,
  selected,
  onToggleClinicMiniprofile,
  selectedMiniprofileClinicId,
}) {
  const isClinicUser = clinic.replacementsCount != null;
  const isBlacklisted = clinic.userBlacklistedClinic || clinic.clinicBlacklistedUser;

  return (
    <div className="matchmaking__row">
      <div className="matchmaking__user">
        <div className="matchmaking__user__infos">
          <div
            className={classNames('matchmaking__clinic__relationship', {
              'matchmaking__clinic__relationship--is-clinic-user': isClinicUser && !isBlacklisted,
              'matchmaking__clinic__relationship--is-not-clinic-user': !isClinicUser && !isBlacklisted,
              'matchmaking__clinic__relationship--is-blacklisted': isBlacklisted,
            })}
          >
            {picture && picture.small ?
              <div
                className="matchmaking__clinic__image"
                style={{ backgroundImage: `url(${picture.small})` }}
              />
            :
              <div className="matchmaking__user__image matchmaking__clinic__image--empty" />
            }
          </div>

          <div className="matchmaking__user__name">
            <span>{clinic.name}</span>
          </div>
        </div>
        <div
          className={classNames('matchmaking__user__actions', {
            'matchmaking__user__actions--proposal': substituteSelected,
          })}
        >
          {selectedMiniprofileClinicId === id ? (
            <>
              <button
                className={classNames(
                  'matchmaking__user__action',
                  'matchmaking__user__action--hideProfile',
                )}
                onClick={() => onToggleClinicMiniprofile()}
              />
              <ClinicMiniProfile clinic={clinic} />
            </>
          ) : (
            <button
              className={classNames(
                'matchmaking__user__action',
                'matchmaking__user__action--showProfile',
              )}
              onClick={() => onToggleClinicMiniprofile(id)}
            />
            )
          }
          <button
            className={classNames('matchmaking__user__action', {
              'matchmaking__user__action--unselect': selected,
              'matchmaking__user__action--select': !selected,
            })}
            onClick={() => onSelect(clinic)}
          />
          {clinic.distance != null && (
            <ProximityTag
              distance={clinic.distance}
              travelTime={clinic.travelTime}
            />
          )}
        </div>
      </div>
      {[...Array(days).keys()].map((count) =>
        <DayEvents
          key={`${clinic.id} ${count}`}
          eventType="replacement"
          clinic={clinic}
          count={count}
          date={moment(date).add(count, 'days').toDate()}
          events={clinic.mandates[moment(date).add(count, 'days').format('DD-MM-YYYY')]}
          availabilities={availabilities[moment(date).add(count, 'days').format('DD-MM-YYYY')]}
        />,
      )}
    </div>
  );
}

ClinicRow.propTypes = {
  availabilities: PropTypes.object,
  clinic: PropTypes.object.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.object,
  ]).isRequired,
  days: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  onToggleClinicMiniprofile: PropTypes.func.isRequired,
  selectedMiniprofileClinicId: PropTypes.string,
  substituteSelected: PropTypes.bool,
};

ClinicRow.defaultProps = {
  availabilities: {},
  days: matchmakingDaysRange(),
  selectedMiniprofileClinicId: '',
  substituteSelected: false,
};

export default ClinicRow;
