// libs
import React from 'react';
import PropTypes from 'prop-types';

function Holiday(props) {
  const { title } = props;
  return (
    <div className="schedule__event--holiday">
      <span>{title}</span>
    </div>
  );
}

Holiday.propTypes = {
  title: PropTypes.string.isRequired,
};

Holiday.contextTypes = {
  t: PropTypes.func,
};

export default Holiday;
