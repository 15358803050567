export default {
  acceptedMandates: 'Remplacements acceptés',
  acceptedReplacements: 'Remplacement acceptés',
  accordion: 'Accordion',
  availabilities: 'Disponibilités saisies',
  calendar: 'Calendrier',
  clinics: 'Gestion des cliniques',
  faq: 'F.A.Q.',
  help: 'Aide',
  faqUrl: {
    on: {
      clinic_manager: 'https://www.vetlocum.com/faq-clinic',
      substitute: 'https://www.vetlocum.com/faq-locum'
    },
    qc: {
      clinic_manager: 'https://www.vetreseau.com/faq-clinique',
      substitute: 'https://www.vetreseau.com/faq-remplacant'
    }
  },
  helpcenter: 'Tutoriel',
  history: 'Historique',
  mandates: 'Demandes saisies',
  mandatesToConfirm: 'Remplacements confirmés / à confirmer',
  matchmakingClinics: 'Vue cliniques',
  matchmakingSubstitutes: 'Vue remplaçants',
  matchmakingTechnician: 'Techniciens en santé animale',
  matchmakingVeterinarian: 'Vétérinaires',
  proposedMandates: 'Remplacements à approuver',
  proposedReplacements: 'Remplacements à approuver',
  timesheets: 'Feuilles de temps',
  users: 'Gestion des utilisateurs',
};
