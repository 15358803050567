const validate = values => {
  const errors = {};

  if (values.substitutes) {
    const selectedSubstitutesCount = values.substitutes.filter(
      substitute => substitute.selected === true,
    );

    if (selectedSubstitutesCount.length === 0) {
      errors.substitutes = { _error: 'validation.required' };
    }
  }

  if (!values.message) {
    errors.message = 'validation.required';
  }

  return errors;
};

export default validate;
