// Libs
import 'react-dates/initialize';
import React, { Component } from 'react';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { changeHour } from '../../helpers/dates';
import { timeSelector } from '../../helpers/form';
import { scrollToError } from '../../helpers/scrollToError';

// Components
import DistanceRadio from '../forms/fields/general/DistanceRadio';
import Checkbox from '../forms/fields/checkbox/Checkbox';
import Textarea from '../forms/fields/textarea/Textarea';
import Select from '../forms/fields/select/Select';
import BasicLoader from '../loader/BasicLoader';

// Actions
import availActions from '../../actions/availabilities/index';
import alertsActions from '../../actions/alerts/index';

// Validation
import validate from './validate/validate';

class EditAvailability extends Component {
  static propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    isAdmin: PropTypes.string.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    user: PropTypes.object,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    error: '',
    user: null,
  };

  constructor() {
    super();
    this.triggerSubmit = this.triggerSubmit.bind(this);
  }

  componentDidMount() {
    const { availabilityToUpdateId, getAvailabilityToUpdate } = this.props;
    getAvailabilityToUpdate(
      {
        id: availabilityToUpdateId,
      },
    );
  }

  componentDidUpdate(prevProps) {
    const { availabilityToUpdateId: prevAvailabilityToUpdateId } = prevProps;
    const { availabilityToUpdateId, getAvailabilityToUpdate } = this.props;
    if (prevAvailabilityToUpdateId !== availabilityToUpdateId) {
      getAvailabilityToUpdate(
        {
          id: availabilityToUpdateId,
        },
      );
    }
  }

  createAvailability({ adminNote, ...values }) {
    const { availabilityToUpdate: { startsAt } } = this.props;
    const startHour = values.startsAt;
    const endHour = values.endsAt;
    const dayDate = startsAt;
    const startTime = changeHour(dayDate, startHour);
    const endTime = changeHour(dayDate, endHour);
    const distance = parseInt(values.distance, 10);
    return {
      allDay: values.allDay,
      consultation: values.consultation,
      distance,
      endsAt: endTime.toISOString(),
      message: values.moreInfo,
      startsAt: startTime.toISOString(),
      surgery: values.surgery,
      ...(adminNote !== null) && { adminNote },
    };
  }

  triggerSubmit() {
    const {
      availabilityToUpdateId,
      hidePanel,
      updateAvailability,
      pushAlert,
      formValues: values,
    } = this.props;
    const input = this.createAvailability(values);
    updateAvailability({
      id: availabilityToUpdateId,
      input,
    }).then((data) => {
      if (data && data.updateAvailability.success) {
        pushAlert({
          type: 'success',
          content: 'alerts.edited',
        });
        hidePanel();
      } else {
        pushAlert({
          type: 'error',
          content: 'alerts.errorOccurred',
        });
      }
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      hidePanel,
      availabilityToUpdate: { startsAt },
      isAllDay,
      isAdmin,
      error,
      user,
      formIsSubmitting,
    } = this.props;
    const { t } = this.context;

    const submitForm = handleSubmit(this.triggerSubmit);

    return (
      <div>
        <form onSubmit={submitForm} className="side-panel__form">
          <div>
            {formIsSubmitting && <BasicLoader />}
            {error && <div className="form-error">{error}</div>}
            {user && (
              <fieldset className="side-panel__form__section side-panel__form__section--profile">
                <div>
                  <div className="profile">
                    {user.profile.picture && user.profile.picture.small ?
                      <div
                        className="profile__image"
                        style={{ backgroundImage: `url(${user.profile.picture.small})` }}
                      />
                      :
                      <div className="profile__image profile__image--empty" />
                    }
                    <div className="profile__infos">
                      <span className="profile__infos__title">
                        {`${user.profile.firstName} ${user.profile.lastName}`}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            )}
            <fieldset className="side-panel__form__section">
              <div>
                <h2 className="side-panel__form__section__title">{t('form.addAvailability.availabilities')}*</h2>
                <div>
                  <div className="day-availability">
                    <div className="day-availability--date">
                      { moment(startsAt).format('ddd') }
                      <span>{ moment(startsAt).format('DD') }</span>
                    </div>
                    <div className="day-availability__schedule">
                      <Field
                        name="startsAt"
                        type="text"
                        component={Select}
                        label="addAvailability.startTime"
                        className="field--select"
                        disabled={isAllDay ? 'disabled' : ''}
                        innerLabel
                      >
                        { timeSelector() }
                      </Field>
                      <span className="day-availability__arrow" />
                      <Field
                        name="endsAt"
                        type="text"
                        component={Select}
                        label="addAvailability.endTime"
                        className="field--select"
                        disabled={isAllDay ? 'disabled' : ''}
                        innerLabel
                      >
                        { timeSelector() }
                      </Field>
                      {isAllDay &&
                        <div className="day-availability__tooltip">
                          <span>{t('form.addAvailability.allDayTooltip')}</span>
                        </div>
                      }
                    </div>
                    <Field
                      name="allDay"
                      type="text"
                      component={Checkbox}
                      className="day-availability__checkbox"
                      label="addAvailability.allDay"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="side-panel__form__section">
              <div>
                <h2 className="side-panel__form__section__title">{t('form.addAvailability.travel')}*</h2>
                <DistanceRadio />
              </div>
            </fieldset>
            <fieldset className="side-panel__form__section">
              <div>
                <h2 className="side-panel__form__section__title">{t('form.addAvailability.other')}</h2>
                <div className="fields-one-col">
                  <Field
                    name="consultation"
                    type="text"
                    label="addAvailability.consultation"
                    component={Checkbox}
                  />
                </div>
                <div className="fields-one-col">
                  <Field
                    name="surgery"
                    type="text"
                    label="addAvailability.surgery"
                    component={Checkbox}
                  />
                </div>
                <Field
                  name="moreInfo"
                  component={Textarea}
                  label="addAvailability.moreInfo"
                />
              </div>
            </fieldset>
            {isAdmin &&
            <fieldset className="side-panel__form__section side-panel__form__section--admin">
              <div>
                <h2 className="side-panel__form__section__title">{t('form.adminSection')}</h2>
                <div className="fields-one-col">
                  <Field
                    name="adminNote"
                    label="adminNoteGeneral"
                    component={Textarea}
                    className="field--light"
                  />
                </div>
              </div>
            </fieldset>
              }
          </div>
          <div className="form__actions">
            <button
              className="form__cancel"
              type="button"
              disabled={submitting || formIsSubmitting}
              onClick={() => hidePanel()}
            >
              {t('form.formActions.cancel')}
            </button>
            <button className="form__submit" type="submit" disabled={pristine || submitting || formIsSubmitting}>
              {t('form.formActions.update')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

EditAvailability.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  availabilityToUpdateId: PropTypes.string.isRequired,
  availabilityToUpdate: PropTypes.object.isRequired,
  getAvailabilityToUpdate: PropTypes.func.isRequired,
  updateAvailability: PropTypes.func.isRequired,
  pushAlert: PropTypes.func.isRequired,
  isAllDay: PropTypes.bool,
  formValues: PropTypes.object,
  formIsSubmitting: PropTypes.bool.isRequired,
};

EditAvailability.defaultProps = {
  isAllDay: false,
  formValues: {},
};

function mapStateToProps(state) {
  const { availabilities: { availabilityToUpdate } } = state;
  const initialStartsAt = moment(availabilityToUpdate.startsAt).format('HH:mm');
  const initialEndsAt = moment(availabilityToUpdate.endsAt).format('HH:mm');
  const formSelector = formValueSelector('editAvailabilities');

  return {
    initialValues: {
      adminNote: availabilityToUpdate.adminNote && availabilityToUpdate.adminNote.toString(),
      distance: availabilityToUpdate.distance && availabilityToUpdate.distance.toString(),
      consultation: availabilityToUpdate.consultation && availabilityToUpdate.consultation,
      surgery: availabilityToUpdate.surgery && availabilityToUpdate.surgery,
      moreInfo: availabilityToUpdate.message && availabilityToUpdate.message,
      startsAt: initialStartsAt,
      endsAt: initialEndsAt,
      allDay: availabilityToUpdate.allDay && availabilityToUpdate.allDay,
    },
    isAdmin: state.user.role === 'admin',
    lang: state.i18nState.lang,
    userId: state.user.data.id,
    eventsInPeriod: state.availabilities.eventsInPeriod,
    allEventsInPeriod: state.availabilities.allEventsInPeriod,
    periodIsUpdated: state.availabilities.periodIsUpdated,
    availabilityToUpdateId: state.availabilities.availabilityToUpdateId,
    isAllDay: formSelector(state, 'allDay'),
    availabilityToUpdate,
    formValues: getFormValues('editAvailabilities')(state),
    formIsSubmitting: state.availabilities.submitting,
  };
}

export default withRouter(
  connect(mapStateToProps, { ...availActions, ...alertsActions })(
    reduxForm({
      form: 'editAvailabilities',
      enableReinitialize: true,
      onSubmitFail: (errors) => scrollToError(errors),
      validate,
    })(EditAvailability)));
