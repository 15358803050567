import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// components
import { DragDrop, StatusBar } from '@uppy/react';

// Styles
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import './PictureUploader.scss';

// actions
import uploadActions from '../../../../../actions/uploads';
import PictureUploadPreview from './PictureUploadPreview';

class PictureUploader extends Component {
  static propTypes = {
    uploader: PropTypes.object.isRequired,
    uploadFileReset: PropTypes.func.isRequired,
    noLabel: PropTypes.bool,
    pictureSize: PropTypes.string,
    displayType: PropTypes.string,
    formContext: PropTypes.string,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    noLabel: false,
    pictureSize: 'small',
    displayType: 'image',
    formContext: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadIsActive: false,
    };
    this.toggleUpload = this.toggleUpload.bind(this);
  }

  componentWillUnmount() {
    const { uploader, uploadFileReset } = this.props;
    uploadFileReset();
    uploader.reset();
  }

  toggleUpload() {
    this.setState(({ uploadIsActive }) => ({
      uploadIsActive: !uploadIsActive,
    }));
  }

  render() {
    const { pictureSize, displayType, noLabel, formContext, ...field } = this.props;
    const { uploader, currentPicture, upload: { fileChanged, filename } } = field;
    const { t } = this.context;
    const { uploadIsActive } = this.state;
    const originalError = field.meta.error;
    const errorTranslation = t(originalError);
    const error = errorTranslation === originalError ? originalError : errorTranslation;
    const label = field.label ? field.label : field.input.name;
    const dropHereOrTranslation = formContext.length > 0 ?
      t(`form.formActions.uploader.dragDrop.${formContext}.drop`) : t('form.formActions.uploader.dragDrop.drop');

    const showError = field.meta.touched && field.meta.error && !field.meta.disabled;
    const dragDropLocales = {
      strings: {
        dropHereOr: t(dropHereOrTranslation),
        browse: t('form.formActions.uploader.dragDrop.browse'),
      },
    };

    const statusBarLocales = {
      strings: {
        uploading: t('form.formActions.uploader.statusBar.uploading'),
        complete: t('form.formActions.uploader.statusBar.complete'),
        uploadFailed: t('form.formActions.uploader.statusBar.uploadFailed'),
      },
    };


    return (
      <div className={
        classNames(
          'field',
          'picture-uploader',
          field.className, {
          'picture-uploader--changed': fileChanged,
        })}
      >
        {!noLabel &&
          <label className="field__label">
            {t(`form.${label}`)}{field.required && ' *'}
          </label>
        }
        <PictureUploadPreview
          picture={currentPicture}
          pictureSize={pictureSize}
          displayType={displayType}
          toggleUpload={this.toggleUpload}
          uploadIsActive={uploadIsActive}
        />
        {uploadIsActive && (
          <div className="picture-uploader__uppy">
            <DragDrop
              uppy={uploader}
              locale={dragDropLocales}
            />
            <StatusBar
              uppy={uploader}
              hideAfterFinish={false}
              hideRetryButton
              hidePauseResumeButton
              hideCancelButton
              locale={statusBarLocales}
            />
            {
              fileChanged &&
              <div>
                <span className="picture-uploader__filename">
                  <svg
                    aria-hidden="true"
                    className="uppy-StatusBar-statusIndicator UppyIcon"
                    width="15"
                    height="11"
                    viewBox="0 0 15 11"
                  >
                    <path d="M.414 5.843L1.627 4.63l3.472 3.472L13.202 0l1.212 1.213L5.1 10.528z" />
                  </svg>
                  {t('form.formActions.uploader.uploadedFile')}: <strong>{filename}</strong>.
                </span>
                <span className="picture-uploader__note">
                  {t('form.formActions.uploader.submitToUpdatePicture')}
                </span>
              </div>
            }
            {showError && <span className="field__error">{t(error)}</span>}
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, { ...uploadActions })(PictureUploader);
