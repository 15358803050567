import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import classNames from 'classnames';

// components
import CallToAllsSubForm from './CallToAllsSubForm';
import CardTitle from './../CardTitle';

// helpers
import { formatCallToAllReviewInput } from '../../../../../../helpers/review';

// actions
import actions from '../../../../../../actions/callToAll';
import alertsActions from '../../../../../../actions/alerts';

class CallToAllReviewForm extends Component {
  constructor(props) {
    super(props);
    this.submitFormHandler = this.submitFormHandler.bind(this);
    this.submitReviews = this.submitReviews.bind(this);
  }

  submitReviews(values) {
    const {
      fetchUserCallToAlls,
      pushAlert,
      role,
      userId,
      userReviewCallToAlls,
    } = this.props;

    const reviewParams = {
      substitute: {
        input: formatCallToAllReviewInput(
          values.replacements,
          'userAccepted',
        ),
      },
    };

    const reviewFromRole = {
      substitute: () => userReviewCallToAlls(reviewParams.substitute)
        .then(
          (data) => {
            if (data && data.userReviewCallToAlls.errors.length === 0) {
              pushAlert({ type: 'success', content: 'alerts.callToAllFormSubmitted' });
              fetchUserCallToAlls(userId);
            }
          },
        ),
    };

    reviewFromRole[role]();
  }

  submitFormHandler(values) {
    this.submitReviews(values);
  }

  render() {
    const {
      change,
      error,
      handleSubmit,
      invalid,
      mandate,
      pristine,
      role,
    } = this.props;

    const replacements = mandate.replacements || [];

    const submitForm = handleSubmit(this.submitFormHandler);
    const { t } = this.context;

    return (
      <div className="mandate-card__form">
        <form onSubmit={submitForm}>
          <CardTitle
            acceptedProposals={false}
            allMandates={false}
            error={error}
            mandate={mandate}
            validForm={invalid || pristine}
            role={role}
          />

          <FieldArray
            name="replacements"
            replacements={replacements}
            component={CallToAllsSubForm}
            role={role}
            change={change}
          />

          <div className="mandate-card__form__footer">
            <button
              className={classNames(
                'mandate-card__submit',
                { 'mandate-card__submit--disabled': invalid || pristine },
              )}
              type="submit"
            >
              { t('form.formActions.submit') }
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state, { mandate }) {
  const { user: { role } } = state;
  const replacements = mandate.replacements || [];

  return {
    role,
    userId: state.user.data.id,
    form: `mandateCallToAlls-${mandate.id}`,
    initialValues: {
      replacements,
    },
  };
}

CallToAllReviewForm.propTypes = {
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  fetchUserCallToAlls: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  mandate: PropTypes.object.isRequired,
  pushAlert: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userReviewCallToAlls: PropTypes.func.isRequired,
};
CallToAllReviewForm.defaultProps = {
  error: null,
};
CallToAllReviewForm.contextTypes = {
  t: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { ...actions, ...alertsActions },
)(reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
})(CallToAllReviewForm));
