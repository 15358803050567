import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import '../Fields.scss';
import './Checkbox.scss';

function CheckboxField(field, { t }) {
  const originalError = field.meta.error;
  const errorTranslation = t(originalError);
  const error = errorTranslation === originalError ? originalError : errorTranslation;

  const showError = field.meta.touched && field.meta.error && !field.meta.disabled;

  return (
    <div className={classNames('field', field.className)}>
      <label>
        <input
          name={field.name}
          {...field.input}
          type="checkbox"
          value={field.value}
          disabled={field.disabled}
          className="field__checkbox"
        />
        <span className="field__checkbox__label"><span>{field.label ? t(`form.${field.label}`) : t(`form.${field.name}`)}</span></span>
      </label>
      {showError && <span className="field__error">{t(error)}</span>}
    </div>
  );
}

CheckboxField.contextTypes = {
  t: PropTypes.func,
};

export default CheckboxField;
