import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function EntryDate({ date }) {
  return (
    <div className="list-item__fulldate">
      <div className="list-item__date">
        {moment(date).format('ddd')}
        <span className="date">{moment(date).format('DD')}</span>
        <span className="month">{moment(date).format('MMM')}</span>
      </div>
      <div className="list-item__month">
        {moment(date).format('MMMM YYYY')}
      </div>
    </div>
  );
}

EntryDate.propTypes = {
  date: PropTypes.string.isRequired,
};
EntryDate.defaultProps = {};
EntryDate.contextTypes = {
  t: PropTypes.func,
};

export default EntryDate;
