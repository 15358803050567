import React from 'react';
import PropTypes from 'prop-types';

function EditGroup({ discarded, edit, enable, disable, canDisable }, { t }) {
  return (
    <div className="form__actions">
      { canDisable &&
        (discarded ? (
          <button
            onClick={enable}
            className="content-list__content-item__button content-list__content-item__button--enable"
          >
            {t('event.actions.enable')}
          </button>
        ) : (
          <button
            onClick={disable}
            className="content-list__content-item__button content-list__content-item__button--disable"
          >
            {t('event.actions.disable')}
          </button>
      ))}
      <button
        onClick={edit}
        className="content-list__content-item__button content-list__content-item__button--edit"
      >
        {t('event.actions.edit')}
      </button>
    </div>
  );
}

EditGroup.propTypes = {
  disable: PropTypes.func.isRequired,
  discarded: PropTypes.bool.isRequired,
  edit: PropTypes.func.isRequired,
  enable: PropTypes.func.isRequired,
  canDisable: PropTypes.bool,
};
EditGroup.defaultProps = {
  canDisable: true,
};
EditGroup.contextTypes = {
  t: PropTypes.func,
};

export default EditGroup;
