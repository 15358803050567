import moment from 'moment';
import types from '../actions/schedule/types';
import { getCurrentYearHolidays } from '../helpers/holidays';

// helpers
import { matchmakingRangeEndDate } from '../helpers/range';

const initialState = {
  date: new Date(),
  modal: false,
  from: moment().subtract(7, 'days'),
  to: matchmakingRangeEndDate(moment()),
  holidays: getCurrentYearHolidays(new Date(), localStorage.getItem('Lang')),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_DATE:
      return {
        ...state,
        date: action.newDate,
        from: moment(action.newDate).subtract(7, 'days'),
        to: matchmakingRangeEndDate(moment(action.newDate)),
      };
    case types.CLOSE_MODAL:
      return { ...state, modal: false };
    case types.UPDATE_HOLIDAYS:
      return {
        ...state,
        holidays: getCurrentYearHolidays(action.year, localStorage.getItem('Lang')),
      };
    default:
  }
  return state;
}
