// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { formatHours } from '../../helpers/formatDates';

// Components
import ListItemDetails from './ListItemDetails';
import EditAvailability from '../availabilities/EditAvailability';

// Styles
import './styles.scss';

// actions
import actionsAvailabilities from '../../actions/availabilities/index';
import alertsActions from '../../actions/alerts/index';
import dynamicSidePanelActions from '../../actions/dynamicSidePanel/index';

class ListItem extends Component {
  constructor() {
    super();
    this.state = {
      showDetails: false,
      availabilityToUpdate: null,
      availabilityToDelete: null,
    };
    this.toggleDetails = this.toggleDetails.bind(this);
    this.updateAvailability = this.updateAvailability.bind(this);
    this.disableAvailability = this.disableAvailability.bind(this);
    this.setAvailabilityToUpdate = this.setAvailabilityToUpdate.bind(this);
    this.setAvailabilityToDelete = this.setAvailabilityToDelete.bind(this);
  }

  componentDidUpdate() {
    const { updateSchedule, fetchUpdatedAvailability, removeAvailabilityFromList } = this.props;
    const { availabilityToUpdate, availabilityToDelete } = this.state;

    if (updateSchedule && availabilityToUpdate !== null) {
      fetchUpdatedAvailability({ id: availabilityToUpdate });
      this.setAvailabilityToUpdate(null);
    }

    if (updateSchedule && availabilityToDelete !== null) {
      removeAvailabilityFromList({ id: availabilityToDelete });
      this.setAvailabilityToDelete(null);
    }
  }

  setAvailabilityToDelete(id) {
    this.setState({ availabilityToDelete: id });
  }

  setAvailabilityToUpdate(id) {
    this.setState({ availabilityToUpdate: id });
  }

  toggleDetails() {
    this.setState(({ showDetails }) => ({
      showDetails: !showDetails,
    }));
  }

  updateAvailability() {
    const {
      setAvailabilityToUpdate,
      updateComponent,
      availability: { id },
    } = this.props;
    setAvailabilityToUpdate(id);
    updateComponent(EditAvailability, 'editAvailability', { sidebarIcon: 'edit' });
    this.setAvailabilityToUpdate(id);
  }

  disableAvailability() {
    const { disableAvailability, availability: { id }, pushAlert } = this.props;
    disableAvailability({ id });
    pushAlert({
      type: 'success',
      content: 'alerts.deleted',
    });
    this.setAvailabilityToDelete(id);
  }

  render() {
    const {
      actionToConfirm,
      availability,
      availability: {
        startsAt,
        endsAt,
        consultation,
        surgery,
        distance,
        allDay,
        message,
      },
    } = this.props;
    const { showDetails } = this.state;
    const { t } = this.context;
    return (
      <li
        className={classNames('list-item', {
          'list-item--open': showDetails,
          'list-item--clickable': message,
        })}
      >
        <div className="list-item__header">
          <div
            className="list-item__infos"
            tabIndex="0"
            role="button"
            onClick={this.toggleDetails}
            onKeyPress={this.toggleDetails}
          >
            <div className="list-item__date">
              { moment(startsAt).format('ddd') }
              <span className="date">{moment(startsAt).format('DD')}</span>
              <span className="month">{moment(startsAt).format('MMM')}</span>
            </div>
            <div className="list-item__month">
              { moment(startsAt).format('MMMM') } { moment(startsAt).format('YYYY') }
            </div>
            { allDay ?
              <div className="list-item__hours">
                {t('event.allDay')}
              </div>
            :
              <div className="list-item__hours">
                {formatHours(startsAt)} <span className="arrow" /> {formatHours(endsAt)}
              </div>
            }
            <div className="list-item__type ">
              <span className="type__text">
                { consultation && <span>{ t('event.consultation') }</span> }
                { surgery && <span>{ t('event.surgery') }</span> }
              </span>
              <span title={t('event.consultation')} className={`type__icon type__icon--consult ${consultation ? ' type__icon--active' : ''}`} />
              <span title={t('event.surgery')} className={`type__icon type__icon--surgery ${surgery ? ' type__icon--active' : ''}`} />
              <span title={t('event.additionnalInfo')} className={`type__icon type__icon--message ${message ? ' type__icon--active' : ''}`} />
            </div>
            <div className="list-item__distance">
              { distance > 150 ?
                <span className="distance__text">{ t('availabilities.moreThan') }<strong>150 km</strong></span>
              :
                <span className="distance__text">{ t('availabilities.lessThan') }<strong>{ distance } km</strong></span>
              }
              <span className="distance__tag">{ t(`availabilities.distancesTag.${distance}`) }</span>
            </div>
          </div>
          <div className="list-item__actions">
            <button
              title={t('event.actions.edit')}
              className="list-item__action list-item__action--edit"
              tabIndex="0"
              onClick={this.updateAvailability}
              onKeyPress={this.updateAvailability}
            />
            <button
              title={t('event.actions.delete')}
              className="list-item__action list-item__action--delete"
              tabIndex="0"
              onClick={() => actionToConfirm(this.disableAvailability, 'availability.delete')}
              onKeyPress={() => actionToConfirm(this.disableAvailability, 'availability.delete')}
            />
          </div>
        </div>
        {(window.innerWidth <= 1120 || message) && showDetails &&
          <ListItemDetails
            availability={availability}
            toggleDetails={this.toggleDetails}
            updateAvailability={this.updateAvailability}
            disableAvailability={() => actionToConfirm(this.disableAvailability, 'availability.delete')}
          />
        }
      </li>
    );
  }
}

function mapStateToProps(state) {
  return {
    userId: state.user.data.id,
    updateSchedule: state.availabilities.updateSchedule,
  };
}

ListItem.propTypes = {
  actionToConfirm: PropTypes.func.isRequired,
  availability: PropTypes.object.isRequired,
  disableAvailability: PropTypes.func.isRequired,
  fetchUpdatedAvailability: PropTypes.func.isRequired,
  pushAlert: PropTypes.func.isRequired,
  removeAvailabilityFromList: PropTypes.func.isRequired,
  setAvailabilityToUpdate: PropTypes.func.isRequired,
  updateComponent: PropTypes.func.isRequired,
  updateSchedule: PropTypes.bool.isRequired,
};

ListItem.defaultProps = {};

ListItem.contextTypes = {
  t: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { ...actionsAvailabilities, ...alertsActions, ...dynamicSidePanelActions },
)(ListItem);
