import React from 'react';
import PropTypes from 'prop-types';
import { formatHours } from '../../../../../../../helpers/formatDates';

function Time({ endsAt, startsAt }) {
  return (
    <span className="list-item__time">
      {formatHours(startsAt)} <span className="arrow" /> {formatHours(endsAt)}
    </span>
  );
}

Time.propTypes = {
  endsAt: PropTypes.string.isRequired,
  startsAt: PropTypes.string.isRequired,
};
Time.defaultProps = {};

export default Time;
