export default function formatExoticAnimals(exoticAnimalsInput) {
  return exoticAnimalsInput.map((exoticAnimalInput) => {
    const { checked, id, exoticAnimalId } = exoticAnimalInput;
    return {
      exoticAnimalId,
      ...(id !== null) && { id },
      ...(!checked) && { destroy: true },
    };
  });
}
