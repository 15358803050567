import { asyncQuery, asyncMutation } from '../../../config/graphql/async-action-creator';
import queries from './queries';
import types from './types';
import store from '../../../config/redux/store';
import client from '../../../config/graphql/client';

export default {
  fetchAvailabilities: (variables) => asyncQuery(
    store,
    types.FETCH_AVAILABILITIES,
    queries.fetchAvailabilities,
    variables,
    client,
  ),
  createAvailabilities: (variables) => asyncMutation(
    store,
    types.CREATE_AVAILABILITIES,
    queries.createAvailabilities,
    variables,
    client,
  ),
  fetchAvailabilitiesInPeriod: (variables) => asyncQuery(
    store,
    types.FETCH_AVAILABILITIES_IN_PERIOD,
    queries.fetchAvailabilities,
    variables,
    client,
  ),
  fetchAllAvailabilities: (variables) => asyncQuery(
    store,
    types.FETCH_ALL_AVAILABILITIES,
    queries.fetchAvailabilities,
    variables,
    client,
  ),
  periodDidUpdate: () => ({
    type: types.PERIOD_IS_UPDATED,
  }),
  disableAvailability: (variables) => asyncMutation(
    store,
    types.DELETE_AVAILABILITY,
    queries.disableAvailability,
    variables,
    client,
  ),
  disableAvailabilityProposal: (variables) => asyncMutation(
    store,
    types.DISABLE_AVAILABILITY_PROPOSAL,
    queries.disableProposal,
    variables,
    client,
  ),
  setAvailabilityToUpdate: (availabilityId) => ({
    type: types.SET_AVAILABILITY_TO_UPDATE,
    availabilityId,
  }),
  setUser: (userId) => ({
    type: types.SET_USER,
    userId,
  }),
  getAvailabilityToUpdate: (variables) => asyncQuery(
    store,
    types.FETCH_AVAILABILITY_TO_UPDATE,
    queries.fetchAvailability,
    variables,
    client,
  ),
  updateAvailability: (variables) => asyncMutation(
    store,
    types.UPDATE_AVAILABILITY,
    queries.updateAvailability,
    variables,
    client,
  ),
  fetchAvailabilitiesForList: (variables = {}) => asyncQuery(
    store,
    types.FETCH_AVAILABILITIES_FOR_LIST,
    queries.fetchAvailabilities,
    { withoutProposals: true, ...variables },
    client,
  ),
  updateAvailabilitiesForList: (variables = {}) => asyncQuery(
    store,
    types.UPDATE_AVAILABILITIES_FOR_LIST,
    queries.fetchAvailabilities,
    { withoutProposals: true, ...variables },
    client,
  ),
  setAvailabilityListLength: (length) => ({
    type: types.SET_AVAILABILITY_LIST_LENGTH,
    length,
  }),
  fetchUpdatedAvailability: (variables) => asyncQuery(
    store,
    types.FETCH_UPDATED_AVAILABILITY,
    queries.fetchAvailability,
    variables,
    client,
  ),
  removeAvailabilityFromList: (availabilityId) => ({
    type: types.REMOVE_AVAILABILITY_FROM_LIST,
    availabilityId,
  }),
  updateAvailabilitiesListDate: (currentDate) => ({
    type: types.UPDATE_AVAILABILITIES_LIST_DATE,
    currentDate,
  }),
  updateAvailabilitiesListPager: (currentPage) => ({
    type: types.UPDATE_AVAILABILITIES_LIST_PAGER,
    currentPage,
  }),
  availabilitiesListIsUpdated: () => ({
    type: types.AVAILABILITIIES_LIST_IS_UPDATED,
  }),
  setDefaultMonthForAvailabilities: (month) => ({
    type: types.SET_DEFAULT_MONTH_FOR_AVAILABILITIES,
    defaultMonthForAvailabilities: month,
  }),
};
