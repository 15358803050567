import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// components
import NotificationsList from './NotificationsList';

// actions
import actions from '../../actions/notifications';

// styles
import './styles.scss';

class Notifications extends Component {
  static propTypes = {
    fetchNotifications: PropTypes.func.isRequired,
    notifications: PropTypes.object.isRequired,
    updateNotifications: PropTypes.func.isRequired,
    updateNotificationStatus: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
  };
  static contextTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.markNotificationAsRead = this.markNotificationAsRead.bind(this);
  }

  componentDidMount() {
    const { fetchNotifications, userId } = this.props;
    fetchNotifications({ userId });
  }

  markNotificationAsRead(notification) {
    const { updateNotificationStatus } = this.props;
    updateNotificationStatus({
      input: { notifications: [{ id: notification.id, status: 'read' }] },
    });
  }

  nextPage(endCursor, status) {
    const { updateNotifications, userId } = this.props;
    updateNotifications({
      after: endCursor,
      userId,
      status,
    });
  }

  render() {
    const { notifications } = this.props;
    const { t } = this.context;
    const notificationStatuses = ['unread', 'read'];
    return (
      <div>
        {notificationStatuses.map(status => (
          (notifications[status].notifications.length > 0 || notifications[status].hasNextPage) && (
          <div className="notification__wrapper" key={status}>
            <h2 className={classNames('notification__title', { 'notification__title--read': status === 'read' })}>
              {t(`pages.notifications.${status}`)}
            </h2>
            <div className="notification__list">
              <NotificationsList
                notifications={notifications}
                status={status}
                markNotificationAsRead={this.markNotificationAsRead}
              />
            </div>
            {notifications[status].hasNextPage && (
            <div className="form__actions notification__actions">
              <button
                className="form__submit"
                type="button"
                onClick={() => this.nextPage(notifications[status].endCursor, status)}
                disabled={notifications[status].loading}
              >
                {t('form.formActions.loadMore')}
              </button>
            </div>
                )}
          </div>
          )
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
    updateReadNotifications: state.notifications.updateReadNotifications,
    userId: state.user.data.id,
  };
}

export default connect(mapStateToProps, actions)(Notifications);
