import types from '../actions/admin/clinics/types';

const initialState = {
  clinic: {},
  loading: false,
  pageInfo: {},
  suggested: [],
  results: [],
  submitting: false,
  clinicPicture: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CREATE_CLINIC:
      return { ...state, submitting: true };
    case types.CREATE_CLINIC_SUCCESS:
      return { ...state, submitting: false };
    case types.CREATE_CLINIC_FAIL:
      return { ...state, submitting: false };
    case types.FETCH_CLINICS:
      return { ...state, loading: true, clinic: {} };
    case types.FETCH_CLINICS_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload.clinics.nodes,
        pageInfo: action.payload.clinics.pageInfo,
      };
    case types.FETCH_NEXT_CLINICS_SUCCESS:
      return {
        ...state,
        loading: false,
        results: [...state.results, ...action.payload.clinics.nodes],
        pageInfo: action.payload.clinics.pageInfo,
      };
    case types.FETCH_SUGGESTED_CLINICS_SUCCESS:
      return {
        ...state,
        suggested: action.payload.clinics.nodes,
      };
    case types.DISABLE_CLINIC_SUCCESS:
      return {
        ...state,
        loading: false,
        results: state.results.map(result => {
          const { payload: { disableClinic: { clinic } } } = action;

          if (clinic.id === result.id) {
            return { ...result, ...clinic };
          }
          return result;
        }),
      };
    case types.ENABLE_CLINIC_SUCCESS:
      return {
        ...state,
        loading: false,
        results: state.results.map(result => {
          const { payload: { enableClinic: { clinic } } } = action;

          if (clinic.id === result.id) {
            return { ...result, ...clinic };
          }
          return result;
        }),
      };
    case types.FETCH_CLINIC:
      return {
        ...state,
        clinic: {},
        clinicPicture: {},
        loading: true,
      };
    case types.FETCH_CLINIC_SUCCESS: {
      const clinicPicture = action.payload.clinic.picture;
      return {
        ...state,
        clinic: action.payload.clinic,
        clinicPicture: clinicPicture !== null ? clinicPicture : {},
        loading: false,
      };
    }
    case types.FETCH_CLINIC_FAIL:
      return {
        ...state,
        clinic: {},
        clinicPicture: {},
        loading: false,
      };
    default:
  }
  return state;
}
