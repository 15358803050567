import gql from 'graphql-tag';

export function asyncSubscription(
  store,
  type,
  query,
  client,
) {
  return (dispatch) => {
    dispatch({ type });

    const locale = { locale: store.getState().i18nState.lang };

    return client.subscribe({
      query: gql(query),
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: { ...locale },
    }).subscribe((data) => dispatch({ type: `${type}_INCOMING`, payload: data }));
  };
}

export default asyncSubscription;
