import React from 'react';
import PropTypes from 'prop-types';

// components
import { NavLink } from 'o2web-react-core';

// styles
import './styles.scss';

function AdminMenu({ hidePanel }, { t }) {
  return (
    <nav className="side-panel__nav">
      <ul className="side-panel__nav__list">
        <li><NavLink to="/en/admin/clinics" onClick={hidePanel}>{t('nav.clinics')}</NavLink></li>
        <li><NavLink to="/en/admin/users" onClick={hidePanel}>{t('nav.users')}</NavLink></li>
      </ul>
    </nav>
  );
}

AdminMenu.propTypes = {
  hidePanel: PropTypes.func.isRequired,
};
AdminMenu.defaultProps = {};
AdminMenu.contextTypes = {
  t: PropTypes.func,
};

export default AdminMenu;
