// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { formatHours } from '../../../helpers/formatDates';
import { getSubstituteName } from '../../../helpers/formatUsers';

// component
import ReplacementDetails from './ReplacementDetails';

// actions
import mandatesActions from '../../../actions/mandates/index';
import alertsActions from '../../../actions/alerts/index';

// css
import './styles.scss';

class Replacement extends Component {
  constructor() {
    super();
    this.toggleDetails = this.toggleDetails.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.enableEvent = this.enableEvent.bind(this);
    this.wrapperRef = React.createRef();
    this.state = {
      showDetails: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  deleteEvent() {
    const { disableReplacement, event: { id }, pushAlert } = this.props;

    disableReplacement({ id }).then((data) => {
      if (data && data.disableReplacement.success) {
        pushAlert({
          type: 'success',
          content: 'alerts.disabled',
        });
      } else {
        pushAlert({
          type: 'error',
          content: data.disableReplacement.errors[0].message,
        });
      }
    });

    this.toggleDetails();
  }

  enableEvent() {
    const { enableReplacement, event: { id }, pushAlert } = this.props;

    enableReplacement({ id }).then((data) => {
      if (data && data.enableReplacement.success) {
        pushAlert({
          type: 'success',
          content: 'alerts.enabled',
        });
      } else {
        pushAlert({
          type: 'error',
          content: data.enableReplacement.errors[0].message,
        });
      }
    });

    this.toggleDetails();
  }

  handleClickOutside(event) {
    const { showDetails } = this.state;
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (showDetails) {
        this.setState({
          showDetails: false,
        });
      }
    }
  }

  toggleDetails() {
    this.setState(({ showDetails }) => ({
      showDetails: !showDetails,
    }));
  }

  render() {
    const {
      event: {
        index,
        start,
        end,
        type,
        firstItem,
        lastItem,
        flexibleTime,
        priority,
        wantedCount,
        totalCount,
        discarded,
        isSingleDay,
        latestProposal,
      },
    } = this.props;
    const { t } = this.context;
    const status = latestProposal ? latestProposal.status : '';
    const statusClass = latestProposal ? `schedule__event--${status}` : '';
    const daysTitle = wantedCount === 1 ? `${wantedCount} ${t('event.daysOutOf.singular')} ${totalCount}` :
      `${wantedCount} ${t('event.daysOutOf.plural')} ${totalCount}`;
    const pendingTitle = (['pending_clinic', 'pending_admin', 'confirmed'].includes(status) && latestProposal.substitute) ?
      getSubstituteName(latestProposal.substitute.profile) : t('event.isPending');
    const title = ['pending', 'pending_clinic', 'pending_admin', 'confirmed'].includes(status) ?
      pendingTitle : daysTitle;
    const { showDetails } = this.state;
    const eventClass = classNames(
      `schedule__event schedule__event--${type}`,
      {
        'schedule__event--first': firstItem,
        'schedule__event--last': lastItem,
        'schedule__event--middle': !isSingleDay && !lastItem && !firstItem,
        'schedule__event--disabled': discarded,
        'schedule__event--toggled': showDetails,
      },
      statusClass,
    );
    const distanceFromTop = `${(index * 3.75) + 3.375}rem`;
    const styles = {
      top: distanceFromTop,
    };
    const isNewSubstitute = latestProposal && latestProposal.isNewSubstitute;

    return (
      <div>
        <span className="schedule__event__expander" />
        <div
          className={eventClass}
          ref={this.wrapperRef}
          style={styles}
        >
          <div
            tabIndex="0"
            role="button"
            onClick={this.toggleDetails}
            onKeyPress={this.toggleDetails}
            className="schedule__event__toggle"
          >
            <div className="schedule__event__pictos">
              <span
                className={classNames('schedule__event__picto schedule__event__picto--flex', {
                'schedule__event__picto--active': flexibleTime,
                })}
              />
              <span
                className={classNames('schedule__event__picto schedule__event__picto--priority', {
                  'schedule__event__picto--active': priority,
                })}
              />
            </div>
            <span className="schedule__event__title">{ !isNewSubstitute ? title : t('event.isPending')}</span>
            <div>
              <span className="schedule__event__from">{formatHours(start)}</span>
              <span className="schedule__event__to">{formatHours(end)}</span>
            </div>
          </div>
          { showDetails &&
          <ReplacementDetails
            {...this.props}
            toggleDetails={this.toggleDetails}
            deleteEvent={this.deleteEvent}
            enableEvent={this.enableEvent}
            wrapperWidth={this.wrapperRef.current.offsetWidth}
          />
          }
        </div>
      </div>
    );
  }
}

Replacement.propTypes = {
  disableReplacement: PropTypes.func,
  enableReplacement: PropTypes.func,
  pushAlert: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
};

Replacement.contextTypes = {
  t: PropTypes.func,
};

Replacement.defaultProps = {
  disableReplacement: () => {},
  enableReplacement: () => {},
};

function mapStateToProps() {
  return {
  };
}


export default connect(
  mapStateToProps,
  { ...mandatesActions, ...alertsActions },
)(Replacement);
