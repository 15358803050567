// Libs
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, translateRoute } from 'o2web-react-core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import validateSignIn from '../validate/validateSignIn';

// Actions
import actions from '../../../actions/user';
import notificationsActions from '../../../actions/notifications';

// Components
import logo from '../../../../assets/images/vetreseau-white.svg';
import TextLogo from '../../layouts/nav/TextLogo';
import Input from '../../forms/fields/input/Input';
import Checkbox from '../../forms/fields/checkbox/Checkbox';
import BasicLoader from '../../loader/BasicLoader';

// Styles
import './styles.scss';

// Login Form
class SignInForm extends Component {
  static propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    signIn: PropTypes.func.isRequired,
    subscribeToNotifications: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    error: '',
  };

  constructor() {
    super();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  redirectToAccountActivation() {
    const { history } = this.props;
    const { t } = this.context;

    history.push(translateRoute('/en/activation', t));
  }

  handleFormSubmit(values) {
    const { signIn, subscribeToNotifications } = this.props;
    signIn(values).then((data) => {
      if (data && data.signIn.user) {
        if (values.rememberMe) {
          localStorage.setItem('RememberMe', data.signIn.user.email);
        } else {
          localStorage.removeItem('RememberMe');
        }
        subscribeToNotifications(localStorage.getItem('Authorization'));
      }
    });
  }
  render() {
    const { handleSubmit, pristine, submitting, error, isLoading } = this.props;
    const showModal = process.env.REACT_APP_REGION_CODE === 'qc';
    const submitForm = handleSubmit(this.handleFormSubmit);
    const { t } = this.context;
    return (
      <section className={classNames('login-page', {
        'login-page--modal': showModal,
      })}
      >
        {showModal &&
          <div className="modal__container">
            <p>
              <b>{`${t('pages.login.modal.title')} `}
                {t(`metas.site.${process.env.REACT_APP_REGION_CODE}`)}
              </b>!
            </p>
            <div className="modal__box modal__box--grey">
              <p>{ `${t('pages.login.modal.contact')} ${process.env.REACT_APP_REGION_PHONE}` }
                { t('pages.login.modal.orWebsite') }
              </p>
              <p><a href={t('pages.login.modal.website')} target="_blank" rel="noopener noreferrer">{t('pages.login.modal.website')}</a></p>
            </div>
          </div>
        }

        <form onSubmit={submitForm} className="form">
          <fieldset>
            <div className="login-page__logos">
              <span>
                <img src={logo} alt={t(`metas.site.${process.env.REACT_APP_REGION_CODE}`)} className="login-page__logos__logo" />
              </span>
              <TextLogo logoClass="login-page__logos__title" />
            </div>
            {error && <div className="form-error">{error}</div>}
            { isLoading && <BasicLoader /> }
            <Field
              name="email"
              component={Input}
              type="email"
              className="field__with-icon field__with-icon--email"
              innerLabel
              required
            />
            <Field
              name="password"
              component={Input}
              type="password"
              className="field__with-icon field__with-icon--lock"
              innerLabel
              required
            />
            <Checkbox
              name="rememberMe"
              component="input"
              type="checkbox"
              className="field--remember-me"
            />
            <div className="form__actions">
              <button className="form__submit" type="submit" disabled={pristine || submitting}>
                {t('pages.login.submit')} <span className="form__submit__arrow" />
              </button>
            </div>
            <div className="form__forgot-password-link log-nav">
              <NavLink to="/en/forgotPassword">
                {t('pages.login.forgotPassword')}
              </NavLink>
            </div>
          </fieldset>
        </form>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const rememberMe = localStorage.getItem('RememberMe');
  return {
    initialValues: {
      rememberMe: !!rememberMe,
      email: rememberMe,
    },
    isLoading: state.user.loading,
  };
}

export default withRouter(connect(
  mapStateToProps, { ...actions, ...notificationsActions },
)(reduxForm({
  form: 'signIn',
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate: validateSignIn,
})(SignInForm)));
