export function containsMinCharacters(password) {
  return password.length >= 8;
}

export function containsRequiredCharacters(password) {
  return /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]+)$/.test(password);
}

export function containsUpperLowerLetters(password) {
  return /^(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9@$!%*?&]+)$/.test(password);
}

export default containsMinCharacters;

export function validPasswordCombinations(passwords) {
  const passwordCombinations = passwords.map((password) => {
    const hasMinCharacters = containsMinCharacters(password);
    const hasRequiredCharacters = containsRequiredCharacters(password);
    const hasLowerAndUppercase = containsUpperLowerLetters(password);

    return hasMinCharacters && hasRequiredCharacters && hasLowerAndUppercase;
  });

  return passwords.every((val, i, arr) => val === arr[0]) &&
    passwordCombinations.every((val) => val === true);
}
