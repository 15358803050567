// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

// components
import { NavLink } from 'o2web-react-core';
import AccountTypes from '../../matchmaking/toolbar/AccountTypes';
import TextLogo from './TextLogo';

// Assets
import logo from '../../../../assets/images/vetreseau-white.svg';
import actions from '../../../actions/admin/users';

// Styles
import './styles.scss';

const contextTypes = {
  t: PropTypes.func,
};

const propTypes = {
  navIsActive: PropTypes.bool.isRequired,
  closeNav: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

class Nav extends Component {
  constructor() {
    super();
    this.navRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { navIsActive } = this.props;
    const { navIsActive: prevNavIsActive } = prevProps;
    if (navIsActive !== prevNavIsActive) {
      if (navIsActive) {
        document.addEventListener('mousedown', this.handleClickOutside);
      } else {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }
    }
  }

  handleClickOutside(event) {
    const { closeNav } = this.props;
    if (this.navRef && !this.navRef.current.contains(event.target)) {
      closeNav();
    }
  }

  render() {
    const { navIsActive, closeNav, userRole } = this.props;
    const { t } = this.context;
    const linksByRole = {
      substitute: {
        main: {
          calendar: <NavLink to="/en" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--calendar" />{t('nav.calendar')}</NavLink>,
        },
        primary: {
          availabilities: <NavLink to="/en/availabilities" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--assignment" />{t('nav.availabilities')}</NavLink>,
          proposedMandates: <NavLink to="/en/proposed-mandates" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--schedule" />{t('nav.proposedMandates')}</NavLink>,
          acceptedMandates: <NavLink to="/en/accepted-mandates" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--circle-check" />{t('nav.acceptedMandates')}</NavLink>,
        },
        secondary: {
          timesheet: <NavLink to="/en/timesheets" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--timesheet" />{t('nav.timesheets')}</NavLink>,
        },
      },
      clinic_manager: {
        main: {
          calendar: <NavLink to="/en" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--calendar" />{t('nav.calendar')}</NavLink>,
        },
        primary: {
          mandates: <NavLink to="/en/mandates" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--assignment" />{t('nav.mandates')}</NavLink>,
          proposedReplacements: <NavLink to="/en/proposed-mandates" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--schedule" />{t('nav.proposedReplacements')}</NavLink>,
          acceptedReplacements: <NavLink to="/en/accepted-mandates" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--circle-check" />{t('nav.acceptedReplacements')}</NavLink>,
        },
        secondary: {},
      },
      admin: {
        main: {
          accountTypes: <AccountTypes />,
        },
        primary: {
          matchmakingSubstitutes: <NavLink to="/en/matchmaking/substitutes" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--matchmaking-substitute" />{t('nav.matchmakingSubstitutes')}</NavLink>,
          matchmakingClinics: <NavLink to="/en/matchmaking/clinics" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--matchmaking-clinic" />{t('nav.matchmakingClinics')}</NavLink>,
          proposedMandates: <NavLink to="/en/proposed-mandates" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--schedule" />{t('nav.proposedMandates')}</NavLink>,
          mandatesToConfirm: <NavLink to="/en/mandates-to-confirm" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--circle-check" />{t('nav.mandatesToConfirm')}</NavLink>,
        },
        secondary: {
          timesheet: <NavLink to="/en/timesheets" onClick={closeNav} exact><span className="primary-nav__icon primary-nav__icon--timesheet" />{t('nav.timesheets')}</NavLink>,
        },
        helpcenter: {},
      },
    };
    const links = linksByRole[userRole];
    const mainLinks = Object.entries(links.main);
    const primaryLinks = Object.entries(links.primary);
    const secondaryLinks = Object.entries(links.secondary);
    return (
      <div className={classNames('primary-nav', { 'primary-nav--open': navIsActive })} ref={this.navRef}>
        <div className="primary-nav__head">
          <div className="primary-nav__logo">
            <NavLink to="/en" onClick={closeNav}>
              <img
                src={logo}
                alt="Logo Vetreseau"
              />
            </NavLink>
            {process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'production' &&
              <div className="primary-nav__env__badge">{process.env.REACT_APP_ENV}</div>
            }
          </div>
          <div className="primary-nav__vetreseau">
            <NavLink to="/en" onClick={closeNav}>
              <TextLogo />
            </NavLink>
          </div>
          <button onClick={() => closeNav()} className="primary-nav__close">
            <span />
            <span />
          </button>
        </div>
        <nav className="primary-nav__lists">
          {mainLinks.length > 0 &&
            <ul className="primary-nav__lists__main">
              {mainLinks.map(([key, link]) =>
                <li key={key}>
                  {link}
                </li>,
              )}
            </ul>
          }
          {primaryLinks.length > 0 &&
            <ul className="primary-nav__lists__sorting primary-nav__lists__sorting--dotted">
              {primaryLinks.map(([key, link]) =>
                <li key={key}>
                  {link}
                </li>,
              )}
            </ul>
          }
          {secondaryLinks.length > 0 &&
            <ul className="primary-nav__lists__sorting">
              {secondaryLinks.map(([key, link]) =>
                <li key={key}>
                  {link}
                </li>,
              )}
            </ul>
          }
        </nav>
      </div>
    );
  }
}

Nav.contextTypes = contextTypes;
Nav.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    userRole: state.user.role,
  };
}

export default withRouter(connect(
  mapStateToProps,
  actions,
)(Nav));
