import types from '../actions/callToAll/types';

import { formatCallToAllsForProposalLists } from '../helpers/formatCallToAlls';

const initialState = {
  loading: false,
  substitutes: [],
  callToAlls: [],
  callToAll: {},
  submitting: false,
  refreshCallToAllStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CREATE_CALL_TO_ALL:
      return {
        ...state,
        submitting: true,
      };
    case types.CREATE_CALL_TO_ALL_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case types.CREATE_CALL_TO_ALL_FAIL:
      return {
        ...state,
        submitting: false,
      };
    case types.FETCH_AVAILABLE_SUBSTITUTES_LIST:
      return {
        ...state,
        loading: true,
        substitutes: [],
      };
    case types.FETCH_AVAILABLE_SUBSTITUTES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        substitutes: action.payload.availableSubstitutes,
      };
    case types.FETCH_AVAILABLE_SUBSTITUTES_LIST_FAIL:
      return {
        ...state,
        loading: false,
        substitutes: [],
      };
    case types.FETCH_CALL_TO_ALL:
      return {
        ...state,
        callToAll: {},
        loading: true,
      };
    case types.FETCH_CALL_TO_ALL_SUCCESS:
      return {
        ...state,
        callToAll: action.payload.callToAll,
        loading: false,
      };
    case types.FETCH_CALL_TO_ALL_FAIL:
      return {
        ...state,
        callToAll: {},
        loading: false,
      };
    case types.FETCH_USER_CALL_TO_ALLS:
      return {
        ...state,
        callToAlls: [],
        loading: true,
      };
    case types.FETCH_USER_CALL_TO_ALLS_SUCCESS:
      return {
        ...state,
        callToAlls: formatCallToAllsForProposalLists(action.payload.user.callToAlls),
        loading: false,
      };
    case types.FETCH_USER_CALL_TO_ALLS_FAIL:
      return {
        ...state,
        callToAlls: [],
        loading: false,
      };
    case types.UPDATE_STATUS_CALL_TO_ALL:
      return {
        ...state,
        submitting: true,
        refreshCallToAllStatus: false,
      };
    case types.UPDATE_STATUS_CALL_TO_ALL_SUCCESS:
      return {
        ...state,
        submitting: false,
        refreshCallToAllStatus: true,
      };
    case types.UPDATE_STATUS_CALL_TO_ALL_FAIL:
      return {
        ...state,
        submitting: false,
        refreshCallToAllStatus: false,
      };
    case types.REPLACEMENT_FOR_CALL_TO_ALL:
      return {
        ...state,
        replacement: action.replacement,
        submitting: false,
      };
    default:
  }
  return state;
}
