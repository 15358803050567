import React from 'react';
import PropTypes from 'prop-types';

// Component
import Break from './Break';

function Breaks({ fields, breaks }) {
  return (
    <div>
      {fields.map((field, index) =>
        <Break
          field={field}
          key={field}
          pause={breaks[index]}
        />,
      )}
    </div>
  );
}

Breaks.propTypes = {
  fields: PropTypes.object,
  breaks: PropTypes.array,
};

Breaks.defaultProps = {
  fields: {},
  breaks: [],
};

Breaks.contextTypes = {};

export default Breaks;
