import types from './types';

export default {
  pushAlert: (alert) => ({
    type: types.PUSH_ALERT,
    alert,
  }),
  removeAlert: () => ({
    type: types.REMOVE_ALERT,
  }),
};
