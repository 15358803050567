// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';
import moment from 'moment';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { monthPickerConfigs } from '../../helpers/monthPickerConfigs';

// styles
import './styles.scss';

class MonthSelector extends Component {
  static propTypes = {
    currentDate: PropTypes.instanceOf(Date).isRequired,
    changeDate: PropTypes.func.isRequired,
    pickerClassNames: PropTypes.string,
    lang: PropTypes.string.isRequired,
    wrapperClassNames: PropTypes.string,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    pickerClassNames: '',
    wrapperClassNames: '',
  };

  constructor() {
    super();
    this.handleAMonthChange = this.handleAMonthChange.bind(this);
    this.handleClickMonthBox = this.handleClickMonthBox.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.closeMonthBox = this.closeMonthBox.bind(this);
    this.pickerRef = React.createRef();
    this.pickerWrapperRef = React.createRef();
    this.state = {
      mvalue: {},
      showed: false,
    };
  }

  componentDidMount() {
    const { currentDate } = this.props;
    this.updatePickerValue({
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { showed } = prevState;
    if (this.state.showed !== showed) {
      this.pickerRef.current.setState({
        showed: this.state.showed,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.pickerWrapperRef && !this.pickerWrapperRef.current.contains(event.target)) {
      this.setState({
        showed: false,
      });
    }
  }

  updatePickerValue({ year, month }) {
    this.setState({
      mvalue: { year, month },
    });
  }

  handleAMonthChange(year, month) {
    const { changeDate } = this.props;
    const newDate = new Date(year, month - 1);
    changeDate(newDate);
    this.updatePickerValue({ year, month });
    this.setState({
      showed: false,
    });
  }

  handleClickMonthBox() {
    this.setState({
      showed: true,
    });
  }

  closeMonthBox() {
    this.setState({
      showed: false,
    });
  }

  render() {
    const { lang, pickerClassNames, currentDate, wrapperClassNames } = this.props;
    const { mvalue, showed } = this.state;

    return (
      <div className={wrapperClassNames}>
        <button className={classNames('month-picker__open', { close: showed })} onClick={this.handleClickMonthBox}>
          <strong>{moment(currentDate).format('MMMM')} </strong>
          {moment(currentDate).format('YYYY')}
        </button>
        { showed && <button className="month-picker__close" onClick={this.closeMonthBox} /> }
        <div className={pickerClassNames} ref={this.pickerWrapperRef}>
          <Picker
            ref={this.pickerRef}
            years={{ min: 2019, max: 2050 }}
            value={mvalue}
            lang={monthPickerConfigs.monthNames[lang]}
            onChange={this.handleAMonthChange}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.i18nState.lang,
  };
}

export default connect(
  mapStateToProps,
  null,
)(MonthSelector);
