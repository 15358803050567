export default {
  fetchMandates: `
    query(
      $id: ID!,
      $first: Int,
      $from: ISO8601DateTime!,
      $to: ISO8601DateTime,
      $last: Int,
      $before: String,
      $after: String,
      $mandateType: String
    ) {
      clinic(id: $id) {
        mandates(
          first: $first,
          from: $from,
          to: $to,
          last: $last,
          before: $before,
          after: $after,
          mandateType: $mandateType,
        ) {
          nodes {
            id
            accommodation
            adminNote
            clinic {
              id
            }
            mandateType
            replacements {
              id
              createdAt
              flexibleTime
              endsAt
              priority
              startsAt
              id
              discarded
              mandate {
                id
              }
              latestProposal {
                clinicStatus
                expirationExtended
                expiresAt
                message
                status
                replacement {
                  id
                }
                userStatus
                substitute{
                  profile{
                    firstName
                    lastName
                    surgery
                    consultation
                    graduation
                  }
                }
                isNewSubstitute
              }
              updatedAt
            }
            consultation
            surgery
            message
            replacementsCount
            wantedCount
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  `,
  fetchMandate: `
    query($id: ID!) {
      mandate(id: $id) {
        id
        accommodation
        adminNote
        clinic {
          id
          name
        }
        mandateType
        replacements {
          flexibleTime
          endsAt
          priority
          startsAt
          id
          discarded
          mandate {
            id
          }
          latestProposal {
            clinicStatus
            expirationExtended
            expiresAt
            message
            status
            replacement {
              id
            }
            userStatus
            isNewSubstitute
          }
        }
        proposals {
          clinicStatus
          expirationExtended
          expiresAt
          message
          id
          replacement {
            endsAt
            startsAt
            id
          }
          substitute{
            profile{
              firstName
              lastName
            }
          }
          userStatus
          isNewSubstitute
        }
        consultation
        replacementsCount
        surgery
        message
        wantedCount
      }
    }
  `,
  createMandate: `
    mutation($input: MandateInput!) {
      createMandate(input: $input) {
        mandate {
          id
          accommodation
          adminNote
          clinic {
            id
          }
          consultation
          createdAt
          mandateType
          replacements {
            id
            flexibleTime
            endsAt
            priority
            startsAt
            mandate {
              id
            }
            latestProposal {
              clinicStatus
              expirationExtended
              expiresAt
              message
              userStatus
            }
          }
          message
          replacementsCount
          surgery
          updatedAt
          wantedCount
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  updateReplacement: `
    mutation($id: ID!, $input: ReplacementInput!) {
      updateReplacement(id: $id, input: $input) {
        replacement {
          createdAt
          flexibleTime
          endsAt
          priority
          startsAt
          id
          discarded
          mandate {
            id
          }
          latestProposal {
            id
            clinicStatus
            createdAt
            expirationExtended
            expiresAt
            message
            replacement {
              id
            }
            status
            substitute{
              profile {
                firstName
                lastName
                picture {
                  mini
                }
              }
            }
            updatedAt
            userStatus
          }
          updatedAt
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  fetchReplacement: `
    query($id: ID!) {
      replacement(id: $id) {
        flexibleTime
        endsAt
        priority
        startsAt
        id
        mandate {
          id
        }
        latestProposal {
          clinicStatus
          expirationExtended
          expiresAt
          message
          status
          replacement {
            id
          }
          userStatus
        }
      }
    }
  `,
  disableMandate: `
    mutation($id: ID!) {
      disableMandate(id: $id) {
        mandate {
          id
          clinic {
            id
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  disableReplacement: `
    mutation($id: ID!) {
      disableReplacement(id: $id) {
        replacement {
          flexibleTime
          endsAt
          priority
          startsAt
          id
          discarded
          mandate {
            id
          }
          latestProposal {
            clinicStatus
            expirationExtended
            expiresAt
            message
            status
            replacement {
              id
            }
            userStatus
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  disableProposal: `
    mutation($id: ID!) {
      disableProposal(id: $id) {
        proposal {
          id
          replacement {
            id
            latestProposal {
              id
              availability{
                id
              }
              clinicStatus
              userStatus
              status
              createdAt
              updatedAt
              clinic{
                name
              }
            }
          }
          clinic {
            id
          }
          discarded
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  enableReplacement: `
    mutation($id: ID!) {
      enableReplacement(id: $id) {
        errors {
          field
          message
        }
        success
      }
    }
  `,
  fetchProposals: `
    query($id: ID!, $first: Int, $from: ISO8601DateTime, $to: ISO8601DateTime, $last: Int, $before: String, $after: String, $latest: Boolean, $proposalStatus: String) {
      clinic(id: $id) {
        mandates(first: $first, from: $from, to: $to, last: $last, before: $before, after: $after, latest: $latest, proposalStatus: $proposalStatus) {
          nodes {
            id
            accommodation
            clinic {
              id
            }
            mandateType
            replacements {
              flexibleTime
              endsAt
              priority
              startsAt
              id
              discarded
              mandate {
                id
              }
              latestProposal {
                clinicStatus
                expirationExtended
                expiresAt
                message
                replacement {
                  id
                }
                userStatus
                isNewSubstitute
              }
            }
            proposals(status: $proposalStatus) {
              expirationExtended
              expiresAt
              message
              id
              replacement {
                endsAt
                startsAt
                id
              }
              substitute{
                profile{
                  firstName
                  lastName
                }
              }
              status
              isNewSubstitute
            }
            consultation
            surgery
            message
            replacementsCount
            wantedCount
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  `,
  fetchAdminProposals: `
    query($first: Int, $from: ISO8601DateTime!, $to: ISO8601DateTime, $last: Int, $before: String, $after: String, $proposalStatus: String) {
      mandates(first: $first, from: $from, to: $to, last: $last, before: $before, after: $after, proposalStatus: $proposalStatus) {
        nodes {
          id
          accommodation
          clinic {
            id
            name
            message
            address {
              address
              address2
              city
              country
              province
              zipcode
            }
          }
          mandateType
          replacements {
            flexibleTime
            endsAt
            priority
            startsAt
            id
            discarded
            mandate {
              id
            }
            latestProposal {
              clinicStatus
              expirationExtended
              expiresAt
              message
              replacement {
                id
              }
              userStatus
            }
          }
          proposals(status: $proposalStatus) {
              id
              clinicStatus
              expirationExtended
              expiresAt
              message
              availability {
                id
              }
              replacement {
                id
                startsAt
                endsAt
                mandate {
                  consultation
                  surgery
                  accommodation
                }
              }
              userStatus
            }
          consultation
          surgery
          message
          replacementsCount
          wantedCount
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  updatesProposalStatus: `
    mutation($input: ProposalReviewsInput!) {
      clinicReviewProposals(input: $input) {
        proposals {
          id
          clinicStatus
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  updateMandate: `
    mutation($id: ID!, $input: MandateInput!) {
      updateMandate(id: $id, input: $input) {
        mandate {
          id
          accommodation
          adminNote
          clinic {
            id
          }
          mandateType
          replacements {
            id
            flexibleTime
            endsAt
            priority
            startsAt
            mandate {
              id
            }
            latestProposal {
              clinicStatus
              expirationExtended
              expiresAt
              message
              userStatus
            }
          }
          message
          replacementsCount
          wantedCount
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
};
