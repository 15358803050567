/* eslint-disable react/prefer-stateless-function */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import squareBlue from '../../../../assets/images/helpcenter/square-blue.svg';
import squareHashBlue from '../../../../assets/images/helpcenter/square-hashBlue.svg';
import squareOrange from '../../../../assets/images/helpcenter/square-orange.svg';
import squareGreen from '../../../../assets/images/helpcenter/square-green.svg';
import squareHashGreen from '../../../../assets/images/helpcenter/square-hashGreen.svg';
import squareGrey from '../../../../assets/images/helpcenter/square-hashGrey.svg';
import edit from '../../../../assets/images/helpcenter/edit.png';
import edit2 from '../../../../assets/images/helpcenter/edit2.png';
import flexRequestMonth from '../../../../assets/images/helpcenter/flex_request_month.png';
import flexRequestWeek from '../../../../assets/images/helpcenter/flex_request_week.png';
import request from '../../../../assets/images/helpcenter/request.png';
import userProfile from '../../../../assets/images/helpcenter/user_profile.png';

import '../helpcenter.scss';

class ClinicHelpcenter extends Component {
  static contextTypes = {
    t: PropTypes.func,
  };

  render() {
    const { t } = this.context;

    return (
      <div className="faq-content addShadow">
        <div className="faq-content-title title">
          <h2>{t('helpcenter.clinic.tutorial.title')}</h2>
          <p>{t('helpcenter.clinic.tutorial.paragraph')}</p>
        </div>
        <div className="faq-content-main">
          <h3>{t('helpcenter.clinic.tutorial.homePage.title')}</h3>
          <h4>{t('helpcenter.clinic.tutorial.homePage.calendarView')}</h4>
          <p>{t('helpcenter.clinic.tutorial.homePage.description')}</p>
        </div>
        <section id="legend" className="faq-content-legend-container">
          <div className="legend-row">
            <div className="faq-legend-icon"><img src={squareBlue} alt="Blue square" /></div>
            <div className="faq-legend-content">
              <p>{t('helpcenter.clinic.tutorial.legend.a')} <strong className="blue">{t('helpcenter.clinic.tutorial.legend.blueCase')}</strong> {t('helpcenter.clinic.tutorial.legend.blue')}</p>
            </div>
          </div>
          <div className="legend-row">
            <div className="faq-legend-icon"><img src={squareHashBlue} alt="Hash blue square" /></div>
            <div className="faq-legend-content">
              <p>{t('helpcenter.clinic.tutorial.legend.a')} <strong className="blue">{t('helpcenter.clinic.tutorial.legend.hashBlueCase')}</strong> {t('helpcenter.clinic.tutorial.legend.hashBlue')}</p>
            </div>
          </div>
          <div className="legend-row">
            <div className="faq-legend-icon"><img src={squareOrange} alt="Orange square" /></div>
            <div className="faq-legend-content">
              <p>{t('helpcenter.clinic.tutorial.legend.a')} <strong className="orange">{t('helpcenter.clinic.tutorial.legend.orangeCase')}</strong> {t('helpcenter.clinic.tutorial.legend.orange')}</p>
            </div>
          </div>
          <div className="legend-row">
            <div className="faq-legend-icon"><img src={squareHashGreen} alt="Hash green square" /></div>
            <div className="faq-legend-content">
              <p>{t('helpcenter.clinic.tutorial.legend.a')} <strong className="green">{t('helpcenter.clinic.tutorial.legend.hashGreenCase')}</strong> {t('helpcenter.clinic.tutorial.legend.hashGreen')}</p>
            </div>
          </div>
          <div className="legend-row">
            <div className="faq-legend-icon"><img src={squareGreen} alt="Green square" /></div>
            <div className="faq-legend-content">
              <p>{t('helpcenter.clinic.tutorial.legend.a')} <strong className="green">{t('helpcenter.clinic.tutorial.legend.greenCase')}</strong> {t('helpcenter.clinic.tutorial.legend.green')}</p>
            </div>
          </div>
          <div className="legend-row">
            <div className="faq-legend-icon"><img src={squareGrey} alt="Grey square" /></div>
            <div className="faq-legend-content">
              <p>{t('helpcenter.clinic.tutorial.legend.a')} <strong className="grey">{t('helpcenter.clinic.tutorial.legend.greyCase')}</strong> {t('helpcenter.clinic.tutorial.legend.grey')}</p>
            </div>
          </div>
        </section>
        {/* Profil utilisateur */ }
        <section id="profiles">
          <div className="faq-content-main">
            <h3>{t('helpcenter.clinic.tutorial.profiles.title')}</h3>
            <h4>{t('helpcenter.clinic.tutorial.profiles.user')}</h4>
            <p>{ t('helpcenter.clinic.tutorial.profiles.userDescription') }<span className="icon icon--profile" /></p>
          </div>
          <div className="faq-warning">
            <div className="faq-warning-icon"><span role="img" aria-label="Warning">⚠️</span></div>
            <div className="faq-warning-content">
              <p>{t('helpcenter.clinic.tutorial.profiles.userWarning.paragraph1')}</p>
              <p>{t('helpcenter.clinic.tutorial.profiles.userWarning.paragraph2')}<a className="email" href={`mailto:${process.env.REACT_APP_REGION_EMAIL}`}>{process.env.REACT_APP_REGION_EMAIL}</a></p>
            </div>
          </div>
          <div className="faq-card-tip addShadow">
            <div className="faq-card-tip-icon"><span role="img" aria-label="Light bulb">💡</span></div>
            <div className="faq-card-tip-content">
              <p>{t('helpcenter.clinic.tutorial.profiles.userTip')}</p>
            </div>
            <div className="faq-card-tip-img">
              <img src={userProfile} alt="" />
            </div>
          </div>

          {/* Profil clinic */}
          <div className="faq-content-main">
            <h4>{t('helpcenter.clinic.tutorial.profiles.clinic')}</h4>
            <p>{t('helpcenter.clinic.tutorial.profiles.clinicDescription')}<span className="icon icon--clinic" /></p>
          </div>

          <div className="faq-card-tip addShadow">
            <div className="faq-card-tip-icon"><span role="img" aria-label="Light bulb">💡</span></div>
            <div className="faq-card-tip-content">
              <p>{t('helpcenter.clinic.tutorial.profiles.clinicTip')} <a className="email" href={`mailto:${process.env.REACT_APP_REGION_EMAIL}`}>{process.env.REACT_APP_REGION_EMAIL}</a></p>
            </div>
          </div>
        </section>

        {/* Demande de remplacement */ }
        <section id="replacement">
          <div className="faq-content-main">
            <h3>{t('helpcenter.clinic.tutorial.requests.title')}</h3>
            <h4>{t('helpcenter.clinic.tutorial.requests.add')}</h4>
            <p>
              {t('helpcenter.clinic.tutorial.requests.buttonClick')}
              <button className="button" />
              <span className="button__label--bold">« {t('mandates.add')} »</span>
              {t('helpcenter.clinic.tutorial.requests.addDescription')}
            </p>
            <img src={request} alt="" />
          </div>
          <div className="faq-content-main">
            <h4>{t('helpcenter.clinic.tutorial.requests.flexible')}</h4>
            <p>{t('helpcenter.clinic.tutorial.requests.flexibleDescription')}</p>
          </div>

          {/* Demande flexible */}
          <div className="faq-content-two-col">
            <div className="faq-content-two-col-title">
              <p>{t('helpcenter.clinic.tutorial.requests.flexibleWeek.description')}</p>
            </div>
            <div className="faq-content-two-col-icon faq-content-two-col-icon--arrow-right" />
            <div className="faq-content-two-col-step">
              <p>{t('helpcenter.clinic.tutorial.requests.flexibleWeek.action')}</p>
            </div>
            <div className="faq-content-two-col-img">
              <img src={flexRequestWeek} alt="" />
            </div>
          </div>
          <div className="faq-content-two-col">
            <div className="faq-content-two-col-title">
              <p>{t('helpcenter.clinic.tutorial.requests.flexibleMonth.description')}</p>
            </div>
            <div className="faq-content-two-col-icon faq-content-two-col-icon--arrow-right" />
            <div className="faq-content-two-col-step">
              <p>{t('helpcenter.clinic.tutorial.requests.flexibleMonth.action')}</p>
            </div>
            <div className="faq-content-two-col-img">
              <img src={flexRequestMonth} alt="" />
            </div>
          </div>
          <div className="faq-content-main">
            <h4>{t('helpcenter.clinic.tutorial.requests.information')}</h4>
            <p>{t('helpcenter.clinic.tutorial.requests.informationDescription')}</p>
          </div>
          {/* item 1 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.clinic.tutorial.requests.informationItems.item1')}</h5>

              <div className="faq-content-sublist">
                <div className="subitem">
                  <div className="faq-content-subitem-icon faq-content-subitem-icon--star" />
                  <p>{t('helpcenter.clinic.tutorial.requests.informationItems.subItem1_1')}</p>
                </div>

                <div className="subitem2">
                  <div className="faq-content-subitem-icon faq-content-subitem-icon--piechart" />
                  <p>{t('helpcenter.clinic.tutorial.requests.informationItems.subItem1_2')}</p>
                </div>
              </div>
            </div>

          </div>
          {/* item 2 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.clinic.tutorial.requests.informationItems.item2')}</h5>
              <p>{t('helpcenter.clinic.tutorial.requests.informationItems.item2Description')}</p>
            </div>
          </div>

          {/* item 3 */}
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              <h5>{t('helpcenter.clinic.tutorial.requests.informationItems.item3')}</h5>
              <p>{t('helpcenter.clinic.tutorial.requests.informationItems.item3Description')}</p>
            </div>
          </div>

          <div className="faq-content-list">
            <p className="centered"><strong className="underlined">{t('helpcenter.clinic.tutorial.requests.informationItems.item4')}</strong></p>
          </div>
          {/* <p>{t('helpcenter.clinic.tutorial.requests.informationItems.item4')}</p>
          <p>{t('helpcenter.clinic.tutorial.requests.informationDescription2')}</p> */}
          <p>Une fois votre demande ajoutée, vous pourrez la retrouver dans la vue <span className="nobreak"><span className="icon icon--calendar blue" /> <strong className="blue">«{t('nav.calendar')}»</strong></span>, ou sous l’onglet <span className="nobreak"><span className="icon icon--list2 blue" /> <strong className="blue">«{t('nav.mandates')}»</strong></span> dans le menu de gauche.</p>

          <div className="faq-content-main">
            <h4>{t('helpcenter.clinic.tutorial.requests.edit')}</h4>
            <p>{t('helpcenter.clinic.tutorial.requests.editDescription')}</p>
            <p>{t('helpcenter.clinic.tutorial.requests.editDescription2')}<span className="icon icon--delete" /></p>
            <img src={edit} alt="" />
            <p>
              Vous pouvez retrouver toutes vos demandes sous l’onglet <strong className="blue">«{t('mandates.mandatesList')}»</strong> dans le menu de gauche. Cliquez sur le crayon <span className="icon icon--pencil" /> pour modifier et sur la corbeille <span className="icon icon--delete" /> pour supprimer
              votre demande.
            </p>
            {/* <p>{t('helpcenter.clinic.tutorial.requests.editDescription3')}</p> */}
            <img src={edit2} alt="" />
          </div>
          <div className="faq-warning">
            <div className="faq-warning-icon"><span role="img" aria-label="Warning">⚠️</span></div>
            <div className="faq-warning-content">
              <p>{t('helpcenter.clinic.tutorial.requests.editWarning.paragraph1')}<a className="phone" href={`tel:${process.env.REACT_APP_REGION_PHONE}`}>{process.env.REACT_APP_REGION_PHONE}</a></p>
            </div>
          </div>

        </section>

        {/* Proposition de remplacement */ }
        <section id="proposition">
          <div className="faq-content-main">
            <h3>{t('helpcenter.clinic.tutorial.proposition.title')}</h3>
            <h4>{t('helpcenter.clinic.tutorial.proposition.answer')}</h4>
            <p>{t('helpcenter.clinic.tutorial.proposition.paragraph1')}</p>
            {/* <p>{ t('helpcenter.clinic.tutorial.proposition.paragraph2') }</p> */ }
            <p>Tous les remplaçants qui vous sont proposés et en attente de votre approbation, se trouvent sous l’onglet <span className="nobreak"><span className="icon icon--schedule orange" /><strong className="orange">«{ t('nav.proposedMandates') }»</strong></span> dans le menu de gauche. <span className="underlined">Vous devez répondre à toutes les propositions d’un même mandat, afin de pouvoir soumettre vos réponses.</span></p>
          </div>

          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              {/* <h5>{t('helpcenter.clinic.tutorial.proposition.item1')}</h5> */}
              <h5>Pour <strong className="green">{ t('form.acceptProposition.accept') }</strong> une proposition de remplacement, cochez <span className="icon icon--circle-check green" /> et cliquez sur «Envoyer».</h5>
            </div>
          </div>
          <div className="faq-content-list">
            <div className="faq-content-list-item">
              <div className="faq-content-list-bullet faq-content-list-bullet--circle" />
              {/* <h5>{t('helpcenter.clinic.tutorial.proposition.item2')}</h5> */}
              <h5>Pour <strong className="red">{ t('form.acceptProposition.refuse') }</strong> une proposition de remplacement, cochez <span className="icon icon--circle-close red" /> et cliquez sur «Envoyer».</h5>
            </div>
          </div>
          <div className="faq-card-tip addShadow">
            <div className="faq-card-tip-icon"><span role="img" aria-label="Light bulb">💡</span></div>
            <div className="faq-card-tip-content">
              <p>{t('helpcenter.clinic.tutorial.proposition.answerTip')}</p>
            </div>
          </div>
          <div className="faq-content-main">
            <h3>{t('helpcenter.clinic.tutorial.approval.title')}</h3>
            <h4>{t('helpcenter.clinic.tutorial.approval.awaiting')}</h4>
            {/* <p>{ t('helpcenter.clinic.tutorial.approval.awaitingDescription') }</p> */ }
            <p>Les mandats que vous avez approuvés se trouvent sous l’onglet <span className="nobreak"><span className="icon icon--circle-check green" /><strong className="green">{ t('nav.acceptedMandates') }</strong></span> dans le menu de gauche. Le mandat sera labellisé «En attente de confirmation» et ce sera au tour de notre équipe de faire la validation finale.</p>
          </div>
          <div className="faq-content-main">
            <h4>{t('helpcenter.clinic.tutorial.approval.confirmed')}</h4>
            {/* <p>{t('helpcenter.clinic.tutorial.approval.confirmedDescription')}</p> */ }
            <p>Les mandats confirmés se trouvent également sous l’onglet <span className="nobreak"><span className="icon icon--circle-check green" /><strong className="green">{ t('nav.acceptedMandates') }</strong></span> dans le menu de gauche. <strong className="underlined">Un remplacement est officiellement confirmé une fois que vous avez reçu le courriel de confirmation.</strong></p>
          </div>
        </section>
        <div className="divider" />
        <section id="contact">
          <div className="contact__container">
            <div className="contact__image icon--question" />
            <div className="contact__title">
              <h4>{t('helpcenter.clinic.tutorial.questions.title')}</h4>
            </div>
            <div className="contact__paragraph">
              <p>{t('helpcenter.clinic.tutorial.questions.contactInfo')}<a className="email" href={`mailto:${process.env.REACT_APP_REGION_EMAIL}`}>{process.env.REACT_APP_REGION_EMAIL}</a><br />{t('helpcenter.clinic.tutorial.questions.or')}<a className="phone" href={`tel:${process.env.REACT_APP_REGION_PHONE}`}>{process.env.REACT_APP_REGION_PHONE}</a></p>
            </div>
            <div className="contact__paragraph">
              <p>{t('helpcenter.clinic.tutorial.questions.paragraph1')}</p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ClinicHelpcenter;
