import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import '../Fields.scss';
import './Select.scss';

function NoLabelSelect(field) {
  return (
    <div className={classNames('field', field.className)}>
      <select {...field.input} disabled={field.disabled} className="field__select">
        {field.children}
      </select>
    </div>
  );
}

NoLabelSelect.contextTypes = {
  t: PropTypes.func,
};

export default NoLabelSelect;
