const validateEntry = values => {
  const errors = {};

  if (values.startsAt === values.endsAt) {
    errors.startsAt = 'validation.timesheetEntries.time';
  }
  if (values.distanceTraveled === '') {
    errors.distanceTraveled = 'validation.timesheetEntries.distanceTraveledEmpty';
  }
  if (values.breaks) {
    const breaksArrayErrors = [];
    values.breaks.forEach((breakValues, breakIndex) => {
      const breaksErrors = {};
      if (breakValues.active === 'true') {
        if (breakValues.duration < 0.25) {
          breaksErrors.duration = 'validation.timesheetEntries.breaks.duration';
        }
        if (!breakValues.reason) {
          breaksErrors.duration = 'validation.timesheetEntries.breaks.reason';
        }
        breaksArrayErrors[breakIndex] = breaksErrors;
      } else {
        if (!breakValues.reason) {
          breaksErrors.duration = 'validation.timesheetEntries.breaks.reason';
        }
        breaksArrayErrors[breakIndex] = breaksErrors;
      }
    });
    errors.breaks = breaksArrayErrors;
  }

  return errors;
};

export default validateEntry;
