import types from '../actions/confirmAction/types';

const initialState = {
  showConfirmation: false,
  actionToTrigger: null,
  message: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.HIDE_CONFIRMATION:
      return {
        ...state,
        showConfirmation: false,
        actionToTrigger: null,
      };
    case types.ACTION_TO_CONFIRM:
      return {
        ...state,
        showConfirmation: true,
        actionToTrigger: action.actionToTrigger,
        message: action.message,
      };
    default:
  }
  return state;
}
