const validate = values => {
  let errors = {};
  if (typeof values.distance === 'undefined') {
    const distanceError = { distance: 'validation.required' };
    errors = Object.assign(errors, distanceError);
  }

  if (values.consultation === false && values.surgery === false) {
    const skillsRequiredError = { surgery: 'validation.selectASkill' };
    errors = Object.assign(errors, skillsRequiredError);
  }

  if (typeof values.daysAvailabilities !== 'undefined') {
    const daysAvailabilitiesArrayErrors = [];
    values.daysAvailabilities.forEach((dayAvailability, dayIndex) => {
      const dayAvailabilityErrors = {};
      const timeFormatRegex = /^[0-2][0-9]:[0-5][0-9]$/g;
      if (!dayAvailability.allDay) {
        if (!dayAvailability.startTime || dayAvailability.startTime === '') {
          dayAvailabilityErrors.startTime = 'validation.dayAvail.required';
        } else if (!dayAvailability.startTime.match(timeFormatRegex)) {
          dayAvailabilityErrors.startTime = 'validation.dayAvail.format';
        }
        if (!dayAvailability.endTime || dayAvailability.endTime === '') {
          dayAvailabilityErrors.endTime = 'validation.dayAvail.required';
        } else if (!dayAvailability.endTime.match(timeFormatRegex)) {
          dayAvailabilityErrors.endTime = 'validation.dayAvail.format';
        }
        if (Date.parse(`01/01/2019 ${dayAvailability.endTime}:00`) <= Date.parse(`01/01/2019 ${dayAvailability.startTime}:00`)) {
          dayAvailabilityErrors.startTime = 'validation.dayAvail.startBeforeEnd';
        }
        daysAvailabilitiesArrayErrors[dayIndex] = dayAvailabilityErrors;
      }
    });
    if (daysAvailabilitiesArrayErrors.length) {
      errors.daysAvailabilities = daysAvailabilitiesArrayErrors;
    }
  }

  if (values.startsAt && values.endsAt) {
    const timeFormatRegex = /^[0-2][0-9]:[0-5][0-9]$/g;
    if (!values.allDay) {
      if (!values.startsAt || values.startsAt === '') {
        errors.startsAt = 'validation.dayAvail.required';
      } else if (!values.startsAt.match(timeFormatRegex)) {
        errors.startsAt = 'validation.dayAvail.format';
      }
      if (!values.endsAt || values.endsAt === '') {
        errors.endsAt = 'validation.dayAvail.required';
      } else if (!values.endsAt.match(timeFormatRegex)) {
        errors.endsAt = 'validation.dayAvail.format';
      }
      if (Date.parse(`01/01/2019 ${values.endsAt}:00`) <= Date.parse(`01/01/2019 ${values.startsAt}:00`)) {
        errors.startsAt = 'validation.dayAvail.startBeforeEnd';
      }
    }
  }
  return errors;
};

export default validate;
