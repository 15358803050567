import { async, createTypes } from 'redux-action-creator';

export default createTypes([
  'SET_ENTRY_TO_UPDATE',
  'SET_REINIT_TIMESHEET',
  ...async('EXPORT_TIMESHEETS'),
  ...async('FETCH_TIMESHEETS'),
  ...async('FETCH_TIMESHEET_ENTRY'),
  ...async('UPDATE_TIMESHEET_ENTRIES'),
  ...async('REVIEW_TIMESHEET_ENTRIES'),
  ...async('UPDATE_NEXT_TIMESHEETS_LIST'),
], 'TIMESHEET');
