export function filterNotificationStatus(notifications, status) {
  return notifications.filter(notification => notification.status === status);
}

export function filterNotificationColor(notificationType) {
  const notificationColors = {
    // New notificationTypes
    ClinicReviewProposalsConfirmed: 'confirmed',
    ClinicReviewProposalsConfirmedRejected: 'confirmedRejected',
    ClinicReviewProposalsRejected: 'rejected',
    UserReviewCallToAlls: 'confirmed',
    UserReviewProposalsConfirmed: 'confirmed',
    UserReviewProposalsConfirmedRejected: 'confirmedRejected',
    UserReviewProposalsRejected: 'rejected',

    AdminConfirmProposals: 'confirmed',
    ClinicReviewProposals: 'confirmed',
    ConfirmProposals: 'confirmed',
    CreateCallToAlls: 'pending',
    CreateProposals: 'pending',
    ExpirationReminderProposals: 'pending',
    ExpiredProposals: 'expired',
    UserReviewProposals: 'pending',

    // Legacy notificationTypes
    clinic_reviewProposals: 'confirmed',
    confirmProposals: 'confirmed',
    expiration_reminderProposals: 'pending',
    expiredProposals: 'expired',
    user_reviewProposals: 'confirmed',
  };

  return (notificationColors[notificationType]) ? `notification--${notificationColors[notificationType]}` : false;
}

export default filterNotificationStatus;
