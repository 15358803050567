// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classNames from 'classnames';
import Select from '../../forms/fields/select/Select';
import { timeSelector } from '../../../helpers/form';

// CSS
import './styles.scss';
import Checkbox from '../../forms/fields/checkbox/Checkbox';

class DaysMandate extends Component {
  static clearFields(periodDays, fields, periodDidUpdate) {
    fields.removeAll();
    DaysMandate.setFields(periodDays, fields, periodDidUpdate);
  }
  static setFields(periodDays, fields, periodDidUpdate) {
    periodDays.forEach((day) => {
      fields.push({ day, startTime: '08:00', endTime: '20:00', priority: false, flexibleTime: false });
    });
    periodDidUpdate();
  }

  componentDidMount() {
    const { periodDays, fields, periodDidUpdate } = this.props;
    DaysMandate.setFields(periodDays, fields, periodDidUpdate);
  }

  componentDidUpdate(prevProps) {
    const { updatePeriod: prevUpdatePeriod } = prevProps;
    const { periodDays, fields, updatePeriod, periodDidUpdate } = this.props;
    if (prevUpdatePeriod !== updatePeriod && updatePeriod) {
      DaysMandate.clearFields(periodDays, fields, periodDidUpdate);
    }
  }

  render() {
    const { fields, moment, periodDays } = this.props;
    const { t } = this.context;
    return (
      <div>
        {fields.map((date, index, data) => (
          <div key={periodDays[index]} className="day-mandate days-mandate">
            <div className="day-mandate--date">
              { moment(periodDays[index]).format('ddd') }
              <span>{ moment(periodDays[index]).format('DD') }</span>
            </div>
            <Field
              name={`${date}.startTime`}
              type="text"
              component={Select}
              label="addMandate.startTime"
              className={classNames('field--select', { 'field--priority': data.get(index).priority })}
              innerLabel
            >
              { timeSelector() }
            </Field>
            <span className="day-mandate__arrow" />
            <Field
              name={`${date}.endTime`}
              type="text"
              component={Select}
              label="addMandate.endTime"
              className={classNames('field--select', { 'field--priority': data.get(index).priority })}
              innerLabel
            >
              { timeSelector() }
            </Field>
            <div className="checkbox-tooltip__wrapper">
              <span className="checkbox-tooltip">{ t('form.addMandate.priority') }</span>
              <Field
                name={`${date}.priority`}
                type="text"
                component={Checkbox}
                label="addMandate.priority"
                className="field__checkbox--round field__checkbox--priority day-mandate__checkbox"
              />
            </div>
            <div className="checkbox-tooltip__wrapper">
              <span className="checkbox-tooltip">{ t('form.addMandate.flexible') }</span>
              <Field
                name={`${date}.flexibleTime`}
                type="text"
                component={Checkbox}
                label="addMandate.flexible"
                className="field__checkbox--round field__checkbox--flexible day-mandate__checkbox"
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

DaysMandate.propTypes = {
  fields: PropTypes.object.isRequired,
  periodDays: PropTypes.array.isRequired,
  updatePeriod: PropTypes.bool.isRequired,
  periodDidUpdate: PropTypes.func.isRequired,
  moment: PropTypes.func.isRequired,
};

DaysMandate.contextTypes = {
  t: PropTypes.func,
};

export default DaysMandate;
