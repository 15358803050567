import moment from 'moment';
import types from '../actions/timesheet/types';
import {
  updateTimesheets,
} from '../helpers/formatTimesheet';

const initialState = {
  timesheets: [],
  loading: false,
  endCursor: null,
  entryToUpdateId: null,
  timesheetEntry: {},
  reinitTimesheets: false,
  file: {},
  submitting: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.EXPORT_TIMESHEETS:
      return {
        ...state,
        loading: true,
        file: {},
      };
    case types.EXPORT_TIMESHEETS_SUCCESS:
      return {
        ...state,
        loading: false,
        file: action.payload.timesheetsExport.file,
      };
    case types.EXPORT_TIMESHEETS_FAIL:
      return {
        ...state,
        loading: false,
        file: {},
      };
    case types.FETCH_TIMESHEETS:
      return {
        ...state,
        loading: true,
        timesheets: [],
        reinitTimesheets: false,
      };
    case types.FETCH_TIMESHEETS_SUCCESS: {
      const { payload: { timesheets: { nodes: timesheets } } } = action;
      const sortedTimesheets = timesheets.map((timesheet) => {
        timesheet.entries = timesheet.entries.sort((entry, nextEntry) => (
          new Date(entry.startsAt) - new Date(nextEntry.startsAt)
        ));
        if (timesheet.status !== 'pending' || timesheet.status !== 'late') {
          const mostRecentConfirmation =
            Math.max(...timesheet.entries.map((entry) => moment(entry.confirmedAt)));
          const mostRecentSubmission =
            Math.max(...timesheet.entries.map((entry) => moment(entry.submittedAt)));

          timesheet.confirmedAt = moment(mostRecentConfirmation).format('DD/MM/YYYY');
          timesheet.submittedAt = moment(mostRecentSubmission).format('DD/MM/YYYY');
        }
        return {
          ...timesheet,
        };
      });
      return {
        ...state,
        loading: false,
        timesheets: sortedTimesheets,
        ...action.payload.timesheets.pageInfo,
      };
    }
    case types.FETCH_TIMESHEETS_FAIL:
      return {
        ...state,
        loading: false,
        timesheets: [],
      };
    case types.FETCH_TIMESHEET_ENTRY:
      return {
        ...state,
        loading: true,
        timesheetEntry: {},
      };
    case types.FETCH_TIMESHEET_ENTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        timesheetEntry: action.payload.timesheetEntry,
      };
    case types.FETCH_TIMESHEET_ENTRY_FAIL:
      return {
        ...state,
        loading: false,
        timesheetEntry: {},
      };
    case types.SET_ENTRY_TO_UPDATE:
      return {
        ...state,
        entryToUpdateId: action.entryId,
      };
    case types.UPDATE_TIMESHEET_ENTRIES:
      return {
        ...state,
        loading: true,
        submitting: true,
      };
    case types.UPDATE_TIMESHEET_ENTRIES_SUCCESS: {
      const updatedTimesheets = updateTimesheets(
        state.timesheets,
        action.payload.updateTimesheetEntries.entries[0],
        state.timesheetToUpdateId,
      );
      return {
        ...state,
        loading: false,
        timesheets: updatedTimesheets,
        submitting: false,
      };
    }
    case types.UPDATE_TIMESHEET_ENTRIES_FAIL:
      return {
        ...state,
        loading: false,
        submitting: false,
      };
    case types.REVIEW_TIMESHEET_ENTRIES:
      return {
        ...state,
        loading: true,
      };
    case types.REVIEW_TIMESHEET_ENTRIES_SUCCESS: {
      const updatedTimesheets = updateTimesheets(
        state.timesheets,
        action.payload.reviewTimesheetEntries.entries[0],
        action.payload.reviewTimesheetEntries.entries[0].id,
      );
      return {
        ...state,
        loading: false,
        timesheets: updatedTimesheets,
      };
    }
    case types.REVIEW_TIMESHEET_ENTRIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case types.SET_REINIT_TIMESHEET:
      return {
        ...state,
        loading: true,
        reinitTimesheets: true,
      };
    case types.UPDATE_NEXT_TIMESHEETS_LIST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_NEXT_TIMESHEETS_LIST_SUCCESS: {
      const { payload: { timesheets: { nodes: timesheets } } } = action;
      const sortedTimesheets = timesheets.map((timesheet) => {
        timesheet.entries = timesheet.entries.sort((entry, nextEntry) => (
          new Date(entry.startsAt) - new Date(nextEntry.startsAt)
        ));
        return {
          ...timesheet,
        };
      });
      return {
        ...state,
        loading: false,
        timesheets: [
          ...state.timesheets,
          ...sortedTimesheets,
        ],
        ...action.payload.timesheets.pageInfo,
      };
    }
    case types.UPDATE_NEXT_TIMESHEETS_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
  }
  return state;
}
