import React from 'react';
import PropTypes from 'prop-types';
import Radio from '../radio/Radio';

const distances = { under50: '50', under150: '150', over150: '151' };

function DistanceRadio({ name }) {
  return (
    <div className="radio-custom radio-custom__distance">
      { Object.entries(distances).map(([label, value], index) =>
        <React.Fragment key={value}>
          <Radio
            name={name}
            component="input"
            value={value}
            label={`addAvailability.${label}`}
            type="radio"
          />
          {Object.entries(distances).length - 1 !== index &&
            <div className="radio-custom__distance__spacer">
              <span>..........</span>
            </div>
          }
        </React.Fragment>,
      )}
    </div>
  );
}

DistanceRadio.propTypes = {
  name: PropTypes.string,
};
DistanceRadio.defaultProps = {
  name: 'distance',
};

export default DistanceRadio;
