export default {
  accommodationIncluded: 'Accommodation available',
  actions: {
    confirm: 'Confirm',
    delete: 'Delete',
    details: 'More',
    disable: "Disable",
    edit: "Edit",
    enable: "Enable",
  },
  additionnalInfo: 'Additional information',
  allDay: 'All day',
  availability: 'Availability',
  available: "Available",
  consultation: 'Consultation',
  daysOutOf: {
    singular: "day out of",
    plural: "days out of",
  },
  isPending: 'Pending...',
  optional: '(optional)',
  outOf: "days out of",
  pendingConfirmation: 'Awaiting confirmation',
  pendingSubstitute: 'Request pending',
  surgery: 'Surgery',
  surgeryOptional: 'Surgery (optional)',
  surgeryType: {
    optional: 'Surgery optional',
    true: 'Surgery required',
  },
  technician: "Animal health technician",
  unavailable: "Not available",
  veterinarian: "Veterinarian",
  yearOfExperience: ' year of experience',
  yearsOfExperience: ' years of experience',
};
