const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'validation.required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'validation.invalid';
  }

  if (values.blacklistedClinics) {
    errors.blacklistedClinics = [];

    values.blacklistedClinics.forEach((clinicUser, index) => {
      errors.blacklistedClinics[index] = {};

      if (!clinicUser.clinicId) {
        errors.blacklistedClinics[index].clinicId = 'validation.required';
      } else if (values.blacklistedClinics.filter(
        user => user.clinicId === clinicUser.clinicId).length > 1
      ) {
        errors.blacklistedClinics[index].clinicId = 'validation.userSelected';
      }
    });
  }

  if (!values.role) {
    errors.role = 'validation.required';
  }

  if (values.profile) {
    errors.profile = {};

    if (!values.profile.firstName) {
      errors.profile.firstName = 'validation.required';
    }

    if (!values.profile.lastName) {
      errors.profile.lastName = 'validation.required';
    }

    if (!values.profile.communicationType) {
      errors.profile.communicationType = 'validation.required';
    }

    if (!values.profile.phone) {
      errors.profile.phone = 'validation.required';
    } else if (!/^(1\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/.test(values.profile.phone)) {
      errors.profile.phone = 'validation.invalid';
    }

    if (!values.profile.accountType) {
      errors.profile.accountType = 'validation.required';
    }

    if (!values.profile.defaultDistance) {
      errors.profile.defaultDistance = 'validation.required';
    }

    if (values.formName === 'editAccount' && !values.profile.graduation) {
      errors.profile.graduation = 'validation.required';
    }

    if (values.formName === 'editAccount' && values.profile.consultation === false && values.profile.surgery === false) {
      errors.profile.surgery = 'validation.selectASkill';
    }

    if (!values.profile.gloveSize) {
      errors.profile.gloveSize = 'validation.required';
    } else if (values.profile.gloveSize > 9 || values.profile.gloveSize < 5) {
      errors.profile.gloveSize = 'validation.gloveSize';
    }

    if (values.profile.softwareOthersCheck && !values.profile.softwareOthers) {
      errors.profile.softwareOthers = 'validation.required';
    }

    if (values.profile.surgerySoftTissueOthersCheck && !values.profile.surgerySoftTissueOthers) {
      errors.profile.surgerySoftTissueOthers = 'validation.required';
    }

    if (values.formName === 'editAccount' && !values.profile.accommodationPreferences) {
      errors.profile.accommodationPreferences = 'validation.required';
    }

    values.profile.languages = values.profile.languages || [];

    if (values.profile.languages) {
      const languagesArrayErrors = [];
      const languageIds = [];

      values.profile.languages.forEach(({ languageId, level }, index) => {
        const languageErrors = {};
        if (!languageId) {
          languageErrors.languageId = 'validation.required';
        }
        if (languageIds.includes(languageId)) {
          languageErrors.languageId = 'validation.duplicateLanguage';
        }
        languageIds.push(languageId);
        if (!level) {
          languageErrors.level = 'validation.required';
        }
        languagesArrayErrors[index] = languageErrors;
      });
      errors.profile.languages = languagesArrayErrors;
    }

    values.profile.address = values.profile.address || {};

    if ((values.role === 'substitute' || values.saveAddress === 'true') && values.profile.address) {
      errors.profile.address = {};

      if (!values.profile.address.address) {
        errors.profile.address.address = 'validation.required';
      }

      if (!values.profile.address.city) {
        errors.profile.address.city = 'validation.required';
      }

      if (!values.profile.address.zipcode) {
        errors.profile.address.zipcode = 'validation.required';
      }

      if ((!/^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$/i.test(values.profile.address.zipcode))) {
        errors.profile.address.zipcode = 'validation.zipcode';
      }

      if (!values.profile.address.province) {
        errors.profile.address.province = 'validation.required';
      }

      if (!values.profile.address.country) {
        errors.profile.address.country = 'validation.required';
      }
    }
  }

  return errors;
};

export default validate;
