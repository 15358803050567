// libs
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

// actions
import actions from '../../../actions/dynamicSidePanel';

// Component
import SidePanelContent from './SidePanelContent';

// Styles
import './styles.scss';

const contextTypes = {
  t: PropTypes.func,
};

const propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

// Index Component
function SidePanel(props) {
  const { isVisible } = props;
  return (
    <div className={classNames('side-panel', { 'side-panel--visible': isVisible })}>
      <SidePanelContent />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isVisible: state.dynamicSidePanel.isVisible,
  };
}

SidePanel.contextTypes = contextTypes;
SidePanel.propTypes = propTypes;

export default connect(mapStateToProps, actions)(SidePanel);
