import { asyncQuery, asyncMutation } from '../../../../config/graphql/async-action-creator';
import queries from './queries';
import types from './types';
import store from '../../../../config/redux/store';
import client from '../../../../config/graphql/client';

export default {
  fetchClinics: (variables = {}) => asyncQuery(
    store,
    variables.after ? types.FETCH_NEXT_CLINICS : types.FETCH_CLINICS,
    queries.fetchClinics,
    { ...variables, first: 10 },
    client,
  ),
  fetchSuggestedClinics: (variables) => asyncQuery(
    store,
    types.FETCH_SUGGESTED_CLINICS,
    queries.fetchClinics,
    { first: 5, ...variables },
    client,
  ),
  fetchClinic: (variables, manager = false) => asyncQuery(
    store,
    types.FETCH_CLINIC,
    manager ? queries.fetchClinicAsManager : queries.fetchClinic,
    variables,
    client,
  ),
  createClinic: (variables) => asyncMutation(
    store,
    types.CREATE_CLINIC,
    queries.createClinic,
    variables,
    client,
  ),
  updateClinic: (variables) => asyncMutation(
    store,
    types.CREATE_CLINIC,
    queries.updateClinic,
    variables,
    client,
  ),
  enableClinic: (variables) => asyncMutation(
    store,
    types.ENABLE_CLINIC,
    queries.enableClinic,
    variables,
    client,
  ),
  disableClinic: (variables) => asyncMutation(
    store,
    types.DISABLE_CLINIC,
    queries.disableClinic,
    variables,
    client,
  ),
  filterClinics: ({ input }) => ({
    type: types.FILTER_CLINICS,
    input,
  }),
};
