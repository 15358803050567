export default {
  acceptProposition: {
    accept: 'Accept',
    refuse: 'Decline'
  },
  accommodationBringAnimals: 'Bring the animals in the accomodation',
  accommodationPreference: 'Accommodation preferences',
  accommodationPreferences: {
    alone: 'Alone',
    none: 'I don\'t mind sharing with the host',
  },
  account: 'Account',
  accounting: 'Accounting / Billing',
  accountType: 'Account type',
  accountTypes: {
    technician: 'Animal health technician',
    veterinarian: 'Veterinarian',
  },
  addAvailability: {
    allDay: 'All day',
    allDayTooltip: 'Available all day',
    availabilities: 'Schedule',
    availability: 'Availability',
    consultation: 'Consultation',
    datesFilled: 'The selected dates already have availabilities listed',
    endDate: 'End',
    endTime: 'End time',
    moreInfo: 'Additional information',
    other: 'Skills',
    over150: 'More than 150 km',
    period: 'Period',
    selectPeriod: 'Select a period to add availabilities',
    startDate: 'Start',
    startTime: 'Start time',
    surgery: 'Surgery',
    travel: 'Travel',
    under50: 'Less than 50 km',
    under150: 'Less than 150 km',
  },
  addBillingAddress: 'Use a different address for billing',
  addCallToAll: {
    message: 'Call to all description*',
    removeAll: 'Remove all substitutes',
    selectAll: 'Select all substitutes',
    selectOneSub: 'You must select at least one substitute',
    selectUsers: 'Select users to includes',
  },
  addMandate: {
    accommodation: {
      available: 'Accommodation available',
      no: 'No',
      yes: 'Yes',
    },
    availabilities: 'Schedule',
    flexible: 'Flexible schedule',
    mandateType: 'Type of professional',
    message: 'Message',
    other: 'Other',
    period: 'Period',
    priority: 'Priority day',
    selectPeriod: 'Select a period to add a request',
    skills: {
      consultation: 'Consultation',
      optional: 'Surgery optional',
      required: 'Surgery required',
      title: 'Skills',
    },
    technician: 'Technician',
    veterinarian: 'Veterinarian',
    wantedCount: 'Number of days in the period',
  },
  addProposals: {
    clinicPrice: "Clinic fee",
    substitutePrice: "Locum rate",
  },
  address2: 'Address details',
  address: 'Address',
  addressBilling: 'Billing address',
  addUser: {
    selectGloveSize: 'Select your glove size',
    selectGraduation: 'Select your graduation year',
  },
  adminNoteClinic: 'Notes on the clinic',
  adminNoteGeneral: 'Notes for internal use',
  adminNoteSubstitute: 'Notes on the locum',
  adminSection: 'Admin section',
  allowExtendedExpiration: 'Allow extension of expiration date',
  animals: 'I have pets',
  billing: 'Billing',
  billingContact: 'Contact for billing',
  blacklistedUsers: 'Blacklisted users',
  blacklistedClinics: 'Blacklisted clinics',
  boolean: {
    no: 'No',
    yes: 'Yes',
  },
  bringAnimals: 'I would like to bring my pet(s) during placements',
  canineMaxWeight: 'Max weight',
  changeEmailInstructions: 'To change your email address, please contact us at',
  city: 'City',
  clinic: {
    picture: "Front of the clinic",
  },
  clinicBilling: 'Billing contact',
  clinicInfo: 'Clinic information',
  communicationType: 'Communication preferences',
  communicationTypes: {
    both: 'Both',
    email: 'Email',
    sms: 'SMS',
  },
  confirmNewPassword: 'Confirm new password',
  passwordConfirmation: 'Confirm password',
  consultation: 'Consultation',
  consultationAverageDuration: 'Average consultation length (minutes)',
  country: 'Country',
  defaultDistance: 'Travel preferences',
  description: 'Clinic description',
  displayedLanguage: 'Display language',
  editCallToAll: {
    changeStatus: 'Status',
    message: 'Call to all description',
    selectedUsers: 'Selected substitutes',
    status: {
      pending: 'Pending',
      confirmed: 'Confirmed',
      canceled: 'Canceled',
    },
  },
  editTimesheetEntry: {
    dinner: 'Dinner',
    distance: 'km/day',
    distanceTraveled: 'Distance travelled',
    duration: 'Duration',
    endsAt: 'End',
    lunch: 'Lunch',
    message: 'Message',
    moreInfo: 'Additional Information',
    schedule: 'Schedule',
    startsAt: 'Start',
  },
  email: 'Email',
  employed: 'Employed',
  en: 'English',
  expertise: 'Patient species',
  expertiseAreas: 'Patient species',
  exoticAnimal: 'Species',
  exoticAnimalAreas: 'Exotic animals',
  exoticAnimalMessage: 'Additional notes (about species)',
  exoticAnimalSkills: 'Exotic animals',
  firstName: 'First name',
  formActions: {
    add: 'Add',
    cancel: 'Cancel',
    confirm: 'Confirm',
    confirmAllEntries: 'Confirm all entries',
    confirmClinicTooltip: 'Confirm clinic',
    confirmUserTooltip: 'Confirm locum',
    delete: 'Delete',
    disable: 'Disable',
    edit: 'Edit',
    enable: 'Enable',
    loadMore: 'Show more',
    remove: 'Delete',
    submit: 'Send',
    update: 'Save',
    updatePassword: 'Update password',
    uploader: {
      dragDrop: {
        browse: 'click to browse',
        clinic: {
          drop: 'Please add a photo of the facade of your building to help the replacement identify the place. Drop file or %{browse}',
        },
        drop: 'Drop file or %{browse}',
      },
      submitToUpdate: 'Submit the form to update {n}',
      submitToUpdatePicture: 'Submit the form to update your profile picture',
      uploadedFile: 'File uploaded',
      statusBar: {
        uploading: 'Uploading',
        complete: 'Upload complete',
        uploadFailed: 'Upload failed',
      },
    },
  },
  fr: 'French',
  gloveSize: 'Glove size',
  graduation: 'Graduation year',
  language: 'Language',
  languages: 'Languages',
  lastName: 'Last name',
  level: 'Level',
  levels: {
    fluent: 'Fluent',
    functional: 'Functional',
  },
  licenceNumber: 'Licence number',
  locations: {
    ca: 'Canada',
    qc: 'Quebec',
    on: 'Ontario',
  },
  managers: 'Managers',
  manualValidation: 'Clinic must validate',
  message: 'Message',
  name: 'Name',
  newPassword: 'New password',
  noFileYet: 'No file uploaded yet',
  notes: 'Notes',
  oldPassword: 'Current password',
  openingHours: 'Opening hours',
  otherVets: 'Other vets on site during placements',
  password: 'Password',
  phone: 'Phone number',
  professionalInfo: 'Professional information',
  province: 'Province',
  profile: {
    picture: "Profile picture",
    voidedCheck: 'Voided check',
    voidedCheckMsg: 'If your specimen check has already been sent to us, you don\'t need to fill out this section.',
  },
  rate: 'Rate',
  rates: {
    city: 'City',
    remote: 'Remote',
  },
  reasons: {
    files: 'Records',
    phone: 'Returned client calls',
    work: 'Work (consultation/surgery)',
    notAvailable: 'Not enough time worked',
  },
  rememberMe: 'Remember me',
  replaceFile: 'Replace file',
  replaceFileShort: 'Replace',
  role: 'Role',
  roles: {
    admin: 'Administrator',
    substitute: 'Locum',
    clinic_manager: 'Manager',
  },
  saveAddress: 'Save address',
  search: 'Search',
  selectSoftware: 'Select software',
  skills: 'Skills',
  softTissue: 'Soft tissue procedures',
  softwareUsed: 'Software used for record keeping',
  softwareSkills: 'Mastered software for record keeping',
  software: 'Software',
  softwareOthers: 'Other(s)',
  surgery: 'Surgery',
  surgeryCanineOvhWeightLimit: 'Max weight for canine OVH',
  surgeryDeclawing: 'Onychectomy',
  surgeryMessage: 'Additional notes (about surgery)',
  surgerySoftTissueCaesarean: 'Caesarean',
  surgerySoftTissueCystotomy: 'Cystotomy',
  surgerySoftTissueForeignBody: 'Foreign body',
  surgerySoftTissueMassAblation: 'Tumor ablation',
  surgerySoftTissuePyometra: 'Pyometra',
  surgerySoftTissueOthers: 'Other',
  surgerySterilization: 'Sterilization',
  taxNumbers: {
    types: {
      hst: 'HST Account Number (if registered)',
      gst: 'GST Account Number (if registered)',
      qst: 'QST Account Number (if registered)',
    },
  },
  uploadFile: 'Upload file',
  uploadFileShort: 'Upload',
  uploadImage: 'Upload image',
  userInfo: 'Personal information',
  viewFile: 'Download file',
  weightOptions: {
    lb: '{n} lb.',
    less: '{n} lb. or less',
    more: 'More than {n} lb.',
  },
  zipcode: 'Postal code',
};
