// libs
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './styles.scss';

// helper
import { containsMinCharacters, containsRequiredCharacters, containsUpperLowerLetters } from '../../../helpers/password';

const contextTypes = {
  t: PropTypes.func,
};

const propTypes = {
  password: PropTypes.string,
};

const defaultProps = {
  password: '',
};

// Index Component
const PasswordRequirement = (props, { t }) => {
  const { password } = props;
  const hasMinCharacters = containsMinCharacters(password);
  const hasRequiredCharacters = containsRequiredCharacters(password);
  const hasLowerAndUppercase = containsUpperLowerLetters(password);
  const isNotEmpty = password !== '';
  return (
    <div className="password-requirements">
      <ul>
        <li className={classNames(
          'password-requirements__item', {
          'password-requirements__item--valid': hasMinCharacters,
          'password-requirements__item--invalid': !hasMinCharacters && isNotEmpty,
        })}
        >
          {t('validation.passwordRequirements.length')}
        </li>
        <li className={classNames(
          'password-requirements__item', {
          'password-requirements__item--valid': hasRequiredCharacters,
          'password-requirements__item--invalid': !hasRequiredCharacters && isNotEmpty,
        })}
        >
          {t('validation.passwordRequirements.characters')}
        </li>
        <li className={classNames(
          'password-requirements__item', {
          'password-requirements__item--valid': hasLowerAndUppercase,
          'password-requirements__item--invalid': !hasLowerAndUppercase && isNotEmpty,
        })}
        >
          {t('validation.passwordRequirements.case')}
        </li>
      </ul>
    </div>
  );
};

PasswordRequirement.contextTypes = contextTypes;
PasswordRequirement.propTypes = propTypes;
PasswordRequirement.defaultProps = defaultProps;

export default PasswordRequirement;
