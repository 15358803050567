import React from 'react';
import moment from 'moment/moment';

export function timeSelector() {
  return [...Array(24)].map((value, hour) => {
    const momentLocal = localStorage.getItem('Lang');
    const time = hour.toString().padStart(2, '0');
    const minutes = ['00', '15', '30', '45'];
    const meridiem = hour <= 12 ? 'AM' : 'PM';
    const enTime = hour > 12 ? hour - 12 : hour;

    return minutes.map(minute =>
      <option key={`option_${time}${minute}`} value={`${time}:${minute}`}>
        {momentLocal === 'fr' ? `${time}:${minute}` : `${enTime}:${minute} ${meridiem}`}
      </option>,
    );
  }).flat(1);
}

export function openingHourSelector(startsAt = 0, endsAt = 24) {
  return [...Array(endsAt)].map((value, hour) => {
    if (hour < startsAt && hour <= endsAt) {
      return false;
    }
    const time = hour.toString().padStart(2, '0');
    const minutes = ['00', '30'];

    return minutes.map(minute =>
      <option key={`option_${time}${minute}`} value={`${time}:${minute}`}>{`${time}:${minute}`}</option>,
    );
  }).flat(1);
}

export function formatOpeningHours(initialOpeningHours = []) {
  const openingHours = [...Array(7).keys()].map((day) => {
    const existingDay = initialOpeningHours.filter(
      (openingHour) => (parseInt(openingHour.day, 10) === day),
    );
    if (existingDay.length > 0) {
      return {
        day,
        opensAt: moment(existingDay[0].opensAt).format('HH:mm'),
        closesAt: moment(existingDay[0].closesAt).format('HH:mm'),
      };
    }
    return { day, opensAt: '', closesAt: '' };
  });

  return openingHours;
}

export function breakDurationSelector() {
  return [...Array(4)].map((value, hour) => {
    const time = hour.toString().padStart(2, '0');
    let minutes = ['00', '15', '30', '45'];
    let values = [0, 0.25, 0.5, 0.75];
    if (hour === 0) {
      minutes = ['15', '30', '45'];
      values = [0.25, 0.5, 0.75];
    } else if (hour === 3) {
      minutes = ['00'];
      values = [0];
    }
    return minutes.map((minute, index) =>
      <option key={`option_${time}${minute}`} value={values[index] + hour}>{`${time}:${minute}`}</option>,
    );
  }).flat(1);
}

export function totalWorkDuration(startsAt, endsAt, breaks) {
  if (breaks) {
    const duration = moment.duration(moment(endsAt).diff(moment(startsAt)));
    let hours = duration.asHours();
    hours -= breaks
      .map((breakObject) => (
        breakObject.duration && breakObject.active ? breakObject.duration : 0
      ))
      .reduce((a, b) => a + b, 0);
    return `${hours}h`;
  }
  return '--h';
}

export default timeSelector;
