import types from './types';

export default {
  hideSidePanel: () => ({
    type: types.HIDE_SIDE_PANEL,
  }),
  updateComponent: (component, name, props = {}) => ({
    component,
    name,
    props,
    type: types.CHANGE_DISPLAYED_COMPONENT,
  }),
};
