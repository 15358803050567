// libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// actions
import actions from '../../../actions/matchmaking';

function AccountTypes({ accountType, selectAccountType }, { t }) {
  const isTechnician = accountType === 'technician';
  const isVeterinarian = accountType === 'veterinarian';

  return (
    <ul className="account__types">
      <li>
        <button
          onClick={() => selectAccountType('veterinarian')}
          className={classNames({ active: isVeterinarian })}
          title={t('matchmaking.substitutes.veterinarian')}
        >
          <span className="primary-nav__icon primary-nav__icon--vet" />
          {t('nav.matchmakingVeterinarian')}
        </button>
      </li>
      <li>
        <button
          onClick={() => selectAccountType('technician')}
          className={classNames({ active: isTechnician })}
          title={t('matchmaking.substitutes.technician')}
        >
          <span className="primary-nav__icon primary-nav__icon--tech" />
          {t('nav.matchmakingTechnician')}
        </button>
      </li>
    </ul>
  );
}

AccountTypes.propTypes = {
  accountType: PropTypes.string.isRequired,
  selectAccountType: PropTypes.func.isRequired,
};
AccountTypes.defaultProps = {};
AccountTypes.contextTypes = {
  t: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    accountType: state.matchmaking.selectedAccountType,
  };
}

export default connect(
  mapStateToProps,
  actions,
)(AccountTypes);
