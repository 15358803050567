// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { getNthMonth, getCurrentMonth, getEndOfNthMonth } from '../../helpers/dates';

// components
import CustomToolbar from './toolbar/CustomToolbar';
import ListItem from './ListItem';
import BasicLoader from '../loader/BasicLoader';
import AddAvailabilities from '../availabilities/AddAvailabilities';

// actions
import actionsSchedule from '../../actions/schedule/index';
import actionsAvailabilities from '../../actions/availabilities/index';
import confirmActionActions from '../../actions/confirmAction/index';
import actionsSidePanel from '../../actions/dynamicSidePanel/index';

// Styles
import './styles.scss';

class AvailabilitiesList extends Component {
  constructor() {
    super();
    this.state = {
      originalDate: new Date(),
      monthsPerRequest: 6,
      requestCount: 1,
    };
    this.updateRequestCount = this.updateRequestCount.bind(this);
    this.addAvailabilitiesForMonth = this.addAvailabilitiesForMonth.bind(this);
  }

  componentDidMount() {
    const {
      availabilitiesForList,
      updateAvailabilitiesListPager,
      setAvailabilityListLength,
      updateAvailabilitiesListDate,
    } = this.props;
    const { monthsPerRequest, requestCount, originalDate } = this.state;
    if (availabilitiesForList.length === 0) {
      setAvailabilityListLength(monthsPerRequest);
      updateAvailabilitiesListPager(requestCount);
      updateAvailabilitiesListDate(originalDate);
      this.fetchNewAvailabilities();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentDate: prevDate, userId: prevUserId } = prevProps;
    const { userId, currentDate, updateList, availabilitiesListIsUpdated } = this.props;
    if (currentDate !== prevDate || userId !== prevUserId) {
      this.fetchNewAvailabilities();
    }
    if (updateList === true) {
      this.updateAvailabilities();
      availabilitiesListIsUpdated();
    }
  }

  updateRequestCount() {
    const { updateAvailabilitiesListPager } = this.props;
    const { requestCount } = this.state;
    updateAvailabilitiesListPager(requestCount + 1);
    this.setState(() => ({
      requestCount: requestCount + 1,
    }));
  }

  loadMoreAvailabilities() {
    const { updateAvailabilitiesListDate, currentDate } = this.props;
    const { monthsPerRequest } = this.state;
    const newDate = getNthMonth(currentDate, monthsPerRequest);
    updateAvailabilitiesListDate(newDate);
    this.updateRequestCount();
  }

  fetchNewAvailabilities() {
    const {
      fetchAvailabilitiesForList,
      userId,
      currentDate,
    } = this.props;
    const { monthsPerRequest } = this.state;
    if (currentDate !== null) {
      const currentMonth = getCurrentMonth(currentDate);
      const nextMonth = getEndOfNthMonth(currentDate, monthsPerRequest);
      fetchAvailabilitiesForList(
        {
          id: userId,
          from: currentMonth.toISOString(),
          to: nextMonth.toISOString(),
        },
      );
    }
  }

  updateAvailabilities() {
    const {
      updateAvailabilitiesForList,
      userId,
    } = this.props;
    const { monthsPerRequest, requestCount, originalDate } = this.state;
    const start = getCurrentMonth(originalDate);
    const end = getEndOfNthMonth(originalDate, monthsPerRequest * requestCount);
    updateAvailabilitiesForList(
      {
        id: userId,
        from: start.toISOString(),
        to: end.toISOString(),
      },
    );
  }

  addAvailabilitiesForMonth(month) {
    const { updateComponent, setDefaultMonthForAvailabilities } = this.props;
    setDefaultMonthForAvailabilities(month);
    updateComponent(AddAvailabilities, 'addAvailabilities', { sidebarIcon: 'edit' });
  }

  render() {
    const { availabilitiesForList, loading, scheduleIsLoading } = this.props;
    const { t } = this.context;
    const btnClass = loading ?
      'availabilities__load-more__btn availabilities__load-more__btn--disabled' :
      'availabilities__load-more__btn';
    return (
      <div className="availabilities">
        <CustomToolbar />
        <div>
          {availabilitiesForList.map((month) =>
            <div key={month.date} className="availabilities-list">
              <div className="availabilities-list__header">
                <h3><strong>{ moment(month.date).format('MMMM') }</strong> { moment(month.date).format('YYYY') }</h3>
                <button
                  onClick={() => this.addAvailabilitiesForMonth(month.date)}
                  className="availabilities__toolbar__add-availability"
                >
                  <span>{t('availabilities.add')}</span>
                </button>
              </div>
              { Object.keys(month.weeks).length ?
                <div className="availabilities-list__content">
                  {Object.keys(month.weeks).map((weekKey) =>
                    <div key={weekKey} className="availabilities-list__week">
                      {month.weeks[weekKey].map((availability) =>
                        <ListItem
                          key={availability.id}
                          {...this.props}
                          availability={availability}
                        />,
                      )}
                    </div>,
                  )}
                </div>
              :
                <div className="availabilities-list__no-availabilities">
                  {t('availabilities.noAvailabilities')}
                </div>
              }
            </div>,
          )}
        </div>
        <div className="availabilities__load-more">
          { availabilitiesForList.length > 0 &&
            <button
              className={btnClass}
              disabled={loading}
              onClick={() => this.loadMoreAvailabilities()}
            >
              { t('form.formActions.loadMore') }
            </button>
          }
        </div>
        { scheduleIsLoading && <BasicLoader /> }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentDate: state.availabilities.listPagerCurrentDate,
    currentPage: state.availabilities.listPager,
    availabilitiesForList: state.availabilities.availabilitiesForList,
    userId: state.user.data.id,
    lang: state.i18nState.lang,
    loading: state.availabilities.loading,
    updateList: state.availabilities.updateList,
    scheduleIsLoading: state.availabilities.scheduleIsLoading,
  };
}

AvailabilitiesList.propTypes = {
  currentDate: PropTypes.instanceOf(Date),
  currentPage: PropTypes.number,
  fetchAvailabilitiesForList: PropTypes.func.isRequired,
  updateAvailabilitiesForList: PropTypes.func.isRequired,
  updateAvailabilitiesListPager: PropTypes.func.isRequired,
  updateAvailabilitiesListDate: PropTypes.func.isRequired,
  availabilitiesListIsUpdated: PropTypes.func.isRequired,
  setAvailabilityListLength: PropTypes.func.isRequired,
  availabilitiesForList: PropTypes.array.isRequired,
  updateList: PropTypes.bool.isRequired,
  scheduleIsLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  setDefaultMonthForAvailabilities: PropTypes.func.isRequired,
  updateComponent: PropTypes.func.isRequired,
};

AvailabilitiesList.defaultProps = {
  currentPage: 0,
  currentDate: null,
  userId: null,
};

AvailabilitiesList.contextTypes = {
  t: PropTypes.func,
};

export default connect(
  mapStateToProps,
  {
    ...actionsAvailabilities,
    ...actionsSchedule,
    ...confirmActionActions,
    ...actionsSidePanel,
  },
)(AvailabilitiesList);
