// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// actions
import actions from '../../../actions/dynamicSidePanel/index';

// components
import EditAccount from '../../user/forms/EditAccount';

// Styles
import './styles.scss';

const contextTypes = {
  t: PropTypes.func,
};

const propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  name: PropTypes.string.isRequired,
  sidebarDefaultProps: PropTypes.object.isRequired,
  hideSidePanel: PropTypes.func.isRequired,
  updateComponent: PropTypes.func.isRequired,
};

const defaultProps = {
  component: null,
};

// Index Component
class SidePanelContent extends Component {
  constructor() {
    super();
    this.hidePanel = this.hidePanel.bind(this);
    this.sidePanelRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { component: TagName } = this.props;
    const { component: PrevTagName } = prevProps;
    if (TagName !== PrevTagName) {
      // Scroll top when content is changed
      this.sidePanelRef.current.scrollTop = 0;
    }
  }

  hidePanel() {
    const { name, hideSidePanel, updateComponent } = this.props;
    if (name === 'editPassword') {
      updateComponent(EditAccount, 'account', { sidebarIcon: 'profile' });
    } else {
      hideSidePanel();
    }
  }

  render() {
    const { component: TagName, name, sidebarDefaultProps } = this.props;
    const { t } = this.context;
    const { sidebarIcon } = sidebarDefaultProps;

    return (
      <section className="side-panel__section">
        <div className="side-panel__section__wrapper" ref={this.sidePanelRef}>
          <div className={`side-panel__head side-panel__head--${name}`}>
            <button
              onClick={() => this.hidePanel()}
              className={classNames('side-panel__close-btn', { 'side-panel__close-btn--arrow': name === 'editPassword' })}
            />
            <h1
              className={classNames('side-panel__head__icon', { [`side-panel__head__icon--${sidebarIcon}`]: sidebarIcon })}
            >
              { t(`pages.sidePanel.title.${name}`)}
            </h1>
          </div>
          <div className="side-panel__content">
            { TagName && <TagName hidePanel={this.hidePanel} {...sidebarDefaultProps} /> }
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    component: state.dynamicSidePanel.component,
    name: state.dynamicSidePanel.name,
    sidebarDefaultProps: state.dynamicSidePanel.defaultProps,
  };
}

SidePanelContent.contextTypes = contextTypes;
SidePanelContent.propTypes = propTypes;
SidePanelContent.defaultProps = defaultProps;

export default connect(mapStateToProps, actions)(SidePanelContent);
