import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classNames from 'classnames';

// Styles
import '../Fields.scss';
import './Checkbox.scss';

function Checkbox(field, { t }) {
  const label = field.label ? field.label : field.name;
  const originalError = field.meta ? field.meta.error : '';
  const errorTranslation = t(originalError);
  const error = errorTranslation === originalError ? originalError : errorTranslation;
  const showError = field.meta && field.meta.touched && field.meta.error && !field.meta.disabled;
  return (
    <div className={classNames('field', field.className)}>
      <label>
        <Field
          name={field.name}
          {...field.input}
          type="checkbox"
          component="input"
          value={field.value}
          disabled={field.disabled}
          className="field__checkbox"
        />
        <span className="field__checkbox__label">
          <span>
            { (field.exactLabel) ? label : t(`form.${label}`) }
          </span>
        </span>
      </label>
      {showError && <span className="field__error field__error--checkbox">{t(error)}</span>}
    </div>
  );
}

Checkbox.contextTypes = {
  t: PropTypes.func,
};

export default Checkbox;
