import React from 'react';
import PropTypes from 'prop-types';
import MandatePeriodLength from './MandatePeriodLength';

function MandateInfo({ mandate }, { t }) {
  const wantedDaysCount = mandate.wantedCount === 1 ?
    `${mandate.wantedCount} ${t('event.daysOutOf.singular')} ${mandate.replacements.length}` :
    `${mandate.wantedCount} ${t('event.daysOutOf.plural')} ${mandate.replacements.length}`;
  return (
    <div className="mandate-card__details__head">
      <div>
        {mandate.replacements && mandate.replacements.length > 0 && [
          <MandatePeriodLength key="period" mandate={mandate} />,
          <span key="wanted" className="mandate-card__wanted">
            {mandate.wantedCount !== mandate.replacements.length &&
              wantedDaysCount
            }
          </span>,
        ]}
      </div>
      <div className="mandate-card__list">
        <span className={`schedule__event-details__${mandate.mandateType}`}>
          {t(`event.${mandate.mandateType}`)}
        </span>
        {mandate.accommodation &&
          <span className="schedule__event-details__accommodation--available">
            {t('event.accommodationIncluded')}
          </span>
        }
        {mandate.consultation && (
          <span className="schedule__event-details__consultation">{t('event.consultation')}</span>
        )}
        {mandate.surgery !== 'false' && (
          <span className="schedule__event-details__surgery">
            {t('event.surgery')} {mandate.surgery === 'optional' && <small>{t('event.optional')}</small>}
          </span>
        )}
      </div>
    </div>
  );
}

MandateInfo.contextTypes = {
  t: PropTypes.func,
};

MandateInfo.propTypes = {
  mandate: PropTypes.object.isRequired,
};
MandateInfo.defaultProps = {};

export default MandateInfo;
