import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { change, Field } from 'redux-form';
import { connect } from 'react-redux';
import Checkbox from '../../forms/fields/checkbox/Checkbox';

class SubstitutesSubForm extends Component {
  constructor() {
    super();
    this.updateAllSubstitutes = this.updateAllSubstitutes.bind(this);
  }

  updateAllSubstitutes(fieldState) {
    const { fields, formChange, formName } = this.props;
    fields.forEach((field) => {
      formChange(formName, `${field}.selected`, fieldState);
    });
  }

  render() {
    const { disabled, fields, substitutes, meta: { error } } = this.props;
    const { t } = this.context;

    return (
      <div>
        {!disabled &&
          <>
            <button
              onClick={() => this.updateAllSubstitutes(true)}
              className="call-to-all__select-all"
              type="button"
            >
              {t('form.addCallToAll.selectAll')}
            </button>
            <button
              onClick={() => this.updateAllSubstitutes(false)}
              className="call-to-all__select-all call-to-all__select-all--remove"
              type="button"
            >
              {t('form.addCallToAll.removeAll')}
            </button>
          </>
        }

        <div className="call-to-all__users-list">
          {fields.map((field, index) =>
            <Field
              name={`${field}.selected`}
              key={substitutes[index].id}
              type="text"
              label={`${substitutes[index].profile.firstName} ${substitutes[index].profile.lastName}`}
              component={Checkbox}
              className="field__checkbox--round"
              exactLabel
              disabled={disabled}
            />,
          )}
        </div>
        {error && <div className="call-to-all__error">{t(error)}</div>}

      </div>
    );
  }
}

SubstitutesSubForm.propTypes = {
  disabled: PropTypes.bool,
  fields: PropTypes.object,
  formChange: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  meta: PropTypes.object,
  substitutes: PropTypes.array.isRequired,
};
SubstitutesSubForm.defaultProps = {
  disabled: false,
  fields: {},
  meta: {},
};
SubstitutesSubForm.contextTypes = {
  t: PropTypes.func,
};

export default connect(
  null,
  { formChange: change },
)(SubstitutesSubForm);
