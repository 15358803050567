// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

// components
import UserForm from './../../admin/users/forms/UserForm';
import BasicLoader from '../../loader/BasicLoader';

// actions
import actions from '../../../actions/admin/users';
import userActions from '../../../actions/user';
import uploadActions from '../../../actions/uploads';
import alertsActions from '../../../actions/alerts';
import actionsSidePanel from '../../../actions/dynamicSidePanel';
import validate from '../../admin/users/validate/validate';
import normalizePhone from '../../forms/normalize/normalizePhone';
import uploader from '../../../helpers/uploader';

// helpers
import { formatEditUserData, generateTaxNumbers } from '../../../helpers/formatUsers';
import { scrollToError } from '../../../helpers/scrollToError';

class EditAccount extends Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    error: PropTypes.string,
    fetchAccountUser: PropTypes.func.isRequired,
    formDataIsLoading: PropTypes.bool.isRequired,
    formIsSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    presignUpload: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    profilePicture: PropTypes.object,
    profileVoidedCheck: PropTypes.object,
    pushAlert: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    updateComponent: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    uploadPicture: PropTypes.object,
    uploadVoidedCheck: PropTypes.object,
    uploadPictureFileChanged: PropTypes.func.isRequired,
    uploadPictureFileReset: PropTypes.func.isRequired,
    uploadVoidedCheckFileChanged: PropTypes.func.isRequired,
    uploadVoidedCheckFileReset: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    error: '',
    profilePicture: {},
    profileVoidedCheck: {},
    uploadPicture: {},
    uploadVoidedCheck: {},
  };

  constructor() {
    super();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);

    this.pictureUploader = uploader(
      this.presignedUploadPath.bind(this),
      this.updateProfilePicture.bind(this),
      'profile_picture',
      ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
    );
    this.voidedCheckUploader = uploader(
      this.presignedUploadPath.bind(this),
      this.updateProfileVoidedCheck.bind(this),
      'profile_voided_check',
      ['image/*', 'application/pdf', '.jpg', '.jpeg', '.pdf'],
    );
  }

  componentDidMount() {
    const { fetchAccountUser, id } = this.props;
    fetchAccountUser({ id });
  }

  componentWillUnmount() {
    const {
      uploadPictureFileReset,
      uploadVoidedCheckFileReset,
    } = this.props;
    uploadPictureFileReset();
    uploadVoidedCheckFileReset();
  }

  handleFormSubmit(values) {
    const {
      updateUser,
      pushAlert,
      hidePanel,
      uploadPictureFileReset,
      uploadVoidedCheckFileReset,
    } = this.props;
    const { id, ...input } = values;

    updateUser({
      id,
      input: formatEditUserData(input),
    })
      .then((data) => {
        if (data && data.updateUser.success) {
          pushAlert({
            type: 'success',
            content: 'alerts.edited',
          });
          hidePanel();
          uploadPictureFileReset();
          uploadVoidedCheckFileReset();
        }
      });
  }

  handleSignOut() {
    const { hidePanel, signOut } = this.props;
    hidePanel();
    signOut();
  }

  presignedUploadPath({ filename }) {
    const { presignUpload, id } = this.props;
    return presignUpload({ input: { filename, instanceId: id, uploadType: 'profile' } });
  }

  updateProfilePicture({ fileData }) {
    const { change, uploadPictureFileChanged } = this.props;
    uploadPictureFileChanged({ pictureFilename: fileData.metadata.filename });
    change('profile.picture', fileData);
  }

  updateProfileVoidedCheck({ fileData }) {
    const { change, uploadVoidedCheckFileChanged } = this.props;
    uploadVoidedCheckFileChanged({ voidedCheckFilename: fileData.metadata.filename });
    change('profile.voidedCheck', fileData);
  }

  render() {
    const {
      error,
      formDataIsLoading,
      formIsSubmitting,
      handleSubmit,
      hidePanel,
      pristine,
      submitting,
      updateComponent,
      profilePicture,
      profileVoidedCheck,
      uploadPicture,
      uploadPictureFileReset,
      uploadVoidedCheck,
      uploadVoidedCheckFileReset,
    } = this.props;
    const { t } = this.context;
    const submitForm = handleSubmit(this.handleFormSubmit);

    return (
      <div>
        <form onSubmit={submitForm} className="side-panel__form">
          <div className="form__spacing form__spacing--disconnect">
            <button className="form__disconnect" type="button" onClick={this.handleSignOut}>
              {t('pages.logout')}
            </button>
          </div>
          {(formIsSubmitting || formDataIsLoading) && <BasicLoader />}
          {error && <div className="form-error">{error}</div>}
          <UserForm
            formName="editAccount"
            updateComponent={updateComponent}
            pictureUploader={this.pictureUploader}
            voidedCheckUploader={this.voidedCheckUploader}
            profilePicture={profilePicture}
            profileVoidedCheck={profileVoidedCheck}
            uploadPicture={uploadPicture}
            uploadPictureFileReset={uploadPictureFileReset}
            uploadVoidedCheck={uploadVoidedCheck}
            uploadVoidedCheckFileReset={uploadVoidedCheckFileReset}
            account
          />
          <div className="form__actions">
            <button
              className="form__cancel"
              type="button"
              disabled={submitting || formIsSubmitting}
              onClick={hidePanel}
            >
              {t('form.formActions.cancel')}
            </button>
            <button
              className="form__submit"
              type="submit"
              disabled={pristine || submitting || formIsSubmitting}
            >
              {t('form.formActions.update')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users: { user = {}, profilePicture, profileVoidedCheck } } = state;
  const { roles, ...formData } = user;
  const provinceCode = process.env.REACT_APP_REGION_CODE;
  if (roles && roles.length > 0) formData.role = roles[0].name;

  return {
    id: state.user.data.id,
    formIsSubmitting: state.users.submitting,
    formDataIsLoading: state.user.loading,
    profilePicture,
    profileVoidedCheck,
    uploadPicture: {
      fileChanged: state.uploads.pictureFileChanged,
      filename: state.uploads.pictureFilename,
    },
    uploadVoidedCheck: {
      fileChanged: state.uploads.voidedCheckFileChanged,
      filename: state.uploads.voidedCheckFilename,
    },
    initialValues: {
      formName: 'editAccount',
      ...formData,
      profile: formData.profile ? {
        ...formData.profile,
        phone: normalizePhone(formData.profile.phone),
        graduation: formData.profile.graduation ?
          new Date(formData.profile.graduation).toISOString() : '',
        address: formData.profile.address ?
          { ...formData.profile.address } : { country: 'ca', province: provinceCode },
        exoticAnimals: [],
        exoticAnimalSkills: formData.profile.exoticAnimalSkills ? 'true' : 'false',
        softwares: [],
        softwareOthersCheck:
          formData.profile.softwareOthers !== '' && formData.profile.softwareOthers !== null,
        surgerySterilization: formData.profile.surgerySterilization ? 'true' : 'false',
        ...(formData.profile.surgeryCanineOvhWeightLimit) && {
          surgeryCanineOvhWeightLimit: formData.profile.surgeryCanineOvhWeightLimit.toString(),
        },
        surgeryDeclawing: formData.profile.surgeryDeclawing ? 'true' : 'false',
        surgerySoftTissueOthersCheck:
          formData.profile.surgerySoftTissueOthers !== '' && formData.profile.surgerySoftTissueOthers !== null,
        taxNumbers: formData.profile.taxNumbers ?
          formData.profile.taxNumbers : generateTaxNumbers(),
      } : {},
      saveAddress: formData.profile && formData.profile.address && formData.profile.address.address
        ? 'true' : 'false',
    },
  };
}

export default connect(
  mapStateToProps,
  { ...actions, ...userActions, ...uploadActions, ...alertsActions, ...actionsSidePanel },
)(
  reduxForm({
    form: 'editAccount',
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToError(errors),
    validate,
  })(EditAccount),
);
