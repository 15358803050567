import moment from 'moment';

const matchmakingDaysRange = (from, to) => {
  if (from && !to) {
    return moment(from).daysInMonth() + 7;
  } else if (from && to) {
    return moment(to).diff(moment(from), 'days');
  }
  return 28;
};

export function matchmakingRangeEndDate(from) {
  return moment(from).add(31, 'days');
}

export function range(start, end, step) {
  return [...Array(end - 1).keys()].map((key) =>
    (key * step) + start,
  );
}

export function yearsRange(yearsAgo) {
  const year = new Date().getFullYear() - yearsAgo;
  return [...Array(yearsAgo).keys()].map((key) =>
    year + key + 1,
  ).reverse();
}

export function matchmakingRange(dateFrom, dateTo) {
  const dayStart = {
    hour: '00',
    minute: '00',
    second: '00',
  };
  const dayEnd = {
    hour: '23',
    minute: '59',
    second: '59',
  };
  const startOfRange = moment(dateFrom).tz('America/New_York');
  const from = startOfRange.startOf('day').set(dayStart).format();

  let to = '';
  if (dateTo) {
    to = moment(dateTo).set(dayEnd).format();
  } else {
    to = startOfRange.clone().endOf('month').set(dayEnd).format();
  }
  return { from, to };
}

export { matchmakingDaysRange };
