// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

// component
import Input from '../../../forms/fields/input/Input';

class SearchForm extends Component {
  static propTypes = {
    fetchClinics: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor() {
    super();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit({ search }) {
    const { fetchClinics } = this.props;

    fetchClinics({ search });
  }

  render() {
    const { handleSubmit } = this.props;
    const submitForm = handleSubmit(this.handleFormSubmit);
    const { t } = this.context;

    return (
      <form onSubmit={submitForm}>
        <Field
          name="search"
          type="search"
          component={Input}
          className="field--light"
          innerLabel
        />
        <button className="form__submit" type="submit">
          {t('form.search')}
        </button>
      </form>
    );
  }
}

function mapStateToProps(state, { formName = 'searchClinics' }) {
  return {
    form: formName,
  };
}

export default connect(
  mapStateToProps,
)(reduxForm({
  destroyOnUnmount: true,
})(SearchForm));
