import { async, createTypes } from 'redux-action-creator';

export default createTypes([
  'MANDATE_TO_UPDATE',
  'PERIOD_IS_UPDATED',
  'SET_CLINIC',
  'SET_REPLACEMENT_TO_UPDATE',
  'SET_MANDATE_TO_UPDATE',
  ...async('CREATE_MANDATE'),
  ...async('DISABLE_MANDATE'),
  ...async('DISABLE_REPLACEMENT'),
  ...async('DISABLE_REPLACEMENT_PROPOSAL'),
  ...async('ENABLE_REPLACEMENT'),
  ...async('FETCH_ACCEPTED_PROPOSALS'),
  ...async('FETCH_PENDING_PROPOSALS'),
  ...async('FETCH_ADMIN_PENDING_PROPOSALS'),
  ...async('FETCH_MANDATES'),
  ...async('FETCH_MANDATE'),
  ...async('FETCH_MANDATE_PROPOSALS'),
  ...async('FETCH_MANDATES_IN_PERIOD'),
  ...async('FETCH_ALL_MANDATES'),
  ...async('FETCH_REPLACEMENT_TO_UPDATE'),
  ...async('FETCH_UNIQUE_MANDATE'),
  ...async('UPDATE_MANDATE_IN_LIST'),
  ...async('UPDATE_REPLACEMENT'),
  ...async('UPDATE_MANDATES_LIST'),
  ...async('UPDATE_NEXT_MANDATES_LIST'),
  ...async('UPDATE_PROPOSAL_STATUS'),
  ...async('FETCH_MANDATE_TO_UPDATE'),
  ...async('UPDATE_MANDATE'),
], 'MANDATES');
