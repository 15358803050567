import types from '../actions/proposals/types';

const initialState = {
  loading: false,
  failed: false,
  mandates: [],
  mandate: null,
  updateList: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PROPOSALS:
      return { ...state, loading: true, failed: false, updateList: false };
    case types.FETCH_PROPOSALS_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        mandates: action.payload.user.mandates.nodes,
      };
    case types.FETCH_PROPOSALS_FAIL:
      return { ...state, loading: false, failed: true };
    case types.FETCH_PROPOSAL:
      return { ...state, loading: true, failed: false, updateList: false };
    case types.FETCH_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        mandate: action.payload.mandate,
      };
    case types.FETCH_PROPOSAL_FAIL:
      return { ...state, loading: false, failed: true };
    case types.REVIEW_PROPOSALS:
      return { ...state, failed: false };
    case types.REVIEW_PROPOSALS_SUCCESS:
      return { ...state, failed: false, updateList: true };
    case types.REVIEW_PROPOSALS_FAIL:
      return { ...state, failed: true };
    case types.UPDATE_PROPOSALS:
      return { ...state, failed: false };
    case types.UPDATE_PROPOSALS_SUCCESS:
      return { ...state, failed: false, updateList: true };
    case types.UPDATE_PROPOSALS_FAIL:
      return { ...state, failed: true };
    default:
  }
  return state;
}
