import moment from 'moment';

function isBetween({ startsAt: date }, { startsAt, endsAt }) {
  return moment(date).isBetween(startsAt, endsAt, 'day', '[]');
}

export function isWithin(event, eventWithin) {
  const { startsAt, endsAt } = event;
  const { allDay, startsAt: startsAtWithin, endsAt: endsAtWithin } = eventWithin;

  return (allDay === true ||
    (moment(startsAt).isBetween(startsAtWithin, endsAtWithin, 'minutes', '[]') &&
    moment(endsAt).isBetween(startsAtWithin, endsAtWithin, 'minutes', '[]'))
  );
}

export function isStartingBefore(event1, event2) {
  return (event2.allDay === false &&
    ((moment(event1.startsAt).isBefore(event2.startsAt, 'minutes')) &&
    !(moment(event1.endsAt).isAfter(event2.endsAt, 'minutes')))
  );
}

export function isEndingAfter(event1, event2) {
  return (event2.allDay === false &&
    ((moment(event1.endsAt).isAfter(event2.endsAt, 'minutes')) &&
    !(moment(event1.startsAt).isBefore(event2.startsAt, 'minutes')))
  );
}

export function isStartingBeforeAndEndingAfter(event1, event2) {
  return (event2.allDay === false &&
    ((moment(event1.endsAt).isAfter(event2.endsAt, 'minutes')) &&
    (moment(event1.startsAt).isBefore(event2.startsAt, 'minutes')))
  );
}

export function validateEventSelection(event, availability) {
  if (isStartingBefore(event, availability) === true) {
    return 'proposal.startingBefore';
  } else if (isEndingAfter(event, availability) === true) {
    return 'proposal.endingAfter';
  } else if (isStartingBeforeAndEndingAfter(event, availability) === true) {
    return 'proposal.startingBeforeAndEndingAfter';
  }
  return null;
}

function isOverlapping(range1, range2) {
  if (isBetween(range1, range2)) {
    return true;
  }

  return isBetween(range2, range1);
}

function formatStartOfWeek(date) {
  return moment(date).startOf('week').format('DD-MM-YYYY');
}

function numberOfWeekForMandate(startsAt, endsAt) {
  const week1 = moment(startsAt).week();
  const week2 = moment(endsAt).week();
  return (week2 - week1) + 1;
}

function findIndex(weekObjectArrays, replacements) {
  const indexArray = weekObjectArrays
    .map((weekObjectArray) => weekObjectArray.filter((weekMandate) =>
      isOverlapping({
        startsAt: weekMandate.replacements[0].startsAt,
        endsAt: weekMandate.replacements[weekMandate.replacements.length - 1].endsAt,
      }, {
        startsAt: replacements[0].startsAt,
        endsAt: replacements[replacements.length - 1].endsAt,
      }),
    ).length > 0);
  // return first free index
  return indexArray.indexOf(false) === -1 ?
    indexArray.lastIndexOf(true) + 1 : indexArray.indexOf(false);
}

export function formatEvents(mandates) {
  const weeksObject = {};
  const weeksMandates = mandates
    .sort((mandate) => mandate.replacements.sort((replacement) => replacement.startsAt)[0].startsAt)
    .reduce((mandatesWithIndex, mandate) => {
      // sorted replacements for current mandate
      const replacements = mandate.replacements.sort((replacement, nextReplacement) => (
        new Date(replacement.startsAt) - new Date(nextReplacement.startsAt)
      ));

      // start of week for first replacement
      const startOfWeek = formatStartOfWeek(replacements[0].startsAt);

      // check number of weeks
      const numberOfWeeks = numberOfWeekForMandate(
        replacements[0].startsAt,
        replacements[replacements.length - 1].endsAt,
      );

      // find or create mandates array
      let weekObjectArrays = weeksObject[startOfWeek] || [[]];

      // find index in weeks objects
      let index = findIndex(weekObjectArrays, replacements);
      weekObjectArrays[index] = (weekObjectArrays[index] || []);
      weekObjectArrays[index].push(mandate);

      weeksObject[startOfWeek] = weekObjectArrays;

      // set index to replacement from mandate position
      const newMandate = {
        ...mandate,
        replacements: replacements.map((replacement) => ({
          ...replacement,
          index,
        })),
      };

      let startOfWeekEnd = formatStartOfWeek(replacements[replacements.length - 1].endsAt);
      if (startOfWeek === startOfWeekEnd) {
        // return mandate with replacements are all in the same week
        return [...mandatesWithIndex, newMandate];
      }
      for (let steps = 1; steps < numberOfWeeks; steps += 1) {
        if (steps + 1 === numberOfWeeks) {
          startOfWeekEnd = formatStartOfWeek(replacements[replacements.length - 1].endsAt);
        } else {
          const replacementIndex = steps * 7;
          startOfWeekEnd = formatStartOfWeek(replacements[replacementIndex].endsAt);
        }

        weekObjectArrays = weeksObject[startOfWeekEnd] || [[]];

        // find index for the next part of the mandate
        index = findIndex(weekObjectArrays, replacements);
        weekObjectArrays[index] = (weekObjectArrays[index] || []);
        weekObjectArrays[index].push(mandate);

        weeksObject[startOfWeekEnd] = weekObjectArrays;

        // update replacements index for second week replacements only
        newMandate.replacements = newMandate.replacements.map((replacement) => {
          if (formatStartOfWeek(replacement.startsAt) === startOfWeekEnd) {
            return { ...replacement, index };
          }

          return replacement;
        });
      }

      // return mandate with replacements with different index for first and second week
      return [...mandatesWithIndex, newMandate];
    }, []);

  return weeksMandates.map((mandate) => {
    const deletableMandate = mandate
      .replacements
      .every((replacement) => replacement.latestProposal === null);

    return mandate.replacements.map((event, index) => ({
      ...event,
      adminNote: mandate.adminNote,
      deletableMandate,
      mandateId: mandate.id,
      start: new Date(event.startsAt),
      end: new Date(event.endsAt),
      periodStart: new Date(mandate.replacements[0].startsAt),
      periodEnd: new Date(mandate.replacements[mandate.replacements.length - 1].endsAt),
      firstItem: index === 0 && mandate.replacements.length > 1,
      lastItem: index === mandate.replacements.length - 1 && mandate.replacements.length > 1,
      wantedCount: mandate.wantedCount,
      totalCount: mandate.replacementsCount,
      flexibleTime: event.flexibleTime,
      priority: event.priority,
      accommodation: mandate.accommodation,
      mandateType: mandate.mandateType,
      message: mandate.message,
      consultation: mandate.consultation,
      surgery: mandate.surgery,
      type: 'replacement',
      isSingleDay: mandate.replacements.length === 1,
      createdAt: event.createdAt || mandate.createdAt,
      updatedAt: event.updatedAt || mandate.updatedAt,
    }));
  }).flat();
}

export function formatMandatesForList(mandates) {
  return mandates.map((mandate) => {
    mandate.replacements = mandate.replacements.sort((replacement, nextReplacement) => (
      new Date(replacement.startsAt) - new Date(nextReplacement.startsAt)
    ));
    return {
      periodStart: new Date(mandate.replacements[0].startsAt),
      periodEnd: new Date(mandate.replacements[mandate.replacements.length - 1].endsAt),
      wantedCount: mandate.wantedCount,
      totalCount: mandate.replacementsCount,
      accommodation: mandate.accommodation,
      mandateType: mandate.mandateType,
      message: mandate.message,
      consultation: mandate.consultation,
      surgery: mandate.surgery,
      replacements: mandate.replacements,
      id: mandate.id,
      canDeleteMandate: !mandate.replacements.some(replacement =>
        replacement.latestProposal !== null,
      ),
    };
  });
}

export function updateMandatesList(mandatesList, updatedMandate) {
  updatedMandate.replacements = updatedMandate.replacements.sort((replacement, nextReplacement) => (
    new Date(replacement.startsAt) - new Date(nextReplacement.startsAt)
  ));

  return mandatesList.map((mandate) => {
    if (mandate.id === updatedMandate.id) {
      return {
        periodStart: new Date(updatedMandate.replacements[0].startsAt),
        periodEnd: new Date(
          updatedMandate.replacements[updatedMandate.replacements.length - 1].endsAt,
        ),
        wantedCount: updatedMandate.wantedCount,
        totalCount: updatedMandate.replacementsCount,
        accommodation: updatedMandate.accommodation,
        mandateType: updatedMandate.mandateType,
        message: updatedMandate.message,
        consultation: updatedMandate.consultation,
        surgery: updatedMandate.surgery,
        replacements: updatedMandate.replacements,
        id: updatedMandate.id,
        canDeleteMandate: !updatedMandate.replacements.some(replacement =>
          replacement.latestProposal !== null,
        ),
      };
    }
    return mandate;
  });
}

export function formatMatchmakingMandates(clinics) {
  const formattedClinics = clinics.map(clinic => ({
    ...clinic,
    mandates: formatEvents(clinic.mandates.nodes).reduce((object, node) => {
      const formattedDate = moment(node.startsAt).format('DD-MM-YYYY');
      return { ...object, [formattedDate]: [...(object[formattedDate] || []), node] };
    }, {}),
  }));

  return formattedClinics;
}

export function addClinicMandate(clinics, newMandate) {
  return clinics.map((clinic) => {
    if (clinic.id === newMandate.clinic.id) {
      return {
        ...clinic,
        mandates: {
          nodes: [
            ...clinic.mandates.nodes,
            newMandate,
          ],
        },
      };
    }

    return clinic;
  });
}

export function removeClinicMandate(clinics, disabledMandate) {
  return clinics.map((clinic) => {
    if (clinic.id === disabledMandate.clinic.id) {
      return {
        ...clinic,
        mandates: {
          nodes: clinic.mandates.nodes.filter((mandate) => mandate.id !== disabledMandate.id),
        },
      };
    }

    return clinic;
  });
}

export function removeClinicProposal(clinics, disabledProposal) {
  return clinics.map((clinic) => {
    if (clinic.id === disabledProposal.clinic.id) {
      return {
        ...clinic,
        mandates: {
          nodes: clinic.mandates.nodes.map((mandate) => ({
            ...mandate,
            replacements: mandate.replacements.map((replacement) => {
              if (disabledProposal.replacement.id === replacement.id) {
                return {
                  ...replacement,
                  latestProposal: disabledProposal.replacement.latestProposal,
                };
              }

              return replacement;
            }),
          })),
        },
      };
    }
    return clinic;
  });
}

export function updateClinicReplacements(clinics, updatedReplacements) {
  return clinics.map((clinic) => ({
    ...clinic,
    mandates: {
      nodes: clinic.mandates.nodes.map((mandate) => ({
        ...mandate,
        replacements: mandate.replacements.map((replacement) => {
          const newReplacement = updatedReplacements.find(updatedReplacement =>
            updatedReplacement.id === replacement.id,
          );

          if (newReplacement) {
            return newReplacement;
          }

          return replacement;
        }),
      })),
    },
  }));
}

export function getUniqueClinics(clinics, selectedClinics) {
  return selectedClinics.map(
    (selectedClinic) => (clinics.map(
      (clinic) => clinic.id)
      .includes(selectedClinic.id) ? [] : selectedClinic
    )).flat();
}

export function sortSelectedClinics(clinics, selectedClinics) {
  const selectedIds = selectedClinics.map(({ id }) => id);

  // Cache selectedClinics that are not included in the new fetched `clinics`
  const cachedClinics = getUniqueClinics(clinics, selectedClinics);

  // Add selectedClinics to the clinics
  const clinicsAndCachedClinics = clinics.concat(cachedClinics);

  return clinicsAndCachedClinics.sort((clinic1, clinic2) => {
    const index1 = selectedIds.indexOf(clinic1.id) === -1 ?
      selectedIds.length : selectedIds.indexOf(clinic1.id);
    const index2 = selectedIds.indexOf(clinic2.id) === -1 ?
      selectedIds.length : selectedIds.indexOf(clinic2.id);

    if (index1 < index2) {
      return -1;
    }

    if (index1 > index2) {
      return 1;
    }

    return 0;
  });
}

export function formatMandatesForProposalLists(mandates) {
  return mandates.map((mandate) => {
    mandate.replacements = mandate.replacements.sort((replacement, nextReplacement) => (
      new Date(replacement.startsAt) - new Date(nextReplacement.startsAt)
    ));
    return {
      periodStart: new Date(mandate.replacements[0].startsAt),
      periodEnd: new Date(mandate.replacements[mandate.replacements.length - 1].endsAt),
      wantedCount: mandate.wantedCount,
      totalCount: mandate.replacementsCount,
      accommodation: mandate.accommodation,
      mandateType: mandate.mandateType,
      message: mandate.message,
      consultation: mandate.consultation,
      surgery: mandate.surgery,
      replacements: mandate.replacements,
      proposals: mandate.proposals,
      clinic: mandate.clinic,
      id: mandate.id,
    };
  });
}

export function formatUniqueProposal(mandate) {
  mandate.replacements = mandate.replacements.sort((replacement, nextReplacement) => (
    new Date(replacement.startsAt) - new Date(nextReplacement.startsAt)
  ));
  mandate.proposals = mandate.proposals.sort((proposal, nextProposal) => (
    new Date(proposal.startsAt) - new Date(nextProposal.startsAt)
  ));
  return {
    periodStart: new Date(mandate.replacements[0].startsAt),
    periodEnd: new Date(mandate.replacements[mandate.replacements.length - 1].endsAt),
    wantedCount: mandate.wantedCount,
    totalCount: mandate.replacementsCount,
    accommodation: mandate.accommodation,
    mandateType: mandate.mandateType,
    message: mandate.message,
    consultation: mandate.consultation,
    surgery: mandate.surgery,
    replacements: mandate.replacements,
    id: mandate.id,
    proposals: mandate.proposals,
    canDeleteMandate: !mandate.replacements.some(
      replacement => replacement.latestProposal !== null,
    ),
  };
}

export default formatEvents;
