import moment from 'moment';
import types from '../actions/mandates/types';
import proposalsTypes from '../actions/proposals/types';
import {
  updateMandatesList,
  formatEvents,
  formatMandatesForList,
  formatMandatesForProposalLists,
  formatUniqueProposal,
} from '../helpers/formatMandates';

const initialState = {
  acceptedReplacementsForList: [],
  allEventsInPeriod: [],
  clinicId: null,
  events: [],
  eventsInPeriod: [],
  failed: false,
  listIsLoading: false,
  loading: false,
  mandatesForList: [],
  mandateToUpdateId: null,
  pageInfo: {},
  pendingReplacementsForList: [],
  periodIsUpdated: false,
  replacementToUpdate: {},
  replacementToUpdateId: '',
  scheduleIsLoading: false,
  updateSchedule: false,
  refreshMandateList: false,
  uniqueMandate: {},
  mandateUnavailable: false,
  submitting: false,
  mandateToUpdate: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_MANDATES:
      return {
        ...state,
        loading: true,
        failed: false,
        events: [],
        updateSchedule: false,
        scheduleIsLoading: true,
      };
    case types.FETCH_MANDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        events: formatEvents(action.payload.clinic.mandates.nodes),
        updateSchedule: false,
        scheduleIsLoading: false,
      };
    case types.FETCH_MANDATES_FAIL:
      return {
        ...state,
        loading: false,
        failed: false,
        events: [],
        updateSchedule: false,
        scheduleIsLoading: false,
      };
    case types.FETCH_ALL_MANDATES:
      return { ...state, loading: true, failed: false, updateSchedule: false };
    case types.FETCH_ALL_MANDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        allEventsInPeriod: action.payload.clinic.mandates.nodes.map((mandate) =>
          mandate.replacements.map((event) => moment(event.startsAt).format('DD-MM-YYYY')),
        ).flat(),
        updateSchedule: false,
      };
    case types.FETCH_ALL_MANDATES_FAIL:
      return { ...state, loading: false, failed: true, updateSchedule: false };
    case types.FETCH_MANDATES_IN_PERIOD:
      return {
        ...state,
        loading: true,
        failed: false,
        updateSchedule: false,
        periodIsUpdated: false,
      };
    case types.FETCH_MANDATES_IN_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        eventsInPeriod: action.payload.clinic.mandates.nodes.map((mandate) =>
          mandate.replacements.map((event) => moment(event.startsAt).format('DD-MM-YYYY')),
        ).flat(),
        updateSchedule: false,
        periodIsUpdated: true,
      };
    case types.FETCH_MANDATES_IN_PERIOD_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
        periodIsUpdated: false,
      };
    case types.SET_CLINIC:
      return { ...state, clinicId: action.clinicId };
    case types.PERIOD_IS_UPDATED:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
        periodIsUpdated: false,
      };
    case types.CREATE_MANDATE:
      return {
        ...state,
        loading: true,
        failed: false,
        updateSchedule: false,
        submitting: true,
      };
    case types.CREATE_MANDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: true,
        mandateToUpdateId: null,
        refreshMandateList: true,
        submitting: false,
      };
    case types.CREATE_MANDATE_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
        submitting: false,
      };
    case types.SET_REPLACEMENT_TO_UPDATE:
      return {
        ...state,
        loading: false,
        failed: true,
        replacementToUpdateId: action.replacementId,
      };
    case types.FETCH_REPLACEMENT_TO_UPDATE:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: false,
      };
    case types.FETCH_REPLACEMENT_TO_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: false,
        replacementToUpdate: action.payload.replacement,
      };
    case types.FETCH_REPLACEMENT_TO_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
      };
    case types.UPDATE_REPLACEMENT: {
      return {
        ...state,
        loading: true,
        failed: false,
        updateSchedule: false,
        submitting: true,
      };
    }
    case types.UPDATE_REPLACEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: true,
        submitting: false,
      };
    case types.UPDATE_REPLACEMENT_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
        submitting: false,
      };
    case types.DISABLE_MANDATE:
      return { ...state, loading: true, failed: false, updateSchedule: false };
    case types.DISABLE_MANDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: true,
        mandateToUpdateId: null,
        refreshMandateList: true,
      };
    case types.DISABLE_MANDATE_FAIL:
      return { ...state, loading: false, failed: true, updateSchedule: false };
    case types.DISABLE_REPLACEMENT:
      return { ...state, loading: true, failed: false, updateSchedule: false };
    case types.DISABLE_REPLACEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: true,
      };
    case types.DISABLE_REPLACEMENT_FAIL:
      return { ...state, loading: false, failed: true, updateSchedule: false };
    case types.ENABLE_REPLACEMENT:
      return { ...state, loading: true, failed: false, updateSchedule: false };
    case types.ENABLE_REPLACEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: true,
      };
    case types.ENABLE_REPLACEMENT_FAIL:
      return { ...state, loading: false, failed: true, updateSchedule: false };
    case types.UPDATE_MANDATES_LIST:
      return {
        ...state,
        loading: true,
        failed: false,
        mandatesForList: [],
        listIsLoading: true,
        refreshMandateList: false,
        updateSchedule: false,
        pageInfo: {},
      };
    case types.UPDATE_MANDATES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        mandatesForList: formatMandatesForList(action.payload.clinic.mandates.nodes),
        listIsLoading: false,
        mandateToUpdateId: null,
        pageInfo: action.payload.clinic.mandates.pageInfo,
        refreshMandateList: false,
      };
    case types.UPDATE_MANDATES_LIST_FAIL:
      return {
        ...state,
        loading: false,
        failed: false,
        mandatesForList: [],
        listIsLoading: false,
        refreshMandateList: false,
      };
    case types.UPDATE_NEXT_MANDATES_LIST:
      return {
        ...state,
        loading: true,
        failed: false,
        listIsLoading: true,
      };
    case types.UPDATE_NEXT_MANDATES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        mandatesForList: [
          ...state.mandatesForList,
          ...formatMandatesForList(action.payload.clinic.mandates.nodes),
        ],
        listIsLoading: false,
        mandateToUpdateId: null,
        pageInfo: action.payload.clinic.mandates.pageInfo,
      };
    case types.UPDATE_NEXT_MANDATES_LIST_FAIL:
      return {
        ...state,
        loading: false,
        failed: false,
        listIsLoading: false,
      };
    case types.UPDATE_MANDATE_IN_LIST:
      return {
        ...state,
        loading: false,
        failed: false,
        listIsLoading: true,
      };
    case types.UPDATE_MANDATE_IN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        listIsLoading: false,
        updateSchedule: false,
        mandatesForList: updateMandatesList(state.mandatesForList, action.payload.mandate),
        mandateToUpdateId: null,
      };
    case types.UPDATE_MANDATE_IN_LIST_FAIL:
      return {
        ...state,
        loading: false,
        failed: false,
        listIsLoading: false,
        updateSchedule: false,
      };
    case types.FETCH_MANDATE:
      return {
        ...state,
        listIsLoading: true,
        updateSchedule: false,
        refreshMandateList: false,
      };
    case types.FETCH_MANDATE_SUCCESS: {
      const { payload: { mandate } } = action;
      mandate.replacements = mandate.replacements.sort((replacement, nextReplacement) => (
        new Date(replacement.startsAt) - new Date(nextReplacement.startsAt)
      ));
      return {
        ...state,
        listIsLoading: false,
        uniqueMandate: mandate,
      };
    }
    case types.FETCH_MANDATE_FAIL:
      return {
        ...state,
        listIsLoading: false,
        mandateUnavailable: true,
        uniqueMandate: {},
      };
    case types.FETCH_ACCEPTED_PROPOSALS:
      return {
        ...state,
        loading: true,
        failed: false,
        acceptedReplacementsForList: [],
        listIsLoading: true,
        refreshMandateList: false,
        updateSchedule: false,
      };
    case types.FETCH_ACCEPTED_PROPOSALS_SUCCESS: {
      const mandatesArr = formatMandatesForProposalLists(action.payload.clinic.mandates.nodes);
      return {
        ...state,
        loading: false,
        failed: false,
        acceptedReplacementsForList: mandatesArr,
        listIsLoading: false,
        mandateToUpdateId: null,
        refreshMandateList: false,
      };
    }
    case types.FETCH_ACCEPTED_PROPOSALS_FAIL:
      return {
        ...state,
        loading: false,
        failed: false,
        acceptedReplacementsForList: [],
        listIsLoading: false,
        refreshMandateList: false,
      };
    case types.FETCH_PENDING_PROPOSALS:
      return {
        ...state,
        loading: true,
        failed: false,
        pendingReplacementsForList: [],
        listIsLoading: true,
        refreshMandateList: false,
        updateSchedule: false,
      };
    case types.FETCH_PENDING_PROPOSALS_SUCCESS: {
      return {
        ...state,
        loading: false,
        failed: false,
        pendingReplacementsForList:
          formatMandatesForProposalLists(action.payload.clinic.mandates.nodes),
        listIsLoading: false,
        mandateToUpdateId: null,
        refreshMandateList: false,
      };
    }
    case types.FETCH_ADMIN_PENDING_PROPOSALS:
      return {
        ...state,
        loading: true,
        failed: false,
        pendingReplacementsForList: [],
        listIsLoading: true,
        refreshMandateList: false,
        updateSchedule: false,
      };
    case types.FETCH_ADMIN_PENDING_PROPOSALS_SUCCESS: {
      return {
        ...state,
        loading: false,
        failed: false,
        pendingReplacementsForList: formatMandatesForProposalLists(action.payload.mandates.nodes),
        listIsLoading: false,
        mandateToUpdateId: null,
        refreshMandateList: false,
      };
    }
    case types.FETCH_PENDING_PROPOSALS_FAIL:
      return {
        ...state,
        loading: false,
        failed: false,
        pendingReplacementsForList: [],
        listIsLoading: false,
        refreshMandateList: false,
      };
    case types.FETCH_MANDATE_PROPOSALS:
      return {
        ...state,
        listIsLoading: true,
        updateSchedule: false,
        refreshMandateList: false,
      };
    case types.FETCH_MANDATE_PROPOSALS_SUCCESS: {
      const { payload: { mandate } } = action;
      return {
        ...state,
        listIsLoading: false,
        uniqueMandate: formatUniqueProposal(mandate),
      };
    }
    case types.FETCH_MANDATE_PROPOSALS_FAIL:
      return {
        ...state,
        listIsLoading: false,
        mandateUnavailable: true,
        uniqueMandate: {},
      };
    case types.UPDATE_PROPOSAL_STATUS:
      return { ...state, loading: true, failed: false, updateSchedule: false };
    case types.UPDATE_PROPOSAL_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: true,
        mandateToUpdateId: null,
        refreshMandateList: true,
      };
    case types.UPDATE_PROPOSAL_STATUS_FAIL:
      return { ...state, loading: false, failed: true, updateSchedule: false };
    case proposalsTypes.UPDATE_PROPOSALS_SUCCESS:
      return {
        ...state,
        refreshMandateList: true,
      };
    case types.SET_MANDATE_TO_UPDATE:
      return {
        ...state,
        mandateToUpdateId: action.mandateId,
      };
    case types.FETCH_MANDATE_TO_UPDATE:
      return {
        ...state,
        loading: true,
        failed: false,
        updateSchedule: false,
        mandateToUpdate: {},
      };
    case types.FETCH_MANDATE_TO_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: false,
        mandateToUpdate: action.payload.mandate,
      };
    case types.FETCH_MANDATE_TO_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
      };
    case types.UPDATE_MANDATE:
      return {
        ...state,
        loading: true,
        failed: false,
        updateSchedule: false,
      };
    case types.UPDATE_MANDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        updateSchedule: false,
      };
    case types.UPDATE_MANDATE_FAIL:
      return {
        ...state,
        loading: false,
        failed: true,
        updateSchedule: false,
      };
    default:
  }
  return state;
}
