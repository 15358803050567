export function shouldReturnEventProposals(action, store, next) {
  const {
    matchmakingAvailabilityProposals,
    matchmakingReplacementProposals,
  } = action.payload;
  const availabilitiesIds = (
    matchmakingAvailabilityProposals && matchmakingAvailabilityProposals.map(
      ({ availability }) => availability.id,
    )
  );
  const replacementIds = (
    matchmakingReplacementProposals && matchmakingReplacementProposals.map(
      ({ replacement }) => replacement.id,
    )
  );
  const eventIds = availabilitiesIds || replacementIds;
  const { eventId: latestProposalEventId } = store.getState().matchmaking.eventTooltip;
  if (eventIds.includes(latestProposalEventId)) {
    return next(action);
  }
  return {};
}

export default shouldReturnEventProposals;
