// libs
import 'react-dates/initialize';
import React, { Component } from 'react';
import { reduxForm, FieldArray, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { getDateRange, changeHour } from '../../helpers/dates';
import { scrollToError } from '../../helpers/scrollToError';

// components
import ReactDates from '../forms/fields/reactDates/ReactDates';
import DayAvailability from './fieldArray/DayAvailability';
import DistanceRadio from '../forms/fields/general/DistanceRadio';
import Checkbox from '../forms/fields/checkbox/Checkbox';
import Textarea from '../forms/fields/textarea/Textarea';
import BasicLoader from '../loader/BasicLoader';

// actions
import availActions from '../../actions/availabilities';
import alertsActions from '../../actions/alerts';
import userActions from '../../actions/user';

// validation
import validate from './validate/validate';

class AddAvailabilities extends Component {
  static createAvailability(day, { adminNote, ...values }) {
    const startHour = day.allDay ? '08:00' : day.startTime;
    const endHour = day.allDay ? '21:00' : day.endTime;
    const startTime = changeHour(day.day, startHour);
    const endTime = changeHour(day.day, endHour);
    const distance = parseInt(values.distance, 10);
    return {
      allDay: day.allDay,
      consultation: values.consultation,
      distance,
      endsAt: endTime.toISOString(),
      message: values.moreInfo,
      startsAt: startTime.toISOString(),
      surgery: values.surgery,
      ...(adminNote !== null) && { adminNote },
    };
  }

  static propTypes = {
    allEventsInPeriod: PropTypes.array.isRequired,
    createAvailabilities: PropTypes.func.isRequired,
    currentDate: PropTypes.instanceOf(Date),
    defaultMonthForAvailabilities: PropTypes.instanceOf(Date),
    defaultStartDate: PropTypes.instanceOf(Date),
    error: PropTypes.string,
    eventsInPeriod: PropTypes.array.isRequired,
    fetchAllAvailabilities: PropTypes.func.isRequired,
    fetchAvailabilitiesInPeriod: PropTypes.func.isRequired,
    formIsSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    periodDidUpdate: PropTypes.func.isRequired,
    periodIsUpdated: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    pushAlert: PropTypes.func.isRequired,
    setDefaultMonthForAvailabilities: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    user: PropTypes.object,
    userId: PropTypes.string,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    error: '',
    userId: null,
    user: null,
    currentDate: null,
    defaultStartDate: null,
    defaultMonthForAvailabilities: null,
  };

  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.periodDidUpdate = this.periodDidUpdate.bind(this);
    this.highlightAvailabilities = this.highlightAvailabilities.bind(this);
    this.updateHighlightedAvailabilities = this.updateHighlightedAvailabilities.bind(this);
    const startDate = props.defaultStartDate ? moment(props.defaultStartDate) : null;

    this.state = {
      startDate,
      endDate: null,
      focusedInput: null,
      periodDays: [],
      updatePeriod: false,
      emptyPeriod: false,
    };
  }

  componentDidMount() {
    const {
      fetchAllAvailabilities,
      userId,
    } = this.props;
    // Set moment locale
    const startOfTheMonth = moment().startOf('month');
    const endOfTheMonth = moment().endOf('month');
    fetchAllAvailabilities(
      {
        id: userId,
        from: startOfTheMonth.toISOString(),
        to: endOfTheMonth.toISOString(),
      },
    );
  }

  componentDidUpdate() {
    const { eventsInPeriod, periodIsUpdated, periodDidUpdate } = this.props;
    const { startDate, endDate } = this.state;
    const bothDateSelected = startDate && endDate;

    if (periodIsUpdated && bothDateSelected) {
      const selectedDays = getDateRange(startDate, endDate);
      const periodDays = [];
      selectedDays.forEach((day) => {
        if (!eventsInPeriod.includes(moment(day).format('DD-MM-YYYY'))) {
          periodDays.push(day);
        }
      });
      this.updatePeriod(periodDays);
      periodDidUpdate();
    }
  }

  componentWillUnmount() {
    const { setDefaultMonthForAvailabilities } = this.props;
    setDefaultMonthForAvailabilities(null);
  }

  updatePeriod(periodDays) {
    if (periodDays.length !== 0) {
      this.setState({
        periodDays,
        updatePeriod: true,
        emptyPeriod: false,
      });
    } else {
      this.setState({
        emptyPeriod: true,
      });
    }
  }

  handleFormSubmit(values) {
    const { userId, createAvailabilities, hidePanel, pushAlert } = this.props;
    const { daysAvailabilities } = values;
    const inputArray = [];
    daysAvailabilities.forEach((day) => {
      const input = AddAvailabilities.createAvailability(day, values);
      inputArray.push(input);
    });
    const availabilitiesToCreate = {
      userId,
      input: {
        availabilities: inputArray,
      },
    };
    createAvailabilities(availabilitiesToCreate)
      .then((data) => {
        if (data && data.createAvailabilities.success) {
          pushAlert({
            type: 'success',
            content: 'alerts.added',
          });
          hidePanel();
        } else {
          pushAlert({
            type: 'error',
            content: 'alerts.errorOccurred',
          });
        }
      });
  }

  handlePeriodChange(startDate, endDate) {
    const { fetchAvailabilitiesInPeriod, userId } = this.props;
    if (startDate && endDate) {
      fetchAvailabilitiesInPeriod(
        {
          id: userId,
          from: startDate.toISOString(),
          to: endDate.toISOString(),
        },
      );
    }
  }

  periodDidUpdate() {
    this.setState({
      updatePeriod: false,
    });
  }

  highlightAvailabilities(date) {
    const { allEventsInPeriod } = this.props;
    return allEventsInPeriod.includes(date.format('DD-MM-YYYY'));
  }

  updateHighlightedAvailabilities(date) {
    const { fetchAllAvailabilities, userId } = this.props;
    const startOfTheMonth = moment(date).startOf('month');
    const endOfTheMonth = moment(date).endOf('month');

    fetchAllAvailabilities(
      {
        id: userId,
        from: startOfTheMonth.toISOString(),
        to: endOfTheMonth.toISOString(),
      },
    );
  }

  render() {
    const {
      currentDate,
      defaultMonthForAvailabilities,
      error,
      formIsSubmitting,
      handleSubmit,
      hidePanel,
      isAdmin,
      lang,
      pristine,
      submitting,
      user,
    } = this.props;
    const { periodDays, updatePeriod, emptyPeriod } = this.state;
    const { t } = this.context;
    const defaultMonth =
      defaultMonthForAvailabilities ? moment(defaultMonthForAvailabilities) : moment(currentDate);
    const submitForm = handleSubmit(this.handleFormSubmit);

    return (
      <div>
        <form onSubmit={submitForm} className="side-panel__form">
          <div>
            {formIsSubmitting && <BasicLoader />}
            {error && <div className="form-error">{error}</div>}
            {user && (
              <fieldset className="side-panel__form__section side-panel__form__section--profile">
                <div>
                  <div className="profile">
                    {user.profile.picture && user.profile.picture.small ?
                      <div
                        className="profile__image"
                        style={{ backgroundImage: `url(${user.profile.picture.small})` }}
                      />
                      :
                      <div className="profile__image profile__image--empty" />
                    }
                    <div className="profile__infos">
                      <span className="profile__infos__title">
                        {`${user.profile.firstName} ${user.profile.lastName}`}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            )}
            <fieldset className="side-panel__form__section">
              <div className={`react-dates-lang-wrapper-${lang}`}>
                <h2 className="side-panel__form__section__title">{t('form.addAvailability.period')}*</h2>
                <ReactDates
                  startDate={this.state.startDate}
                  startDateId="periodStart"
                  endDate={this.state.endDate}
                  endDateId="periodEnd"
                  onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                  onClose={({ startDate, endDate }) => this.handlePeriodChange(startDate, endDate)}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                  numberOfMonths={1}
                  startDatePlaceholderText={t('form.addAvailability.startDate')}
                  endDatePlaceholderText={t('form.addAvailability.endDate')}
                  isDayHighlighted={(date) => this.highlightAvailabilities(date)}
                  initialVisibleMonth={
                    this.state.startDate === null ?
                      () => defaultMonth : () => this.state.startDate
                  }
                  onPrevMonthClick={(newMonth) => this.updateHighlightedAvailabilities(newMonth)}
                  onNextMonthClick={(newMonth) => this.updateHighlightedAvailabilities(newMonth)}
                />
              </div>
            </fieldset>
            {Array.isArray(periodDays) && periodDays.length > 0 ?
              <div>
                <fieldset className="side-panel__form__section">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.addAvailability.availabilities')}*</h2>
                    <div>
                      <FieldArray
                        name="daysAvailabilities"
                        component={DayAvailability}
                        periodDays={periodDays}
                        updatePeriod={updatePeriod}
                        periodDidUpdate={this.periodDidUpdate}
                        moment={moment}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset className="side-panel__form__section">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.addAvailability.travel')}*</h2>
                    <DistanceRadio />
                  </div>
                </fieldset>
                <fieldset className="side-panel__form__section">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.addAvailability.other')}</h2>
                    <div>
                      <Field
                        name="consultation"
                        type="text"
                        label="addAvailability.consultation"
                        component={Checkbox}
                        className="field__checkbox--full-width field__checkbox--clustered"
                      />
                      <Field
                        name="surgery"
                        type="text"
                        label="addAvailability.surgery"
                        component={Checkbox}
                        className="field__checkbox--full-width field__checkbox--clustered"
                      />
                    </div>
                    <Field
                      name="moreInfo"
                      component={Textarea}
                      label="addAvailability.moreInfo"
                    />
                  </div>
                </fieldset>
                {isAdmin &&
                <fieldset className="side-panel__form__section side-panel__form__section--admin">
                  <div>
                    <h2 className="side-panel__form__section__title">{t('form.adminSection')}</h2>
                    <div className="fields-one-col">
                      <Field
                        name="adminNote"
                        label="adminNoteGeneral"
                        component={Textarea}
                        className="field--light"
                      />
                    </div>
                  </div>
                </fieldset>
              }
              </div>
              :
              <div>
                <fieldset className="side-panel__form__section side-panel__form__section--padded">
                  <div>
                    <span className="side-panel__form__hint">{t('form.addAvailability.selectPeriod')}</span>
                    {emptyPeriod &&
                      <span className="side-panel__form__hint side-panel__form__hint--alert">{t('form.addAvailability.datesFilled')}</span>
                    }
                  </div>
                </fieldset>
              </div>
            }
          </div>
          <div className="form__actions">
            <button
              className="form__cancel"
              type="button"
              disabled={submitting || formIsSubmitting}
              onClick={() => hidePanel()}
            >
              {t('form.formActions.cancel')}
            </button>
            <button
              className="form__submit"
              type="submit"
              disabled={
                pristine || submitting || formIsSubmitting ||
                !Array.isArray(periodDays) || periodDays.length === 0
              }
            >
              {t('form.formActions.add')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: {
      distance: state.user.data.profile.defaultDistance.toString(),
      consultation: true,
      surgery: state.user.data.profile.surgery,
      moreInfo: '',
    },
    allEventsInPeriod: state.availabilities.allEventsInPeriod,
    currentDate: state.schedule.date,
    defaultMonthForAvailabilities: state.availabilities.defaultMonthForAvailabilities,
    eventsInPeriod: state.availabilities.eventsInPeriod,
    formIsSubmitting: state.availabilities.submitting,
    isAdmin: state.user.role === 'admin',
    lang: state.i18nState.lang,
    periodIsUpdated: state.availabilities.periodIsUpdated,
    userId: state.user.role === 'admin' ?
      state.availabilities.userId :
      state.user.data.id,
  };
}

export default withRouter(connect(mapStateToProps,
  { ...availActions, ...alertsActions, ...userActions })(
  reduxForm({
    form: 'addAvailabilities',
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToError(errors),
    validate,
  })(AddAvailabilities)));
