import { async, createTypes } from 'redux-action-creator';

export default createTypes([
  ...async('CREATE_CALL_TO_ALL'),
  ...async('FETCH_AVAILABLE_SUBSTITUTES_LIST'),
  ...async('FETCH_CALL_TO_ALL'),
  ...async('FETCH_USER_CALL_TO_ALLS'),
  ...async('UPDATE_STATUS_CALL_TO_ALL'),
  ...async('USER_REVIEW_CALL_TO_ALLS'),
  'REPLACEMENT_FOR_CALL_TO_ALL',
], 'CALL_TO_ALL');
