// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// Components
import BasicLoader from '../loader/BasicLoader';
import TimesheetSelectWeek from './TimesheetSelectWeek';
import UserTimesheets from './UserTimesheets';

// Actions
import actions from '../../actions/timesheet';

// Styles
import './styles.scss';
import TimesheetExport from './TimesheetExport';

class TimesheetByUserList extends Component {
  static propTypes = {
    accountType: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    timesheets: PropTypes.array,
    reinitTimesheets: PropTypes.bool.isRequired,
    fetchTimesheets: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    timesheets: [],
  };

  constructor() {
    super();
    this.state = {
      date: moment().hour(0),
      statusFilter: '',
    };
    this.updateListDate = this.updateListDate.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
  }

  componentDidMount() {
    this.fetchTimesheetsList();
  }

  componentDidUpdate(prevProps) {
    const { accountType: prevAccountType } = prevProps;
    const { accountType, reinitTimesheets } = this.props;

    if (reinitTimesheets || prevAccountType !== accountType) {
      this.fetchTimesheetsList();
    }
  }

  changeFilter(event) {
    this.setState({ statusFilter: event.target.value });
    this.fetchTimesheetsList();
  }

  updateListDate(date) {
    this.setState({ date });
  }

  fetchTimesheetsList() {
    const {
      accountType,
      fetchTimesheets,
    } = this.props;
    const { date } = this.state;
    fetchTimesheets({
      accountType,
      from: date.toISOString(),
      to: date.toISOString(),
      first: 10000,
    });
  }

  render() {
    const { accountType, loading, timesheets } = this.props;
    const { t } = this.context;
    const { statusFilter, date } = this.state;
    return (
      <div className="timesheets__wrapper">
        <div className="timesheets__toolbar">
          <div className="timesheets__toolbar__title">
            <h2>{t('timesheets.title')}</h2>
          </div>
          <div className="timesheets__toolbar__actions">
            <div className="field">
              <label className="field__label">
                {t('timesheets.filters.title')}
              </label>
              <div className="field__select__wrapper">
                <select
                  value={this.state.value}
                  onChange={this.changeFilter}
                  className="field__select"
                >
                  <option value="">{t('timesheets.filters.none')}</option>
                  <option value="pending">{t('timesheets.filters.incomplete')}</option>
                  <option value="submitted">{t('timesheets.filters.complete')}</option>
                  <option value="confirmed">{t('timesheets.filters.confirmed')}</option>
                </select>
              </div>
            </div>
            <TimesheetSelectWeek accountType={accountType} updateListDate={this.updateListDate} />
          </div>
        </div>
        <TimesheetExport
          accountType={accountType}
          date={date}
          timesheetsAvailables={timesheets.length > 0}
        />
        { timesheets.length ?
          <div>
            {timesheets.map((timesheet) => (
              (!statusFilter || timesheet.status === statusFilter || (statusFilter === 'pending' && timesheet.status === 'late')) && (
                <UserTimesheets
                  key={timesheet.id}
                  timesheet={timesheet}
                  statusFilter={statusFilter}
                />
              )
            ))}
          </div>
        :
          <div className="timesheets-text">
            <p>{t('timesheets.noTimesheet')}</p>
          </div>
        }
        {loading && <BasicLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    accountType: state.matchmaking.selectedAccountType,
    loading: state.timesheets.loading,
    timesheets: state.timesheets.timesheets,
    reinitTimesheets: state.timesheets.reinitTimesheets,
  };
}

export default withRouter(connect(
  mapStateToProps,
  { ...actions },
)(TimesheetByUserList));
