import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'redux-form';
import Input from '../input/Input';
import ZipCodeField from '../general/ZipCode';
import Select from '../select/Select';

function AddressForm({ subform, fieldName }, { t }) {
  const prefix = subform ? `${subform}.` : '';
  const provinceCode = process.env.REACT_APP_REGION_CODE;
  return (
    <div>
      <div className="fields-one-col">
        <Field
          name={`${prefix}${fieldName}.address`}
          label="address"
          type="text"
          component={Input}
          className="field--light"
          required
        />
      </div>
      <div className="fields-one-col">
        <Field
          name={`${prefix}${fieldName}.address2`}
          label="address2"
          type="text"
          component={Input}
          className="field--light"
        />
      </div>
      <div className="fields-two-col">
        <Field
          name={`${prefix}${fieldName}.city`}
          label="city"
          type="text"
          component={Input}
          className="field--light"
          required
        />
        <ZipCodeField
          name={`${prefix}${fieldName}.zipcode`}
        />
      </div>
      <div className="fields-two-col">
        <Field
          name={`${prefix}${fieldName}.province`}
          label="province"
          type="text"
          component={Select}
          disabled
          className="field--light"
          required
        >
          <option key={provinceCode} value={provinceCode}>
            {t(`form.locations.${provinceCode}`)}
          </option>
        </Field>
        <Field
          name={`${prefix}${fieldName}.country`}
          label="country"
          type="text"
          component={Select}
          disabled
          className="field--light"
          required
        >
          <option key="canada" value="ca">{t('form.locations.ca')}</option>
        </Field>
      </div>
    </div>
  );
}

AddressForm.contextTypes = {
  t: PropTypes.func,
};

AddressForm.propTypes = {
  subform: PropTypes.string,
  fieldName: PropTypes.string,
};

AddressForm.defaultProps = {
  subform: '',
  fieldName: 'address',
};

export default AddressForm;
