// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { formatHours } from '../../../helpers/formatDates';

// component
import EventDetails from './EventDetails';

// actions
import actionsAvailabilities from '../../../actions/availabilities/index';
import alertsActions from '../../../actions/alerts/index';

// css
import './styles.scss';

class Event extends Component {
  constructor() {
    super();
    this.toggleDetails = this.toggleDetails.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.wrapperRef = React.createRef();
    this.state = {
      showDetails: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  deleteEvent() {
    const { disableAvailability, event: { type, id }, pushAlert } = this.props;
    switch (type) {
      case 'availability':
        disableAvailability({ id }).then((data) => {
          if (data && data.disableAvailability.success) {
            pushAlert({
              type: 'success',
              content: 'alerts.deleted',
            });
          } else {
            pushAlert({
              type: 'error',
              content: 'alerts.errorOccurred',
            });
          }
        });
        break;
      default:
    }
    this.toggleDetails();
  }

  handleClickOutside(event) {
    const { showDetails } = this.state;
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (showDetails) {
        this.setState({
          showDetails: false,
        });
      }
    }
  }

  toggleDetails() {
    this.setState(({ showDetails }) => ({
      showDetails: !showDetails,
    }));
  }

  render() {
    const {
      event: {
        allDay,
        start,
        end,
        type,
        latestProposal,
      },
    } = this.props;
    const { showDetails } = this.state;
    const { t } = this.context;
    const eventClass = `schedule__event schedule__event--${type}`;
    const status = latestProposal ? latestProposal.status : 'availability';
    const statusClass = `schedule__event--${status}`;
    const hasProposals = ['pending', 'pending_clinic', 'pending_admin', 'confirmed'].includes(status);
    const title = hasProposals ? latestProposal.clinic.name : t(`event.${type}`);
    const replacement = hasProposals && latestProposal.replacement ?
      latestProposal.replacement : false;
    const startDate = replacement ? replacement.startsAt : start;
    const endDate = replacement ? replacement.endsAt : end;
    return (
      <div
        className={classNames(
          eventClass,
          statusClass,
          { 'schedule__event--toggled': showDetails },
        )}
        ref={this.wrapperRef}
      >
        <div
          tabIndex="0"
          role="button"
          onClick={this.toggleDetails}
          onKeyPress={this.toggleDetails}
          className="schedule__event__toggle"
        >
          <span className="schedule__event__title">{title}</span>
          { allDay && !hasProposals ?
            <div>
              <span>{t('event.allDay')}</span>
            </div>
            :
            <div>
              <span className="schedule__event__from">{formatHours(startDate)}</span>
              <span className="schedule__event__to">{formatHours(endDate)}</span>
            </div>
          }
        </div>
        { showDetails &&
          <EventDetails
            {...this.props}
            toggleDetails={this.toggleDetails}
            deleteEvent={this.deleteEvent}
            wrapperWidth={this.wrapperRef.current.offsetWidth}
          />
        }
      </div>
    );
  }
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
  pushAlert: PropTypes.func.isRequired,
  disableAvailability: PropTypes.func,
};

Event.contextTypes = {
  t: PropTypes.func,
};

Event.defaultProps = {
  disableAvailability: () => {},
};

function mapStateToProps() {
  return {
  };
}


export default connect(
  mapStateToProps,
  { ...actionsAvailabilities, ...alertsActions },
)(Event);
