import formatExoticAnimals from './formatExoticAnimals';
import formatSoftwares from './formatSoftwares';

export function formatRole(user) {
  if (user.roles.length === 0) {
    return null;
  }

  return user.roles[0].name;
}

export function getCurrentClinicId(managedClinics) {
  if (managedClinics.length > 0) {
    const currentClinicId = localStorage.getItem('currentClinicId');

    if (currentClinicId) {
      const clinic = managedClinics.find((managedClinic) => managedClinic.id === currentClinicId);

      if (clinic) {
        return clinic.id;
      }
    }

    return managedClinics[0].id;
  }

  return null;
}

export function setCurrentClinicId(clinicId) {
  localStorage.setItem('currentClinicId', clinicId);

  return clinicId;
}

export function getSubstituteName(profile) {
  return `${profile.firstName} ${profile.lastName}`;
}

export function formatEditUserData(values) {
  const {
    formName,
    saveAddress,
    profile: {
      accommodationPreferences,
      accountType,
      address,
      adminNote,
      exoticAnimalMessage,
      graduation,
      licenceNumber,
      picture,
      softwareOthers,
      softwareOthersCheck,
      surgeryCanineOvhWeightLimit,
      surgeryMessage,
      surgerySoftTissueOthers,
      surgerySoftTissueOthersCheck,
      voidedCheck,
      ...profile
    },
    ...input
  } = values;
  const isSubstitute = values.role === 'substitute';

  return {
    ...input,
    profile: {
      ...profile,
      defaultDistance: parseInt(profile.defaultDistance, 10),
      gloveSize: parseFloat(profile.gloveSize),
      graduation: profile.graduation || null,
      phone: `+1${profile.phone.replace(/[^\d]/g, '')}`,
      licenceNumber: licenceNumber || null,
      softwareOthers: softwareOthersCheck ? softwareOthers : null,
      softwares: formatSoftwares(profile.softwares),
      surgerySterilization: profile.surgerySterilization === 'true',
      ...(surgeryCanineOvhWeightLimit !== '' && surgeryCanineOvhWeightLimit !== null) && {
        surgeryCanineOvhWeightLimit: parseInt(surgeryCanineOvhWeightLimit, 10),
      },
      surgeryDeclawing: profile.surgeryDeclawing === 'true',
      surgeryMessage: surgeryMessage || null,
      surgerySoftTissueCaesarean: profile.surgerySoftTissueCaesarean || false,
      surgerySoftTissueCystotomy: profile.surgerySoftTissueCystotomy || false,
      surgerySoftTissueForeignBody: profile.surgerySoftTissueForeignBody || false,
      surgerySoftTissueMassAblation: profile.surgerySoftTissueMassAblation || false,
      surgerySoftTissuePyometra: profile.surgerySoftTissuePyometra || false,
      surgerySoftTissueOthers: surgerySoftTissueOthersCheck ? surgerySoftTissueOthers : null,
      exoticAnimals: formatExoticAnimals(profile.exoticAnimals),
      ...(accommodationPreferences) && { accommodationPreferences },
      exoticAnimalMessage: exoticAnimalMessage || null,
      exoticAnimalSkills: profile.exoticAnimalSkills === 'true',
      accommodationBringAnimals: profile.accommodationBringAnimals || false,
      ...(graduation !== '') && { graduation },
      ...(adminNote !== null) && { adminNote },
      ...(isSubstitute) && { accountType },
      ...(isSubstitute || saveAddress === 'true') ?
        { address } :
        {
          ...(address && address.id) && {
            address: {
              ...address,
              destroy: true,
            },
          },
        },
      ...(picture && picture.id !== undefined) && { picture },
      ...(voidedCheck && voidedCheck.id !== undefined) && { voidedCheck },
    },
  };
}

export function generateTaxNumbers() {
  return (process.env.REACT_APP_REGION_CODE === 'qc') ?
    [
      {
        type: 'gst',
        number: '',
      },
      {
        type: 'qst',
        number: '',
      },
    ]
    :
    [
      {
        type: 'hst',
        number: '',
      },
    ];
}
