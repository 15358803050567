// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { connect } from 'react-redux';
import 'react-dates/initialize';

// styles
import './styles.scss';

class DateSelector extends Component {
  static propTypes = {
    changeDate: PropTypes.func.isRequired,
    currentDate: PropTypes.instanceOf(Date).isRequired,
    dateFrom: PropTypes.object.isRequired,
    dateTo: PropTypes.object.isRequired,
    wrapperClassNames: PropTypes.string,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    wrapperClassNames: '',
  };

  constructor() {
    super();
    this.state = {
      focused: null,
    };
  }

  handlePeriodChange(date) {
    const { changeDate } = this.props;
    changeDate(date);
  }

  highlightedDates(date) {
    const { dateFrom, dateTo } = this.props;
    if (moment(date).isBetween(dateFrom, dateTo)) {
      return true;
    }
    return false;
  }

  render() {
    const { currentDate, wrapperClassNames } = this.props;
    return (
      <div className={wrapperClassNames}>
        <SingleDatePicker
          id="matchmakingDateSelector"
          date={moment(currentDate)}
          focused={this.state.focused}
          hideKeyboardShortcutsPanel
          isDayHighlighted={(date) => this.highlightedDates(date)}
          isOutsideRange={() => false}
          noBorder
          numberOfMonths={2}
          onDateChange={(date) => this.handlePeriodChange(date)}
          onFocusChange={({ focused }) => this.setState({ focused })}
          readOnly
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentDate: state.schedule.date,
    dateFrom: state.schedule.from,
    dateTo: state.schedule.to,
    lang: state.i18nState.lang,
  };
}

export default connect(
  mapStateToProps,
  null,
)(DateSelector);
