import types from '../actions/softwares/types';

const initialState = {
  softwares: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_SOFTWARES:
      return { ...state };
    case types.FETCH_SOFTWARES_SUCCESS:
      return {
        ...state,
        softwares: action.payload.softwares,
      };
    default:
  }
  return state;
}
