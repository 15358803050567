import { asyncQuery, asyncMutation } from '../../../config/graphql/async-action-creator';
import queries from './queries';
import types from './types';
import store from '../../../config/redux/store';
import client from '../../../config/graphql/client';

export default {
  exportTimesheets: (variables = {}) => asyncQuery(
    store,
    types.EXPORT_TIMESHEETS,
    queries.timesheetsExport,
    { ...variables },
    client,
  ),
  fetchTimesheets: (variables = {}) => asyncQuery(
    store,
    variables.after ? types.UPDATE_NEXT_TIMESHEETS_LIST : types.FETCH_TIMESHEETS,
    queries.fetchTimesheets,
    { ...variables },
    client,
  ),
  fetchTimesheetEntry: (variables = {}) => asyncQuery(
    store,
    types.FETCH_TIMESHEET_ENTRY,
    queries.fetchTimesheetEntry,
    { ...variables },
    client,
  ),
  reviewTimesheetEntries: (variables) => asyncMutation(
    store,
    types.REVIEW_TIMESHEET_ENTRIES,
    queries.reviewTimesheetEntries,
    variables,
    client,
  ),
  setEntryToUpdate: (entryId, entryDate) => ({
    type: types.SET_ENTRY_TO_UPDATE,
    entryId,
    entryDate,
  }),
  setReinitTimesheets: () => ({
    type: types.SET_REINIT_TIMESHEET,
  }),
  updateTimesheetEntries: (variables) => asyncMutation(
    store,
    types.UPDATE_TIMESHEET_ENTRIES,
    queries.updateTimesheetEntries,
    variables,
    client,
  ),
};
