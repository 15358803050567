// libs
import React from 'react';
import PropTypes from 'prop-types';

// components
import { Field, FieldArray, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Input from '../../../forms/fields/input/Input';
import PictureUploader from '../../../forms/fields/file/PictureUploader/PictureUploader';
import TextArea from '../../../forms/fields/textarea/Textarea';
import Checkbox from '../../../forms/fields/checkbox/Checkbox';
import AddressForm from '../../../forms/fields/general/AddressForm';
import PhoneField from '../../../forms/fields/general/Phone';
import RadioGroup from '../../../forms/fields/radio/RadioGroup';
import RadioBoolean from '../../../forms/fields/radio/RadioBoolean';
import ClinicUserSubForm from './ClinicUserSubForm';
import OpeningHoursSubForm from './OpeningHoursSubForm';
import SoftwaresSubForm from './SoftwaresSubForm';
import LanguageSubForm from '../../users/forms/LanguageSubForm';

function ClinicForm({
  adminNote,
  addBillingAddress,
  blacklistedUsers,
  clinicName,
  clinicPicture,
  formTitle,
  managers,
  openingHours,
  otherVets,
  softwareSkills,
  softwareOthersCheck,
  uploader,
  uploadPicture,
  uploadPictureFileReset,
  userRole,
  ...props
}, { t }) {
  const isAdmin = userRole === 'admin';
  return (
    <div>
      {formTitle !== 'createClinic' &&
        <fieldset className="side-panel__form__section">
          <div>
            <div className="fields-one-col">
              <Field
                name="picture"
                label="clinic.picture"
                component={PictureUploader}
                uploader={uploader}
                uploadFileReset={uploadPictureFileReset}
                currentPicture={clinicPicture}
                upload={uploadPicture}
                pictureSize="medium"
                noLabel
                formContext="clinic"
              />
            </div>
            <div className="side-panel__form__name">
              {clinicName}
            </div>
          </div>
        </fieldset>
      }
      <fieldset className="side-panel__form__section side-panel__form__section--continued">
        <div>
          <h2 className="side-panel__form__section__title">{t('form.clinicInfo')}</h2>
          <div className="fields-two-col">
            <Field
              name="name"
              type="text"
              component={Input}
              className="field--light"
              required
            />
            <PhoneField />
          </div>
          <div className="fields-one-col">
            <Field
              name="message"
              label="description"
              component={TextArea}
              className="field--light"
            />
          </div>

          {isAdmin && (
            <Field
              name="manualValidation"
              type="text"
              label="manualValidation"
              component={Checkbox}
            />
          )}
        </div>
      </fieldset>

      <fieldset className="side-panel__form__section side-panel__form__section--continued">
        <div>
          <h2 className="side-panel__form__section__title">{t('form.address')}</h2>
          <AddressForm />
        </div>
      </fieldset>

      <fieldset className="side-panel__form__section side-panel__form__section--continued">
        <div>
          <h2 className="side-panel__form__section__title">{t('form.managers')}</h2>
          {isAdmin ? (
            <FieldArray
              {...props}
              managers={managers}
              name="clinicUsers"
              formTitle={formTitle}
              component={ClinicUserSubForm}
            />
          ) : (
            <ul className="managers">
              {managers.map(({ id, profile: { firstName, lastName, picture } }) =>
                <li key={id} className="manager__user">
                  {picture && picture.small ?
                    <div
                      className="manager__user__image"
                      style={{ backgroundImage: `url(${picture.small})` }}
                    />
                  :
                    <div className="manager__user__image manager__user__image--empty" />
                  }
                  <div className="manager__user__name">
                    <span>{`${firstName} ${lastName}`}</span>
                  </div>
                </li>,

              )}
            </ul>
          )}
        </div>
      </fieldset>
      <fieldset className="side-panel__form__section side-panel__form__section--continued">
        <div>
          <h2 className="side-panel__form__section__title">{t('form.openingHours')}</h2>
          <FieldArray
            name="openingHours"
            component={OpeningHoursSubForm}
            openingHours={openingHours}
          />
        </div>
      </fieldset>
      <fieldset className="side-panel__form__section side-panel__form__section--continued">
        <div>
          <h2 className="side-panel__form__section__title">{t('form.professionalInfo')}</h2>
          <div className="fields-one-col">
            <Field
              name="consultationAverageDuration"
              type="number"
              component={Input}
              className="field--light"
            />
          </div>

          <div className="fields-one-col">
            <Field
              name="softwareSkills"
              type="text"
              label="softwareUsed"
              component={RadioBoolean}
            />
          </div>
          { softwareSkills && (
            <div className="fields-one-col fields-one-col--conditional">
              <div className="fields-one-col">
                <div className="field__checkbox-list">
                  <div className="field__checkbox-list__wrapper field__checkbox-list__wrapper--no-label">
                    <FieldArray
                      name="softwares"
                      component={SoftwaresSubForm}
                    />
                    <div className="field">
                      <Field
                        name="softwareOthersCheck"
                        type="text"
                        label="softwareOthers"
                        component={Checkbox}
                      />
                      {softwareOthersCheck &&
                        <div className="field__checkbox-list__other">
                          <Field
                            name="softwareOthers"
                            label="softwareOthers"
                            type="text"
                            component={Input}
                            className="field--light field--no-label"
                            required
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="fields-one-col">
            <Field
              name="otherVets"
              type="text"
              label="otherVets"
              className="radio-boolean--wrap"
              component={RadioBoolean}
            />
          </div>
        </div>
      </fieldset>

      <fieldset className="side-panel__form__section side-panel__form__section--continued">
        <div>
          <h2 className="side-panel__form__section__title">{t('form.languages')}</h2>
          <FieldArray
            name="languages"
            component={LanguageSubForm}
          />
        </div>
      </fieldset>

      {isAdmin && (
        <fieldset className="side-panel__form__section side-panel__form__section--admin">
          <div>
            <h2 className="side-panel__form__section__title">{t('form.rate')}</h2>
            <RadioGroup
              small
              label="rate"
              name="rate"
              radioList={['city', 'remote']}
            />
          </div>
        </fieldset>
      )}

      <fieldset className="side-panel__form__section">
        <div>
          <h2 className="side-panel__form__section__title">{t('form.clinicBilling')}</h2>
          <div className="fields-one-col">
            <Field
              name="billingContact"
              type="text"
              component={Input}
              className="field--light"
            />
          </div>
          <Field
            name="addBillingAddress"
            type="text"
            label="addBillingAddress"
            className="radio-boolean--wrap"
            component={RadioBoolean}
          />
          {addBillingAddress &&
            <AddressForm fieldName="billingAddress" />
          }
        </div>
      </fieldset>
      {isAdmin && (
        <fieldset className="side-panel__form__section">
          <div>
            <h2 className="side-panel__form__section__title">{t('form.adminSection')}</h2>
            <div className="fields-one-col">
              <Field
                name="adminNote"
                label="adminNoteClinic"
                component={TextArea}
                className="field--light"
              />
            </div>
          </div>
          <div>
            <h2 className="side-panel__form__section__title">{t('form.blacklistedUsers')}</h2>
            <FieldArray
              {...props}
              managers={blacklistedUsers}
              name="blacklistedUsers"
              formTitle={formTitle}
              component={ClinicUserSubForm}
            />
          </div>
        </fieldset>
      )}
    </div>
  );
}

ClinicForm.propTypes = {
  addBillingAddress: PropTypes.bool,
  adminNote: PropTypes.string,
  blacklistedUsers: PropTypes.array,
  clinicName: PropTypes.string,
  clinicPicture: PropTypes.object,
  formTitle: PropTypes.string.isRequired,
  managers: PropTypes.array,
  openingHours: PropTypes.array.isRequired,
  otherVets: PropTypes.bool,
  softwareSkills: PropTypes.bool.isRequired,
  softwareOthersCheck: PropTypes.bool,
  upload: PropTypes.object,
  uploader: PropTypes.object,
  uploadPicture: PropTypes.object,
  uploadPictureFileReset: PropTypes.func,
  userRole: PropTypes.string.isRequired,
};

ClinicForm.defaultProps = {
  addBillingAddress: false,
  adminNote: '',
  clinicName: '',
  blacklistedUsers: [],
  clinicPicture: {},
  managers: [],
  otherVets: false,
  softwareOthersCheck: false,
  upload: {},
  uploader: {},
  uploadPicture: {},
  uploadPictureFileReset: () => {},
};


ClinicForm.contextTypes = {
  t: PropTypes.func,
};

function mapStateToProps(state, { formTitle }) {
  const selector = formValueSelector(formTitle);
  return {
    addBillingAddress: selector(state, 'addBillingAddress') === 'true' || false,
    clinicName: selector(state, 'name'),
    openingHours: selector(state, 'openingHours'),
    otherVets: selector(state, 'otherVets') === 'true' || false,
    softwareSkills: selector(state, 'softwareSkills') === 'true' || false,
    softwareOthersCheck: selector(state, 'softwareOthersCheck') || false,
  };
}

export default connect(mapStateToProps)(ClinicForm);

