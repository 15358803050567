// libs
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function MandatePeriodLength({ mandate }) {
  const replacements = mandate.replacements ?
    mandate.replacements.sort((replacement, nextReplacement) => (
      new Date(replacement.startsAt) - new Date(nextReplacement.startsAt)
    )) : [];

  const start = new Date(replacements[0].startsAt);
  const end = new Date(replacements[replacements.length - 1].startsAt);
  const isSameDay = start.getDay() === end.getDay();

  return (
    <div className="mandate-card__range">
      <div className="mandate-card__range__day">
        <span>
          {moment(start).format('MMM')} <strong>{moment(start).format('DD')}</strong>
        </span>
      </div>
      {!isSameDay && [
        <span key="spacer" className="mandate-card__range__spacer" />,
        <div key="range" className="mandate-card__range__day">
          <span>
            {moment(end).format('MMM')} <strong>{moment(end).format('DD')}</strong>
          </span>
        </div>,
      ]}
    </div>
  );
}

MandatePeriodLength.propTypes = {
  mandate: PropTypes.object.isRequired,
};

MandatePeriodLength.contextTypes = {
  t: PropTypes.func,
};

export default MandatePeriodLength;
