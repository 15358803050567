import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// components
import Date from './Date';
import Flexible from './Flexible';
import Time from './Time';

function DateTime({
  endsAt,
  flexibleTime,
  role,
  startsAt,
  status,
}) {
  return ([
    <Date key="date" startsAt={startsAt} />,
    <div key="year" className="list-item__month">
      {moment(startsAt).format('MMMM')} {moment(startsAt).format('YYYY')}
    </div>,
    <React.Fragment key="hour">
      {(!status || (role === 'substitute')) &&
        <div className="list-item__hours">
          <Time startsAt={startsAt} endsAt={endsAt} />
          <Flexible flexibleTime={flexibleTime} />
        </div>
      }
    </React.Fragment>,
  ]);
}

DateTime.propTypes = {
  endsAt: PropTypes.string.isRequired,
  flexibleTime: PropTypes.bool,
  startsAt: PropTypes.string.isRequired,
};
DateTime.defaultProps = {
  flexibleTime: false,
};

export default DateTime;
