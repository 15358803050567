// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'o2web-react-core';
import {
  formatDayWithDateAndMonth,
  formatHours,
  formatPeriodLengthDate,
  yearsSinceGraduation,
} from '../../../helpers/formatDates';
import { getSubstituteName } from '../../../helpers/formatUsers';

// components
import EditReplacement from '../../mandates/EditReplacement';

// actions
import sidePanelActions from '../../../actions/dynamicSidePanel/index';
import mandatesActions from '../../../actions/mandates/index';

// css
import './styles.scss';

class ReplacementDetails extends Component {
  constructor() {
    super();
    this.detailsRef = React.createRef();
    this.state = {
      left: '0',
      position: '',
    };
  }

  componentDidMount() {
    this.changeDetailsPosition();
  }

  changeDetailsPosition() {
    const { wrapperWidth } = this.props;
    const detailsDOMData = this.detailsRef.current.getBoundingClientRect();
    let positionLeft = `-${(detailsDOMData.width / 2) - (wrapperWidth / 2)}`;
    let position = '';
    if (window.innerWidth > 1000) {
      if (detailsDOMData.x < 200) {
        positionLeft = 0;
        position = 'schedule__event-details--left';
      } else if ((detailsDOMData.x + 220) > window.innerWidth) {
        positionLeft = `-${detailsDOMData.width - wrapperWidth}`;
        position = 'schedule__event-details--right';
      }
      this.setState({
        left: `${positionLeft}px`,
        position,
      });
    }
  }

  updateReplacement() {
    const {
      setReplacementToUpdate,
      toggleDetails,
      updateComponent,
      event: {
        id,
        mandateId,
      },
      setMandateToUpdate,
    } = this.props;
    setReplacementToUpdate(id);
    setMandateToUpdate(mandateId);
    updateComponent(EditReplacement, 'editReplacement', { sidebarIcon: 'edit' });
    toggleDetails();
  }

  render() {
    const {
      toggleDetails,
      event: {
        start,
        end,
        surgery,
        consultation,
        message,
        periodStart,
        periodEnd,
        mandateType,
        accommodation,
        discarded,
        latestProposal,
      },
      deleteEvent,
      enableEvent,
    } = this.props;
    const { left, position } = this.state;
    const { t } = this.context;
    const status = latestProposal ? latestProposal.status : '';
    const hasProposals = ['pending', 'pending_clinic', 'pending_admin', 'confirmed'].includes(status);
    const pendingConfirmation = status === 'pending_admin';
    const pendingSubstitute = status === 'pending';
    const detailsPath = {
      pending_clinic: '/en/proposed-mandates/',
      confirmed: '/en/accepted-mandates/',
      pending_admin: '/en/accepted-mandates/',
    };
    return (
      <div className={`schedule__event-details ${position}`} ref={this.detailsRef} style={{ left }}>
        <div className="schedule__event-details__head">
          <span className="schedule__event-details__head--date">
            {hasProposals && !discarded ?
              formatDayWithDateAndMonth(start) : formatPeriodLengthDate(periodStart, periodEnd)}
          </span>
          <span
            className="schedule__event-details__head__close"
            onClick={toggleDetails}
            onKeyPress={toggleDetails}
            role="button"
            tabIndex="0"
          />
        </div>
        {(pendingConfirmation || pendingSubstitute) &&
          <div className="schedule__event-details__pending">
            {pendingConfirmation ? t('event.pendingConfirmation') : t('event.pendingSubstitute')}
          </div>
        }
        <div className="schedule__event-details__body">
          <div className="schedule__event-details__time">
            <span className="schedule__event__from">{formatHours(start)}</span>
            <span className="schedule__event__to">{formatHours(end)}</span>
          </div>
          <span className={`schedule__event-details__${mandateType}`}>{t(`event.${mandateType}`)}</span>
          {consultation && <span className="schedule__event-details__consultation">{ t('event.consultation') }</span> }
          {surgery !== 'false' &&
            <span className="schedule__event-details__surgery">
              { surgery === 'optional' ? t('event.surgeryOptional') : t('event.surgery') }
            </span>
          }
          {accommodation && <span className="schedule__event-details__accommodation--available">{t('event.accommodationIncluded')}</span>}
          {message &&
            <span className="schedule__event-details__info">
              <strong>{t('event.additionnalInfo')}</strong>
              <span>{message}</span>
            </span>
          }
        </div>
        {hasProposals ? (
          <div>
            {!discarded && !pendingSubstitute && latestProposal && latestProposal.substitute &&
              <div className="schedule__event-details__clinic">
                <div className="clinic">
                  <div className="clinic__infos">
                    <span className="clinic__infos__title">
                      { !latestProposal.isNewSubstitute ? getSubstituteName(latestProposal.substitute.profile) : t('event.isPending') }
                    </span>
                    <span className="clinic__infos__desc">
                      {latestProposal.substitute.profile.surgery && <span>{ t('event.surgery') }</span>}
                      {latestProposal.substitute.profile.consultation && <span>{ t('event.consultation') }</span>}
                    </span>
                    <span className="clinic__infos__desc">
                      {yearsSinceGraduation(latestProposal.substitute.profile.graduation)}
                      {yearsSinceGraduation(latestProposal.substitute.profile.graduation) > 1 ?
                        t('event.yearsOfExperience') : t('event.yearOfExperience') }
                    </span>
                  </div>
                </div>
              </div>
            }
            <div className="schedule__event-details__foot">
              {discarded &&
                <span
                  onClick={() => enableEvent()}
                  onKeyPress={() => enableEvent()}
                  role="button"
                  tabIndex="0"
                  className="schedule__event-details__btn schedule__event-details__btn--modif"
                >
                  {t('event.actions.enable')}
                </span>
              }
              <NavLink
                to={detailsPath[status] ? detailsPath[status] : '/en/mandates/'}
                className="schedule__event-details__btn schedule__event-details__btn--modif"
              >
                {t('event.actions.details')}
              </NavLink>
            </div>
          </div>
        ) : (
          <div className="schedule__event-details__foot">
            {!discarded ?
              <span
                className="schedule__event-details__btn schedule__event-details__btn--delete icon"
                onClick={() => deleteEvent()}
                onKeyPress={() => deleteEvent()}
                role="button"
                tabIndex="0"
              />
              :
              <span
                onClick={() => enableEvent()}
                onKeyPress={() => enableEvent()}
                role="button"
                tabIndex="0"
                className="schedule__event-details__btn schedule__event-details__btn--modif"
              >
                {t('event.actions.enable')}
              </span>
            }
            {!discarded &&
              <span
                onClick={() => this.updateReplacement()}
                onKeyPress={() => this.updateReplacement()}
                role="button"
                tabIndex="0"
                className="schedule__event-details__btn schedule__event-details__btn--modif"
              >
                {t('event.actions.edit')}
              </span>
            }
            <NavLink
              to="/en/mandates"
              className="schedule__event-details__btn schedule__event-details__btn--modif"
            >
              {t('event.actions.details')}
            </NavLink>
          </div>
        )}
      </div>
    );
  }
}

ReplacementDetails.propTypes = {
  toggleDetails: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  wrapperWidth: PropTypes.number.isRequired,
  updateComponent: PropTypes.func.isRequired,
  setReplacementToUpdate: PropTypes.func.isRequired,
  enableEvent: PropTypes.func.isRequired,
  setMandateToUpdate: PropTypes.func.isRequired,
};

ReplacementDetails.contextTypes = {
  t: PropTypes.func,
};

function mapStateToProps() {
  return {
  };
}

export default connect(
  mapStateToProps,
  { ...sidePanelActions, ...mandatesActions },
)(ReplacementDetails);
