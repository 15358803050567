import React from 'react';
import PropTypes from 'prop-types';

// component
function PendingSubstitute(props, { t }) {
  return (
    <div className="mandate-item__user">
      <span className="mandate-item__user__picture" />
      <span>{t('mandates.cards.proposalWaiting')}</span>
    </div>
  );
}

PendingSubstitute.propTypes = {};
PendingSubstitute.defaultProps = {};
PendingSubstitute.contextTypes = {
  t: PropTypes.func,
};

export default PendingSubstitute;
