// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, FieldArray } from 'redux-form';
import { scrollToError } from '../../helpers/scrollToError';

// components
import Clinics from './parts/proposals/fieldArray/Clinics';

// actions
import actions from '../../actions/matchmaking';
import alertsActions from '../../actions/alerts';
import confirmActionActions from '../../actions/confirmAction/index';
import validate from './validate/validateAddProposals';

// helper
import { getPrice } from '../../helpers/formatPrices';
import {
  yearsSinceGraduation,
} from '../../helpers/formatDates';

class AddProposals extends Component {
  static propTypes = {
    actionToConfirm: PropTypes.func.isRequired,
    createProposals: PropTypes.func.isRequired,
    discardProposals: PropTypes.func.isRequired,
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    lockPrices: PropTypes.func.isRequired,
    unlockedPricesClinicIds: PropTypes.array.isRequired,
    pushAlert: PropTypes.func.isRequired,
    hideSubstituteMessage: PropTypes.func.isRequired,
    showSubstituteMessage: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    unlockPrices: PropTypes.func.isRequired,
    substituteMessageReplacementIds: PropTypes.array.isRequired,
    unselectReplacementForProposals: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    error: '',
  };

  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.discardSelection = this.discardSelection.bind(this);
    this.unselectEvent = this.unselectEvent.bind(this);
  }

  discardSelection() {
    const { discardProposals } = this.props;
    discardProposals();
  }

  unselectEvent(event) {
    const { unselectReplacementForProposals } = this.props;

    unselectReplacementForProposals(event);
  }

  handleFormSubmit(values) {
    const { actionToConfirm } = this.props;

    actionToConfirm(() => {
      const { createProposals, pushAlert } = this.props;
      const formattedValues = values.clinics
        .map((clinic) =>
          clinic.proposals.map((proposal) =>
            ({
              ...proposal,
              manualValidation: clinic.manualValidation,
              clinicPrice: Number(clinic.clinicPrice),
              substitutePrice: Number(clinic.substitutePrice),
            })))
        .flat()
        .map(({
          availabilityId,
          replacementId,
          allowExtendedExpiration,
          manualValidation,
          substitutePrice,
          clinicPrice,
          substituteMessage,
        }) => ({
          availabilityId,
          replacementId,
          allowExtendedExpiration,
          manualValidation,
          substitutePrice,
          clinicPrice,
          substituteMessage,
        }));
      createProposals({ input: { proposals: formattedValues } })
        .then((data) => {
          if (data && data.createProposals.success) {
            pushAlert({
              type: 'success',
              content: 'alerts.proposalAdded',
            });
          }
        });
    }, 'matchmaking.submitSelection');
  }
  toggleLockPrices(clinicId) {
    const {
      lockPrices,
      unlockedPricesClinicIds,
      unlockPrices,
    } = this.props;
    if (unlockedPricesClinicIds.includes(clinicId)) {
      unlockPrices(clinicId);
    } else {
      lockPrices(clinicId);
    }
  }
  toggleSubstituteMessage(replacementId) {
    const {
      showSubstituteMessage,
      hideSubstituteMessage,
      substituteMessageReplacementIds,
    } = this.props;

    if (substituteMessageReplacementIds.includes(replacementId)) {
      hideSubstituteMessage(replacementId);
    } else {
      showSubstituteMessage(replacementId);
    }
  }
  render() {
    const {
      actionToConfirm,
      error,
      handleSubmit,
      submitting,
      unlockedPricesClinicIds,
      substituteMessageReplacementIds,
    } = this.props;
    const { t } = this.context;

    const submitForm = handleSubmit(this.handleFormSubmit);

    return (
      <div className="matchmaking__proposals">
        <form onSubmit={submitForm} className="side-panel__form">
          <div className="matchmaking__proposals__header">
            <h2>{t('matchmaking.callList')}</h2>
            <div className="matchmaking__proposals__actions">
              <button
                className="matchmaking__proposals__action matchmaking__proposals__action--cancel"
                type="button"
                onClick={() => actionToConfirm(this.discardSelection, 'matchmaking.discardSelection')}
              >
                {t('form.formActions.cancel')}
              </button>
              <button
                className="matchmaking__proposals__action matchmaking__proposals__action--submit"
                type="submit"
                disabled={submitting}
              >
                {t('form.formActions.submit')}
              </button>
            </div>
            {error && <div className="form-error">{error}</div>}
          </div>
          <div className="matchmaking__proposals__content">
            <div>
              <FieldArray
                name="clinics"
                component={Clinics}
                unselectEvent={this.unselectEvent}
                onToggleLockPrices={(clinicId) => { this.toggleLockPrices(clinicId); }}
                onToggleSubstituteMessage={(replacementId) => {
                    this.toggleSubstituteMessage(replacementId);
                  }}
                substituteMessageReplacementIds={substituteMessageReplacementIds}
                unlockedPricesClinicIds={unlockedPricesClinicIds}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapsStateToProps(state) {
  const selector = formValueSelector('addProposals');

  return {
    initialValues: {
      clinics: state.matchmaking.proposals.reduce((clinicsWithProposals, proposal, index) => {
        const accountType = state.matchmaking.selectedAccountType;
        const substituteExperience =
          yearsSinceGraduation(state.matchmaking.substitutes[0].profile.graduation);
        const { distance } = proposal.clinic;
        const formattedProposal = {
          ...proposal,
          substituteMessage: null,
          replacementId: proposal.replacement.id,
          availabilityId: proposal.availability.id,
          allowExtendedExpiration: true,
        };

        if (clinicsWithProposals.find((clinicWithProposals) =>
          clinicWithProposals.clinic.id === proposal.clinic.id)
        ) {
          return clinicsWithProposals.map((clinicWithProposals) => {
            if (clinicWithProposals.clinic.id === proposal.clinic.id) {
              const proposals = [...clinicWithProposals.proposals, formattedProposal]
                .sort((currentProposal, nextProposal) => (
                  new Date(currentProposal.availability.startsAt)
                  - new Date(nextProposal.availability.startsAt)
                ));
              return {
                ...clinicWithProposals,
                proposals,
              };
            }

            return { ...clinicWithProposals };
          });
        }

        const oldClinicsData = selector(state, 'clinics');

        const manualValidation = oldClinicsData && oldClinicsData[index] ?
          oldClinicsData[index].manualValidation : proposal.clinic.manualValidation;
        return [
          ...clinicsWithProposals,
          {
            manualValidation,
            clinic: proposal.clinic,
            proposals: [formattedProposal],
            clinicPrice: getPrice(accountType, substituteExperience, distance, 'clinic'),
            substitutePrice: getPrice(accountType, substituteExperience, distance, 'substitute'),
          },
        ];
      }, []),
    },
    substituteMessageReplacementIds: state.matchmaking.substituteMessageReplacementIds,
    unlockedPricesClinicIds: state.matchmaking.unlockedPricesClinicIds,
  };
}

export default connect(
  mapsStateToProps,
  { ...actions, ...alertsActions, ...confirmActionActions },
)(reduxForm({
  validate,
  enableReinitialize: true,
  form: 'addProposals',
  onSubmitFail: (errors) => scrollToError(errors),
})(AddProposals));
