import { asyncQuery, asyncMutation } from '../../../config/graphql/async-action-creator';
import { asyncSubscription } from '../../../config/graphql/subscription';
import graphQLSubscriptionClient from '../../../config/graphql/websocket-client';
import queries from './queries';
import types from './types';
import store from '../../../config/redux/store';
import client from '../../../config/graphql/client';

export default {
  subscribeToNotifications: (authToken) => asyncSubscription(
    store,
    types.SUBSCRIBE_TO_NOTIFICATIONS,
    queries.subscribeToNotifications,
    graphQLSubscriptionClient(authToken),
  ),
  fetchNotifications: (variables = {}) => asyncQuery(
    store,
    types.FETCH_NOTIFICATIONS,
    queries.fetchNotifications,
    {
      readFirst: 5,
      readStatus: 'read',
      unreadFirst: 50,
      unreadStatus: 'unread',
      ...variables,
    },
    client,
  ),
  updateNotifications: (variables = {}) => asyncQuery(
    store,
    variables.status === 'read' ? types.UPDATE_READ_NOTIFICATIONS : types.UPDATE_UNREAD_NOTIFICATIONS,
    queries.updateNotifications,
    { first: 50, ...variables },
    client,
  ),
  updateNotificationStatus: (variables) => asyncMutation(
    store,
    types.UPDATE_NOTIFICATION_STATUS,
    queries.updateNotificationStatus,
    variables,
    client,
  ),
};
