import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Footer({ invalid, status, submittedAt }, { t }) {
  return (
    <div className="timesheet-card__footer">
      { (status === 'pending' || status === 'late') &&
        <button className={classNames('timesheet-card__submit', { 'timesheet-card__submit--invalid': invalid })}>
          {t('form.formActions.submit')}
        </button>
      }
      { status === 'submitted' &&
        <span className="timesheet-card__submitted">
          {`${t('timesheets.submitted')} ${submittedAt}`}
        </span>
      }
    </div>
  );
}

Footer.propTypes = {
  invalid: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  submittedAt: PropTypes.string.isRequired,
};
Footer.defaultProps = {};
Footer.contextTypes = {
  t: PropTypes.func,
};

export default Footer;
