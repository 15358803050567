import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

// components
import Checkbox from '../../../forms/fields/checkbox/Checkbox';

// actions
import softwaresActions from '../../../../actions/softwares';

// styles
import './styles.scss';

class SoftwaresSubForm extends Component {
  static propTypes = {
    fetchSoftwares: PropTypes.func.isRequired,
    fields: PropTypes.object,
    softwares: PropTypes.array.isRequired,
    userSoftwares: PropTypes.array.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    fields: {},
  };

  constructor(props) {
    super(props);
    this.formatFields = this.formatFields.bind(this);
  }

  componentDidMount() {
    const { softwares, fetchSoftwares } = this.props;

    if (softwares.length === 0) {
      fetchSoftwares().then(() => {
        this.formatFields();
      });
    } else {
      this.formatFields();
    }
  }

  formatFields() {
    const { softwares, userSoftwares, fields } = this.props;
    const userSoftwaresIds = userSoftwares.map((software) =>
      (software.softwareId));
    const softwaresFields = softwares.map((software) => ({
      id: userSoftwares
        .filter((userSoftware) => (userSoftware.softwareId === software.id))
        .map(userSoftware => userSoftware.id)[0] || null,
      softwareId: software.id,
      name: software.name,
      checked: userSoftwaresIds.includes(software.id),
    }));

    fields.removeAll();
    softwaresFields.forEach((software) => (
      fields.push({
        id: software.id,
        softwareId: software.softwareId,
        name: software.name,
        checked: software.checked,
      })
    ));
  }

  render() {
    const { softwares, fields } = this.props;

    if (softwares.length === 0) {
      return null;
    }

    return (
      <>
        {fields.map((software, index, data) => (
          <div key={`software-${data.get(index).softwareId}`} className="field">
            <label>
              <Field
                name={`${software}.checked`}
                type="checkbox"
                component={Checkbox}
                checked={data.get(index).checked}
                label={data.get(index).name}
                exactLabel
              />
            </label>
          </div>
        ))}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users: { user = {} } } = state;
  const { ...formData } = user;
  return {
    userSoftwares: formData.profile && formData.profile.softwares
      ? formData.profile.softwares : [],
    softwares: (state.softwares && state.softwares.softwares) || [],
  };
}

export default connect(mapStateToProps, softwaresActions)(SoftwaresSubForm);

