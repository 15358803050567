// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'o2web-react-core';
import Input from '../../forms/fields/input/Input';
import validate from '../validate/validate';

import actions from '../../../actions/user/';

// Styles
import './styles.scss';

// Components
import logo from '../../../../assets/images/vetreseau-white.svg';
import TextLogo from '../../layouts/nav/TextLogo';

class ForgotPasswordForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    forgotPassword: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor() {
    super();
    this.state = { resetInstructionsSent: false };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(values) {
    const { forgotPassword } = this.props;
    forgotPassword(values).then((data) => {
      if (data && data.forgotPassword.success) {
        this.setState({ resetInstructionsSent: true });
      }
    });
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    const { resetInstructionsSent } = this.state;
    const { t } = this.context;

    const submitForm = handleSubmit(this.handleFormSubmit);

    return (
      <section className="forgot-password-page">
        <div className="forgot-password__fieldset">
          <div className="login-page__logos">
            <span><img src={logo} alt="Logo Vetreseau" className="login-page__logos__logo" /></span>
            <TextLogo logoClass="login-page__logos__title" />
          </div>
          <h1>
            {t('pages.forgotPassword.title')}
          </h1>
          { resetInstructionsSent ?
            <p className="forgot-password__success">{t('pages.forgotPassword.instructionsSent')}</p>
            :
            <form onSubmit={submitForm} className="form form--login">
              <Field
                name="email"
                component={Input}
                type="email"
                label="email"
                innerLabel
              />
              <div className="form__actions">
                <button className="form__submit" type="submit" disabled={pristine || submitting}>
                  {t('pages.forgotPassword.submit')}
                </button>
              </div>
            </form>
          }
          <div className="log-nav">
            <NavLink to="/en/login">
              {t('pages.login.submit')}
            </NavLink>
          </div>
        </div>
      </section>
    );
  }
}

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function mapStateToProps() {
  return {
    initialValues: {},
  };
}

export default withRouter(connect(mapStateToProps, actions)(reduxForm({
  form: 'forgotPassword',
  enableReinitialize: true,
  validate,
})(ForgotPasswordForm)));
