import React from 'react';
import PropTypes from 'prop-types';

function MessageDrawer({ message, toggleDetails }, { t }) {
  const appName = process.env.REACT_APP_REGION_NAME;
  return (
    <div className="list-item__drawer">
      <button
        className="list-item__drawer__close"
        onClick={toggleDetails}
      />
      <div className="list-item__drawer__infos">
        <div className="list-item__drawer__message">
          <div className="list-item__drawer__message--title">{t('mandates.cards.drawerTitle', { name: appName })}</div>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}

MessageDrawer.propTypes = {
  message: PropTypes.string.isRequired,
  toggleDetails: PropTypes.func.isRequired,
};
MessageDrawer.defaultProps = {};
MessageDrawer.contextTypes = {
  t: PropTypes.func,
};

export default MessageDrawer;
