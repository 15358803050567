import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// components
import TimesheetList from './TimesheetList';
import TimesheetsByUserList from './TimesheetsByUserList';

function Timesheets({ userRole }) {
  const timesheetsComponents = {
    substitute: <TimesheetList />,
    admin: <TimesheetsByUserList />,
  };
  return (
    <div>
      {timesheetsComponents[userRole]}
    </div>
  );
}

Timesheets.propTypes = {
  userRole: PropTypes.string.isRequired,
};
Timesheets.defaultProps = {};
Timesheets.contextTypes = {
};

function mapStateToProps(state) {
  return {
    userRole: state.user.role,
  };
}

export default withRouter(connect(
  mapStateToProps,
)(Timesheets));
