import React from 'react';
import PropTypes from 'prop-types';

// Component
import EntryRow from './EntryRow';

function EntriesSubForm({ fields, entries }) {
  return (
    <div className="timesheet-card__form">
      <ul>
        {fields.map((field, index) =>
          <EntryRow
            field={field}
            entry={entries[index]}
            key={entries[index].id}
          />,
        )}
      </ul>
    </div>
  );
}

EntriesSubForm.propTypes = {
  fields: PropTypes.object,
  entries: PropTypes.array.isRequired,
};

EntriesSubForm.defaultProps = {
  fields: {},
};

EntriesSubForm.contextTypes = {};

export default EntriesSubForm;
