export default {
  next_month: 'Mois suivant',
  noEventsInRangeClinic: 'Aucun remplacement créé pour ce mois',
  noEventsInRange: 'Aucune disponibilité pour ce mois',
  prev_month: 'Mois précédent',
  today: "Aujourd'hui",
  modal: {
    welcome: 'Bienvenue dans la nouvelle app VetRéseau! 🥳🎉',
    substitute: {
      line1: 'Assure-toi d’aller valider les informations de ton profil',
      picture: 'et de mettre à jour ta photo.',
    },
    clinic_manager: {
      line1: 'Assurez-vous d’aller valider les informations dans le profil de votre clinique',
    },
  },
};
