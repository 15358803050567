import { asyncMutation } from '../../../config/graphql/async-action-creator';
import graphQLClient from '../../../config/graphql/client';
import queries from './queries';
import types from './types';
import store from '../../../config/redux/store';

export default {
  presignUpload: (variables) => asyncMutation(
    store,
    types.PRESIGN_UPLOAD,
    queries.presignUpload,
    variables,
    graphQLClient,
  ),
  uploadFileChanged: (variables) => ({
    variables,
    type: types.UPLOAD_FILE_CHANGED,
  }),
  uploadPictureFileChanged: (variables) => ({
    variables,
    type: types.UPLOAD_PICTURE_FILE_CHANGED,
  }),
  uploadVoidedCheckFileChanged: (variables) => ({
    variables,
    type: types.UPLOAD_VOIDED_CHECK_FILE_CHANGED,
  }),
  uploadFileReset: () => ({
    type: types.UPLOAD_FILE_RESET,
  }),
  uploadPictureFileReset: () => ({
    type: types.UPLOAD_PICTURE_FILE_RESET,
  }),
  uploadVoidedCheckFileReset: () => ({
    type: types.UPLOAD_VOIDED_CHECK_FILE_RESET,
  }),
};
