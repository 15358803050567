// libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// components
import { Redirect } from 'react-router-dom';
import Schedule from '../schedule/Schedule';
import ScheduleClinic from '../schedule/ScheduleClinic';

// actions
import actions from '../../actions/admin/users';

// routes
import RoutesEn from '../../../config/locales/en/routes';
import RoutesFr from '../../../config/locales/fr/routes';
import CalendarModal from '../schedule/CalendarModal';

function Calendar({ userRole, lang, modal }) {
  const redirectRoute = (lang === 'fr') ?
    RoutesFr.matchmakingRedirect : RoutesEn.matchmakingRedirect;
  return (
    <div>
      { process.env.REACT_APP_REGION_CODE === 'qc' && modal && <CalendarModal role={userRole} />}
      {userRole === 'substitute' && (
        <>
          <Schedule />
        </>
      )}
      {userRole === 'clinic_manager' && (
        <ScheduleClinic />
      )}
      {userRole === 'admin' && (
        <Redirect to={redirectRoute} />
      )}
    </div>
  );
}

Calendar.propTypes = {
  userRole: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  modal: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    userRole: state.user.role,
    lang: state.i18nState.lang,
    modal: state.schedule.modal,
  };
}

export default connect(
  mapStateToProps,
  actions,
)(Calendar);
