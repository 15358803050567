export default {
  accountActivation: {
    instructionsSent: 'Instructions to activate your account have been sent to the email address',
    spam: 'If you don\'t receive the activation email, verify your spam folder',
    submit: 'Get link to activate your account',
    title: 'Activate your account',
  },
  forgotPassword: {
    instructionsSent: 'Password reset instructions have been sent to the email address provided',
    submit: 'Get link to reset password',
    title: 'Forgot your password?',
  },
  login: {
    forgotPassword: 'Forgot your password?',
    submit: 'Log in',
    forgotPasswordLink: 'Get a new link',
    modal: {
      title: 'Bienvenue sur l\'appli ',
      content: 'S\'il s\'agit de votre première visite et que vous êtes déjà client ou remplaçant, cliquez sur ce lien pour activer votre compte 👇',
      contact: 'Si vous n\'avez pas de compte et souhaitez obtenir plus d\'informations, contactez-nous au',
      orWebsite: ' ou via notre site web : ',
      website: 'https://www.vetreseau.com/joindre',
      activationLink: 'https://app.vetreseau.com/fr/activation',
    },
  },
  logout: 'Log out',
  newPassword: {
    passwordWasReset: 'Your password has been reset',
    passwordResetFailed: 'The link to reset your password has expired',
    submit: 'Save password',
    title: 'New password',
  },
  notFound: {
    text:'The page you are looking for is unavailable',
    back: 'Back to website',
  },
  notAllowed: {
    text:'You are not authorized to access this page. Contact customer support for more information.',
    back: 'Back to website',
  },
  notifications: {
    read: 'Read notifications',
    unread: 'New notifications',
    markAsRead: 'Mark as read',
  },
  sidePanel: {
    title: {
      account: 'My account',
      addAvailabilities: 'Add availabilities',
      addCallToAll: 'Send a call to all',
      addClinic: 'Add a clinic',
      addMandate: 'Add new request',
      addProposals: 'Add new proposals',
      addUser: 'Add a user',
      admin: 'Administration',
      editAvailability: 'Edit availability',
      editCallToAll: 'Edit call to all',
      editClinic: 'Edit a clinic',
      editEntry: 'Edit schedule',
      editPassword: 'Change my password',
      editReplacement: 'Edit a replacement',
      editUser: 'Edit a user',
      editMandate: 'Edit a mandate',
      helpCenter: 'Center d\'aide',
      notifications: 'Notifications',
    },
  },
  titles: {
    'accepted-mandates': 'Accepted mandates',
    'accepted-replacements': 'Accepted replacements',
    'availabilities': 'Availabilities',
    'clinics': 'Clinics',
    'edit-account': 'Edit my profile',
    'en': 'Homepage',
    'mandates': 'Mandates',
    'mandates-to-confirm': 'Mandates to confirm',
    'matchmaking': 'Matchmaking',
    'my-account': 'My profile',
    'notAllowed': 'Page not available',
    'notFound': 'The page you are looking for does not exist',
    'not-found': 'Page not found',
    'proposals': 'Proposals',
    'proposed-mandates': 'Proposed mandates',
    'proposed-replacements': 'Proposed replacements',
    'substitutes': 'Substitutes',
    'feuilles-de-temps': 'Timesheets',
    'timesheets': 'Timesheets',
    'users': 'Users',
  },
};
