export function formatReviewInput(proposals, fieldName, admin = false) {
  if (admin) {
    proposals = proposals.filter((proposal) => ['true', 'false'].includes(proposal[fieldName]));
  }

  return {
    reviews: proposals.map((proposal) => ({
      accepted: proposal[fieldName] === 'true',
      proposalId: proposal.id,
    })),
  };
}

export function formatCallToAllReviewInput(replacements, fieldName) {
  return {
    reviews: replacements.map((replacement) => ({
      accepted: replacement[fieldName] === 'true',
      callToAllId: replacement.callToAllId,
    })),
  };
}

export function changedReviewInput(proposals, fieldName) {
  return proposals.filter(proposal => (
    proposal[`${fieldName}Was`] !== proposal[fieldName]
  ));
}

export default formatReviewInput;
