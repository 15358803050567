// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { change, Field, touch } from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';

// components
import AutoComplete from 'react-autocomplete';

// actions
import actions from '../../../../actions/admin/clinics';

// styles
import './styles.scss';

class ClinicUserSubForm extends Component {
  static propTypes = {
    fetchSuggestedClinics: PropTypes.func.isRequired,
    fields: PropTypes.object,
    formChange: PropTypes.func.isRequired,
    formTouch: PropTypes.func.isRequired,
    formTitle: PropTypes.string.isRequired,
    blacklistedClinics: PropTypes.array,
    clinics: PropTypes.array.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    fields: {},
    blacklistedClinics: [],
  };

  constructor(props) {
    super(props);
    this.fetchSuggestedClinics = this.fetchSuggestedClinics.bind(this);
    this.selectCLinic = this.selectClinic.bind(this);
    this.removeClinic = this.removeClinic.bind(this);
    this.state = {
      values: props.blacklistedClinics.map(({ name }) =>
        name,
      ),
    };
  }

  componentWillUpdate(nextProps) {
    const { blacklistedClinics: nextBlacklistedClinics } = nextProps;
    const { blacklistedClinics } = this.props;

    if (blacklistedClinics.length === 0 && nextBlacklistedClinics.length > 0) {
      this.setBlacklistedClinicsValues(nextBlacklistedClinics);
    }
  }

  setBlacklistedClinicsValues(blacklistedClinics) {
    this.setState({
      values: blacklistedClinics.map(({ name }) =>
        name,
      ),
    });
  }

  fetchSuggestedClinics(value, index) {
    const { fetchSuggestedClinics } = this.props;
    const { values } = this.state;
    values[index] = value;
    this.setState({ values });
    fetchSuggestedClinics({ search: value });
  }

  selectClinic(value, index) {
    const { formTitle, clinics, formChange, formTouch, fields } = this.props;
    const selectedClinic = clinics.find(({ name }) =>
      value === name,
    );
    const { values } = this.state;
    values[index] = value;
    this.setState({ values });
    formTouch(formTitle, `${fields.name}[${index}].clinicId`);
    formChange(formTitle, `${fields.name}[${index}].clinicId`, selectedClinic.id);
    formChange(formTitle, `${fields.name}[${index}].userType`, 'user');
    formChange(formTitle, `${fields.name}[${index}].userBlacklistedClinic`, true);
  }

  removeClinic(index, fields, id) {
    const { formTitle, formTouch, formChange } = this.props;

    if (id) {
      formTouch(formTitle, `${fields.name}[${index}].clinicId`);
      formChange(formTitle, `${fields.name}[${index}].userBlacklistedClinic`, false);
    } else {
      fields.remove(index);
    }
  }

  render() {
    const { clinics, fields } = this.props;
    const { values } = this.state;
    const { t } = this.context;

    return (
      <div className="clinic-subform">
        {fields.map((clinicUser, index, data) =>
          <div key={`${fields.name} ${data.get(index).clinicId} ${clinicUser}`}>
            {data.get(index).userBlacklistedClinic === false ? (
              <div />
            ) : (
              <div>
                <div className="clinic-subform__row">
                  <div className="field field__autocomplete">
                    <AutoComplete
                      items={clinics}
                      getItemValue={({ name }) => name}
                      renderItem={({ id, name }, highlighted) =>
                        <div
                          key={id}
                          className={classNames('field__autocomplete-result', { 'field__autocomplete-result--highlighted': highlighted })}
                        >
                          {name}
                        </div>
                      }
                      renderInput={(props) => <input {...props} name={`${clinicUser}.clinicId`} className="field__input" />}
                      value={values[index]}
                      onChange={(event) => this.fetchSuggestedClinics(event.target.value, index)}
                      onSelect={(item) => this.selectClinic(item, index)}
                    />
                    <Field
                      name={`${clinicUser}.clinicId`}
                      component={({ meta }) =>
                        <div>
                          {meta.error && meta.touched && <span className="field__error">{t(meta.error)}</span>}
                        </div>}
                    />
                  </div>
                  <button
                    type="button"
                    className="clinic-subform__delete"
                    onClick={() => this.removeClinic(index, fields, data.get(index).id)}
                  />
                </div>
              </div>
            )}
          </div>,
        )}
        <button type="button" className="form__submit" onClick={() => fields.push({})}>
          {t('form.formActions.add')}
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clinics: state.clinics.suggested,
  };
}

export default connect(
  mapStateToProps,
  { ...actions, formChange: change, formTouch: touch },
)(ClinicUserSubForm);

