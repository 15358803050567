// libs
import React from 'react';
import PropTypes from 'prop-types';

// helpers
import { formatValueToMoney } from '../../../../../../../helpers/formatPrices';

function ProposalDetails({
  distance,
  mandate,
  substitutePrice,
}, { t }) {
  if (mandate === undefined) {
    return null;
  }
  const { accommodation, consultation, surgery } = (mandate !== undefined) && mandate;
  return (
    <div className="mandate-card__content__header__proposal-details">
      { consultation &&
        <div className="proposal-details proposal-details__icon proposal-details__icon--consult">
          <span>{ t('event.consultation') }</span>
        </div>
      }
      { (surgery !== 'false') &&
        <div className="proposal-details proposal-details__icon proposal-details__icon--surgery">
          <span>{ t(`event.surgeryType.${surgery}`) }</span>
        </div>
      }
      { substitutePrice > 0 &&
        <div className="proposal-details proposal-details__icon proposal-details__icon--price">
          <span>{`${formatValueToMoney(substitutePrice)}${t('mandates.cards.hourlyRate')}`}</span>
        </div>
      }
      {distance &&
        <div className="proposal-details proposal-details__icon proposal-details__icon--distance">
          <span>{ distance }</span>
        </div>
      }
      {accommodation &&
        <div className="proposal-details proposal-details__icon proposal-details__icon--lodging">
          <span>{t('mandates.withLodging')}</span>
        </div>
      }
    </div>
  );
}

ProposalDetails.propTypes = {
  distance: PropTypes.string.isRequired,
  mandate: PropTypes.object,
  substitutePrice: PropTypes.number,
};
ProposalDetails.defaultProps = {
  mandate: {},
  substitutePrice: 0,
};
ProposalDetails.contextTypes = {
  t: PropTypes.func,
};

export default ProposalDetails;
