// libs
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getMinRemainingHours } from '../../../../../../../helpers/formatProposals';

function MandateCardHeader({ extendProposalsTime, proposals, role }, { t }) {
  const allowExtendedExpiration = proposals.some(proposal =>
    proposal.allowExtendedExpiration && proposal.expirationExtended === false);
  const awaitingConfirmation = proposals.some(proposal => ['pending_clinic', 'pending_admin'].includes(proposal.status));
  const isPending = proposals.some(proposal => proposal.status === 'pending');
  const remaining = getMinRemainingHours(proposals);

  const substituteRole = role === 'substitute';
  const managerRole = role === 'clinic_manager';
  const adminRole = role === 'admin';

  return (
    <div className={classNames(
      'mandate-card__header',
      { 'mandate-card__header--empty': !(isPending || awaitingConfirmation) },
    )}
    >
      {(isPending || awaitingConfirmation) &&
        <div className="mandate-card__tab">
          {substituteRole &&
            (awaitingConfirmation ?
              <span>{t('mandates.awaitingConfirmation')}</span>
              :
              <span>
                {remaining && (
                  <span><strong>{`${remaining}h`}</strong> {t('mandates.remaining')}</span>
                )}
              </span>
            )
          }

          {(managerRole || adminRole) &&
            <span>{t('mandates.awaitingConfirmation')}</span>
          }
        </div>
      }
      {(substituteRole || adminRole) && isPending && allowExtendedExpiration &&
        <button
          className="mandate-card__add-time"
          tabIndex="0"
          onClick={() => extendProposalsTime(proposals)}
          onKeyPress={() => extendProposalsTime(proposals)}
        >
          <span className="long">{t('mandates.addTime.long')}</span>
          <span className="short">{t('mandates.addTime.short')}</span>
        </button>
      }
    </div>
  );
}

MandateCardHeader.propTypes = {
  extendProposalsTime: PropTypes.func.isRequired,
  proposals: PropTypes.array,
  role: PropTypes.string.isRequired,
};

MandateCardHeader.defaultProps = {
  proposals: [],
};

MandateCardHeader.contextTypes = {
  t: PropTypes.func,
};

export default MandateCardHeader;
