// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components

// Actions
import actions from '../../actions/timesheet';
import alertsActions from '../../actions/alerts';

// Styles
import './styles.scss';

class TimesheetExport extends Component {
  static propTypes = {
    accountType: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    exportTimesheets: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
    pushAlert: PropTypes.func.isRequired,
    timesheetsAvailables: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      fileName: '',
      fileUrl: '',
    };
    this.reinitDownloadUrl = this.reinitDownloadUrl.bind(this);
    this.exportTimesheets = this.exportTimesheets.bind(this);
    this.setDownloadUrl = this.setDownloadUrl.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { accountType: prevAccountType } = prevProps;
    const { accountType } = this.props;
    if (prevAccountType !== accountType) {
      this.reinitDownloadUrl();
    }
  }

  setDownloadUrl() {
    const { file: { data, name } } = this.props;
    const base64Url = `data:application/octet-stream;base64,${data}`;
    this.setState({
      fileName: name,
      fileUrl: base64Url,
    });
  }

  reinitDownloadUrl() {
    this.setState({
      fileName: '',
      fileUrl: '',
    });
  }

  exportTimesheets() {
    const { accountType, exportTimesheets, date, pushAlert } = this.props;
    exportTimesheets({
      from: date.toISOString(),
      to: date.toISOString(),
      accountType,
    }).then((data) => {
      if (data && data.timesheetsExport.success) {
        this.setDownloadUrl();
        pushAlert({
          type: 'success',
          content: 'timesheets.export.ready',
        });
      }
    });
  }

  render() {
    const { t } = this.context;
    const { fileName, fileUrl } = this.state;
    const { timesheetsAvailables } = this.props;
    return (
      <div className="timesheets__export">
        <div>
          { timesheetsAvailables &&
            <div>
              <div className="field">
                <button onClick={this.exportTimesheets} type="button">{t('timesheets.export.generate')}</button>
                { fileUrl && <a href={fileUrl} download={fileName}>{t('timesheets.export.download')}</a> }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    file: state.timesheets.file,
  };
}

export default connect(
  mapStateToProps,
  { ...actions, ...alertsActions },
)(TimesheetExport);
