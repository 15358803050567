export default {
  faq: 'F.A.Q.',
  substitute: {
    tutorial: {
      legend: {
        a: 'Une',
        blueCase: 'case bleue',
        orangeCase: 'case orange',
        greenCase: 'case verte',
        hashGreenCase: 'case verte hachurée',
        blue: 'correspond à une journée pour laquelle tu as ajouté une disponibilité.',
        orange: 'correspond à un remplacement qui t’a été proposé et qui est en attente d’approbation de ta part.',
        hashGreen: 'correspond à une proposition de remplacement que tu as acceptée et qui est en attente de confirmation par notre équipe. ',
        green: 'correspond à une demande de remplacement confirmée : ce jour-là, tu travailles!',
      },
      title: 'Démarrer avec notre application web',
      paragraph: 'Ce guide t\’aidera à apprendre les bases et bien démarrer avec l\’utilisation de notre application web VetRéseau.',
      homePage: {
        title: 'Page d’accueil',
        calendarView: 'Vue calendrier',
        description: 'Dans ton calendrier, chaque case a une signification :',
      },
      profiles: {
        title: 'Profil remplaçant',
        userDescription: 'Notre équipe aura créé et pré-rempli ton profil remplaçant. Pour compléter ton profil, il te suffit simplement de cliquer sur l\’icône utilisateur ',
        userWarning: {
          paragraph1: 'Assure-toi de bien vérifier les informations déjà inscrites et de remplir tous les champs demandés afin que l\’on puisse te proposer les mandats qui correspondent le mieux à tes préférences.',
          paragraph2: 'Ton numéro de permis, ton nom ainsi que ta photo seront les seules informations personnelles qui seront communiquées aux cliniques une fois que tu auras accepté le remplacement.',
        },
      },
      availabilities: {
        title: 'Disponibilités',
        add: 'Ajouter une disponibilité',
        buttonClick: 'Clique sur le bouton',
        addDescription: ' en haut et à droite du calendrier. Le formulaire d\’ajout de disponibilités s\’ouvrira dans un panneau à droite.',
        availabilityInfo: 'Informations de la disponibilité',

        information: 'Informations de la disponibilité',
        availabilityInformationDescription: 'Ces informations nous aiderons à trouver le remplacement qui correspondra le mieux à tes disponibilités et à tes préférences. Assure-toi de bien remplir tous les champs avant d\’enregistrer.',

        informationItems: {
          item1: 'Entre le début et la fin de la période durant laquelle tu souhaites faire du remplacement.',
          item2: 'Indique l\’horaire souhaité pour chacun des jours sélectionnés ',
          item2Description: 'Coche la case à droite si tu es disponible toute la journée ou que tu n’as de préférence d’horaire. Si tu as un horaire précis, décoche la case à droite et entre les heures durant lesquelles tu aimerais travailler.',
          item3: 'Choisis la distance (aller-retour) que tu souhaites parcourir quotidiennement',
          item3Description: 'Ex : 150km aller-retour.',
          item4: 'Précise si tu souhaites faire de la consultation, de la chirurgie ou les deux pendant ce remplacement.',
        },
        edit: 'Modifier ou supprimer une disponibilité',
        editDescription: 'Pour modifier une disponibilité, clique sur la case de la disponibilité dans ton calendrier puis sur le bouton «Modifier».',
        editDescription2: 'Pour supprimer une disponibilité, clique sur la case de la disponibilité dans ton calendrier puis sur la corbeille',
        editDescription3: 'Vous pouvez retrouver toutes vos demandes sous l’onglet         “Liste des mandats saisis” dans le menu de gauche. Cliquez sur le crayon pour modifier et sur la corbeille pour supprimer votre demande.',
        editWarning: {
          paragraph1: 'Il n’\est pas possible de modifier une disponibilité en attente d\’approbation ou un remplacement déjà confirmé, via l\’application web. Si tu souhaites la modifier, contacte-nous par téléphone au ',
          SMS: " ou par SMS au ",
        },
      },
      proposition: {
        title: 'Proposition de remplacement',
        answer: 'Répondre à une proposition de remplacement',
        answerTip: 'Un délai de 48h t\’es alloué pour accepter ou refuser une proposition. Si tu souhaites avoir un peu plus de temps, tu as l\’option d’ajouter un 24h supplémentaire en cliquant sur le bouton ',
      },
      approval: {
        title: 'Approbation/confirmation',
        awaiting: 'Remplacements approuvés/en attente d’approbation',
        awaitingDescription: 'Les mandats que vous avez approuvé se trouvent sous l\’onglet [!!ICON!!] «Remplacements acceptés» dans le menu de gauche. Le mandat sera labellisé «En attente de confirmation» et ce sera au tour de notre équipe de faire la validation finale.',
        confirmed: 'Mandats confirmés',
        confirmedDescription: 'Les mandats confirmés se trouvent également sous l\’onglet [!!ICON!!] «Remplacements acceptés» dans le menu de gauche. Un remplacement est officiellement confirmé une fois que vous avez reçu le courriel de confirmation.',
      },
      timesheets: {
        title: 'Transmission des heures travaillées',
        description: '',
        warning: {
          paragraph1: 'Il est important de remplir ta feuille de temps au plus tard le dimanche de la même semaine de ton remplacement.',
        },
        informationItems: {
          item1: 'Inscris les heures travaillées',
          item2: 'Indique si tu as pris une pause repas',
          item2Description1: 'Si oui : ajoute la durée de ta pause repas',
          item2Description2: 'Si non : indique la raison',
          item3: 'Indique le kilométrage que tu as parcouru (aller-retour PAR JOUR)',
          item3Description: 'Ex : moins de 150km aller-retour.',
          item4: 'Indique des informations supplémentaires au besoin',
        },
        answerTip: 'Tu peux enregistrer tes heures de la semaine à chaque jour, et nous envoyer ta feuille de temps finale lorsque ton dernier jour de remplacement de la semaine sera terminé.',
      },
      questions: {
        title: 'Tu ne trouves pas la réponse à ta question?',
        contactInfo: 'Contacte-nous à ',
        or: ' ou au ',
        paragraph1: 'Si tu as remarqué un problème ou que tu as une suggestion d’amélioration, n\’hésite pas à nous en faire part! ',
      },
    },
  },

  clinic: {
    tutorial: {
      legend: {
        test: 'case bleue',
        a: 'Une',
        blueCase: 'case bleue',
        hashBlueCase: 'case bleue hachurée',
        orangeCase: 'case orange',
        greenCase: 'case verte',
        hashGreenCase: 'case verte hachurée',
        greyCase: 'case grise',
        blue: 'correspond à une journée pour laquelle vous avez fait une demande de remplacement.',
        hashBlue: 'correspond à un remplacement qui a été proposé à un remplaçant et qui est en attente d’une réponse de sa part.',
        orange: 'correspond à une proposition de remplacement qui vous a été envoyée et qui est en attente d’approbation de votre part.',
        green: 'correspond à une proposition de remplacement que vous avez acceptée et qui est en attente de confirmation par notre équipe. ',
        hashGreen: 'correspond à une demande de remplacement confirmée : ce jour-là, vous aurez un remplaçant dans votre clinique!',
        grey: 'correspond à une demande de remplacement que vous avez annulée. Il est possible de la réactiver en tout temps en cliquant dessus puis sur "ACTIVER", si finalement vous souhaitez avoir un remplaçant ce jour-là. ',
      },
      title: 'Démarrer avec notre application web',
      paragraph: 'Ce guide vous aidera à apprendre les bases et bien démarrer avec l\’utilisation de notre application web VetRéseau.',
      homePage: {
        title: 'Page d’accueil',
        calendarView: 'Vue calendrier',
        description: 'Dans votre calendrier, chaque case a une signification :',
      },
      profiles: {
        title: 'Profils',
        user: 'Profil utilisateur',
        userDescription: 'En tant qu’utilisateur de l’application, vous aurez un profil «Gestionnaire» qui sera ensuite jumelé au profil de votre clinique. Votre profil sera pré-rempli, il vous suffira simplement de le compléter en cliquant sur ',
        userWarning: {
          paragraph1: 'Si vous choisissez les sms comme moyen de communication, assurez-vous que le numéro fourni puisse recevoir les sms.',
          paragraph2: 'Si vous souhaitez changer l\’adresse courriel à votre compte, contactez nous à ',
        },
        userTip: 'Un utilisateur peut gérer plusieurs comptes de cliniques. Pour passer d\’un compte clinique à un autre, il suffit de sélectionner la clinique dans le menu déroulant :',
        clinic: 'Profil clinique',
        clinicDescription: 'Une fois que vous aurez complété votre profil utilisateur, vous aurez accès au profil de votre clinique qui sera également pré-rempli. Il est important de bien saisir toutes les informations concernant le profil de la clinique. Pour accéder au profil de la clinique, cliquez sur ',
        clinicTip: 'Il est possible d’avoir plusieurs profils d\’utilisateurs pour un compte de clinique. Si vous souhaitez ajouter un membre de votre équipe comme autre utilisateur, contactez nous à ',
      },
      requests: {
        title: 'Demande de remplacement',
        add: 'Ajouter une demande',
        buttonClick: 'Cliquez sur le bouton',
        addDescription: ' en haut et à droite du calendrier. Le formulaire de demande de remplacement s’ouvrira dans un panneau à droite.',
        flexible: 'Demande flexible',
        flexibleWeek: {
          description: 'J\’ai besoin d\’un remplaçant 3 jours durant la semaine, peu importe lesquels.',
          action: 'Sélectionnez la semaine dans le calendrier et précisez 3 jours.',
        },
        flexibleMonth: {
          description: 'Je veux une semaine de remplacement au mois de novembre, peu importe laquelle.',
          action: 'Sélectionnez le mois de novembre dans le calendrier et précisez 5 jours.',
        },
        flexibleDescription: 'Si votre demande est flexible, vous pouvez entrer la période et choisir un nombre de jours total durant lesquels vous souhaitez avoir un remplaçant. Par exemple : ',

        information: 'Informations de la demande',
        informationDescription: 'Ces informations nous aideront à trouver le remplaçant qui correspondra le mieux à votre demande. Assurez-vous de bien remplir tous les champs avant d\’enregistrer votre demande.',
        informationDescription2: 'Une fois votre demande ajoutée, vous pourrez la retrouver dans la vue [!!ICON!!] «Calendrier», ou sous l\’onglet [!!ICON!!] «Demandes saisies» dans le menu de gauche.',
        informationItems: {
          item1: 'Indiquez l\’horaire souhaité pour chacun des jours sélectionnés.',
          subItem1_1: 'Cochez l\’étoile si vous souhaitez que cette journée soit prioritaire durant la période sélectionnée (lors d\’une demande flexible).',
          subItem1_2: 'Cochez le graphique circulaire si vous êtes flexible concernant la plage horaire de la journée.',
          item2: 'Choisissez le type professionnel.',
          item2Description: 'Si vous souhaitez avoir un(e) vétérinaire ET un(e) technicien(ne) pour la même plage horaire, il vous faudra faire 2 demandes distinctes',
          item3: 'Précisez les compétences souhaitées.',
          item3Description: 'Si vous cochez «chirurgie obligatoire», cela signifie que le remplaçant fera de la chirurgie lors de son remplacement. Si vous cochez «chirurgie facultative», cela signifie que vous n\’imposez pas de chirurgie à votre remplaçant.',
          item4: 'Assurez-vous de bien remplir tous les champs avant d\’enregistrer votre demande.',
        },
        edit: 'Modifier/Supprimer une demande',
        editDescription: 'Pour modifier une demande de remplacement, cliquez sur la case de la demande puis sur le bouton «Modifier».',
        editDescription2: 'Pour supprimer une demande de remplacement, cliquez sur la case de la demande puis sur la corbeille ',
        editDescription3: 'Vous pouvez retrouver toutes vos demandes sous l’onglet         “Liste des mandats saisis” dans le menu de gauche. Cliquez sur le crayon pour modifier et sur la corbeille pour supprimer votre demande.',
        editWarning: {
          paragraph1: 'Il n’est pas possible de modifier une demande en attente d\’approbation ou une demande de remplacement déjà confirmée, via l\’application web. Si vous voulez la modifier, appelez-nous au ',
        },
      },
      proposition: {
        title: 'Proposition de remplacement',
        answer: 'Répondre à une proposition de remplacement',
        answerTip: 'Si le remplaçant n\’est jamais venu remplacer dans votre clinique, seules ses années d\’expérience et sa distance à parcourir seront visibles. Vous aurez accès à son nom et son numéro de permis une fois que le remplacement sera officiellement confirmé.',
        paragraph1: 'Lorsque nous aurons sélectionné le remplaçant qui correspond le mieux à votre clinique et à votre demande, vous recevrez une proposition de remplacement.',
        paragraph2: 'Tous les remplaçants qui vous sont proposés et en attente de votre approbation, se trouvent sous l’onglet [!!ICON!!] «Remplacements proposés» dans le menu de gauche. Vous devez répondre à toutes les propositions d’un même mandat, afin de pouvoir soumettre vos réponses.',
        item1: 'Pour accepter une proposition de remplaçant, cochez [!!ICON!!] et cliquez sur «Envoyer».',
        item2: 'Pour refuser une proposition de remplaçant, cochez [!!ICON!!] et cliquez sur «Envoyer».',
      },
      approval: {
        title: 'Approbation/confirmation',
        awaiting: 'Mandats approuvés/en attente d’approbation',
        awaitingDescription: 'Les mandats que vous avez approuvé se trouvent sous l\’onglet [!!ICON!!] «Remplacements acceptés» dans le menu de gauche. Le mandat sera labellisé «En attente de confirmation» et ce sera au tour de notre équipe de faire la validation finale.',
        confirmed: 'Mandats confirmés',
        confirmedDescription: 'Les mandats confirmés se trouvent également sous l\’onglet [!!ICON!!] «Remplacements acceptés» dans le menu de gauche. Un remplacement est officiellement confirmé une fois que vous avez reçu le courriel de confirmation.',
      },
      sectionTitle: 'Démarrer avec notre application web',
      sectionSubtitle: 'Ce guide vous aidera à apprendre les bases et bien démarrer avec l\’utilisation de notre application web VetRéseau.',
      questions: {
        title: 'Vous ne trouvez pas la réponse à votre question?',
        contactInfo: 'Contactez-nous à ',
        or: ' ou au ',
        paragraph1: 'Si vous avez remarqué un problème ou que vous avez une suggestion d’amélioration, n\’hésitez pas à nous en faire part! ',
      },
    }
  },
};