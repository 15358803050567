export default {
  added: 'Successfully created',
  callToAllFormSubmitted: "Successfully submitted",
  callToAllSent: 'Call to all sent',
  callToAllUpdated: 'Call to all updated',
  confirmed: 'Placement confirmed',
  deleted: 'Successfully deleted',
  disabled: 'Successfully disabled',
  edited: 'Successfully edited',
  enabled: 'Successfully enabled',
  errorOccurred: 'An error occurred',
  proposalFormSubmitted: 'Successfully submitted',
  proposalAdded: 'The proposal has been sent!',
  sessionExpired: 'Oops! Your session has expired. Please log back in.',
  timeAdded: 'Time added',
  timesheetSent: 'Time sheet sent',
  addressClipboard: 'Address copied'
};
