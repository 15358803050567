// Libs
import React, { Component } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'o2web-react-core';

// Components
import logo from '../../../../assets/images/vetreseau-white.svg';
import TextLogo from '../../layouts/nav/TextLogo';
import Input from '../../forms/fields/input/Input';
import validate from '../validate/validate';
import PasswordRequirement from './PasswordRequirement';

// helper
import { validPasswordCombinations } from '../../../helpers/password';

// actions
import actions from '../../../actions/user/';

// Styles
import './styles.scss';

class ResetPasswordForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    resetPassword: PropTypes.func.isRequired,
    error: PropTypes.string,
    match: PropTypes.object.isRequired,
    password: PropTypes.string.isRequired,
    passwordConfirmation: PropTypes.string.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    error: '',
  };

  constructor() {
    super();
    this.state = { resetPasswordStep: 'resetPassword' };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(values) {
    const { resetPassword, match: { params: { token } } } = this.props;

    resetPassword({ ...values, token }).then((data) => {
      if (data && data.resetPassword.success) {
        this.setState({ resetPasswordStep: 'resetSuccess' });
      } else if (data && data.resetPassword.errors.length) {
        const { resetPassword: { errors } } = data;
        const error = errors[0];
        const errorDetail = error.details[0];
        const tokenErrorType = errorDetail.error;

        if (tokenErrorType === 'invalid' || tokenErrorType === 'expired') {
          this.setState({ resetPasswordStep: 'resetFailed' });
        }
      }
    });
  }

  render() {
    const {
      error,
      handleSubmit,
      password,
      passwordConfirmation,
      pristine,
      submitting,
    } = this.props;
    const { resetPasswordStep } = this.state;
    const { t } = this.context;
    const submitForm = handleSubmit(this.handleFormSubmit);
    const canSubmit = validPasswordCombinations([password, passwordConfirmation]);

    const newPasswordContent = {
      resetPassword: [
        <div key="resetPassword">
          <form onSubmit={submitForm} className="form form--login">
            {error && <div className="form-error">{error}</div>}
            <Field
              name="password"
              component={Input}
              type="password"
              label="newPassword"
              innerLabel
            />
            <Field
              name="passwordConfirmation"
              component={Input}
              type="password"
              label="passwordConfirmation"
              innerLabel
            />
            <PasswordRequirement password={password} />
            <div className="form__actions">
              <button className="form__submit" type="submit" disabled={pristine || submitting || !canSubmit}>
                {t('pages.newPassword.submit')}
              </button>
            </div>
          </form>
          <div className="log-nav">
            <NavLink to="/en/login">
              {t('pages.login.submit')}
            </NavLink>
          </div>
        </div>,
      ],
      resetSuccess: [
        <div key="resetSuccess">
          <p className="forgot-password__success">{t('pages.newPassword.passwordWasReset')}</p>
          <div className="log-nav">
            <NavLink to="/en/login">
              {t('pages.login.submit')}
            </NavLink>
          </div>
        </div>,
      ],
      resetFailed: [
        <div key="resetFailed">
          <p className="forgot-password__failed">{t('pages.newPassword.passwordResetFailed')}</p>
          <div className="log-nav">
            <NavLink to="/en/forgotPassword">
              {t('pages.login.forgotPasswordLink')}
            </NavLink>
          </div>
        </div>,
      ],
    };

    return (
      <section className="forgot-password-page">
        <div className="forgot-password__fieldset">
          <div className="login-page__logos">
            <span><img src={logo} alt={t(`metas.site.${process.env.REACT_APP_REGION_CODE}`)} className="login-page__logos__logo" /></span>
            <TextLogo logoClass="login-page__logos__title" />
          </div>
          <h1>
            {t('pages.newPassword.title')}
          </h1>
          { newPasswordContent[resetPasswordStep] }
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector('resetPassword');
  return {
    initialValues: {},
    password: selector(state, 'password') || '',
    passwordConfirmation: selector(state, 'passwordConfirmation') || '',
  };
}

export default withRouter(connect(mapStateToProps, actions)(reduxForm({
  form: 'resetPassword',
  enableReinitialize: true,
  validate,
}, mapStateToProps)(ResetPasswordForm)));
