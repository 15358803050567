import types from '../actions/uploads/types';

const initialState = {
  fileChanged: false,
  filename: '',
  pictureFilename: false,
  pictureFileChanged: '',
  voidedCheckFilename: false,
  voidedCheckFileChanged: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.UPLOAD_FILE_CHANGED:
      return {
        ...state,
        filename: action.variables.filename,
        fileChanged: true,
      };
    case types.UPLOAD_PICTURE_FILE_CHANGED:
      return {
        ...state,
        pictureFilename: action.variables.pictureFilename,
        pictureFileChanged: true,
      };
    case types.UPLOAD_VOIDED_CHECK_FILE_CHANGED:
      return {
        ...state,
        voidedCheckFilename: action.variables.voidedCheckFilename,
        voidedCheckFileChanged: true,
      };
    case types.UPLOAD_FILE_RESET:
      return {
        ...state,
        fileChanged: false,
        filename: '',
      };
    case types.UPLOAD_PICTURE_FILE_RESET:
      return {
        ...state,
        pictureFilename: false,
        pictureFileChanged: '',
      };
    case types.UPLOAD_VOIDED_CHECK_FILE_RESET:
      return {
        ...state,
        voidedCheckFilename: false,
        voidedCheckFileChanged: '',
      };
    default:
  }
  return state;
}
