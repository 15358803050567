import types from '../actions/expertises/types';

const initialState = {
  expertises: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_EXPERTISES_SUCCESS:
      return { ...state, expertises: action.payload.expertises };
    default:
  }
  return state;
}
