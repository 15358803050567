import React from 'react';
import PropTypes from 'prop-types';
import ReplacementRow from '../callToAlls/ReplacementRow';

function CallToAllsSubForm(props) {
  const { fields, replacements, role, change } = props;

  return (
    <ul className="mandate-card__form__list">
      {fields.map((field, index) =>
        <div key={field}>
          { replacements[index] &&
            <ReplacementRow
              field={field}
              replacement={replacements[index]}
              role={role}
              change={change}
            />
          }
        </div>,
      )}
    </ul>
  );
}

CallToAllsSubForm.propTypes = {
  change: PropTypes.func,
  fields: PropTypes.object,
  replacements: PropTypes.array,
  role: PropTypes.string.isRequired,
};

CallToAllsSubForm.defaultProps = {
  change: () => {},
  fields: {},
  replacements: [],
};

CallToAllsSubForm.contextTypes = {
  t: PropTypes.func,
};

export default CallToAllsSubForm;
