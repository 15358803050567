// libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// actions
import alertsActions from '../../../actions/alerts/index';

// Components
import Alert from './Alert';

// Styles
import './styles.scss';

const propTypes = {
  alerts: PropTypes.array.isRequired,
};

function Alerts({ alerts }) {
  return (
    <div className="alert-message__wrapper">
      {alerts.map((alert) =>
        <Alert
          key={alert.count}
          alertContent={alert.content}
          alertDetails={alert.details}
          alertType={alert.type}
        />,
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts.alerts,
  };
}

Alerts.propTypes = propTypes;

export default connect(mapStateToProps, alertsActions)(Alerts);
