import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

// helpers
import { isWeekendDay } from '../../../helpers/dates';
import { getHoliday } from '../../../helpers/holidays';

function HeaderRow({
  date,
  days,
  holidays,
  title,
}) {
  return (
    <div className="matchmaking__row matchmaking__row--header">
      <div className="matchmaking__user">
        <span className="matchmaking__user__title">{title}</span>
      </div>
      {[...Array(days).keys()].map((index) => {
        const day = moment(date).add(index, 'days');
        const isWeekend = isWeekendDay(date, index);
        const isHoliday = getHoliday(day, holidays);
        return (
          <div
            key={index}
            className={classNames('matchmaking__day matchmaking__day--empty', {
            'matchmaking__day--weekend': isWeekend,
            'matchmaking__day--holiday': isHoliday,
          })}
          />
        );
      })}
    </div>
  );
}

HeaderRow.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  days: PropTypes.number,
  holidays: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
HeaderRow.defaultProps = {
  days: 28,
};

export default React.memo(HeaderRow);
