export default {
  fetchTimesheets: `
    query(
      $accountType: String,
      $after: String,
      $before: String,
      $first: Int!,
      $from: ISO8601DateTime,
      $last: Int,
      $status: String,
      $userId: ID,
      $to: ISO8601DateTime
    ) {
      timesheets(
        accountType: $accountType,
        after: $after,
        before: $before
        first: $first,
        from: $from,
        last: $last,
        status: $status,
        userId: $userId,
        to: $to
      ) {
        nodes {
          id
          user {
            id
            profile {
              firstName
              lastName
              picture {
                small
              }
            }
          }
          endsAt
          startsAt
          status
          entries {
            adminNote
            breaks {
              active
              duration
              reason
              type
            }
            clinic {
              name
            }
            confirmedAt
            submittedAt
            distanceTraveled
            endsAt
            id
            message
            startsAt
            status
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  fetchTimesheetEntry: `
    query($id: ID!) {
      timesheetEntry(id: $id) {
        adminNote
        breaks {
          active
          duration
          reason
          type
        }
        clinic {
          name
        }
        distanceTraveled
        endsAt
        id
        message
        startsAt
        status
      }
    }
  `,
  updateTimesheetEntries: `
    mutation($input: TimesheetEntriesInput!) {
      updateTimesheetEntries(input: $input) {
        entries {
          adminNote
          breaks {
            active
            duration
            reason
            type
          }
          clinic {
            name
          }
          distanceTraveled
          endsAt
          id
          message
          startsAt
          status
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  reviewTimesheetEntries: `
    mutation($input: TimesheetEntryReviewsInput!) {
      reviewTimesheetEntries(input: $input) {
        entries {
          adminNote
          breaks {
            active
            duration
            reason
            type
          }
          clinic {
            name
          }
          distanceTraveled
          endsAt
          id
          message
          startsAt
          status
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  timesheetsExport: `
    query(
      $accountType: String!,
      $from: ISO8601DateTime,
      $to: ISO8601DateTime
    ) {
      timesheetsExport(
        accountType: $accountType,
        from: $from,
        to: $to
      ) {
        file {
          data
          name
          mimeType
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
};
