export default {
  fetchClinics: `
    query($first: Int!, $search: String, $last: Int, $before: String, $after: String) {
      clinics(first: $first, search: $search, after: $after, last: $last, before: $before) {
        nodes {
          id
          discarded
          name
          phone
          address {
            address
            city
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  fetchClinic: `
    query(
      $id: ID!,
      $userType: String
    ) {
      clinic(id: $id) {
        address {
          id
          address
          address2
          zipcode
          city
          province
          country
        }
        adminNote
        id
        manualValidation
        message
        name
        otherVets
        phone
        rate
        billingAddress {
          id
          address
          address2
          zipcode
          city
          province
          country
        }
        billingContact
        consultationAverageDuration
        openingHours {
          day
          opensAt
          closesAt
        }
        clinicUsers(userType: $userType) {
          id
          userId
          userType
          user {
            email
            profile {
              firstName
              lastName
            }
          }
        }
        blacklistedUsers {
          id
          userId
          userType
          clinicBlacklistedUser
          user {
            email
            profile {
              firstName
              lastName
            }
          }
        }
        languages {
          id
          languageId
          level
        }
        picture {
          mini
          small
          medium
          large
        }
        softwareOthers
        softwares {
          id
          softwareId
          name
        }
        softwareSkills
      }
    }
  `,
  fetchClinicAsManager: `
    query($id: ID!) {
      clinic(id: $id) {
        address {
          id
          address
          address2
          zipcode
          city
          province
          country
        }
        id
        message
        name
        otherVets
        phone
        billingAddress {
          id
          address
          address2
          zipcode
          city
          province
          country
        }
        billingContact
        consultationAverageDuration
        openingHours {
          day
          opensAt
          closesAt
        }
        languages {
          id
          languageId
          level
        }
        managers {
          id
          email
          profile {
            firstName
            lastName
            picture {
              small
            }
          }
        }
        picture {
          mini
          small
          medium
          large
        }
        softwareOthers
        softwares {
          id
          softwareId
          name
        }
        softwareSkills
      }
    }
  `,
  createClinic: `
    mutation($input: ClinicInput!) {
      createClinic(input: $input) {
        clinic {
          id
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  updateClinic: `
    mutation($id: ID!, $input: ClinicInput!) {
      updateClinic(id: $id, input: $input) {
        clinic {
          id
          name
          picture {
            mini
            small
            medium
            large
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  enableClinic: `
    mutation($id: ID!) {
      enableClinic(id: $id) {
        clinic {
          id
          discarded
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  disableClinic: `
    mutation($id: ID!) {
      disableClinic(id: $id) {
        clinic {
          id
          discarded
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
};
