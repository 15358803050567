import types from './types';

export default {
  changeDate: (newDate) => ({
    type: types.CHANGE_DATE,
    newDate,
  }),
  closeModal: () => ({
    type: types.CLOSE_MODAL,
  }),
  updateHolidays: (year) => ({
    type: types.UPDATE_HOLIDAYS,
    year,
  }),
};
