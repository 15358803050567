// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

// actions
import matchmakingActions from '../../../actions/matchmaking';

// components
import SmallLoader from '../../loader/SmallLoader';

// helpers
import {
  formatDayWithDateAndMonth,
  formatTimeRange,
} from '../../../helpers/formatDates';
import { getSubstituteProposalsList } from '../../../helpers/formatProposals';

class AvailabilityEventTooltip extends Component {
  static propTypes = {
    deleteEvent: PropTypes.func.isRequired,
    deleteProposal: PropTypes.func.isRequired,
    updateEvent: PropTypes.func.isRequired,
    event: PropTypes.object,
    loading: PropTypes.bool,
    resetEventProposals: PropTypes.func.isRequired,
    fetchSubstituteProposals: PropTypes.func.isRequired,
    substituteProposals: PropTypes.array,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    substituteProposals: [],
    event: {},
    loading: false,
  };

  componentWillMount() {
    const { resetEventProposals } = this.props;
    resetEventProposals();
  }

  componentDidMount() {
    const { fetchSubstituteProposals, event } = this.props;
    const { latestProposal } = event;
    if (latestProposal !== null) {
      fetchSubstituteProposals({
        availabilityId: event.id,
      });
    }
  }

  componentWillUnmount() {
    const { resetEventProposals } = this.props;
    resetEventProposals();
  }

  render() {
    const { substituteProposals } = this.props;
    const {
      event,
      event: {
        adminNote,
        createdAt,
        endsAt,
        latestProposal,
        startsAt,
        message,
      },
      deleteEvent,
      deleteProposal,
      loading,
      updateEvent,
    } = this.props;
    const { t } = this.context;
    const isDeletable =
    (['confirmed'].includes(latestProposal && latestProposal.status));
    const isDifferentSchedule =
    latestProposal && latestProposal.replacement && (
      (latestProposal.replacement.startsAt !== startsAt) ||
      (latestProposal.replacement.endsAt !== endsAt)
    );
    const isEditable = !['confirmed'].includes(latestProposal && latestProposal.status);

    const showProposalInfo =
    latestProposal &&
    latestProposal.replacement &&
    !['expired', 'rejected'].includes(latestProposal.status);
    const proposalsList = getSubstituteProposalsList(substituteProposals, latestProposal);
    return (
      <div className="matchmaking__tooltip">
        <div>
          <div className="matchmaking__tooltip__header">
            <span className="matchmaking__tooltip__date">
              {formatDayWithDateAndMonth(startsAt)}
              {loading && <SmallLoader />}
            </span>
            <div>
              {isEditable &&
              <button
                className="matchmaking__event__action matchmaking__event__action--edit"
                onClick={() => updateEvent(event)}
              />
                }
              {!latestProposal &&
              <button
                className="matchmaking__event__action matchmaking__event__action--delete"
                onClick={() => deleteEvent(event)}
              />
                }
            </div>
          </div>
          <div className="matchmaking__tooltip__proposals">
            {proposalsList && (
            <ul>
              {proposalsList.map((
                { id: proposalId,
                  clinic,
                  clinicStatus,
                  userStatus,
                  status,
                  updatedAt,
                }) => (
                  <li key={proposalId}>
                    <div className="matchmaking__user__infos">
                      <div className="matchmaking__user__name">
                        <span>{clinic && clinic.name}</span>
                        <span className="date">{moment(updatedAt).format('DD MMM YYYY')}</span>
                      </div>
                    </div>
                    <div className="matchmaking__event__actions">
                      <div className="tooltip__wrapper">
                        <span className="tooltip">{t('matchmaking.substitute')}</span>
                        <div className={classNames(
                        'matchmaking__user__status',
                        `matchmaking__user__status--${userStatus}`, {
                        'matchmaking__user__status--expired': status === 'expired',
                        })}
                        />
                      </div>
                      <div className="tooltip__wrapper">
                        <span className="tooltip">{t('matchmaking.clinic')}</span>
                        <div className={classNames(
                        'matchmaking__clinic__status',
                        `matchmaking__clinic__status--${clinicStatus}`)}
                        />
                      </div>
                      {(isDeletable || ['expired', 'rejected'].includes(status)) ?
                        <div>
                          <button
                            className="matchmaking__event__action matchmaking__event__action--delete"
                            onClick={() => deleteProposal(event, proposalId)}
                          />
                        </div>
                          : <div className="matchmaking__event__action matchmaking__event__action--no-action" />
                        }
                    </div>
                  </li>
                ))
            }
            </ul>
          )}
          </div>
          {showProposalInfo && isDifferentSchedule && (
          <div className="matchmaking__tooltip__footer">
            <span>{`${t('matchmaking.originalAvailability')}: `} {formatTimeRange(startsAt, endsAt)}</span>
          </div>
            )
            }
          {message &&
          <div className="matchmaking__tooltip__footer">
            <div className="tooltip__wrapper">
              <span className="tooltip">{t('matchmaking.tooltip.substituteMessage')}</span>
              <span className="
                  matchmaking__event__icon
                  matchmaking__event__icon--message"
              />
            </div>
            <div className="matchmaking__event__message">
              <div>{message}</div>
            </div>
          </div>
            }
          {adminNote &&
          <div className="matchmaking__tooltip__footer">
            <div className="tooltip__wrapper">
              <span className="tooltip">{t('matchmaking.tooltip.adminNote')}</span>
              <span className="
                  matchmaking__event__icon
                  matchmaking__event__icon--admin-note"
              />
            </div>
            <div className="matchmaking__event__message">
              <div>{adminNote}</div>
            </div>
          </div>
            }
          <div className="matchmaking__tooltip__footer matchmaking__tooltip__footer--dates">
            {showProposalInfo ? (
              <span>{`${t('matchmaking.tooltip.proposalCreatedAt')} : ${moment(latestProposal.replacement.createdAt).format('DD MMM YYYY')}`}</span>
                ) : (
                  <>
                    {event.updatedAt && (event.updatedAt !== event.createdAt) && <span>{`${t('matchmaking.tooltip.updatedAt')} : ${moment(event.updatedAt).format('DD MMM YYYY')}`}</span>}
                    {createdAt && <span>{`${t('matchmaking.tooltip.createdAt')} : ${moment(createdAt).format('DD MMM YYYY')}`}</span>}
                  </>
                )
              }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    substituteProposals: state.matchmaking.eventTooltip.proposals,
    loading: state.matchmaking.eventTooltip.loading,
  };
}

export default connect(
  mapStateToProps,
  { ...matchmakingActions },
)(AvailabilityEventTooltip);
