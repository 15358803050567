export default {
  callList: 'Call list',
  addCallToAll: 'Add call to All',
  editCallToAll: "Edit call to All",
  clinics: 'Clinics',
  miniprofile: {
    profileOverview: 'Profile Overview',
    adminNote: 'Internal Notes',
  },
  noProposals: 'Click on a request day that matches the locum\'s availabilities to make an offer',
  originalAvailability: 'Original availability',
  search: {
    noClinics: 'No clinics to display',
    noSubstitutes: 'No locums to display',
  },
  status: {
    availability: 'Locums availabilities',
    confirmed: 'Confirmed placement',
    empty: 'Locum availabilities / Clinic requests',
    expired: 'Expired',
    pending: 'Pending',
    rejected: 'Declined',
    replacement: 'Clinic requests',
  },
  statuses: 'Visible statuses',
  substitutes: {
    veterinarian: 'Vet locums',
    technician: 'AHT locums',
  },
  tooltip: {
    adminNote: 'Notes for internal purpose',
    clinicNote: 'Clinic message',
    createdAt: 'Created on',
    proposalCreatedAt: 'Proposal created on',
    substituteMessage: 'Substitute message',
    updatedAt: 'Updated on',
  },
};
