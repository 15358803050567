import types from './types';

export default {
  toggleAccordion: (accordionId) => ({
    type: types.TOGGLE_ACCORDION,
    accordionId,
  }),
  closeAllPanels: () => ({
    type: types.CLOSE_ALL_PANELS,
  }),
};
