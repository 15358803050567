import React from 'react';
import PropTypes from 'prop-types';
import Radio from '../radio/Radio';

const accommodation = { yes: 'true', no: 'false' };

function Accommodation({ name }) {
  return (
    <div className="radio-inline">
      { Object.entries(accommodation).map(([label, value]) =>
        <Radio
          key={value}
          name={name}
          component="input"
          value={value}
          label={`addMandate.accommodation.${label}`}
          type="radio"
        />,
      )}
    </div>
  );
}

Accommodation.propTypes = {
  name: PropTypes.string,
};
Accommodation.defaultProps = {
  name: 'accommodation',
};

export default Accommodation;
