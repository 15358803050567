// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

// components
import ClinicForm from './forms/ClinicForm';
import BasicLoader from '../../loader/BasicLoader';

// actions
import actions from '../../../actions/admin/clinics';
import alertsActions from '../../../actions/alerts/index';
import validate from './validate/validate';

// helpers
import { formatClinicFormData } from '../../../helpers/formatClinics';
import { scrollToError } from '../../../helpers/scrollToError';

class AddClinic extends Component {
  static propTypes = {
    createClinic: PropTypes.func.isRequired,
    error: PropTypes.string,
    fetchClinics: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hidePanel: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    pushAlert: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    formIsSubmitting: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    error: '',
  };

  constructor() {
    super();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(values) {
    const { createClinic, fetchClinics, pushAlert, hidePanel } = this.props;

    createClinic({ input: formatClinicFormData(values, false) })
      .then((data) => {
        if (data && data.createClinic.success) {
          fetchClinics();
          pushAlert({
            type: 'success',
            content: 'alerts.added',
          });
          hidePanel();
        }
      });
  }

  render() {
    const { error, handleSubmit, hidePanel, pristine, submitting, formIsSubmitting } = this.props;
    const { t } = this.context;
    const submitForm = handleSubmit(this.handleFormSubmit);

    return (
      <div>
        <form onSubmit={submitForm} className="side-panel__form">
          {formIsSubmitting && <BasicLoader />}
          {error && <div className="form-error">{error}</div>}
          <ClinicForm formTitle="createClinic" userRole="admin" />
          <div className="form__actions">
            <button
              className="form__cancel"
              type="button"
              disabled={submitting || formIsSubmitting}
              onClick={() => hidePanel()}
            >
              {t('form.formActions.cancel')}
            </button>
            <button className="form__submit" type="submit" disabled={pristine || submitting || formIsSubmitting}>
              {t('form.formActions.add')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formIsSubmitting: state.clinics.submitting,
  };
}

export default connect(
  mapStateToProps,
  { ...actions, ...alertsActions },
)(
  reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: 'createClinic',
    initialValues: {
      addBillingAddress: 'false',
      address: {
        country: 'ca',
        province: process.env.REACT_APP_REGION_CODE,
      },
      billingAddress: {
        country: 'ca',
        province: process.env.REACT_APP_REGION_CODE,
      },
      manualValidation: false,
      openingHours: [...Array(7).keys()].map((day) => ({ day, opensAt: '', closesAt: '' })),
      otherVets: 'false',
      rate: 'city',
      softwares: [],
      softwareSkills: false,
    },
    onSubmitFail: (errors) => scrollToError(errors),
    validate,
  })(AddClinic),
);
