export default {
  fetchClinicProposals: `
    query ($replacementId: ID!) {
      matchmakingReplacementProposals(replacementId: $replacementId) {
        id
        clinicStatus
        userStatus
        status
        createdAt
        updatedAt
        replacement{
          id
        }
        substitute{
          profile {
            firstName
            lastName
            picture {
              mini
            }
          }
        }       
      }
    }
  `,
  fetchClinicAddress: `
    query($id: ID!) {
      clinic(id: $id) {
        id
        address {
          id
          address
          address2
          zipcode
          city
          province
          country
        }
      }
    }
  `,
  fetchSubstituteAddress: `
    query($id: ID!) {
      user(id: $id) {
        id
        profile {
          id
          address {
            id
            address
            address2
            city
            country
            province
            zipcode
          }
        }
      }
    }
  `,
  fetchSubstituteProposals: `
    query(
      $availabilityId: ID!
    ) {
      matchmakingAvailabilityProposals(
        availabilityId: $availabilityId
      ) {
        id
        availability{
          id
        }
        clinicStatus
        userStatus
        status
        createdAt
        updatedAt
        clinic{
          name
        }
      }
    }
  `,
  fetchSubstituteAndClinics: `
    query(
      $after: String,
      $first: Int,
      $from: ISO8601DateTime!,
      $id: ID!,
      $mandateType: String,
      $search: String,
      $to: ISO8601DateTime!
    ) {
      user(id: $id) {
        id
        email
        profile {
          adminNote
          firstName
          graduation
          lastName
          picture {
            small
          }
        }
        availabilities(from: $from, to: $to) {
          nodes {
            id
            adminNote
            allDay
            availabilityType
            consultation
            createdAt
            distance
            endsAt
            message
            startsAt
            surgery
            latestProposal {
              id
              status
              userStatus
              clinicStatus
              clinic {
                id
                name
              }
              replacement {
                id
                createdAt
                endsAt
                mandate {
                  consultation
                  surgery
                }
                startsAt
                updatedAt
              }
            }
            updatedAt
          }
        }
      }
      bestClinics(
        after: $after,
        first: $first,
        from: $from,
        search: $search,
        to: $to,
        userId: $id
      ) {
        nodes {
          id
          adminNote
          distance(userId: $id)
          travelTime(userId: $id)
          name
          manualValidation
          mandates(mandateType: $mandateType, first: $first, from: $from, to: $to) {
            nodes {
              id
              adminNote
              accommodation
              clinic {
                id
              }
              consultation
              mandateType
              replacements {
                id
                createdAt
                flexibleTime
                endsAt
                priority
                startsAt
                discarded
                latestProposal {
                  id
                  clinicStatus
                  userStatus
                  status
                  createdAt
                  updatedAt
                  substitute{
                    profile {
                      firstName
                      lastName
                      picture {
                        mini
                      }
                    }
                  } 
                }
                updatedAt
              }
              message
              replacementsCount
              surgery
              wantedCount
            }
          }
          picture {
            small
          }
          replacementsCount(userId: $id)
          clinicBlacklistedUser(userId: $id)
          userBlacklistedClinic(userId: $id)
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  fetchSubstitutes: `
    query(
      $after: String,
      $accountType: String,
      $first: Int!,
      $from: ISO8601DateTime!,
      $roleName: String!,
      $search: String,
      $to: ISO8601DateTime!
    ) {
      users: matchmakingUsers(
        after: $after,
        accountType: $accountType,
        first: $first,
        from: $from,
        roleName: $roleName,
        search: $search,
        to: $to) {
        nodes {
          id
          email
          profile {
            adminNote
            firstName
            lastName
            picture {
              small
            }
          }
          availabilities(from: $from, to: $to) {
            nodes {
              id
              adminNote
              allDay
              availabilityType
              consultation
              createdAt
              distance
              endsAt
              message
              startsAt
              surgery
              updatedAt
              latestProposal {
                id
                status
                userStatus
                clinicStatus
                clinic {
                  id
                  name
                }
                replacement {
                  id
                  createdAt
                  endsAt
                  mandate {
                    consultation
                    surgery
                  }
                  startsAt
                  updatedAt
                }
                updatedAt
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  fetchClinics: `
    query(
      $after: String,
      $first: Int,
      $from: ISO8601DateTime!,
      $mandateType: String,
      $to: ISO8601DateTime!,
      $search: String
    ) {
      clinics: matchmakingClinics(
        after: $after,
        first: $first,
        from: $from,
        mandateType: $mandateType,
        search: $search,
        to: $to
      ) {
        nodes {
          adminNote
          id
          name
          manualValidation
          mandates(
            mandateType: $mandateType,
            first: $first,
            from: $from,
            to: $to
          ) {
            nodes {
              id
              adminNote
              accommodation
              clinic {
                id
              }
              consultation
              mandateType
              replacements {
                id
                createdAt
                updatedAt
                callToAll {
                  id
                  status
                }
                flexibleTime
                endsAt
                priority
                startsAt
                discarded
                latestProposal {
                  id
                  clinicStatus
                  userStatus
                  status
                  createdAt
                  updatedAt
                  substitute{
                    profile {
                      firstName
                      lastName
                      picture {
                        mini
                      }
                    }
                  }       
                }
              }
              message
              replacementsCount
              surgery
              wantedCount
            }
          }
          picture {
            small
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  createProposals: `
    mutation($input: ProposalsInput!) {
      createProposals(input: $input) {
        proposals {
          id
          replacement {
            id
            createdAt
            discarded
            endsAt
            flexibleTime
            priority
            startsAt
            updatedAt
            latestProposal {
              id
              clinicStatus
              userStatus
              status
              createdAt
              updatedAt
              replacement {
                mandate {
                  consultation
                  surgery
                }
              }
              substitute{
                profile {
                  firstName
                  lastName
                  picture {
                    mini
                  }
                }
              }       
            }
            proposals{
              id
              clinicStatus
              userStatus
              status
              updatedAt
              userStatus
              substitute{
                profile {
                  firstName
                  lastName
                  picture {
                    mini
                  }
                }
              }
            }
          }
          availability{
            id
            adminNote
            allDay
            availabilityType
            consultation
            distance
            endsAt
            message
            startsAt
            surgery
            createdAt
            updatedAt
            latestProposal {
              replacement {
                mandate {
                  consultation
                  surgery
                }
              }
              status
            }
            proposals{
              id
              clinicStatus
              userStatus
              status
              createdAt
              updatedAt
              clinic{
                name
              }
            }
            user {
              id
            }
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  enableMatchmakingReplacement: `
    mutation($id: ID!) {
      enableReplacement(id: $id) {
        errors {
          field
          message
        }
        success
        replacement{
          flexibleTime
          endsAt
          priority
          startsAt
          id
          discarded
          mandate {
            id
          }
          latestProposal {
            clinicStatus
            expirationExtended
            expiresAt
            message
            status
            replacement {
              id
            }
            userStatus
          }
          proposals{
            id
            clinicStatus
            userStatus
            status
            createdAt
            updatedAt
            substitute{
              profile {
                firstName
                lastName
                picture {
                  mini
                }
              }
            }
          }
        }
      }
    }
  `,
};
