// libs
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'o2web-react-core';

// styles
import './styles.scss';

function NotAllowed(props, { t }) {
  return (
    <div className="not-found">
      <h2>{t('pages.titles.notAllowed')}</h2>
      <p>{t('pages.notAllowed.text')}</p>
      <NavLink to="/en">
        {t('pages.notAllowed.back')}
      </NavLink>
    </div>
  );
}

NotAllowed.contextTypes = {
  t: PropTypes.func,
};

export default NotAllowed;
