function normalizePhone(value) {
  if (!value) {
    return value;
  }

  const nums = value.replace(/(?:\+1)/, '').replace(/[^\d]/g, '');

  if (nums.length <= 3) {
    return nums;
  }
  if (nums.length <= 6) {
    return `${nums.slice(0, 3)} ${nums.slice(3)}`;
  }

  return `${nums.slice(0, 3)} ${nums.slice(3, 6)}-${nums.slice(6, 10)}`;
}

export default normalizePhone;
