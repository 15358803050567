const validate = values => {
  const errors = {};
  if (!values.clinics || !values.clinics.length) {
    errors.clinics = { _error: 'At least one clinic must be selected' };
  } else {
    const clinicsArrayErrors = [];
    values.clinics.forEach((clinic, clinicIndex) => {
      const clinicErrors = {};
      if (clinic) {
        let clinicPriceError = {};
        let substitutePriceError = {};
        if (!clinic.clinicPrice) {
          clinicPriceError = 'Required';
        }
        if (!clinic.substitutePrice) {
          substitutePriceError = 'Required';
        }
        if (clinicPriceError.length) {
          clinicErrors.clinicPrice = clinicPriceError;
          clinicsArrayErrors[clinicIndex] = clinicErrors;
        }
        if (substitutePriceError.length) {
          clinicErrors.substitutePrice = substitutePriceError;
          clinicsArrayErrors[clinicIndex] = clinicErrors;
        }
      }
    });
    if (clinicsArrayErrors.length) {
      errors.clinics = clinicsArrayErrors;
    }
  }
  return errors;
};

export default validate;
