export function formatCallToAllsForProposalLists(callToAlls) {
  const callToAllsGroupedByMandates = callToAlls.reduce((groupedCallToAlls, callToAll) => {
    const { replacement, replacement: { mandate } } = callToAll;
    const replacementWithCallToAllId = { ...replacement, callToAllId: callToAll.id };

    const existingKey = groupedCallToAlls.find(
      groupedCallToAll => groupedCallToAll.id === mandate.id,
    );

    if (existingKey === undefined) {
      groupedCallToAlls[groupedCallToAlls.length] = {
        id: mandate.id,
        callToAll,
        mandate,
        replacements: [],
      };
    }

    groupedCallToAlls[groupedCallToAlls.length - 1].replacements.push(replacementWithCallToAllId);

    return groupedCallToAlls;
  }, []);

  return callToAllsGroupedByMandates.map((callToAll) => {
    callToAll.replacements = callToAll.replacements.sort((replacement, nextReplacement) => (
      new Date(replacement.startsAt) - new Date(nextReplacement.startsAt)
    ));

    const { replacements, mandate } = callToAll;

    return {
      periodStart: new Date(replacements[0].startsAt),
      periodEnd: new Date(replacements[replacements.length - 1].endsAt),
      wantedCount: mandate.wantedCount,
      totalCount: mandate.replacementsCount,
      accommodation: mandate.accommodation,
      mandateType: mandate.mandateType,
      message: mandate.message,
      consultation: mandate.consultation,
      surgery: mandate.surgery,
      replacements,
      clinic: mandate.clinic,
      id: mandate.id,
      callToAll,
    };
  });
}

export default formatCallToAllsForProposalLists;
