// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-month-picker/css/month-picker.css';
import { connect } from 'react-redux';
import moment from 'moment';

// components
import DateSelector from './DateSelector';
import Statuses from './Statuses';

// actions
import actionsSchedule from '../../../actions/schedule';

// styles
import './styles.scss';

class Toolbar extends Component {
  static propTypes = {
    currentDate: PropTypes.instanceOf(Date).isRequired,
    changeDate: PropTypes.func.isRequired,
    dateFrom: PropTypes.instanceOf(Date).isRequired,
    dateTo: PropTypes.instanceOf(Date).isRequired,
    updateHolidays: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor() {
    super();
    this.nextRange = this.nextRange.bind(this);
    this.prevRange = this.prevRange.bind(this);
    this.currentWeek = this.currentWeek.bind(this);
  }
  componentDidUpdate() {
    const {
      dateFrom,
      dateTo,
      updateHolidays,
    } = this.props;
    if (dateFrom.year() !== dateTo.year()) {
      updateHolidays([dateFrom, dateTo]);
    }
  }
  nextRange() {
    const { currentDate, changeDate } = this.props;
    const newDate = moment(currentDate).add(1, 'months').toDate();
    changeDate(newDate);
  }

  prevRange() {
    const { currentDate, changeDate } = this.props;
    const newDate = moment(currentDate).subtract(1, 'months').toDate();
    changeDate(newDate);
  }

  currentWeek() {
    const { changeDate } = this.props;
    const now = new Date();
    changeDate(now);
  }

  render() {
    const { currentDate, changeDate } = this.props;
    const { t } = this.context;

    return (
      <div className="matchmaking__toolbar">
        <div className="matchmaking__toolbar__schedule">
          <button
            onClick={this.prevRange}
            className="matchmaking__toolbar__action matchmaking__toolbar__action--prev"
            title={t('calendar.prev_month')}
          />
          <button
            onClick={this.currentWeek}
            className="matchmaking__toolbar__today"
            title={t('calendar.today')}
          />
          <button
            onClick={this.nextRange}
            className="matchmaking__toolbar__action matchmaking__toolbar__action--next"
            title={t('calendar.next_month')}
          />
          <DateSelector
            wrapperClassNames="matchmaking__toolbar__current-date"
            className="matchmaking__toolbar__date-selector"
            currentDate={currentDate}
            changeDate={changeDate}
          />
        </div>
        <Statuses />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentDate: state.schedule.date,
    dateFrom: state.schedule.from,
    dateTo: state.schedule.to,
  };
}

export default connect(
  mapStateToProps,
  actionsSchedule,
)(Toolbar);
