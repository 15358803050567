export default {
  fetchNotifications: `
    query(
      $readAfter: String, 
      $readFirst: Int,
      $readStatus: String,  
      $unreadAfter: String,
      $unreadFirst: Int,
      $unreadStatus: String,  
      $userId: ID!
    ) {
      user(id: $userId) {
        id
        unreadNotificationsCount
        readNotifications: notifications(after: $readAfter, first: $readFirst, status: $readStatus) {
          nodes {
            id
            createdAt
            appLink
            message
            notificationType
            status
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
        unreadNotifications: notifications(after: $unreadAfter, first: $unreadFirst, status: $unreadStatus) {
          nodes {
            id
            createdAt
            appLink
            message
            notificationType
            status
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  `,
  subscribeToNotifications: `
    subscription {
      notificationReceived {
        id
        createdAt
        appLink
        message
        notificationType
        status
      }
    }
  `,
  updateNotificationStatus: `
    mutation($input: UpdateStatusNotificationsInput!) {
      updateStatusNotifications(input: $input) {
        notifications {
          id
          createdAt
          appLink
          message
          notificationType
          status
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  updateNotifications: `
    query(
      $after: String, 
      $first: Int,
      $status: String,
      $userId: ID!) {
      user(id: $userId) {
        id
        notifications(after: $after, first: $first, status: $status) {
          nodes {
            id
            createdAt
            appLink
            message
            notificationType
            status
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  `,
};
