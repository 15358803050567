export function openInGoogleMaps(address) {
  const baseUrl = 'https://www.google.ca/maps/place/';
  const url = `${baseUrl}${address.address},+${address.city},+QC,+${address.zipcode}`;
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
}

export function copyAddressToClipboard(addressObject) {
  const addressToCopy = `${addressObject && addressObject.address}, ${addressObject && addressObject.city}, ${addressObject && addressObject.zipcode}`;
  navigator.clipboard.writeText(addressToCopy);
}

export default openInGoogleMaps;
