import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import '../Fields.scss';
import './Checkbox.scss';

function CheckboxField(field) {
  return (
    <label className={classNames(field.className, 'checkbox-tooltip__wrapper')}>
      <span className="checkbox-tooltip">{field.status}</span>
      <input
        name={field.name}
        {...field.input}
        type="checkbox"
        defaultChecked
      />
      <span className="checkbox" />
    </label>
  );
}

CheckboxField.contextTypes = {
  t: PropTypes.func,
};

export default CheckboxField;
