export default {
  fetchExoticAnimals: `
    query {
      exoticAnimals {
        id
        name
      }
    }
  `,
};
