import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// components
import { NavLink } from 'o2web-react-core';

function HelpcenterMenu({ hidePanel, userRole }, { t }) {
  return (
    <nav className="side-panel__nav">
      <ul className="side-panel__nav__list">
        <li><NavLink to="/en/helpcenter" onClick={hidePanel}>{t('nav.helpcenter')}</NavLink></li>
        {userRole === 'substitute' &&
          <li><NavLink to="/en/faq" onClick={hidePanel}>{t('nav.faq')}</NavLink></li>
        }
      </ul>
    </nav>
  );
}

HelpcenterMenu.propTypes = {
  hidePanel: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};
HelpcenterMenu.defaultProps = {};
HelpcenterMenu.contextTypes = {
  t: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    userRole: state.user.role,
  };
}

export default connect(mapStateToProps)(HelpcenterMenu);
