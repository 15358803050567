export default {
  fetchAdminMandates: `
    query(
      $first: Int
      $from: ISO8601DateTime
      $to: ISO8601DateTime
      $after: String
      $latest: Boolean
      $proposalStatus: String
      $mandateType: String
    ) {
      mandates(
        first: $first
        from: $from
        to: $to
        after: $after
        latest: $latest
        proposalStatus: $proposalStatus
        mandateType: $mandateType
      ) {
        nodes {
          id
          accommodation
          clinic {
            id
            name
            address {
              address
              address2
              city
              country
              province
              zipcode
            }
          }
          mandateType
          replacements {
            flexibleTime
            endsAt
            priority
            startsAt
            id
            discarded
            mandate {
              id
            }
            latestProposal {
              clinicStatus
              expirationExtended
              expiresAt
              message
              distance
              replacement {
                id
              }
              userStatus
            }
          }
          proposals(status: $proposalStatus) {
            id
            clinicStatus
            allowExtendedExpiration
            expirationExtended
            expiresAt
            message
            distance
            availability {
              id
            }
            clinicPrice
            replacement {
              id
              startsAt
              endsAt
              priority
              flexibleTime
              mandate {
                consultation
                surgery
                accommodation
              }
            }
            substitute {
              profile {
                consultation
                firstName
                lastName
                graduation
                picture {
                  mini
                }
                surgery
              }
            }
            substitutePrice
            userStatus
            status
          }
          consultation
          surgery
          message
          replacementsCount
          wantedCount
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  fetchClinicMandates: `
    query(
      $id: ID!
      $first: Int
      $from: ISO8601DateTime
      $to: ISO8601DateTime
      $after: String
      $latest: Boolean
      $proposalStatus: String
      $boostUnconfirmedProposals: Boolean
    ) {
      clinic(id: $id) {
        mandates(
          boostUnconfirmedProposals: $boostUnconfirmedProposals
          first: $first
          from: $from
          to: $to
          after: $after
          latest: $latest
          proposalStatus: $proposalStatus
        ) {
          nodes {
            id
            accommodation
            clinic {
              id
            }
            mandateType
            replacements {
              flexibleTime
              endsAt
              priority
              startsAt
              id
              discarded
              mandate {
                id
              }
              latestProposal {
                clinicStatus
                expirationExtended
                expiresAt
                message
                distance
                replacement {
                  id
                }
                substitute {
                  profile {
                    firstName
                    lastName
                    graduation
                    picture {
                      mini
                    }
                  }
                }
                status
                userStatus
                isNewSubstitute
              }
            }
            proposals(status: $proposalStatus) {
              expirationExtended
              expiresAt
              message
              id
              distance
              clinicPrice
              clinicStatus
              replacement {
                id
                endsAt
                startsAt
                priority
                flexibleTime
                mandate {
                  consultation
                  surgery
                  accommodation
                }
              }
              substitute {
                profile {
                  consultation
                  firstName
                  lastName
                  graduation
                  picture {
                    mini
                  }
                  surgery
                }
              }
              isNewSubstitute
              status
              userStatus
            }
            consultation
            surgery
            message
            replacementsCount
            wantedCount
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  `,
  fetchSubstituteMandates: `
    query(
      $id: ID!
      $proposalStatus: String
      $from: ISO8601DateTime
      $to: ISO8601DateTime
      $latest: Boolean
      $first: Int
      $after: String
    ) {
      user(id: $id) {
        mandates(
          proposalStatus: $proposalStatus
          from: $from
          to: $to
          latest: $latest
          first: $first
          after: $after
        ) {
          nodes {
            id
            accommodation
            clinic {
              id
              distance(userId: $id)
              name
              message
              address {
                address
                address2
                city
                country
                province
                zipcode
              }
            }
            consultation
            surgery
            mandateType
            proposals(status: $proposalStatus, userId: $id) {
              id
              clinicStatus
              expirationExtended
              allowExtendedExpiration
              expiresAt
              message
              distance
              availability {
                id
              }
              replacement {
                id
                startsAt
                endsAt
                flexibleTime
                mandate {
                  consultation
                  surgery
                  accommodation
                }
              }
              status
              substituteMessage
              substitutePrice
              userStatus
            }
            replacementsCount
            wantedCount
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  `,
  userReviewProposals: `
    mutation($input: ProposalReviewsInput!) {
      userReviewProposals(input: $input) {
        proposals {
          id
          userStatus
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  adminReviewUsersProposals: `
    mutation($usersInput: ProposalReviewsInput!) {
      userReviewProposals(input: $usersInput) {
        proposals {
          id
          userStatus
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  adminReviewClinicsProposals: `
    mutation($clinicInput: ProposalReviewsInput!) {
      clinicReviewProposals(input: $clinicInput) {
        proposals {
          id
          clinicStatus
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  adminReviewCompleteProposals: `
    mutation($usersInput: ProposalReviewsInput!, $clinicInput: ProposalReviewsInput!) {
      userReviewProposals(input: $usersInput) {
        proposals {
          id
          userStatus
        }
        errors {
          field
          message
        }
        success
      }
      clinicReviewProposals(input: $clinicInput) {
        proposals {
          id
          clinicStatus
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  clinicReviewProposals: `
    mutation($input: ProposalReviewsInput!) {
      clinicReviewProposals(input: $input) {
        proposals {
          id
          clinicStatus
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  updateProposals: `
    mutation($input: ProposalsInput!) {
      updateProposals(input: $input) {
        proposals {
          id
          expirationExtended
          allowExtendedExpiration
          expiresAt
          replacement {
            id
          }
          availability {
            id
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  fetchClinicMandate: `
    query(
      $id: ID!
      $status: String
    ) {
      mandate(
        id: $id
      ) {
        id
        accommodation
        clinic {
          id
        }
        mandateType
        replacements {
          flexibleTime
          endsAt
          priority
          startsAt
          id
          discarded
          mandate {
            id
          }
          latestProposal {
            clinicStatus
            expirationExtended
            expiresAt
            message
            distance
            replacement {
              id
            }
            substitute {
              profile {
                firstName
                lastName
                graduation
                picture {
                  mini
                }
              }
            }
            isNewSubstitute
            userStatus
          }
        }
        proposals(status: $status) {
          clinicStatus
          expirationExtended
          expiresAt
          message
          id
          distance
          replacement {
            id
            endsAt
            startsAt
            priority
            flexibleTime
            mandate {
              consultation
              surgery
              accommodation
            }
          }
          substitute{
            profile{
              firstName
              lastName
              graduation
              picture {
                mini
              }
            }
          }
          isNewSubstitute
          status
          userStatus
        }
        consultation
        replacementsCount
        surgery
        message
        wantedCount
      }
    }
  `,
  fetchSubstituteMandate: `
    query($id: ID!, $userId: ID!, $status: String) {
      mandate(id: $id) {
        id
        accommodation
        clinic {
          id
          distance(userId: $userId)
          name
          address {
            address
            address2
            city
            country
            province
            zipcode
          }
        }
        mandateType
        proposals(userId: $userId, status: $status) {
          id
          clinicStatus
          expirationExtended
          allowExtendedExpiration
          expiresAt
          distance
          availability {
            id
          }
          replacement {
            id
            startsAt
            endsAt
            flexibleTime
            mandate {
              consultation
              surgery
              accommodation
            }
          }
          status
          userStatus
        }
        consultation
        surgery
        replacementsCount
        wantedCount
      }
    }
  `,
};
