import { asyncQuery, asyncMutation } from '../../../config/graphql/async-action-creator';
import queries from './queries';
import types from './types';
import store from '../../../config/redux/store';
import client from '../../../config/graphql/client';

export default {
  fetchAdminMandates: (variables = {}) => asyncQuery(
    store,
    variables.after ? types.FETCH_NEXT_ADMIN_MANDATES : types.FETCH_ADMIN_MANDATES,
    queries.fetchAdminMandates,
    { first: 50, latest: true, ...variables },
    client,
  ),
  fetchClinicMandates: (variables = {}) => asyncQuery(
    store,
    variables.after ? types.FETCH_NEXT_CLINIC_MANDATES : types.FETCH_CLINIC_MANDATES,
    queries.fetchClinicMandates,
    { first: 10, latest: true, ...variables },
    client,
  ),
  fetchSubstituteMandates: (variables = {}) => asyncQuery(
    store,
    variables.after ? types.FETCH_NEXT_SUBSTITUTE_MANDATES : types.FETCH_SUBSTITUTE_MANDATES,
    queries.fetchSubstituteMandates,
    { first: 10, latest: true, ...variables },
    client,
  ),
  updateProposals: (variables) => asyncMutation(
    store,
    types.UPDATE_PROPOSALS,
    queries.updateProposals,
    variables,
    client,
  ),
  adminReviewUsersProposals: (variables) => asyncMutation(
    store,
    types.ADMIN_REVIEW_USERS_PROPOSALS,
    queries.adminReviewUsersProposals,
    variables,
    client,
  ),
  adminReviewClinicsProposals: (variables) => asyncMutation(
    store,
    types.ADMIN_REVIEW_CLINICS_PROPOSALS,
    queries.adminReviewClinicsProposals,
    variables,
    client,
  ),
  adminReviewCompleteProposals: (variables) => asyncMutation(
    store,
    types.ADMIN_REVIEW_COMPLETE_PROPOSALS,
    queries.adminReviewCompleteProposals,
    variables,
    client,
  ),
  clinicReviewProposals: (variables) => asyncMutation(
    store,
    types.CLINIC_REVIEW_PROPOSALS,
    queries.clinicReviewProposals,
    variables,
    client,
  ),
  userReviewProposals: (variables) => asyncMutation(
    store,
    types.USER_REVIEW_PROPOSALS,
    queries.userReviewProposals,
    variables,
    client,
  ),
  fetchClinicMandate: (variables) => asyncQuery(
    store,
    types.FETCH_CLINIC_MANDATE,
    queries.fetchClinicMandate,
    variables,
    client,
  ),
  fetchSubstituteMandate: (variables) => asyncQuery(
    store,
    types.FETCH_SUBSTITUTE_MANDATE,
    queries.fetchSubstituteMandate,
    variables,
    client,
  ),
};
