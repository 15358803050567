import types from '../actions/admin/users/types';

const initialState = {
  loading: false,
  pageInfo: {},
  results: [],
  suggested: [],
  user: {},
  profilePicture: {},
  profileVoidedCheck: {},
  submitting: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CREATE_USER:
      return {
        ...state,
        submitting: true,
      };
    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case types.CREATE_USER_FAIL:
      return {
        ...state,
        submitting: false,
      };
    case types.UPDATE_USER:
      return {
        ...state,
        submitting: true,
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case types.UPDATE_USER_FAIL:
      return {
        ...state,
        submitting: false,
      };
    case types.FETCH_USERS:
      return { ...state, loading: true, user: {} };
    case types.FETCH_SUGGESTED_USERS_SUCCESS:
      return {
        ...state,
        suggested: action.payload.users.nodes,
      };
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload.users.nodes,
        pageInfo: action.payload.users.pageInfo,
      };
    case types.FETCH_NEXT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        results: [...state.results, ...action.payload.users.nodes],
        pageInfo: action.payload.users.pageInfo,
      };
    case types.DISABLE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        results: state.results.map(result => {
          const { payload: { disableUser: { user } } } = action;

          if (user.id === result.id) {
            return { ...result, ...user };
          }
          return result;
        }),
      };
    case types.ENABLE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        results: state.results.map(result => {
          const { payload: { enableUser: { user } } } = action;

          if (user.id === result.id) {
            return { ...result, ...user };
          }
          return result;
        }),
      };
    case types.FETCH_USER:
      return { ...state, user: {}, profilePicture: {}, profileVoidedCheck: {} };
    case types.FETCH_USER_SUCCESS: {
      const profilePicture = action.payload.user.profile.picture;
      const profileVoidedCheck = action.payload.user.profile.voidedCheck;
      return {
        ...state,
        user: action.payload.user,
        profilePicture: profilePicture !== null ? profilePicture : {},
        profileVoidedCheck: profileVoidedCheck !== null ? profileVoidedCheck : {},
      };
    }
    default:
  }
  return state;
}
