// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { translateRoute } from 'o2web-react-core';
import { formValueSelector } from 'redux-form';
import FlipMove from 'react-flip-move';

// components
import AddProposals from './AddProposals';
import BasicLoader from '../loader/BasicLoader';
import ClinicRow from './row/ClinicRow';
import DatesHeader from './row/DatesHeader';
import HeaderRow from './row/HeaderRow';
import EmptyRow from './row/EmptyRow';
import SearchClinics from '../admin/clinics/forms/SearchForm';
import SubstituteRow from './row/SubstituteRow';

// helpers
import { matchmakingDaysRange, matchmakingRange } from '../../helpers/range';
import { isClinicSelected } from '../../helpers/matchmaking';

// actions
import actions from '../../actions/matchmaking';

class Proposals extends Component {
  static propTypes = {
    clinics: PropTypes.array.isRequired,
    currentDate: PropTypes.instanceOf(Date).isRequired,
    dateFrom: PropTypes.object.isRequired,
    dateTo: PropTypes.object.isRequired,
    discardProposals: PropTypes.func.isRequired,
    endCursor: PropTypes.string,
    fetchSubstituteAndClinics: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    holidays: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    mandateType: PropTypes.string.isRequired,
    proposals: PropTypes.array.isRequired,
    search: PropTypes.string,
    selectClinic: PropTypes.func.isRequired,
    selectedClinics: PropTypes.array.isRequired,
    selectedMiniprofileClinicId: PropTypes.string,
    selectedMiniprofileUserId: PropTypes.string,
    selectedUserId: PropTypes.string,
    substitutes: PropTypes.array.isRequired,
    substituteSelected: PropTypes.bool.isRequired,
    toggleClinicMiniprofile: PropTypes.func.isRequired,
    toggleSubstituteMiniprofile: PropTypes.func.isRequired,
    unselectClinic: PropTypes.func.isRequired,
    unselectSubstitute: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    endCursor: null,
    search: '',
    selectedUserId: null,
    selectedMiniprofileClinicId: '',
    selectedMiniprofileUserId: '',
  };

  constructor(props, context) {
    super(props, context);
    this.unselectSubstitute = this.unselectSubstitute.bind(this);
    this.updateClinics = this.updateClinics.bind(this);
    this.toggleClinicSelection = this.toggleClinicSelection.bind(this);
    this.toggleClinicMiniprofile = this.toggleClinicMiniprofile.bind(this);
    this.toggleSubstituteMiniprofile = this.toggleSubstituteMiniprofile.bind(this);

    if (!props.substituteSelected) {
      this.redirectToSubstitutes();
    }
  }

  componentDidMount() {
    const { substituteSelected } = this.props;

    if (substituteSelected) {
      this.updateSubstituteAndClinics();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentDate: prevDate, mandateType: prevMandateType } = prevProps;
    const { currentDate, mandateType } = this.props;

    if (prevMandateType !== mandateType) {
      this.updateSubstituteAndClinics();
      this.unselectSubstitute();
    } else if (moment(prevDate).valueOf() !== moment(currentDate).valueOf()) {
      this.updateSubstituteAndClinics();
    }
  }

  componentWillUnmount() {
    const { unselectSubstitute, discardProposals } = this.props;
    unselectSubstitute();
    discardProposals();
  }

  updateClinics() {
    this.updateSubstituteAndClinics();
  }

  updateSubstituteAndClinics(nextPage = false) {
    const {
      dateFrom,
      dateTo,
      endCursor,
      fetchSubstituteAndClinics,
      mandateType,
      search,
      selectedUserId,
    } = this.props;
    const { from, to } = matchmakingRange(dateFrom, dateTo);
    fetchSubstituteAndClinics({
      id: selectedUserId,
      from,
      search,
      to,
      mandateType,
      ...(nextPage && { after: endCursor }),
    });
  }

  unselectSubstitute() {
    const { unselectSubstitute, discardProposals } = this.props;
    unselectSubstitute();
    discardProposals();
    this.redirectToSubstitutes();
  }

  redirectToSubstitutes() {
    const { history } = this.props;
    const { t } = this.context;

    history.push(translateRoute('/en/matchmaking/substitutes', t));
  }

  toggleClinicMiniprofile(clinicId) {
    const { toggleClinicMiniprofile } = this.props;
    toggleClinicMiniprofile(clinicId);
  }

  toggleClinicSelection(clinic) {
    const { selectClinic, selectedClinics, unselectClinic } = this.props;

    if (isClinicSelected(selectedClinics, clinic)) {
      unselectClinic(clinic);
    } else {
      selectClinic(clinic);
    }
  }

  toggleSubstituteMiniprofile(userId) {
    const { toggleSubstituteMiniprofile } = this.props;
    toggleSubstituteMiniprofile(userId);
  }

  render() {
    const {
      clinics,
      currentDate,
      dateFrom,
      dateTo,
      hasNextPage,
      holidays,
      loading,
      proposals,
      selectedClinics,
      selectedMiniprofileClinicId,
      selectedMiniprofileUserId,
      selectedUserId,
      substitutes,
      substituteSelected,
    } = this.props;
    const { t } = this.context;

    const selectedSubstitute = substitutes.find((substitute) => selectedUserId === substitute.id);

    if (!substituteSelected) {
      return null;
    }

    return (
      <div className="wrapper--fullwidth">
        <div className="matchmaking__calendar__wrapper">
          <div className="matchmaking__calendar matchmaking__calendar--proposals">
            <div className="matchmaking__calendar__header">
              <DatesHeader
                currentDate={currentDate}
                date={dateFrom}
                days={matchmakingDaysRange(dateFrom, dateTo)}
                holidays={holidays}
              >
                <SearchClinics
                  fetchClinics={this.updateClinics}
                  formName="matchmakingSearch"
                />
              </DatesHeader>
              <SubstituteRow
                date={moment(dateFrom)}
                days={matchmakingDaysRange(dateFrom, dateTo)}
                onSelect={this.unselectSubstitute}
                holidays={holidays}
                onToggleSubstituteMiniprofile={this.toggleSubstituteMiniprofile}
                selected
                selectedMiniprofileUserId={selectedMiniprofileUserId}
                substitute={selectedSubstitute}
              />
            </div>
            <HeaderRow
              date={dateFrom}
              days={matchmakingDaysRange(dateFrom, dateTo)}
              holidays={holidays}
              title={t('matchmaking.clinics')}
            />
            <FlipMove>
              {clinics.map(clinic =>
                <div key={clinic.id}>
                  <ClinicRow
                    key={clinic.id}
                    availabilities={selectedSubstitute.availabilities}
                    clinic={clinic}
                    date={moment(dateFrom)}
                    days={matchmakingDaysRange(dateFrom, dateTo)}
                    holidays={holidays}
                    onSelect={this.toggleClinicSelection}
                    onToggleClinicMiniprofile={this.toggleClinicMiniprofile}
                    selected={isClinicSelected(selectedClinics, clinic)}
                    selectedMiniprofileClinicId={selectedMiniprofileClinicId}
                    substituteSelected={substituteSelected}
                  />
                </div>,
              )}
            </FlipMove>
            {clinics.length === 0 &&
              <EmptyRow text={t('matchmaking.search.noClinics')} />
            }
          </div>

          {hasNextPage && (
            <div className="matchmaking__calendar__pagination">
              <button
                className="content-item__button form__submit"
                type="button"
                onClick={() => this.updateSubstituteAndClinics(true)}
              >
                {t('form.formActions.loadMore')}
              </button>
            </div>
          )}
        </div>
        { proposals.length > 0 && <AddProposals /> }
        { loading && (
          <BasicLoader />
        )}
      </div>
    );
  }
}

function mapsStateToProps(state) {
  const selector = formValueSelector('matchmakingSearch');

  return {
    clinics: state.matchmaking.clinics,
    currentDate: state.schedule.date,
    dateFrom: state.schedule.from,
    dateTo: state.schedule.to,
    endCursor: state.matchmaking.endCursor,
    hasNextPage: state.matchmaking.hasNextPage,
    holidays: state.schedule.holidays,
    loading: state.matchmaking.loading,
    mandateType: state.matchmaking.selectedAccountType,
    proposals: state.matchmaking.proposals,
    search: selector(state, 'search'),
    selectedClinics: state.matchmaking.selectedClinics,
    selectedMiniprofileClinicId: state.matchmaking.selectedMiniprofileClinicId,
    selectedMiniprofileUserId: state.matchmaking.selectedMiniprofileUserId,
    selectedUserId: state.matchmaking.selectedUserId,
    substitutes: state.matchmaking.substitutes,
    substituteSelected: state.matchmaking.substituteSelected,
  };
}

export default withRouter(connect(
  mapsStateToProps,
  { ...actions },
)(Proposals));
