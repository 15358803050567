import moment from 'moment';

export function formatHours(time) {
  const momentLocal = localStorage.getItem('Lang');
  if (momentLocal === 'fr') {
    return `${moment(time).format('H')}h${moment(time).format('mm')}`;
  }
  return `${moment(time).format('h')}:${moment(time).format('mm A')}`;
}

export function formatDay(date) {
  return moment(date).format('DD');
}

export function formatDateAndTime(date) {
  const momentLocal = localStorage.getItem('Lang');
  if (momentLocal === 'fr') {
    return moment(date).format('LL [-] H[h]mm');
  }
  return moment(date).format('LL [-] h:mm A');
}

export function formatDayAndMonth(date) {
  return moment(date).format('DD MMM');
}

export function formatDayWithDate(date) {
  return moment(date).format('dddd DD');
}

export function formatDayWithDateAndMonth(date) {
  return moment(date).format('dddd DD MMM');
}

export function formatTimeRange(startsAt, endsAt) {
  const momentLocal = localStorage.getItem('Lang');
  if (momentLocal === 'fr') {
    return `${moment(startsAt).format('HH:mm')} - ${moment(endsAt).format('HH:mm')}`;
  }
  return `${moment(startsAt).format('h:mm A')} - ${moment(endsAt).format('h:mm A')}`;
}

export function formatPeriodLengthDate(dateStart, dateEnd) {
  const isSameDay = dateStart.getDay() === dateEnd.getDay();
  const isSameMonth = dateStart.getMonth() === dateEnd.getMonth();
  if (!isSameMonth) {
    return `${moment(dateStart).format('DD MMM')} - ${moment(dateEnd).format('DD MMM')}`;
  } else if (isSameDay) {
    return moment(dateStart).format('dddd DD MMM');
  }
  return `${
    moment(dateStart).format('DD')
  } - ${
    moment(dateEnd).format('DD')
  } ${
    moment(dateStart).format('MMM')
  }`;
}

export function formatMonthWithYear(date) {
  return moment(date).format('MMMM YYYY');
}

export function yearsSinceGraduation(date) {
  return moment().diff(date, 'years');
}
