export const accordions = {
  replacement: {
    title: 'Remplacement',
    content: {
      0: {
        rowTitle: 'Est-ce possible de faire du remplacement à temps plein? ',
        rowContent: 'Oh que oui! Beaucoup de remplaçants ont choisi de consacrer leur carrière à faire du remplacement à temps plein. Ils profitent donc du plein contrôle de leur horaire et d’une rémunération supérieure au salaire moyen d\’une clinique, en tout temps, en plus d\'aider un grand nombre d\'établissements vétérinaires qui en ont besoin!',
      },
      1: {
        rowTitle: 'Est-ce qu\’il y a un minimum d\’heures à offrir?',
        rowContent: 'Il n\'y a aucun minimum d\'heures demandé! Tu peux travailler avec nous aussi peu qu\'une journée par mois! Tu as toujours le plein contrôle du nombre d\'heures que tu veux effectuer et de la durée des mandats.',
      },
      2: {
        rowTitle: 'Est-ce que je peux demander à retourner dans une clinique que j\'ai particulièrement appréciée?',
        rowContent: 'Bien sûr! Tous nos remplaçants ont leur coup de coeur et les cliniques favorites chez qui ils aiment retourner remplacer. Si tu souhaites renouveler l\'expérience dans une clinique que tu as appréciée, nous te proposerons en priorité les mandats demandés dans cette même clinique.',
      },
      3: {
        rowTitle: 'Est-ce que je peux refuser une proposition?',
        rowContent: 'Absolument! Tu peux refuser un mandat qui t\'est proposé, sans même avoir besoin de te justifier. Notre mission est de te proposer des mandats qui correspondent le mieux à tes préférences, mais tu as toujours le dernier mot!',
      },
      4: {
        rowTitle: 'Est-ce que je peux annuler un mandat confirmé?',
        rowContent: 'Parce qu\'une clinique et son équipe comptent sur toi, il n\'est malheureusement pas possible d\'annuler un mandat confirmé. Entendons-nous, il y a évidemment des cas de forces majeures! Nous évaluerons chaque situation au cas par cas, au besoin.',
      },
    }
  },
  invoicing: {
    title: 'Facturation',
    content: {
      0: {
      rowTitle: 'En tant que travailleur autonome, comment fonctionne la facturation? ',
      rowContent: 'Il y a 2 façons de fonctionner : tu peux produire toi-même tes factures et nous les transmettre, ou bien encore plus simple pour toi, tu peux remplir la feuille de temps disponible dans l\'application VetRéseau, et nous nous occuperons de produire tes factures, (et ce gratuitement!). Nous t\'enverrons par la suite tes factures par courriel, suivi du paiement par dépôt direct.',
      },
      1: {
        rowTitle: 'Quand dois-je faire parvenir mes heures ou ma facture?',
        rowContent: 'Dans la semaine des heures travaillées, le dimanche avant minuit. Par exemple, si tu travailles le mardi, le mercredi et le vendredi, tu as jusqu\'au dimanche à 23h59 pour nous acheminer le tout!',
      },
      2: {
        rowTitle: 'À quel moment devrais-je recevoir le paiement des heures travaillées?',
        rowContent: 'Les paiements sont versés aux 2 semaines. Tu recevras ton paiement le jeudi, 2 semaines après ta semaine de remplacement. Comme tu es travailleur autonome, il n\'y a pas de retenues d\'impôts dans ta paie. Il est donc important de voir avec ton comptable quelle somme tu dois penser à mettre de côté.',
      },
      3: {
        rowTitle: 'Comment les déplacements sont-ils rémunérés?',
        rowContent: 'Pour chaque kilomètre parcouru, nous te remboursons 0,30$ du kilomètre. C\'est aussi simple que ça!',
      },
    }
  },
};

export default accordions;