// libs
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classNames from 'classnames';

// components
import Input from '../../../forms/fields/input/Input';

function ProposalsPrices({ field, onToggleLockPrices, isPricesUnlocked }, { t }) {
  const preventEnterKey = (e) => e.key === 'Enter' && e.preventDefault();
  return (
    <div className="prices__wrapper">
      <div
        className={classNames('prices__clinic__icon', {
          'prices__clinic__icon--lock': !isPricesUnlocked,
          'prices__clinic__icon--unlock': isPricesUnlocked,
        })}
        onClick={onToggleLockPrices}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      />
      <div className="price__fields__wrapper">
        <div className="prices__clinic">
          <span className="prices__clinic__icon prices__clinic__icon--clinic" />
          <div className="tooltip__wrapper">
            <span className="tooltip">{t('form.addProposals.clinicPrice')}</span>
            <Field
              name={`${field}.clinicPrice`}
              className="price__field"
              component={Input}
              noLabel
              onKeyPress={preventEnterKey}
              type="number"
              disabled={!isPricesUnlocked}
            />
          </div>
        </div>
        <div className="prices__substitute">
          <span className="prices__clinic__icon prices__clinic__icon--substitute" />
          <div className="tooltip__wrapper">
            <span className="tooltip">{t('form.addProposals.substitutePrice')}</span>
            <Field
              name={`${field}.substitutePrice`}
              className="price__field"
              component={Input}
              noLabel
              onKeyPress={preventEnterKey}
              type="number"
              disabled={!isPricesUnlocked}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ProposalsPrices.defaultProps = {};

ProposalsPrices.propTypes = {
  field: PropTypes.string.isRequired,
  onToggleLockPrices: PropTypes.func.isRequired,
  isPricesUnlocked: PropTypes.bool.isRequired,
};

ProposalsPrices.contextTypes = {
  t: PropTypes.func,
};

export default ProposalsPrices;
