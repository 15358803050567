export default {
  fetchUsers: `
    query($first: Int!, $search: String, $roleName: String, $last: Int, $before: String, $after: String) {
      users(first: $first, search: $search, roleName: $roleName, after: $after, last: $last, before: $before) {
        nodes {
          id
          discarded
          email
          roles {
            name
          }
          profile {
            id
            accountType
            firstName
            lastName
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  fetchUser: `
    query($id: ID!) {
      user(id: $id) {
        id
        email
        roles {
          name
        }
        blacklistedClinics {
          id
          clinicId
          userType
          userBlacklistedClinic
          clinic {
            id
            name
          }
        }
        profile {
          id
          accommodationBringAnimals
          accommodationPreferences
          accountType
          address {
            id
            address
            address2
            city
            country
            province
            zipcode
          }
          adminNote
          animals
          bringAnimals
          communicationType
          consultation
          defaultDistance
          displayLanguageId
          exoticAnimals {
            id
            exoticAnimalId
            name
          }
          exoticAnimalMessage
          exoticAnimalSkills
          firstName
          gloveSize
          graduation
          languages {
            id
            languageId
            level
          }
          lastName
          licenceNumber
          phone
          picture {
            mini
            small
            medium
            large
          }
          surgery
          softwareOthers
          softwares {
            id
            softwareId
            name
          }
          surgeryCanineOvhWeightLimit
          surgeryDeclawing
          surgeryMessage
          surgerySoftTissueCaesarean
          surgerySoftTissueCystotomy
          surgerySoftTissueForeignBody
          surgerySoftTissueMassAblation
          surgerySoftTissuePyometra
          surgerySoftTissueOthers
          surgerySterilization
          taxNumbers {
            number
            type
          }
          voidedCheck {
            original
          }
        }
      }
    }
  `,
  fetchAccountUser: `
    query($id: ID!) {
      user(id: $id) {
        id
        email
        roles {
          name
        }
        profile {
          id
          accommodationBringAnimals
          accommodationPreferences
          accountType
          address {
            id
            address
            address2
            city
            country
            province
            zipcode
          }
          adminNote
          animals
          bringAnimals
          communicationType
          consultation
          defaultDistance
          displayLanguageId
          exoticAnimals {
            id
            exoticAnimalId
            name
          }
          exoticAnimalMessage
          exoticAnimalSkills
          firstName
          gloveSize
          graduation
          languages {
            id
            languageId
            level
          }
          lastName
          licenceNumber
          phone
          picture {
            mini
            small
            medium
            large
          }
          surgery
          softwareOthers
          softwares {
            id
            softwareId
            name
          }
          surgeryCanineOvhWeightLimit
          surgeryDeclawing
          surgeryMessage
          surgerySoftTissueCaesarean
          surgerySoftTissueCystotomy
          surgerySoftTissueForeignBody
          surgerySoftTissueMassAblation
          surgerySoftTissuePyometra
          surgerySoftTissueOthers
          surgerySterilization
          taxNumbers {
            number
            type
          }
          voidedCheck {
            original
          }
        }
      }
    }
  `,
  createUser: `
    mutation($input: UserInput!) {
      createUser(input: $input) {
        user {
          id
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  updateUser: `
    mutation($id: ID!, $input: UserInput!) {
      updateUser(id: $id, input: $input) {
        user {
          id
          profile {
            adminNote
            displayLanguage {
              key
            }
            picture {
              mini
              small
              medium
              large
            }
            voidedCheck {
              original
            }
          }
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  enableUser: `
    mutation($id: ID!) {
      enableUser(id: $id) {
        user {
          id
          discarded
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
  disableUser: `
    mutation($id: ID!) {
      disableUser(id: $id) {
        user {
          id
          discarded
        }
        errors {
          field
          message
        }
        success
      }
    }
  `,
};
