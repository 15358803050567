export default function formatSoftwares(softwaresInput) {
  return softwaresInput.map((softwareInput) => {
    const { checked, id, softwareId } = softwareInput;
    return {
      softwareId,
      ...(id !== null) && { id },
      ...(!checked) && { destroy: true },
    };
  });
}
