export default {
  clinicCannotBeChanged: 'Le statut de la clinique ne peut pas être changé',
  dayAvail: {
    format: 'Format non valide. Ex. 01:23',
    required: 'Entrez une période de temps ou sélectionnez toute la journée',
    startBeforeEnd: 'La période sélectionnée est invalide',
  },
  duplicateLanguage: 'Cette langue a déjà été saisie',
  gloveSize: 'La taille des gants doit être entre 5 et 9',
  invalid: 'Ce champ est invalide',
  passwordRequirements: {
    case: 'Contient des lettres minuscules et majuscules',
    characters: 'Contient au moins une lettre, un chiffre et un caractère spécial',
    completeRequirements: 'Minimum 8 caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial',
    length: 'Contient au moins 8 caractères',
    passwordsMustMatch: 'Les mots de passes doivent correspondre',
  },
  required: 'Champ obligatoire',
  selectASkill: 'Sélectionnez au moins une compétence',
  selectOneSurgery: 'Sélectionnez seulement un type de chirurgie',
  timesheetEntries: {
    breaks: {
      duration: 'Vous devez entrer une durée minimale de 0.5h',
      reason: 'Sélectionnez la raison de la pause',
    },
    time: 'Les valeurs de début et de fin doivent être différentes',
    distanceTraveledEmpty: 'Vous devez entrer une distance pour la journée',
    distanceTraveled: 'Vous devez entrer une distance plus grande que 0',
  },
  uniqueness: 'Champ unique',
  userCannotBeChanged: 'Le statut du remplaçant ne peut pas être changé',
  userSelected: 'Utilisateur déjà assigné à la clinique',
  wantedCount: 'Sélectionnez le nombre de jours de remplacement désiré pour cette période',
  wantedCountLength: 'Vous avez dépassé le nombre de jours maximum pour la période',
  zipcode: 'Format non valide. Ex. A1A 1A1',
}
