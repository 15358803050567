export const statuses = ['empty', 'pending', 'expired', 'confirmed', 'rejected'];

export function statusColor({ type, latestProposal }) {
  return latestProposal ? latestProposal.status : type;
}

export function isFilteredEvent(event, filters) {
  const status = statusColor(event);
  let filter = status;

  switch (status) {
    case 'pending_admin':
      filter = 'confirmed';
      break;
    case 'pending_clinic':
      filter = 'pending';
      break;
    case 'replacement':
    case 'availability':
      filter = 'empty';
      break;
    default:
      break;
  }
  return filters[filter] ? filters[filter] : false;
}

export default statusColor;
