import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';

const uploader = (presignedUploadPath, updateFile, metaType, allowedFileTypes) =>
  Uppy({
    meta: { type: metaType },
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes,
    },
    autoProceed: true,
  })
    .use(AwsS3, {
      getUploadParameters: (file) => {
        // remove special chars and accents from filename
        const filename = file.name.replace(/[^a-zA-Z0-9_.-]/g, '_');
        return presignedUploadPath({ filename })
          .then((data) => data.presignUpload.presignedUpload);
      },
    })
    .on('upload-success', (file) => {
      const uploadedFileData = {
        id: file.meta.key.match(/^cache\/(.+)/)[1],
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mimeType: file.type,
        },
      };
      updateFile({ uploadedFileData, fileData: uploadedFileData });
    });


export default uploader;
