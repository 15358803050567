import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// components
import RadioGroupWithData from '../../../forms/fields/radio/RadioGroupWithData';

// actions
import languagesActions from '../../../../actions/languages';

// styles
import './styles.scss';

class DisplayLanguageSubForm extends Component {
  static propTypes = {
    fetchLanguages: PropTypes.func.isRequired,
    languages: PropTypes.array.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  componentDidMount() {
    const { languages, fetchLanguages } = this.props;

    if (languages.length === 0) {
      fetchLanguages();
    }
  }

  render() {
    const { languages } = this.props;

    if (languages.length === 0) {
      return null;
    }

    return (
      <RadioGroupWithData
        small
        label="displayLanguage"
        name="profile.displayLanguageId"
        radioList={languages.map(language => ({ value: language.id, label: language.key }))}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    languages: state.languages.languages,
  };
}

export default connect(mapStateToProps, languagesActions)(DisplayLanguageSubForm);

