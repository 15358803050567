// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

// component
import Input from '../../../forms/fields/input/Input';

class UserForm extends Component {
  static propTypes = {
    accountType: PropTypes.string,
    fetchUsers: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    accountType: '',
  };

  constructor() {
    super();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit({ search }) {
    const { fetchUsers, accountType } = this.props;

    fetchUsers({ search, accountType });
  }

  render() {
    const { handleSubmit } = this.props;
    const submitForm = handleSubmit(this.handleFormSubmit);
    const { t } = this.context;

    return (
      <form onSubmit={submitForm}>
        <Field
          name="search"
          type="search"
          component={Input}
          className="field--light"
          innerLabel
        />
        <button className="form__submit" type="submit">
          {t('form.search')}
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'searchUsers',
})(UserForm);
