export default {
  add: 'Add availabilities',
  availabilities: 'Availabilities',
  distancesTag: {
    50: '< 50 km',
    150: '< 150 km',
    151: '> 150 km',
  },
  distancesText: {
    50: 'Less than 50 km',
    150: 'Less than 150 km',
    151: 'More than 150 km',
  },
  lessThan: 'Less than ',
  moreThan: 'More than ',
  noAvailabilities: 'You have not entered any availabilities yet for this month',
};
