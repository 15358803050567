export const momentTranslations = {
  en: {
    longDateFormat: {
      LT: 'HH:mm A',
      LTS: 'HH:mm:ss A',
      LLL: 'D MMMM YYYY HH:mm A',
      LLLL: 'dddd, D MMMM YYYY HH:mm A',
    },
  },
  fr: {
    weekdaysShort: 'dim_lun_mar_mer_jeu_ven_sam'.split('_'),
    monthsShort: 'jan_fév_mar_avr_mai_jun_jul_aoû_sep_oct_nov_déc'.split('_'),
  },
};

export default momentTranslations;
