import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatClinicDistance } from '../../../../../../../helpers/distance';

function Distance({ userDistance, userPending }) {
  return (
    <div className="mandate-item__distance">
      <span
        className={classNames(
          'mandate-item__distance__tag', {
          'mandate-item__distance__tag--hidden': userPending,
        })}
      >
        {formatClinicDistance(userDistance)}
      </span>
    </div>
  );
}

Distance.propTypes = {
  userDistance: PropTypes.number.isRequired,
  userPending: PropTypes.bool.isRequired,
};
Distance.defaultProps = {};
Distance.contextTypes = {};

export default Distance;
