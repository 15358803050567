import { change } from 'redux-form';
import { asyncQuery, asyncMutation } from '../../../config/graphql/async-action-creator';
import queries from './queries';
import types from './types';
import store from '../../../config/redux/store';
import client from '../../../config/graphql/client';

export default {
  fetchMandates: (variables = {}) => asyncQuery(
    store,
    types.FETCH_MANDATES,
    queries.fetchMandates,
    { first: 100, ...variables },
    client,
  ),
  fetchMandatesInPeriod: (variables = {}) => {
    const state = store.getState();
    if (state.user.role === 'admin') {
      variables = { ...variables, mandateType: state.matchmaking.selectedAccountType };
    }

    return asyncQuery(
      store,
      types.FETCH_MANDATES_IN_PERIOD,
      queries.fetchMandates,
      { ...variables, first: 100 },
      client,
    );
  },
  fetchAllMandates: (variables = {}) => {
    const state = store.getState();
    if (state.user.role === 'admin') {
      variables = { ...variables, mandateType: state.matchmaking.selectedAccountType };
    }

    return asyncQuery(
      store,
      types.FETCH_ALL_MANDATES,
      queries.fetchMandates,
      { ...variables, first: 100 },
      client,
    );
  },
  setClinic: (clinicId) => ({
    type: types.SET_CLINIC,
    clinicId,
  }),
  periodDidUpdate: () => ({
    type: types.PERIOD_IS_UPDATED,
  }),
  createMandate: (variables) => asyncMutation(
    store,
    types.CREATE_MANDATE,
    queries.createMandate,
    variables,
    client,
  ),
  setReplacementToUpdate: (replacementId) => ({
    type: types.SET_REPLACEMENT_TO_UPDATE,
    replacementId,
  }),
  getReplacementToUpdate: (variables) => asyncQuery(
    store,
    types.FETCH_REPLACEMENT_TO_UPDATE,
    queries.fetchReplacement,
    variables,
    client,
  ),
  updateReplacement: (variables) => asyncMutation(
    store,
    types.UPDATE_REPLACEMENT,
    queries.updateReplacement,
    variables,
    client,
  ),
  disableMandate: (variables) => asyncMutation(
    store,
    types.DISABLE_MANDATE,
    queries.disableMandate,
    variables,
    client,
  ),
  disableReplacement: (variables) => asyncMutation(
    store,
    types.DISABLE_REPLACEMENT,
    queries.disableReplacement,
    variables,
    client,
  ),
  disableReplacementProposal: (variables) => asyncMutation(
    store,
    types.DISABLE_REPLACEMENT_PROPOSAL,
    queries.disableProposal,
    variables,
    client,
  ),
  enableReplacement: (variables) => asyncMutation(
    store,
    types.ENABLE_REPLACEMENT,
    queries.enableReplacement,
    variables,
    client,
  ),
  updateMandatesList: (variables = {}) => asyncQuery(
    store,
    variables.after ? types.UPDATE_NEXT_MANDATES_LIST : types.UPDATE_MANDATES_LIST,
    queries.fetchMandates,
    { first: 10, ...variables },
    client,
  ),
  updateMandateInList: (variables = {}) => asyncQuery(
    store,
    types.UPDATE_MANDATE_IN_LIST,
    queries.fetchMandate,
    { ...variables },
    client,
  ),
  fetchMandate: (variables = {}) => asyncQuery(
    store,
    types.FETCH_MANDATE,
    queries.fetchMandate,
    { ...variables },
    client,
  ),
  changeFieldsValues: (name, value) => (
    change('addMandate', name, value)
  ),
  setMandateToUpdate: (mandateId) => ({
    type: types.SET_MANDATE_TO_UPDATE,
    mandateId,
  }),
  getMandateToUpdate: (variables) => asyncQuery(
    store,
    types.FETCH_MANDATE_TO_UPDATE,
    queries.fetchMandate,
    variables,
    client,
  ),
  updateMandate: (variables) => asyncMutation(
    store,
    types.UPDATE_MANDATE,
    queries.updateMandate,
    variables,
    client,
  ),
};
