export default {
  confirm: 'Are you sure that you want to confirm this time sheet?',
  confirmed: 'Timesheet confirmed on',
  confirmEntry: 'Confirm',
  dinner: 'Dinner',
  entryIsConfirmed: 'Entry confirmed',
  export: {
    download: 'Download',
    generate: 'Generate report',
    ready: 'Report is ready for download',
    technician: 'Technicians',
    veterinarian: 'Veterinarians',
  },
  filters: {
    title: 'Filter by',
    none: 'No filter',
    complete: 'Complete',
    incomplete: 'Incomplete',
    confirmed: 'Confirmed',
  },
  instructions: 'You must fill out the information for each day in order to submit your time sheet.',
  late: 'Your time sheet is late',
  lunch: 'Lunch',
  noTimesheet: 'No time sheet available',
  phone_reminders: 'Returned client calls',
  title: 'Time sheets',
  schedule: 'Schedule',
  submitted: 'Time sheet submitted on',
};
