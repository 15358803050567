export default {
  addCallToAll: {
    send: 'Are you sure that you want to send this call to all?',
  },
  editCallToAll: {
    send: 'Are you sure that you want to edit this call to all?',
  },
  availability: {
    delete: 'Are you sure you want to delete this availability?',
    edit: 'Are you sure you want to edit this availability?',
  },
  cancel: 'cancel',
  clinic: {
    disable: 'Are you sure you want to disable this clinic?',
    enable: 'Are you sure you want to enable this clinic?',
  },
  confirm: 'confirm',
  mandate: {
    delete: 'Are you sure you want to delete this request?',
  },
  matchmaking: {
    discardSelection: 'Are you sure you want to cancel the current selection? All unsent offers will be lost.',
    submitSelection: 'Are you sure you want to submit the current offers?',
    unselectSubstitute: 'Are you sure you want to unselect the current locum? All unsent offers will be lost.',
  },
  proposal: {
    delete: 'Are you sure you want to delete this proposal?',
    startingBefore: 'The locum prefers to begin later in the day. Are you sure you want to add this date?',
    endingAfter: 'The locum prefers to finish earlier in the day. Are you sure you want to add this date?',
    startingBeforeAndEndingAfter : 'The locum prefers to begin later and finish earlier in the day. Are you sure you want to add this date?',
  },
  replacement: {
    delete: 'Are you sure you want to delete this placement?',
    enable: 'Are you sure you want to edit this placement?',
  },
  timesheets: {
    confirm: 'Are you sure you want to confirm this time sheet?',
    confirmAllEntries: 'Are you sure you want to confirm all time entries?'
  },
  user: {
    disable: 'Are you sure you want to disable this user?',
    enable: 'Are you sure you want to enable this user?',
  },
};
