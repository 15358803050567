// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray } from 'redux-form';
import moment from 'moment';
import PropTypes from 'prop-types';
import { timeSelector } from '../../helpers/form';
import { scrollToError } from '../../helpers/scrollToError';

// components
import Select from '../forms/fields/select/Select';
import Input from '../forms/fields/input/Input';
import Breaks from './form/Breaks';
import Textarea from '../forms/fields/textarea/Textarea';

// actions
import actionsTimesheet from '../../actions/timesheet';
import alertsActions from '../../actions/alerts';

// validation
import validateEntry from './validate/validateEntry';
import BasicLoader from '../loader/BasicLoader';

class EditEntry extends Component {
  constructor() {
    super();
    this.submitEntry = this.submitEntry.bind(this);
    this.formatTime = this.formatTime.bind(this);
  }

  componentDidMount() {
    const { entryToUpdateId, fetchTimesheetEntry } = this.props;
    fetchTimesheetEntry({ id: entryToUpdateId });
  }

  componentDidUpdate(prevProps) {
    const { entryToUpdateId, fetchTimesheetEntry } = this.props;
    const { entryToUpdateId: prevEntryToUpdateId } = prevProps;
    if (entryToUpdateId !== prevEntryToUpdateId) {
      fetchTimesheetEntry({ id: entryToUpdateId });
    }
  }

  formatTime(startsAtValue, endsAtValue) {
    const {
      entry: {
        startsAt,
      },
    } = this.props;
    const startArr = startsAtValue.split(':');
    const endArr = endsAtValue.split(':');
    const startTime = moment(startsAt).set({
      hours: startArr[0],
      minutes: startArr[1],
    });
    const endTime = moment(startsAt).set({
      hours: endArr[0],
      minutes: endArr[1],
    });
    if (startTime.diff(endTime, 'hours') > 0) {
      endTime.add(1, 'day');
    }
    return {
      start: startTime.toISOString(),
      end: endTime.toISOString(),
    };
  }

  submitEntry({ adminNote, ...values }) {
    const {
      entry: {
        id,
      },
      updateTimesheetEntries,
      hidePanel,
      pushAlert,
    } = this.props;
    const {
      breaks,
      distanceTraveled,
      message,
      endsAt: endsAtValue,
      startsAt: startsAtValue,
    } = values;
    const timeObj = this.formatTime(startsAtValue, endsAtValue);
    const breaksArr = breaks.map((singleBreak) => ({
      duration: singleBreak.active === 'true' ? parseFloat(singleBreak.duration) : 0,
      reason: singleBreak.reason,
      type: singleBreak.type,
      active: singleBreak.active === 'true',
    }));
    const input = {
      input: {
        entries: [{
          id,
          endsAt: timeObj.end,
          startsAt: timeObj.start,
          distanceTraveled: parseInt(distanceTraveled, 10),
          breaks: breaksArr,
          message,
          ...(adminNote !== null) && { adminNote },
        }],
      },
    };
    if (message && message !== '') {
      input.message = message;
    }
    updateTimesheetEntries(input)
      .then((data) => {
        if (data && data.updateTimesheetEntries.success) {
          pushAlert({
            type: 'success',
            content: 'alerts.edited',
          });
          hidePanel();
        }
      });
  }

  render() {
    const { t } = this.context;
    const {
      handleSubmit,
      entry: { startsAt, breaks },
      error,
      formIsSubmitting,
      hidePanel,
      isAdmin,
      pristine,
      submitting,
    } = this.props;

    const submitForm = handleSubmit(this.submitEntry);

    return (
      <form onSubmit={submitForm} className="side-panel__form">
        <div>
          {formIsSubmitting && <BasicLoader />}
          {error && <div className="form-error">{error}</div>}
          <fieldset className="side-panel__form__section">
            <div>
              <h2 className="side-panel__form__section__title">{t('form.editTimesheetEntry.schedule')}*</h2>
              <div className="schedule-fields">
                <div className="schedule-fields__date">
                  {moment(startsAt).format('ddd')}
                  <span className="date">{moment(startsAt).format('DD')}</span>
                  <span className="month">{moment(startsAt).format('MMM')}</span>
                </div>
                <Field
                  name="startsAt"
                  label="editTimesheetEntry.startsAt"
                  component={Select}
                >
                  { timeSelector() }
                </Field>
                <span className="schedule-fields__arrow" />
                <Field
                  name="endsAt"
                  label="editTimesheetEntry.endsAt"
                  component={Select}
                >
                  { timeSelector() }
                </Field>
              </div>
            </div>
          </fieldset>
          <FieldArray
            name="breaks"
            breaks={breaks}
            component={Breaks}
          />
          <fieldset className="side-panel__form__section">
            <div>
              <h2 className="side-panel__form__section__title">{t('form.editTimesheetEntry.distanceTraveled')}</h2>
              <Field
                name="distanceTraveled"
                component={Input}
                type="number"
                label="editTimesheetEntry.distance"
                className="field--light field--aside"
              />
            </div>
          </fieldset>
          <fieldset className="side-panel__form__section">
            <div>
              <h2 className="side-panel__form__section__title">{t('form.editTimesheetEntry.moreInfo')}</h2>
              <Field
                name="message"
                component={Textarea}
                label="message"
              />
            </div>
          </fieldset>
          {isAdmin &&
            <fieldset className="side-panel__form__section side-panel__form__section--admin">
              <div>
                <h2 className="side-panel__form__section__title">{t('form.adminSection')}</h2>
                <div className="fields-one-col">
                  <Field
                    name="adminNote"
                    label="adminNoteGeneral"
                    component={Textarea}
                    className="field--light"
                  />
                </div>
              </div>
            </fieldset>
              }
        </div>
        <div className="form__actions">
          <button
            className="form__cancel"
            type="button"
            disabled={submitting || formIsSubmitting}
            onClick={() => hidePanel()}
          >
            {t('form.formActions.cancel')}
          </button>
          <button
            className="form__submit"
            type="submit"
            disabled={pristine || submitting || formIsSubmitting}
          >
            {t('form.formActions.update')}
          </button>
        </div>
      </form>
    );
  }
}

EditEntry.propTypes = {
  entryToUpdateId: PropTypes.string,
  entry: PropTypes.object,
  error: PropTypes.string,
  formIsSubmitting: PropTypes.bool.isRequired,
  fetchTimesheetEntry: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hidePanel: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  pushAlert: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  updateTimesheetEntries: PropTypes.func.isRequired,
};
EditEntry.defaultProps = {
  entryToUpdateId: null,
  entry: {},
  error: '',
};
EditEntry.contextTypes = {
  t: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    startsAt,
    endsAt,
    distanceTraveled,
    message,
    breaks,
    adminNote,
  } = state.timesheets.timesheetEntry;
  const initialStartsAt = moment(startsAt).format('HH:mm');
  const initialEndsAt = moment(endsAt).format('HH:mm');
  return {
    entryToUpdateId: state.timesheets.entryToUpdateId,
    entry: state.timesheets.timesheetEntry,
    form: 'editEntry',
    formIsSubmitting: state.timesheets.submitting,
    initialValues: {
      adminNote,
      startsAt: initialStartsAt,
      endsAt: initialEndsAt,
      breaks: breaks && breaks.map(({ duration, reason, type, active }) => ({
        active: active === true ? 'true' : 'false',
        duration: duration || 0,
        reason: reason || 'notAvailable',
        type,
      })),
      message: message || '',
      distanceTraveled: distanceTraveled || 0,
    },
    isAdmin: state.user.role === 'admin',
  };
}

export default connect(
  mapStateToProps,
  { ...actionsTimesheet, ...alertsActions },
)(reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  form: 'editEntry',
  onSubmitFail: (errors) => scrollToError(errors),
  validate: validateEntry,
})(EditEntry));
