// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from '../../forms/fields/input/Input';
import validate from '../validate/validate';

import actions from '../../../actions/user';

// Styles
import './styles.scss';

// Components
import logo from '../../../../assets/images/vetreseau-white.svg';
import TextLogo from '../../layouts/nav/TextLogo';

class AccountActivation extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    forgotPassword: PropTypes.func.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor() {
    super();
    this.state = {
      resetInstructionsSent: false,
      accountEmail: '',
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(values) {
    const { forgotPassword } = this.props;
    forgotPassword(values).then((data) => {
      if (data && data.forgotPassword.success) {
        this.setState({
          resetInstructionsSent: true,
          accountEmail: values.email,
        });
      }
    });
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    const { resetInstructionsSent, accountEmail } = this.state;
    const { t } = this.context;

    const submitForm = handleSubmit(this.handleFormSubmit);

    return (
      <section className="activation-page">
        <div className="activation__fieldset">
          <div className="login-page__logos">
            <span><img src={logo} alt="Logo Vetreseau" className="login-page__logos__logo" /></span>
            <TextLogo logoClass="login-page__logos__title" />
          </div>
          <h1>
            {t('pages.accountActivation.title')}
          </h1>
          { resetInstructionsSent ? (
            <>
              <p className="activation__success">{t('pages.accountActivation.instructionsSent')} <strong>{accountEmail}</strong><br />
                <p>
                  {t('pages.accountActivation.spam')}
                </p>
              </p>
            </>
          )
            :
            <form onSubmit={submitForm} className="form form--login">
              <Field
                name="email"
                component={Input}
                type="email"
                label="email"
                innerLabel
              />
              <div className="form__actions">
                <button className="form__submit" type="submit" disabled={pristine || submitting}>
                  {t('pages.accountActivation.submit')}
                </button>
              </div>
            </form>
          }
        </div>
      </section>
    );
  }
}

AccountActivation.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function mapStateToProps() {
  return {
    initialValues: {},
  };
}

export default withRouter(connect(mapStateToProps, actions)(reduxForm({
  form: 'forgotPassword',
  enableReinitialize: true,
  validate,
})(AccountActivation)));
