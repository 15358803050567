import { asyncMutation, asyncQuery } from '../../../config/graphql/async-action-creator';
import queries from './queries';
import types from './types';
import store from '../../../config/redux/store';
import client from '../../../config/graphql/client';

export default {
  fetchClinicAddress: (clinicId) => asyncQuery(
    store,
    types.FETCH_CLINIC_ADDRESS,
    queries.fetchClinicAddress,
    clinicId,
    client,
  ),
  fetchSubstituteAddress: (userId) => asyncQuery(
    store,
    types.FETCH_SUBSTITUTE_ADDRESS,
    queries.fetchSubstituteAddress,
    userId,
    client,
  ),
  fetchSubstituteProposals: (availabilityId) => asyncQuery(
    store,
    types.FETCH_SUBSTITUTE_PROPOSALS,
    queries.fetchSubstituteProposals,
    availabilityId,
    client,
  ),
  fetchSubstituteAndClinics: (variables = {}) => asyncQuery(
    store,
    variables.after ? types.FETCH_NEXT_SUBSTITUTE_AND_CLINICS : types.FETCH_SUBSTITUTE_AND_CLINICS,
    queries.fetchSubstituteAndClinics,
    { first: 30, ...variables },
    client,
  ),
  fetchSubstitutes: (variables = {}) => asyncQuery(
    store,
    variables.after ? types.FETCH_NEXT_SUBSTITUTES : types.FETCH_SUBSTITUTES,
    queries.fetchSubstitutes,
    { first: 30, roleName: 'substitute', ...variables },
    client,
  ),
  fetchClinics: (variables = {}) => asyncQuery(
    store,
    variables.after ? types.FETCH_NEXT_CLINICS : types.FETCH_CLINICS,
    queries.fetchClinics,
    { ...variables, first: 30 },
    client,
  ),
  fetchClinicProposals: (replacementId) => asyncQuery(
    store,
    types.FETCH_CLINIC_PROPOSALS,
    queries.fetchClinicProposals,
    replacementId,
    client,
  ),
  selectReplacementForProposals: (event) => ({
    type: types.SELECT_REPLACEMENT,
    event,
  }),
  setEventIdForTooltip: (eventId) => ({
    type: types.SET_EVENT_ID_FOR_TOOLTIP,
    eventId,
  }),
  resetClinicAddress: () => ({
    type: types.RESET_CLINIC_ADDRESS,
  }),
  resetSubstituteAddress: () => ({
    type: types.RESET_SUBSTITUTE_ADDRESS,
  }),
  resetEventProposals: () => ({
    type: types.RESET_EVENT_PROPOSALS,
  }),
  unselectReplacementForProposals: (event) => ({
    type: types.UNSELECT_REPLACEMENT,
    event,
  }),
  discardProposals: () => ({
    type: types.DISCARD_PROPOSALS,
  }),
  toggleSubstituteMiniprofile: (userId) => ({
    type: types.TOGGLE_SUBSTITUTE_MINIPROFILE,
    userId,
  }),
  toggleClinicMiniprofile: (clinicId) => ({
    type: types.TOGGLE_CLINIC_MINIPROFILE,
    clinicId,
  }),
  showSubstituteMessage: (replacementId) => ({
    type: types.SHOW_SUBSTITUTE_MESSAGE,
    replacementId,
  }),
  hideSubstituteMessage: (replacementId) => ({
    type: types.HIDE_SUBSTITUTE_MESSAGE,
    replacementId,
  }),
  selectSubstitute: (userId) => ({
    type: types.SELECT_SUBSTITUTE,
    userId,
  }),
  unselectSubstitute: () => ({
    type: types.UNSELECT_SUBSTITUTE,
  }),
  selectClinic: (clinic) => ({
    type: types.SELECT_CLINIC,
    clinic,
  }),
  unselectClinic: (clinic) => ({
    type: types.UNSELECT_CLINIC,
    clinic,
  }),
  selectAccountType: (accountType) => ({
    type: types.SELECT_ACCOUNT_TYPE,
    accountType,
  }),
  createProposals: (variables) => asyncMutation(
    store,
    types.CREATE_PROPOSALS,
    queries.createProposals,
    variables,
    client,
  ),
  enableMatchmakingReplacement: (variables) => asyncMutation(
    store,
    types.ENABLE_MATCHMAKING_REPLACEMENT,
    queries.enableMatchmakingReplacement,
    variables,
    client,
  ),
  lockPrices: (clinicId) => ({
    type: types.LOCK_PRICES,
    clinicId,
  }),
  unlockPrices: (clinicId) => ({
    type: types.UNLOCK_PRICES,
    clinicId,
  }),
};
