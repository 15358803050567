import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import '../Fields.scss';
import './Select.scss';

function Select(field, { t }) {
  const originalError = field.meta.error;
  const errorTranslation = t(originalError);
  const error = errorTranslation === originalError ? originalError : errorTranslation;
  const label = field.label ? field.label : field.input.name;

  const showError = field.meta.touched && field.meta.error && !field.meta.disabled;
  return (
    <div className={classNames('field', field.className)}>
      <label className="field__label">
        {t(`form.${label}`)}{field.required && ' *'}
      </label>
      <div className="field__select__wrapper">
        <select {...field.input} disabled={field.disabled} className={classNames('field__select', { 'field--invalid': showError })}>
          {field.children}
        </select>
      </div>
      {showError && <span className="field__error">{t(error)}</span>}
    </div>
  );
}

Select.contextTypes = {
  t: PropTypes.func,
};

export default Select;
