// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import validate from '../../forms/validate/validate';
import { scrollToError } from '../../../helpers/scrollToError';

// components
import EditAccount from './EditAccount';
import Input from '../../forms/fields/input/Input';
import PasswordRequirement from './PasswordRequirement';
import BasicLoader from '../../loader/BasicLoader';

// actions
import actions from '../../../actions/user/';
import actionsSidePanel from '../../../actions/dynamicSidePanel/index';
import alertsActions from '../../../actions/alerts/index';

// helper
import { validPasswordCombinations } from '../../../helpers/password';

// Styles
import './styles.scss';

class EditPassword extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    updateAccount: PropTypes.func.isRequired,
    pushAlert: PropTypes.func.isRequired,
    password: PropTypes.string.isRequired,
    passwordConfirmation: PropTypes.string.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  constructor() {
    super();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(values) {
    const { updateAccount, pushAlert, updateComponent } = this.props;

    updateAccount(values)
      .then((data) => {
        if (data && data.updateAccount.success) {
          pushAlert({
            type: 'success',
            content: 'alerts.edited',
          });
          updateComponent(EditAccount, 'account', { sidebarIcon: 'profile' });
        }
      });
  }

  render() {
    const {
      formIsSubmitting,
      handleSubmit,
      pristine,
      updateComponent,
      submitting,
      password,
      passwordConfirmation,
    } = this.props;
    const { t } = this.context;

    const submitForm = handleSubmit(this.handleFormSubmit);
    const canSubmit = validPasswordCombinations([password, passwordConfirmation]);

    return (
      <div>
        <form onSubmit={submitForm} className="side-panel__form edit-password">
          <div>
            {formIsSubmitting && <BasicLoader />}
            <fieldset className="side-panel__form__section">
              <div>
                <div className="fields-one-col">
                  <Field
                    name="currentPassword"
                    component={Input}
                    type="password"
                    label="oldPassword"
                    className="field--light"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="side-panel__form__section">
              <div>
                <div className="fields-one-col">
                  <Field
                    name="password"
                    component={Input}
                    type="password"
                    label="newPassword"
                    className="field--light"
                  />
                </div>
                <div className="fields-one-col">
                  <Field
                    name="passwordConfirmation"
                    component={Input}
                    type="password"
                    label="passwordConfirmation"
                    className="field--light"
                  />
                </div>
                <div className="fields-one-col">
                  <PasswordRequirement password={password} />
                </div>
              </div>
            </fieldset>
          </div>
          <div className="form__actions">
            <button
              className="form__cancel"
              type="button"
              disabled={submitting || formIsSubmitting}
              onClick={() => updateComponent(EditAccount, 'account', { sidebarIcon: 'profile' })}
            >
              {t('form.formActions.cancel')}
            </button>
            <button
              className="form__submit"
              type="submit"
              disabled={pristine || submitting || formIsSubmitting || !canSubmit}
            >
              {t('form.formActions.update')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

EditPassword.propTypes = {
  formIsSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  updateComponent: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const selector = formValueSelector('updateAccount');
  return {
    formIsSubmitting: state.user.submitting,
    password: selector(state, 'password') || '',
    passwordConfirmation: selector(state, 'passwordConfirmation') || '',
  };
}

export default connect(
  mapStateToProps,
  { ...actions, ...alertsActions, ...actionsSidePanel },
)(reduxForm({
  form: 'updateAccount',
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToError(errors),
  validate,
})(EditPassword));
