import { asyncQuery, asyncMutation } from '../../../../config/graphql/async-action-creator';
import queries from './queries';
import types from './types';
import store from '../../../../config/redux/store';
import client from '../../../../config/graphql/client';

export default {
  fetchUsers: (variables = {}) => asyncQuery(
    store,
    variables.after ? types.FETCH_NEXT_USERS : types.FETCH_USERS,
    queries.fetchUsers,
    { first: 10, ...variables },
    client,
  ),
  fetchSuggestedUsers: (variables) => asyncQuery(
    store,
    types.FETCH_SUGGESTED_USERS,
    queries.fetchUsers,
    { first: 5, roleName: 'clinic_manager', ...variables },
    client,
  ),
  fetchUser: (variables) => asyncQuery(
    store,
    types.FETCH_USER,
    queries.fetchUser,
    variables,
    client,
  ),
  fetchAccountUser: (variables) => asyncQuery(
    store,
    types.FETCH_USER,
    queries.fetchAccountUser,
    variables,
    client,
  ),
  createUser: (variables) => asyncMutation(
    store,
    types.CREATE_USER,
    queries.createUser,
    variables,
    client,
  ),
  updateUser: (variables) => asyncMutation(
    store,
    types.UPDATE_USER,
    queries.updateUser,
    variables,
    client,
  ),
  enableUser: (variables) => asyncMutation(
    store,
    types.ENABLE_USER,
    queries.enableUser,
    variables,
    client,
  ),
  disableUser: (variables) => asyncMutation(
    store,
    types.DISABLE_USER,
    queries.disableUser,
    variables,
    client,
  ),
};
