import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classNames from 'classnames';
import moment from 'moment';
import ConfirmCheckbox from '../../../../../../forms/fields/checkbox/ConfirmCheckbox';
import RadioCheckboxAccept from '../../../../../../forms/fields/radio/RadioCheckboxAccept';
import orangeClock from '../../../../../../../../assets/images/animated-clock-orange.svg';

function Fields({
  acceptedProposals,
  change,
  proposalDate,
  field,
  role,
  pending,
  status,
  clinicStatus,
}, { t }) {
  const adminRole = role === 'admin';
  const clinicRole = role === 'clinic_manager';
  const clinicCanConfirm = clinicRole && !pending;
  const showClinic = clinicRole || adminRole;
  const showSubstitute = role === 'substitute' || adminRole;
  const pastProposal = moment().diff(proposalDate, 'days') > 0;
  const hideClinicValidation = adminRole && clinicStatus === 'confirmed';

  // Don't allow any actions if date is past
  if (pastProposal) {
    return <div
      className={
        classNames(
          'list-item__actions',
        { 'list-item__actions--full-width': adminRole && !acceptedProposals },
      )}
    />;
  }

  if (acceptedProposals) {
    return (
      <div className="list-item__actions">
        {adminRole && (
          status === 'pending_admin' ? (
            <Field
              name={`${field}.adminConfirm`}
              type="input"
              component={ConfirmCheckbox}
              change={change}
            />
          ) : (
            <span className="list-item__action--confirmed" />
          )
        )}
      </div>
    );
  }

  return (
    <div className={
      classNames(
        'list-item__actions',
        { 'list-item__actions--both': showClinic && showSubstitute },
        { 'list-item__actions--full-width': showClinic && hideClinicValidation },
      )}
    >
      {showSubstitute && (
        <div className="tooltip__wrapper">
          { adminRole && <span className="tooltip">{t('form.formActions.confirmUserTooltip')}</span> }
          <Field
            name={`${field}.userAccepted`}
            type="input"
            component={RadioCheckboxAccept}
            change={change}
          />
        </div>
      )}
      {showClinic && (
        (adminRole && clinicStatus === 'pending') || clinicCanConfirm ? (
          <div className="tooltip__wrapper">
            { adminRole && <span className="tooltip">{t('form.formActions.confirmClinicTooltip')}</span> }
            <Field
              name={`${field}.clinicAccepted`}
              type="input"
              component={RadioCheckboxAccept}
              change={change}
            />
          </div>
        ) : (
          !hideClinicValidation && (
            <span className="mandate-item__actions__wait">
              <img src={orangeClock} alt={t('matchmaking.statuses.pending')} />
            </span>
          )
        )
      )}
    </div>
  );
}

Fields.propTypes = {
  acceptedProposals: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  clinicStatus: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  proposalDate: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
Fields.defaultProps = {};
Fields.contextTypes = {
  t: PropTypes.func,
};

export default Fields;
