import React from 'react';
import PropTypes from 'prop-types';

// components
import TimesheetCard from './card/TimesheetCard';

function UserTimesheets(
  {
    statusFilter,
    timesheet,
    timesheet: {
      user: {
        profile: {
          firstName,
          lastName,
          picture,
        },
      },
    },
  },
) {
  return (
    <div className="user-timesheets">
      <div className="user-timesheets__user">
        {picture && picture.small ?
          <div
            className="user-timesheets__user__image"
            style={{ backgroundImage: `url(${picture.small})` }}
          />
          :
          <div className="user-timesheets__user__image user-timesheets__user__image--empty" />
        }
        <div className="user-timesheets__user__name">
          <span>{`${firstName} ${lastName}`}</span>
        </div>
      </div>
      <div>
        <TimesheetCard key={timesheet.id} timesheet={timesheet} statusFilter={statusFilter} />
      </div>
    </div>
  );
}

UserTimesheets.propTypes = {
  timesheet: PropTypes.object.isRequired,
  statusFilter: PropTypes.string.isRequired,
};
UserTimesheets.defaultProps = {};
UserTimesheets.contextTypes = {};

export default UserTimesheets;
