// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '../../../forms/fields/input/Input';

// CSS
import './styles.scss';

class TaxNumbersSubForm extends Component {
  componentDidMount() {
    const { fields, taxNumbers } = this.props;
    if (fields.length === 0) {
      taxNumbers.forEach((taxNumber) => {
        fields.push({ ...taxNumber });
      });
    }
  }

  render() {
    const { fields } = this.props;
    return (
      <div>
        {fields.map((taxNumber, index, data) => (
          <div key={taxNumber} className="tax-number">
            <Field
              name={`${taxNumber}.number`}
              type="text"
              label={`taxNumbers.types.${data.get(index).type}`}
              component={Input}
              className="field--light"
            />
          </div>
        ))}
      </div>
    );
  }
}

TaxNumbersSubForm.propTypes = {
  fields: PropTypes.object,
  taxNumbers: PropTypes.array.isRequired,
};

TaxNumbersSubForm.defaultProps = {
  fields: {},
};


TaxNumbersSubForm.contextTypes = {
  t: PropTypes.func,
};

export default TaxNumbersSubForm;
