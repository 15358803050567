import React from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './styles.scss';

const ReactDateSingle = (props) => (
  <div className="field field__react-date">
    <SingleDatePicker
      {...props}
      hideKeyboardShortcutsPanel
      displayFormat="YYYY-MM-DD"
      readOnly
    />
  </div>
);

export default ReactDateSingle;
