import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import RadioBoolean from '../../forms/fields/radio/RadioBoolean';
import RadioList from '../../forms/fields/radio/RadioList';
import Select from '../../forms/fields/select/Select';
import { breakDurationSelector } from '../../../helpers/form';

// Component

class Break extends Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
    };
    this.changeFieldsDisabledState = this.changeFieldsDisabledState.bind(this);
    this.setDisabledState = this.setDisabledState.bind(this);
  }

  componentDidMount() {
    this.setDisabledState();
  }

  setDisabledState() {
    const { pause: { active } } = this.props;
    this.setState({
      disabled: active === false,
    });
  }

  changeFieldsDisabledState(e) {
    this.setState({
      disabled: e.target.value !== 'true',
    });
  }

  render() {
    const { field, pause: { type } } = this.props;
    const { disabled } = this.state;
    return (
      <fieldset className="side-panel__form__section">
        <div>
          <div className="break-fields">
            <Field
              name={`${field}.active`}
              label={`editTimesheetEntry.${type}`}
              component={RadioBoolean}
              onChange={this.changeFieldsDisabledState}
            />
            {disabled ?
              <div className="fields-one-col fields-one-col--conditional">
                <div>
                  <RadioList
                    groupLabel="reasons"
                    label="reason"
                    name={`${field}.reason`}
                    radioList={['work', 'files', 'phone', 'notAvailable']}
                    disabled={!disabled}
                    noLabel
                  />
                </div>
              </div>
              :
              <div className="break-fields__subfields">
                <Field
                  name={`${field}.duration`}
                  label="editTimesheetEntry.duration"
                  className="field--aside-select"
                  component={Select}
                  disabled={disabled}
                >
                  { breakDurationSelector() }
                </Field>
              </div>
            }
          </div>
        </div>
      </fieldset>
    );
  }
}

Break.propTypes = {
  field: PropTypes.string.isRequired,
  pause: PropTypes.object,
};

Break.defaultProps = {
  pause: {},
};

Break.contextTypes = {};

export default Break;
