import { asyncQuery } from '../../../config/graphql/async-action-creator';
import graphQLClient from '../../../config/graphql/client';
import queries from './queries';
import types from './types';
import store from '../../../config/redux/store';

export default {
  fetchExoticAnimals: (variables) => asyncQuery(
    store,
    types.FETCH_EXOTIC_ANIMALS,
    queries.fetchExoticAnimals,
    variables,
    graphQLClient,
  ),
};
