// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { change, Field, touch } from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';

// components
import AutoComplete from 'react-autocomplete';

// actions
import actions from '../../../../actions/admin/users';

// styles
import './styles.scss';

class ClinicUserSubForm extends Component {
  static propTypes = {
    fetchSuggestedUsers: PropTypes.func.isRequired,
    fields: PropTypes.object,
    formChange: PropTypes.func.isRequired,
    formTouch: PropTypes.func.isRequired,
    formTitle: PropTypes.string.isRequired,
    managers: PropTypes.array,
    users: PropTypes.array.isRequired,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    fields: {},
    managers: [],
  };

  constructor(props) {
    super(props);
    this.fetchSuggestedUsers = this.fetchSuggestedUsers.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.state = {
      values: props.managers.map(({ email, profile: { firstName, lastName } }) =>
        `${firstName} ${lastName} ${email}`,
      ),
    };
  }

  componentWillUpdate(nextProps) {
    const { managers: nextManagers } = nextProps;
    const { managers } = this.props;

    if (managers.length === 0 && nextManagers.length > 0) {
      this.setManagersValues(nextManagers);
    }
  }

  setManagersValues(managers) {
    this.setState({
      values: managers.map(({ email, profile: { firstName, lastName } }) =>
        `${firstName} ${lastName} ${email}`,
      ),
    });
  }

  fetchSuggestedUsers(value, index) {
    const { fetchSuggestedUsers, fields } = this.props;
    const { values } = this.state;
    values[index] = value;
    this.setState({ values });
    if (fields.name === 'blacklistedUsers') {
      fetchSuggestedUsers({ search: value, roleName: 'substitute' });
    } else {
      fetchSuggestedUsers({ search: value });
    }
  }

  selectUser(value, index) {
    const { formTitle, users, formChange, formTouch, fields } = this.props;
    const selectedUser = users.find(({ email, profile: { firstName, lastName } }) =>
      value === `${firstName} ${lastName} - ${email}`,
    );
    const { values } = this.state;
    values[index] = value;
    this.setState({ values });
    formTouch(formTitle, `${fields.name}[${index}].userId`);
    formChange(formTitle, `${fields.name}[${index}].userId`, selectedUser.id);
    if (fields.name === 'blacklistedUsers') {
      formChange(formTitle, `${fields.name}[${index}].userType`, 'user');
      formChange(formTitle, `${fields.name}[${index}].clinicBlacklistedUser`, true);
    } else {
      formChange(formTitle, `${fields.name}[${index}].userType`, 'manager');
    }
  }

  removeUser(index, fields, id) {
    const { formTitle, formTouch, formChange } = this.props;

    if (id) {
      formTouch(formTitle, `${fields.name}[${index}].userId`);
      if (fields.name === 'blacklistedUsers') {
        formChange(formTitle, `${fields.name}[${index}].clinicBlacklistedUser`, false);
      } else {
        formChange(formTitle, `${fields.name}[${index}].destroy`, true);
      }
    } else {
      fields.remove(index);
    }
  }

  render() {
    const { users, fields } = this.props;
    const { values } = this.state;
    const { t } = this.context;

    return (
      <div className="clinic-subform">
        {fields.map((clinicUser, index, data) =>
          <div key={`${fields.name} ${data.get(index).userId} ${clinicUser}`}>
            {data.get(index).destroy || data.get(index).clinicBlacklistedUser === false ? (
              <div />
            ) : (
              <div>
                <div className="clinic-subform__row">
                  <div className="field field__autocomplete">
                    <AutoComplete
                      items={users}
                      getItemValue={({ email, profile: { firstName, lastName } }) => `${firstName} ${lastName} - ${email}`}
                      renderItem={({ id, email, profile: { firstName, lastName } }, highlighted) =>
                        <div
                          key={id}
                          className={classNames('field__autocomplete-result', { 'field__autocomplete-result--highlighted': highlighted })}
                        >
                          {`${firstName} ${lastName} - ${email}`}
                        </div>
                      }
                      renderInput={(props) => <input {...props} name={`${clinicUser}.userId`} className="field__input" />}
                      value={values[index]}
                      onChange={(event) => this.fetchSuggestedUsers(event.target.value, index)}
                      onSelect={(item) => this.selectUser(item, index)}
                    />
                    <Field
                      name={`${clinicUser}.userId`}
                      component={({ meta }) =>
                        <div>
                          {meta.error && meta.touched && <span className="field__error">{t(meta.error)}</span>}
                        </div>}
                    />
                  </div>
                  <button
                    type="button"
                    className="clinic-subform__delete"
                    onClick={() => this.removeUser(index, fields, data.get(index).id)}
                  />
                </div>
              </div>
            )}
          </div>,
        )}
        <button type="button" className="form__submit" onClick={() => fields.push({})}>
          {t('form.formActions.add')}
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.suggested,
  };
}

export default connect(
  mapStateToProps,
  { ...actions, formChange: change, formTouch: touch },
)(ClinicUserSubForm);

