import { daysBetween } from '../../../helpers/dates';

const validate = values => {
  let errors = {};
  const skillsIsNotChecked = !values.consultation && !values.surgery && !values.surgeryOptional;

  if (typeof values.wantedCount === 'undefined' || values.wantedCount <= 0) {
    const wantedCountError = { wantedCount: 'validation.wantedCount' };
    errors = Object.assign(errors, wantedCountError);
  }

  if (typeof values.replacements !== 'undefined') {
    const periodLength = daysBetween(
      values.replacements[0].day,
      values.replacements[values.replacements.length - 1].day,
    );
    if (values.wantedCount > periodLength) {
      const wantedLengthError = { wantedCount: 'validation.wantedCountLength' };
      errors = Object.assign(errors, wantedLengthError);
    }
  }

  if (skillsIsNotChecked) {
    errors.surgeryOptional = 'validation.selectASkill';
  }

  if (values.surgery && values.surgeryOptional) {
    errors.surgeryOptional = 'validation.selectOneSurgery';
  }

  if (typeof values.replacements !== 'undefined') {
    const replacementsArrayErrors = [];
    values.replacements.forEach((replacement, dayIndex) => {
      const replacementsErrors = {};
      const timeFormatRegex = /^[0-2][0-9]:[0-5][0-9]$/g;
      if (!replacement.startTime || replacement.startTime === '') {
        replacementsErrors.startTime = 'validation.dayAvail.required';
      } else if (!replacement.startTime.match(timeFormatRegex)) {
        replacementsErrors.startTime = 'validation.dayAvail.format';
      }
      if (!replacement.endTime || replacement.endTime === '') {
        replacementsErrors.endTime = 'validation.dayAvail.required';
      } else if (!replacement.endTime.match(timeFormatRegex)) {
        replacementsErrors.endTime = 'validation.dayAvail.format';
      }
      if (Date.parse(`01/01/2019 ${replacement.endTime}:00`) <= Date.parse(`01/01/2019 ${replacement.startTime}:00`)) {
        replacementsErrors.startTime = 'validation.dayAvail.startBeforeEnd';
      }
      replacementsArrayErrors[dayIndex] = replacementsErrors;
    });
    if (replacementsArrayErrors.length) {
      errors.replacements = replacementsArrayErrors;
    }
  }

  if (values.startsAt && values.endsAt) {
    const timeFormatRegex = /^[0-2][0-9]:[0-5][0-9]$/g;
    if (!values.startsAt || values.startsAt === '') {
      errors.startsAt = 'validation.dayAvail.required';
    } else if (!values.startsAt.match(timeFormatRegex)) {
      errors.startsAt = 'validation.dayAvail.format';
    }
    if (!values.endsAt || values.endsAt === '') {
      errors.endsAt = 'validation.dayAvail.required';
    } else if (!values.endsAt.match(timeFormatRegex)) {
      errors.endsAt = 'validation.dayAvail.format';
    }
    if (Date.parse(`01/01/2019 ${values.endsAt}:00`) <= Date.parse(`01/01/2019 ${values.startsAt}:00`)) {
      errors.startsAt = 'validation.dayAvail.startBeforeEnd';
    }
  }

  return errors;
};

export default validate;
