export default {
  acceptedReplacements: 'List of accepted requests',
  add: 'Add a request',
  addTime: {
    long: 'Add 24h to response time',
    short: 'Add 24h'
  },
  allAcceptedMandates: 'View all accepted locum requests',
  allMandates: 'View all requests entered',
  allProposedReplacements: 'View all locum requests',
  awaitingConfirmation: 'Awaiting confirmation',
  callToAllHeader: 'Appeal to all',
  cards: {
    deleteMandate: 'Delete request',
    details: 'Details',
    drawerTitle: 'Message from {name}',
    proposalWaiting: 'Pending...',
    title: {
      'accepted-replacement': 'Locum request accepted',
      list: 'Selected time periods',
      'pending-replacement': 'Locum requests',
    },
    year: ' year',
    years: ' years',
    hourlyRate: '$/h',
  },
  instructions: 'Accept or decline each date, then click Send.',
  listTitle: {
    substitute: {
      confirmed: 'List of accepted requests',
      pending: 'List of requests awaiting approval',
    },
    clinic_manager: {
      all: 'List of all requests',
      confirmed: 'List of accepted locum requests',
      pending: 'Proposed replacements',
    },
    admin: {
      confirmed: 'List of accepted locum requests',
      pending: 'List of locum requests awaiting approval',
    },
  },
  lodging: 'Accommodation',
  mandate: {
    confirmed: 'Accepted request',
    pending: 'Locum requests',
    pending_admin: 'Accepted request awaiting validation',
    pending_clinic: 'Accepted request awaiting validation',
  },
  mandatesList: 'List of requests',
  noMandates: 'No requests to display at the moment',
  noProposals: 'Nothing to display at the moment',
  proposedReplacements: 'List of locum requests awaiting approval',
  remaining: 'remaining',
  title: {
    admin: {
      confirmed: 'Requests awaiting confirmation / Confirmed requests',
      pending: 'Requests awaiting approval',
    },
    confirmed: 'List of accepted requests',
    list: 'List of requests',
    pending: 'List of requests awaiting approval',
  },
  unavailableMandate: 'The request you are looking for does not exist',
  viewCurrent: 'View upcoming mandates',
  viewPast: 'View past mandates',
  withLodging: 'Accommodation included',
  withoutLodging: 'Accommodation not included',
  editMandate: 'Edit mandate',
};
