import types from './types';

export default {
  hideConfirmation: () => ({
    type: types.HIDE_CONFIRMATION,
  }),
  actionToConfirm: (actionToTrigger, message) => ({
    actionToTrigger,
    message,
    type: types.ACTION_TO_CONFIRM,
  }),
};
