export function scrollToSelectedDay(selectedDayElemId, wrapperElemClass) {
  const calendarElem = document.getElementsByClassName(wrapperElemClass)[0];

  if (calendarElem) {
    calendarElem.scrollLeft = 0;

    const selectedDayHeaderElem = document.getElementById(selectedDayElemId);

    if (selectedDayHeaderElem) {
      const selectedDayHeaderRect = selectedDayHeaderElem.getBoundingClientRect();
      calendarElem.scrollLeft = selectedDayHeaderRect.left - 390;
    }
  }
}

export function isClinicSelected(selectedClinics, clinic) {
  return selectedClinics.map(({ id }) => id).includes(clinic.id);
}

export default scrollToSelectedDay;
