import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import classNames from 'classnames';
import { changedReviewInput, formatReviewInput } from '../../../../../../helpers/review';
import validate from './validate/validate';

// components
import ProposalsSubForm from './ProposalsSubForm';
import Replacements from './Replacements';
import CardTitle from './../CardTitle';

// actions
import actions from '../../../../../../actions/mandateProposals';
import alertsActions from '../../../../../../actions/alerts';
import mandatesActions from '../../../../../../actions/mandates';
import actionsSidePanel from '../../../../../../actions/dynamicSidePanel';

class MandateProposalsForm extends Component {
  constructor(props) {
    super(props);
    this.confirmProposals = this.confirmProposals.bind(this);
    this.submitFormHandler = this.submitFormHandler.bind(this);
    this.submitReviews = this.submitReviews.bind(this);
  }

  confirmProposals(values) {
    const { proposals } = values;
    const { updateProposals, pushAlert } = this.props;
    const input = {
      input: {
        proposals: proposals
          .filter(proposal => proposal.adminConfirm === true)
          .map((proposal) => ({ id: proposal.id, confirmProposal: true })),
      },
    };
    updateProposals(input)
      .then((data) => {
        if (data && data.updateProposals.errors.length === 0) {
          pushAlert({
            type: 'success',
            content: 'alerts.confirmed',
          });
        }
      });
  }

  submitReviews(values) {
    const {
      adminReviewCompleteProposals,
      adminReviewClinicsProposals,
      adminReviewUsersProposals,
      clinicReviewProposals,
      pushAlert,
      role,
      userReviewProposals,
    } = this.props;

    const userProposalsToReview = changedReviewInput(values.proposals, 'userAccepted');
    const clinicProposalsToReview = changedReviewInput(values.proposals, 'clinicAccepted');
    const reviewParams = {
      admin: {
        ...(userProposalsToReview && userProposalsToReview.length &&
          { usersInput: formatReviewInput(userProposalsToReview, 'userAccepted', true) }
        ),
        ...(clinicProposalsToReview && clinicProposalsToReview.length &&
          { clinicInput: formatReviewInput(clinicProposalsToReview, 'clinicAccepted', true) }
        ),
      },
      clinic_manager: { input: formatReviewInput(clinicProposalsToReview, 'clinicAccepted') },
      substitute: { input: formatReviewInput(values.proposals, 'userAccepted') },
    };

    const reviewFromRole = {
      admin: () => {
        if (reviewParams.admin.usersInput && reviewParams.admin.clinicInput) {
          // Review users and clinics proposals
          adminReviewCompleteProposals(reviewParams.admin).then((data) => {
            if (
              data &&
              data.clinicReviewProposals.errors.length === 0 &&
              data.userReviewProposals.errors.length === 0
            ) {
              pushAlert({ type: 'success', content: 'alerts.proposalFormSubmitted' });
            }
          });
        } else if (reviewParams.admin.usersInput) {
          // Review users proposal only
          adminReviewUsersProposals(reviewParams.admin).then((data) => {
            if (data && data.userReviewProposals.errors.length === 0) {
              pushAlert({ type: 'success', content: 'alerts.proposalFormSubmitted' });
            }
          });
        } else if (reviewParams.admin.clinicInput) {
          // Review clinics proposal only
          adminReviewClinicsProposals(reviewParams.admin).then((data) => {
            if (data && data.clinicReviewProposals.errors.length === 0) {
              pushAlert({ type: 'success', content: 'alerts.proposalFormSubmitted' });
            }
          });
        }
      },
      clinic_manager: () => clinicReviewProposals(reviewParams.clinic_manager).then((data) => {
        if (data && data.clinicReviewProposals.errors.length === 0) {
          pushAlert({ type: 'success', content: 'alerts.proposalFormSubmitted' });
        }
      }),
      substitute: () => userReviewProposals(reviewParams.substitute).then((data) => {
        if (data && data.userReviewProposals.errors.length === 0) {
          pushAlert({ type: 'success', content: 'alerts.proposalFormSubmitted' });
        }
      }),
    };

    reviewFromRole[role]();
  }

  submitFormHandler(values) {
    const { acceptedProposals } = this.props;
    if (acceptedProposals) {
      this.confirmProposals(values);
    } else {
      this.submitReviews(values);
    }
  }

  render() {
    const {
      acceptedProposals,
      allMandates,
      change,
      error,
      handleSubmit,
      invalid,
      mandate,
      pristine,
      role,
    } = this.props;
    const proposals = mandate.proposals || [];
    const replacements = mandate.replacements || [];
    const submitForm = handleSubmit(this.submitFormHandler);
    const { t } = this.context;

    return (
      <div className="mandate-card__form">
        <form onSubmit={submitForm}>
          <CardTitle
            acceptedProposals={acceptedProposals}
            allMandates={allMandates}
            error={error}
            mandate={mandate}
            validForm={invalid || pristine}
            role={role}
          />
          {allMandates ? (
            <Replacements
              replacements={replacements}
              role={role}
            />
          ) : (
            <FieldArray
              name="proposals"
              acceptedProposals={acceptedProposals}
              allMandates={allMandates}
              proposals={proposals}
              component={ProposalsSubForm}
              role={role}
              change={change}
            />
          )}
          {((!acceptedProposals && !allMandates) || (role === 'admin' && acceptedProposals)) && (
            <div className="mandate-card__form__footer">
              <button
                className={classNames(
                  'mandate-card__submit',
                  { 'mandate-card__submit--disabled': invalid || pristine },
                )}
                type="submit"
                disabled={invalid || pristine}
              >
                {role === 'admin' ? t('form.formActions.confirm') : t('form.formActions.submit') }
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

function mapStateToProps(state, { allMandates, mandate }) {
  const { user: { role } } = state;
  const proposals = mandate.proposals || [];
  const userAccepted = ['admin', 'substitute'].includes(role);
  const clinicAccepted = ['admin', 'clinic_manager'].includes(role);
  const statuses = { confirmed: 'true', rejected: 'false', pending: null };
  return {
    role,
    form: `mandateProposals-${mandate.id}-${allMandates ? 'all' : 'proposals'}`,
    initialValues: {
      proposals: proposals.map(({ id, clinicStatus, userStatus }) => ({
        id,
        ...(userAccepted && { userAcceptedWas: statuses[userStatus] || null }),
        ...(clinicAccepted && { clinicAcceptedWas: statuses[clinicStatus] || null }),
        ...(userAccepted && { userAccepted: statuses[userStatus] }),
        ...(clinicAccepted && { clinicAccepted: statuses[clinicStatus] }),
      })),
    },
  };
}

MandateProposalsForm.propTypes = {
  acceptedProposals: PropTypes.bool.isRequired,
  adminReviewCompleteProposals: PropTypes.func.isRequired,
  adminReviewClinicsProposals: PropTypes.func.isRequired,
  adminReviewUsersProposals: PropTypes.func.isRequired,
  allMandates: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  clinicReviewProposals: PropTypes.func.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  pushAlert: PropTypes.func.isRequired,
  mandate: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  updateProposals: PropTypes.func.isRequired,
  userReviewProposals: PropTypes.func.isRequired,
};
MandateProposalsForm.defaultProps = {
  error: null,
};
MandateProposalsForm.contextTypes = {
  t: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { ...actions, ...alertsActions, ...mandatesActions, ...actionsSidePanel },
)(reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate,
})(MandateProposalsForm));
