import React from 'react';
import PropTypes from 'prop-types';
import ProposalRow from './ProposalRow';

function Replacements({ replacements, role }) {
  return (
    <ul className="mandate-card__form__list">
      {replacements.map((replacement) => (
        <ProposalRow
          key={replacement.id}
          allMandates
          proposal={{ replacement }}
          role={role}
          isNewSubstituteForMandate={replacement.latestProposal &&
            replacement.latestProposal.isNewSubstitute}
        />),
      )}
    </ul>
  );
}

Replacements.propTypes = {
  replacements: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
};

Replacements.contextTypes = {
  t: PropTypes.func,
};

export default Replacements;
