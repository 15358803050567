import moment from 'moment';

export function getRemainingHours(expiresAt) {
  const now = moment(new Date());
  expiresAt = moment(new Date(expiresAt));
  const remaining = moment.duration(expiresAt.diff(now));
  return remaining.asHours().toFixed(0);
}

export function getMinRemainingHours(proposals) {
  const remainingHours = proposals.map(proposal => getRemainingHours(proposal.expiresAt));
  return Math.min(...remainingHours);
}

export function formatZipCode(zipcode) {
  zipcode = zipcode.toUpperCase();
  const zipcodeLength = zipcode.length;
  return `${zipcode.substring(0, 3)}\u00A0${zipcode.substring(zipcodeLength - 3, zipcodeLength)}`;
}

export function getClinicAddress(address) {
  if (!address) { return null; }
  const streetAddress = address.address2 !== null ?
    `${address.address} ${address.address2}` :
    `${address.address}`;
  return (`
    ${streetAddress}, ${address.city}, ${address.province.toUpperCase()}, ${address.country.toUpperCase()}, ${formatZipCode(address.zipcode)}
  `);
}

export function getClinicProposalsList(clinicProposals, latestProposal) {
  const proposalsList = clinicProposals.length !== 0 ?
    clinicProposals : Array(latestProposal);

  return proposalsList.filter(proposal => (
    proposal !== null && proposal.substitute
  ));
}

export function getSubstituteProposalsList(substituteroposals, latestProposal) {
  const proposalsList = substituteroposals.length !== 0 ?
    substituteroposals : Array(latestProposal);

  return proposalsList.filter(proposal => (
    proposal !== null && proposal.clinic
  ));
}

