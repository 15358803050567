import { async, createTypes } from 'redux-action-creator';

export default createTypes([
  ...async('FETCH_NOTIFICATIONS'),
  ...async('UPDATE_READ_NOTIFICATIONS'),
  ...async('UPDATE_UNREAD_NOTIFICATIONS'),
  ...async('UPDATE_NOTIFICATION_STATUS'),
  'SUBSCRIBE_TO_NOTIFICATIONS',
  'SUBSCRIBE_TO_NOTIFICATIONS_INCOMING',
], 'NOTIFICATIONS');
