// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

// components
import User from './User';
import AddUser from './AddUser';
import SearchUser from './forms/SearchForm';

// actions
import actions from '../../../actions/admin/users';
import actionsSidePanel from '../../../actions/dynamicSidePanel/index';

class Users extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    updateComponent: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    search: PropTypes.string,
    after: PropTypes.string,
    hasNextPage: PropTypes.bool,
  };

  static contextTypes = {
    t: PropTypes.func,
  };

  static defaultProps = {
    search: '',
    after: '',
    hasNextPage: false,
  };

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
  }

  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }

  nextPage() {
    const { fetchUsers, after, search } = this.props;
    fetchUsers({ after, search });
  }

  fetchUsers() {
    const { fetchUsers, search } = this.props;
    fetchUsers({ search });
  }

  render() {
    const { users, hasNextPage, updateComponent } = this.props;
    const { t } = this.context;

    return (
      <div className="content-list">
        <div className="content-list__tools">
          <div className="content-list__tools__search">
            <SearchUser fetchUsers={this.fetchUsers} />
          </div>
          <button
            onClick={() => updateComponent(AddUser, 'addUser', { sidebarIcon: 'profile' })}
            className="schedule__toolbar__add-availability"
          >
            {t('users.add')}
          </button>
        </div>
        <div className="content-list__content">
          {users.map(user =>
            <User
              key={user.id}
              user={user}
              updateComponent={updateComponent}
            />,
          )}
        </div>
        {hasNextPage && (
          <button className="content-list__button form__submit" type="button" onClick={this.nextPage}>
            {t('form.formActions.loadMore')}
          </button>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector('searchUsers');

  return {
    users: state.users.results,
    after: state.users.pageInfo.endCursor,
    hasNextPage: state.users.pageInfo.hasNextPage,
    search: selector(state, 'search'),
  };
}

export default connect(mapStateToProps, { ...actions, ...actionsSidePanel })(Users);

